import React, {Component} from "react";
import  "./ProductItemLimit.less";
import { Link,withRouter  } from "react-router-dom";
import {connect} from 'react-redux';
import { Progress } from 'antd';
import tag from "../../images/ic_baoshui.png";
import { getDataFromServer } from '../../httpRequest/Api.js';
import loginAction from "../../actions/loginAction.js";
const {toggleLogin} = loginAction;

@connect(
  state => state.loginReducer,{toggleLogin}
)

class ProductItemLimit extends Component {
  render(){
    let {goodsInfo,nowStatus,timesId,updateList,toggleLogin} = this.props;
    let progress = 0;  //进度条
    let btnText = "";  //按钮文本
    let btnStyle = {};  //按钮样式
    if (goodsInfo.totalStock != 0) {
      if (goodsInfo.limitBuySold < goodsInfo.totalStock) {
        progress = parseInt((goodsInfo.limitBuySold + goodsInfo.virtualBuySold)
            * 100 / (goodsInfo.totalStock + goodsInfo.virtualTotalStock));
      } else {
        progress = 100;
      }
    }
    if (progress >= 100) {
      progress = 100;
    } else if (progress <= 0) {
      progress = 0;
    }
    if (nowStatus === "即将开抢") {
      if (goodsInfo.flag == 2) {
        btnText = "已设提醒";
        btnStyle = {background: "#E5E5E5",color: "#666666"};
      } else {
        btnText = "开抢提醒";
        btnStyle = {background: "#FF6C00",color: "#fff"};
      }
    }else{
      if (progress >= 100) {
        btnText = "已抢光";
        btnStyle = {background: "#E5E5E5",color: "#666666"};
      } else {
        btnText = "立即抢购";
        btnStyle = {background: "#E41135",color: "#fff"};
      }
    }

    let userId = this.props.userLoginInfo ? this.props.userLoginInfo.id : "";
    let history = this.props.history;
    async function btnClick(e){
      e.preventDefault();
      if (nowStatus === "即将开抢") {
        if (goodsInfo.flag == 2) {
          
        } else {
          if (userId) {
            let loginInfo = {
              url: `${baseUrl}/openremaindrecord/insert?userId=${userId}&timesId=${timesId}&goodsId=${goodsInfo.id}&appkey=${appkey}`,
              method: 'PUT'
            }
            let data = await getDataFromServer(loginInfo);
            if (data.data.result == 0) {
              updateList();
            }
          }else{
            toggleLogin();
          }
        }
      }else{
        if (progress >= 100) {

        } else {
          history.push(`/home/goodsdetail/${goodsInfo.id}`);
        }
      }
    }

    return (
      <Link to={`/home/goodsdetail/${goodsInfo.id}`} target="_blank">
        <div className="ProductItemLimit">
          {/*包税标签*/}
          {
            goodsInfo.dutyFreeFlag === 1 &&
            <img src={tag} alt="" className="tag"/>
          }
          <div className="Img">
            <img src={goodsInfo.imgpath} className="proImg" alt=""/>
          </div>
          <div className="proContent">
            <div className="price">
              <span className="symbol">￥</span>
              <span className="number">{goodsInfo.priceOverseaVAT}</span>
              {
                nowStatus !== "已开抢" && <span className="discount">{(goodsInfo.priceOverseaVAT * 10 / goodsInfo.originalPrice).toFixed(1)}折</span>
              }
              {
                nowStatus !== "已开抢" && <span className="oriPrice">原价 ￥{goodsInfo.originalPrice}</span>
              }
            </div>
            <div className="Title">{goodsInfo.title}</div>
            <div className="progress">
              <div className="progressNum">已售{progress}%</div>
              <Progress percent={progress} showInfo={false} strokeWidth={10} strokeColor="#E41135"/>
            </div>
            <div className="btn" onClick={btnClick} style={btnStyle}>{btnText}</div>
          </div>
        </div>
      </Link>
    )
  }
}

export default withRouter(ProductItemLimit);