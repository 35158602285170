import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Statistic } from 'antd';
import "./Limitbuy.less";
import {connect} from 'react-redux';
import ProductTem from "../ProductTem/ProductTem";
import LArrowImg from "../../images/ic_arrow_L_banyuan .png";
import RArrowImg from "../../images/ic_arrow_R_banyuan .png";

const { Countdown } = Statistic;

@connect(state => state.homeReducer)

class LimitBuy extends Component {
  constructor(props){
    super(props)
    this.state={
      //轮播的信息
      width: '10000px',
      transition: '500ms ease-out',
      transform: 'translatex(0px)',
      countnum: this.props.limitBuyData.limitGoods.length,     //图片数量
      curPageIndex: 0, //点击右箭头+1，左箭头减1
      viewcount: 5,    //初始展示图片数量（和div宽度相关） 
      Larrowstate: true,
      Rarrowstate: true
    }
  }

  //上一张
  handleclickPrev = () =>{
    let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state
    if(curPageIndex === countnum - viewcount){
      this.setState({
        Rarrowstate:!Rarrowstate
      })
    }
    this.getPage(curPageIndex-1)
  }

  //下一张
  handleclickNext = () =>{
    let { countnum,viewcount,curPageIndex,Larrowstate } = this.state
    if (curPageIndex < 0) {
        curPageIndex = 0
    }
    if(curPageIndex === 0){
        this.setState({
            Larrowstate:true
        })
    }
    this.getPage(curPageIndex + 1)
  }

  getPage(value) {
    let { countnum,viewcount,Larrowstate,Rarrowstate} = this.state
    let n = countnum - viewcount
    //一项的宽度
    let itemwidth = 198.5
    let transform = 'translatex(-' + value * itemwidth + 'px)'
    value > n || 0 > value || this.setState({transform:transform})
    this.setState({
        curPageIndex: value,
    })
    if (value == 0) {
        this.setState({
            Larrowstate: !Larrowstate
        })
    }
    if (value == n) {
      this.setState({
          Rarrowstate: !Rarrowstate
      })
    }
  }

  //解析场次状态
  limitBuyStatus = (startTime,endTime,nowTime = new Date().getTime()) =>{
    let status = "";
    let deadLine = 0;
    if (nowTime > startTime * 1 && nowTime < endTime * 1) {
      status = "抢购中";
      deadLine = endTime * 1;
    }else if (nowTime < startTime * 1) {
      status = "即将开抢";
      deadLine = startTime * 1;
    }else if (nowTime > endTime * 1) {
      status = "已开抢";
    }
    return status + "_" + deadLine;
  }
  render(){
    let { width ,transition ,transform ,Larrowstate ,Rarrowstate ,hours,minutes ,seconds ,inCountdown } = this.state;
    let limitProduct = this.props.limitBuyData ? this.props.limitBuyData.limitGoods : [];
    let templateId = this.props.limitBuyData ? this.props.limitBuyData.templateId : "";
    let LArrowClaName = Larrowstate ? 'arrow_l arrow' : 'hidden';
    let RArrowClaName = Rarrowstate ? 'arrow_r arrow' : 'hidden';
    let status = "0_0";
    if (this.props.limitBuyData) {
      status = this.limitBuyStatus(this.props.limitBuyData.limitBeginTime,this.props.limitBuyData.limitEndTime);
    }
    let nowStatusTime = status.split("_")[1] * 1;
    status = status.split("_")[0];
    return (
      <div className="limitBuy">
        <div className="inner">
          <div className="countDown">
            <p className="bigTitle">限时秒杀</p>
            <p className="subtitle">距离{status !== "即将开抢" ? "结束" : "开始"}仅剩</p>
            <div className="daojishiBox">
              <div className="maohao r">:</div>
              <div className="maohao l">:</div>
              <Countdown value={nowStatusTime ? nowStatusTime : 0} onFinish={this.onFinish} valueStyle={{background:"#000",color:"#fff",display:"inline-block",padding: "0 5px 0 5px",height: "32px"}} format={"HH ：mm   ：ss"}/>
            </div>
            <div className="clickAll"><Link to={`/home/limitbuy/${templateId}`} target="_blank">查看全部</Link></div>
          </div>
          <div className="limitProduct">
            <div className="productShow">
              <ul className="InnerContent" style={{ width: width, transition: transition, transform: transform }}>
                {limitProduct.map((item,i) =>{
                  return <li key={i}>
                  <ProductTem goodsInfo={item}/>
                 </li>
                })}
              </ul>
            </div>
            <div className={LArrowClaName}><img src={LArrowImg}  onClick={this.handleclickPrev} /></div>
            <div className={RArrowClaName}><img src={RArrowImg}  onClick={this.handleclickNext} /></div>
          </div>
        </div>
      </div>
    )
  }
}

export default LimitBuy;