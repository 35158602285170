import React , {Component} from 'react';
import './CancelOrderBox.less';
import { getDataFromServer } from '../../httpRequest/Api.js';
import { message } from 'antd';
const closeIcon = require("../../images/pop_usercenter_close_nor.png");
const Warning = require("../../images/ic_warning.png");

class CancelOrderBox extends Component{
  constructor(props){
    super(props);
    this.state=({
     
    });
  }
  //确定申请退货
  sure = () =>{
    this.applyForRefund(this.props.orderId);
  }
  //申请退款
  async applyForRefund(orderId){
    let loginInfo = {
      url:baseUrl + "/order/updatestatus?orderStatus=26&appkey=" + appkey + "&orderId=" + orderId,
      method: 'POST',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      message.success(<span className="msgText">{data.data.msg}</span>);
      this.CancelOrderBox.style.display = "none";
      this.props.refreshPage();
    }else{
      message.error(<span className="msgText">{data.data.msg}</span>);
    }
  }
  
  //关闭弹框
  closeBox = () =>{
    this.CancelOrderBox.style.display = "none";
  }
  render(){
    return(
      <div className="cancelOrderBox" ref={r => this.CancelOrderBox = r}>
        <div className="sureAndCancelInner">
          <div className="title">
            <span className="boxName">提示</span>
            <img src={closeIcon} alt="" className="closeBtn" onClick={this.closeBox}/>
          </div>
          <div className="section">
            <div className="alertText"><img src={Warning} alt=""/>是否取消该订单？</div>
            <div className="opBtn">
              <span className="sure" onClick={this.sure}>确定</span>
              <span className="cancel" onClick={this.closeBox}>取消</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CancelOrderBox;