import React,{Component} from "react";
import "./RealName.less";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import volume from "../../images/volume.png";
import uploadImg from "../../images/Image_upload_big.png";
import headHKDApp from "../../images/head_hkd_app.png";
import RealNamePopView from "../../components/RealNamePopView/RealNamePopView.js";
import { Upload, Icon, message } from 'antd';
import DeleteAddressPopView from "../DeleteAddressPopView/DeleteAddressPopView.js";

@connect(
  state => state.loginReducer
)
class RealName extends Component{
  constructor(props){
    super(props);
    this.state={
      idcardList:[],
      realName:"",//姓名
      idNum:"",//身份证号
      img1:"",//身份证正面
      img2:"",//反面
      isEdit:false,//是否是编辑
      isDelete:false,//是否正在删除
      index:-1,
      qiniuToken:"",
    }
  }
  componentDidMount(){
    this.loadRealName();
    this.qiniuToken();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }

  async loadRealName(){
    let userInfo = this.props.userLoginInfo;
    if (userInfo) {
      let userId = userInfo.id;
      let reqUrl =  `${baseUrl}/user/auth?appTab=2&appkey=${appkey}&userId=${userId}`;
      let method = "GET";
      let realname = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(realname);
      this.setState({
        idcardList:response.data.idcardList,
      });
    }
  }
  async addRealName(){
    let userInfo = this.props.userLoginInfo;
    if (userInfo) {
      let userId = userInfo.id;
      let {realName,idNum,img1,img2} = this.state;
      let reqUrl =  `${baseUrl}/user/auth?appTab=2&appkey=${appkey}&userId=${userId}&realname=${realName}&idcard=${idNum}&img1=${img1}&img2=${img2}`;
      let method = "PUT";
      let addRealName = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(addRealName);
      this.loadRealName();
      if (response.data.result == 0) {
        message.success(<span className="msgText">新增成功</span>);
        this.setState({
          realName:"",//姓名
          idNum:"",//身份证号
        });
        this.setDefaultRealName(response.data.userIDcard.id);
      }else{
        message.error(<span className="msgText">新增失败，身份校验不成功</span>);
      }
    }
  }
  handleNameOnchange = (e) =>{
    this.setState({
      realName:e.target.value
    })
  }
  handleIDCardOnCahnge = (e) =>{
    this.setState({
      idNum:e.target.value
    })
  }
  submitAction = () =>{
    let {realName,idNum} = this.state;
    if(realName == null || realName == ""){
      // console.log("请输入正确的名字")
    }else if (idNum == null || idNum ==""){
      // console.log("请输入正确的身份证号")
    }else{
      // console.log("上传身份证信息");
      this.addRealName();
    }
  }
  //图片上传token生成
 async qiniuToken(){
  let loginInfo = {
    url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      qiniuToken:data.data.uploadToken,
    })
  }
}
  getBase64 = (img, callback) =>{
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

   beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  handleimgChange1 = info => {
    if (info.file.status === 'uploading') {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let hash = info.file.response.hash;
      let imageUrl = "http://img.maigang360.com/" + hash;
      this.setState({
          img1:imageUrl,
        });
    }
  };
  handleimgChange2 = info => {
    if (info.file.status === 'uploading') {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let hash = info.file.response.hash;
      let imageUrl = "http://img.maigang360.com/" + hash;
      this.setState({
          img2:imageUrl,
        });
    }
  };
//编辑
  editAction = (index) =>{
    this.setState({
      index:index,
      isEdit:true,
    });
  }
  //删除
  deleteAction = (index) =>{
    this.setState({
      index:index,
      isDelete:true,
    });
  }
  hiddenDeleteView = (isDelete) =>{
    if(isDelete){
      this.delectRealName();
    }
    this.setState({
      isDelete:false,
    });
  }
  async delectRealName(){
    let userInfo = this.props.userLoginInfo;
    if (userInfo) {
      let userId = userInfo.id;
      let {idcardList,index} = this.state;
      let id = idcardList[index].id;
      let reqUrl =  `${baseUrl}/user/auth?appTab=2&appkey=${appkey}&userId=${userId}&id=${id}`;
      let method = "DELETE";
      let deleteRealName = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(deleteRealName);
      this.loadRealName();
    }
  }
  async setDefaultRealName(idCardID){
    let userInfo = this.props.userLoginInfo;
    if (userInfo) {
      let userId = userInfo.id;
      let reqUrl =  `${baseUrl}/user/auth?appTab=2&appkey=${appkey}&userId=${userId}&id=${idCardID}`;
      let method = "POST";
      let setDefaultRealName = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(setDefaultRealName);
      this.loadRealName();
    }
  }
  hiddeAddpopView = (isSubmit) =>{
    this.setState({
      isEdit:false,
    });
    this.loadRealName();
  }
  setDefault = (index) =>{
    let {idcardList} = this.state;
    let id = idcardList[index].id;
    this.setDefaultRealName(id);
  }
  render(){
    let {idcardList,img1,img2,isEdit,isDelete,index,realName,idNum} = this.state;
    return(
      <div className="real_name_bg">
        <div className="real_name_top_title">实名认证</div>
        <div className="tip_bg">
          <img className="tip_img" src={volume}/>
          <div className="tip_info">
            <div>根据海关规定，购买跨境商品需要办理清关手续，请您配合进行实名认证，以确保您购买的商品顺利通过海关检查。身份证明只用于办理跨境商品的清关手续，不作他途使用，其他任何人均无法查看。</div>
            <div>购买跨境商品需填写注册人的真实姓名及身份证号码，部分商品下单时需提供收货人的实名信息（含身份证照片），具体请以下单时的提示为准。</div>
          </div>
        </div>
        {/*实名列表*/}
        {(idcardList && idcardList.length > 0) && <div className="real_name_list_bg">
          <div className="real_name_title">已认证的实名信息</div>
          <div className="list_content_bg">
            <div className="list_title_bg">
              <div className="colum_title margin-12">姓名</div>
              <div className="colum_title margin-12">身份证号</div>
              <div className="colum_title margin-12">身份证照片</div>
              <div className="colum_title margin-12">操作</div>
            </div>
            {
              idcardList.map((idInfo,index)=>{
                return(
                  <div className="list_conten_bg" key={index}>
                    <div className="top_line"></div>
                    <div className="colum_title margin-20">{idInfo.realname}</div>
                    <div className="colum_title margin-20">{idInfo.idcard}</div>
                    <div className="colum_title margin-20">{(idInfo.img1 && idInfo.img1 != "") ? "已上传（加密）" : "未上传"}</div>
                    <div className="colum_title margin-20">
                      <div className="update_btn" onClick={this.editAction.bind(this,index)}>编辑</div>
                      <div className="mid_line"></div>
                      <div className="update_btn" onClick={this.deleteAction.bind(this,index)}>删除</div>
                    </div>
                    <div className={idInfo.isDefault == 1 ? "default_btn_true":"default_btn_false"} onClick={this.setDefault.bind(this,index)}>{idInfo.isDefault == 1 ? "默认" : "设为默认"}</div>
                  </div>
                )
              })
            }
          </div>
        </div>}

        <div className="new_real_bg">
          <div className="real_name_title">新增实名信息</div>
          <div className="info_bg">
            <div className="left_title">真实姓名：</div>
            <div className="left_star">*</div>
            <input className="user_name" value={realName} placeholder="请输入您的真实姓名" onChange={this.handleNameOnchange}/>
          </div>
          <div className="info_bg">
            <div className="left_title">身份证号：</div>
            <div className="left_star">*</div>
            <input className="user_name" value={idNum} placeholder="请输入姓名对应的身份证" onChange={this.handleIDCardOnCahnge}/>
          </div>
          <div className="photo_bg">
            <div className="left_title">身份证照片：</div>
            <div className="updload_bg">
              <div className="item_bg">
                <div className="upload_img_bg">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleimgChange1}
                  >
                    <img src={img1 ? img1 : uploadImg } className="upload_img"/>
                  </Upload>
                </div>
                {/* <img className="upload_img" src={uploadImg}/> */}
                <div className="upload_title">带人像面</div>
              </div>
              <div className="item_bg">
                <div className="upload_img_bg">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleimgChange2}
                  >
                    <img src={img2 ? img2 : uploadImg } className="upload_img"/>
                  </Upload>
                </div>
                {/* <img className="upload_img" src={uploadImg}/> */}
                <div className="upload_title">国旗像面</div>
              </div>
              <div className="item_bg">
                <img className="qrcode_img" src={headHKDApp}/>
                <div className="qr_info">上传照太麻烦？</div>
                <div className="qr_info">下载港颜APP，实名认证超方便</div>
              </div>
            </div>
            <div className="bottom_tip_bg">
              <div className="tip_info">注：</div>
              <div className="tip_info">1. 需上传清晰的身份证正面、反面共2张照片</div>
              <div className="tip_info">2. 照片不超过5MB，支持格式jpg，jpeg，png</div>
              <div className="submit_btn" onClick={this.submitAction}>提交</div>
            </div>
          </div>
        </div>
        {isEdit && <RealNamePopView realInfo={idcardList[index]} hiddeAddpopView={this.hiddeAddpopView} loadRealName={this.loadRealName}/>}
        {isDelete && <DeleteAddressPopView tip="是否删除该实名认证?" hiddenDeleteView={this.hiddenDeleteView}/>}
      </div>
    );
  }
}

export default RealName;
