import React , {Component} from 'react';
import './ListNone.less';
import WARN from "../../images/warning.png";

class ListNone extends Component{
  constructor(props){
    super(props);
    this.state=({
      list:[],
      text:""
    });
  }
  componentDidMount(){
    
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      list:nextProps.list,
      text:nextProps.text
    });
  }

  //返回上一步
  goBack = () =>{
    console.log(111);
    console.log(window.history.back);
    
    window.history.back(-1);
  }
  render(){
    var show = {"display":"block"};
    var hide = {"display":"none"};
    const { list, text } = this.props;
    return(
      <div className="noneList" style={list.length?hide:show} ref={r => this.listNone = r}>
        <div className="notice">
          <img className="tanhao" src={WARN} alt=""/>
          <div className="noticeText">抱歉，没有找到与 <span>"{text}"</span> 相关的商品</div>
        </div>
        <div className="advise">
          <span>建议您：</span>
          <span>1.尝试其他关键字</span>
          <span>2.调整价格区间</span>
          <span>3.减少筛选条件</span>
        </div>
        <div className="backBtn" onClick={this.goBack}>
          返回上一步
        </div>
      </div>
    );
  }
}

export default ListNone;