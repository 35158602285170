import React,{ Component } from 'react';
import {connect} from 'react-redux';
import { Statistic } from 'antd';
import  "./ProductTem.less";
import { Link } from "react-router-dom";

@connect(state => state.homeReducer)

class ProductTem extends Component{
  render(){
    const {goodsInfo,systemData} = this.props;
    let stock = goodsInfo.virtualFlag === 1 ? goodsInfo.virtualStock : goodsInfo.warestock1;
    let originalPriceText = "";  //原价文本
    let originalPrice = 0;  //原价
    if (systemData) {
      if (systemData.copywritingEnable !== 1) {
        originalPriceText = systemData.copywritingText;
      }
    }
    if (goodsInfo.limitBuyFlag === 1) {
      originalPrice = goodsInfo.originalPrice;
      if(goodsInfo.limitStatus === 2){
        originalPriceText = "抢购价";
      }else if(goodsInfo.limitStatus === 1){
        originalPriceText = "原价";
      }
    }else{
      originalPrice = goodsInfo.b2cOriginalPrice;
    }
    return (
      <Link to={`/home/goodsdetail/${goodsInfo.id}`} target="_blank">
        <div className="ProductTem">
          <div className="Img">
            <div className="label"><img src={goodsInfo.imgpath} alt=""/></div>
            {
              goodsInfo.goodsLabels && goodsInfo.goodsLabels.map((item,index) =>{
                let zindexStyle = {zIndex:"-" + index};
                return <div key={index} className="label" style={zindexStyle}><img src={item.labelImg} alt=""/><span className="labelPrice" style={item.labelType === 1?{display:"none"}:{}}><Statistic prefix="￥" value={item.priceB2COverseaVATTheir}  precision={1} groupSeparator="" /></span></div>
              })
            }
          </div>
          <div className="proContent">
            <h2 className="Title">{goodsInfo.title}</h2>
            <div className="price">
              <span className="symbol">￥</span><span className="number">{goodsInfo.priceB2COverseaVAT ? goodsInfo.priceB2COverseaVAT : goodsInfo.priceOverseaVAT}</span>
              {
                originalPrice > 0 &&
                <span className="discount">{ goodsInfo.priceB2COverseaVAT ? (goodsInfo.priceB2COverseaVAT * 10 / originalPrice).toFixed(1) : (goodsInfo.priceOverseaVAT * 10 / originalPrice).toFixed(1) }折</span>
              }
            </div>
            {
              originalPrice > 0 ?
              <div className="special_price">{originalPriceText} ￥{originalPrice}</div> : <div className="special_price"></div>
            }
          </div>
        </div>
      </Link>
    )
  }
}

export default ProductTem;