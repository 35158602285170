import React,{ Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import "./TemplateA.less";
import TemplateItem from "../../components/TemplateItem/TemplateItem";

const TemplateA = ({templateAInfo}) =>{
  let templateDetails = templateAInfo.templateDetailsModels;
  return (
    <div className="templateA">
      <div className="inner">
        <div className="recTitle">{templateAInfo.title}</div>
        <div className="recClassify">
          <div className="leftArea">
            <TemplateItem itemInfo={templateDetails[0]}/>
            <TemplateItem itemInfo={templateDetails[1]}/>
          </div>
          <div className="rightArea">
            <TemplateItem itemInfo={templateDetails[2]}/>
            <TemplateItem itemInfo={templateDetails[3]}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateA;