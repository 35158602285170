import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import homeAction from "./actions/homeAction";
import loginAction from "./actions/loginAction.js";
import Home from "./pages/Home/Home";
import ModelGoods from "./pages/ModelGoods/ModelGoods";
import RankCenter from "./pages/RankCenter/RankCenter";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import GoodsDetail from "./pages/GoodsDetail/GoodsDetail";
import LoginThirdBind from "./pages/LoginThirdBind/LoginThirdBind";
import HelpCenter from "./pages/HelpCenter/HelpCenter.js";
import UserCenter from "./pages/UserCenter/UserCenter.js";
import CosumesInterests from "./pages/CosumesInterests/CosumesInterests.js"
import FeedBack from "./pages/FeedBack/FeedBack.js"
import LimitBuyDetail from "./pages/LimitBuyDetail/LimitBuyDetail.js";
import SearchPage from "./pages/SearchPage/SearchPage.js";
import Buycart from "./pages/Buycart/Buycart.js";
import SubmitOrder from "./pages/SubmitOrder/SubmitOrder.js";
import OrderDetail from "./pages/OrderDetail/OrderDetail.js";
import RefundOrderDetail from "./pages/RefundOrderDetail/RefundOrderDetail.js";
import ApplyForServe from "./pages/ApplyForServe/ApplyForServe.js";
import Review from "./pages/Review/Review";
import PayCenter from "./pages/PayCenter/PayCenter";
import PayZFB from "./pages/PayZFB/PayZFB";
import PayFinish from "./pages/PayFinish/PayFinish";

import Header from "./components/HeadView/Header";
import Search from "./components/HomeNavigation/HomeNavigation";
import Footer from "./components/FooterView/Footer";
import NavBar from "./components/NavBar/NavBar";
import MoreOfficialNews from "./components/MoreOfficialNews/MoreOfficialNews.js";
import PopGift from "./components/NewUserCouponPopView/NewUserCouponPopView";
import LoginPage from "./components/LoginPage/LoginPage.js";
import { getDataFromServer } from './httpRequest/Api.js';
import Cookies from 'js-cookie';

const { bannerInfo, setSystemData } = homeAction;
const {userLoginInfo} = loginAction;

@connect(state => state.homeReducer,
  { bannerInfo, setSystemData } 
)

@connect(
  ({loginReducer,homeReducer}) => ({
    userLoginInfo: loginReducer.userLoginInfo,
    bannerInfo:homeReducer.bannerInfo,
    setSystemData: homeReducer.systemData
  }),{bannerInfo,setSystemData}
)

class App extends Component {
  constructor(props){
    super(props)
    this.getBannerInfo = this.getBannerInfo.bind(this);
    this.getSystemSet = this.getSystemSet.bind(this);
  }

  componentDidMount(){
    this.getBannerInfo();
    let feedback = this.GetQueryString("feedback");
    
    if(feedback){
      Cookies.set('feedback', feedback, { expires: 1, path: '/',Domain: window.location.hostname });
    }
  }

  GetQueryString = (name) =>{
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if(r!=null) return escape(r[2]); 
    return null;
  }
  
  //获取banner,首页导航,限时抢购信息
  async getBannerInfo (){
    let loginInfo = {
      url: baseUrl + "/pchome/firstpart?appkey="+appkey,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    this.props.bannerInfo(data.data.banners,data.data.homeLimitTemp,data.data.homeNavigation,data.data.goodsOn);
    this.getSystemSet();
  }

  //获取全局配置信息
  async getSystemSet (){
    let systemInfo = {
      url: baseUrl + "/systemset/detail?appkey="+appkey,
      method: 'GET'
    }
    let data = await getDataFromServer(systemInfo);
    this.props.setSystemData(data.data.systemSet);
  }
  render () {
    return (
      <Router>
        <div>
          <Route path="/home" component={Header} />
          <Route path="/home" component={Search} />
          <Route path="/home" component={NavBar} />
          <Switch>
            <Route exact path="/home" component={Home} />
            <Route exact path="/home/goodsdetail/:id" component={GoodsDetail} />
            <Route exact path="/home/limitbuy/:id" component={LimitBuyDetail} />
            <Route exact path="/home/modelgoods/:id" component={ModelGoods} />
            <Route exact path="/home/rankcenter/:type" component={RankCenter} />
            <Route exact path="/home/moreofficialnews" component={MoreOfficialNews} />
            <Route exact path="/home/helpcenter/:type" component={HelpCenter}/>
            <Route exact path="/home/usercenter/:type" component={UserCenter}/>
            <Route exact path="/home/cosumesInterests" component={CosumesInterests}/>
            <Route exact path="/home/feedback" component={FeedBack}/>
            <Route exact path="/home/search/:name" component={SearchPage}/>
            <Route exact path="/home/buycart" component={Buycart}/>
            <Route exact path="/home/submitorder" component={SubmitOrder}/>
            <Route exact path="/home/orderDetail/:orderId" component={OrderDetail} />
            <Route exact path="/home/refundorderDetail/:orderId" component={RefundOrderDetail} />
            <Route exact path="/home/applyforserve/:param" component={ApplyForServe} />
            <Route exact path="/home/review/:orderId" component={Review} />
            <Route exact path="/home/paycenter/:orderId" component={PayCenter} />
            <Route exact path="/home/payzfb" component={PayZFB} />
            <Route exact path="/home/payfinish" component={PayFinish} />
            <Route path="/login" component={Login} />
            <Redirect to="/home" />
          </Switch>
          <Route path="/home" component={Footer} />
          <Route path="/home" component={PopGift} />
          <Route path="/home" component={LoginPage} />
          <Route path="/login/reset" component={ResetPassword} />
          <Route path="/login/bindthird" component={LoginThirdBind} />
        </div>
      </Router>
    );
  }
}
export default App

