import React, {Component} from "react";
import {connect} from 'react-redux';
import  "./CouponItemSmall.less";
import yilingqu from "../../images/tag_yilingqu.png";
import { message } from 'antd';
import { getDataFromServer } from '../../httpRequest/Api.js';
import loginAction from "../../actions/loginAction.js";

const {toggleLogin} = loginAction;

@connect(
  state => state.loginReducer,{toggleLogin}
)

class CouponItemSmall extends Component{
  render(){
    let {couponInfo,updateCoupon,userLoginInfo,toggleLogin} = this.props;
    let btnText = "";
    let btnStyle = null;
    let bg_color = "";
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1,
    });
    switch (couponInfo.type) {
      case 0:
        //全场通用
        bg_color = "#FA5471";
        break;
      case 1:
        //品牌专用
        bg_color = "#6CBDF6";
        break;
      case 2:
        //分类专用
        bg_color = "#A2A1EC";
        break;
      case 3:
        //单品专用
        bg_color = "#FEB331";
        break;
    }

    if (couponInfo.flag == 0) {
      btnText = "立即领取";
      btnStyle = {
        height: "80px",
        color: "#fff"
      }
    }else{
      btnText = "已领取";
      btnStyle = {
        height: "60px",
        color: "#999"
      }
      bg_color = "#CCCCCC";
    }

    async function getCoupon(){
      if (userLoginInfo) {
        if (couponInfo.flag == 0) {
          let loginInfo = {
            url: `${baseUrl}/user/coupon/${couponInfo.couponId}?userId=${userLoginInfo ? userLoginInfo.id : ""}&appkey=${appkey}`,
            method: 'PUT'
          }
          let data = await getDataFromServer(loginInfo);
          if (data.data.result == 0) {
            if (data.data.restTimes > 0) {
              message.success(<span className="msgText">领取成功，该优惠券您还可以领取<span className="resetTimes">{data.data.restTimes}</span>次</span>);
            }else{
              message.success(<span className="msgText">领取成功</span>);
              updateCoupon("coupon");
            }
          }else{
            message.error(<span className="msgText">领取失败</span>);
          }
        }else{
          message.error(<span className="msgText">您已领取该优惠券</span>);
        }
      }else{
        toggleLogin();
      } 
    }
    
    return (
      <div className="CouponItemSmall">
        {couponInfo.flag !== 0 && <img src={yilingqu} className="yilingqu" alt=""/>}
        <div className="couponLeft">
          <span className="symbol">￥</span>
          <span className="money">{couponInfo.money}</span><br/>
          <span className="tiaojian">{couponInfo.condition <= 0 ? "无门槛使用" : `满${couponInfo.condition}元可用`}</span><br/>
          <div className="couponTitle">{couponInfo.description}</div>
          <div className="couponTime">领取截止：{couponInfo.invalidTime.split(" ")[0]}</div>
        </div>
        <div className="couponRight" onClick={getCoupon} style={{backgroundColor:bg_color}}>
          <div className="getNow" style={btnStyle}>{btnText}</div>
        </div>
      </div>
    )
  }
}


export default CouponItemSmall;

