const TemplateLinktype = (itemInfo,type) =>{
	let path = "";
  if (itemInfo) {
    switch (itemInfo.linkType) {
      case 0:
        path = `/home`;  //无
        break;
      case 1:
        path = `/home/modelgoods/${type}_${itemInfo.id}`;  //大图展示
        break;
      case 2:
        path = `/home/goodsdetail/${itemInfo.goodsId}`;  //单个商品详情
        break;
      case 3:
        path = `/home/modelgoods/${type}_${itemInfo.id}`;  //多个商品
        break;
      case 4:
        path = `/home/modelgoods/${type}_${itemInfo.id}`;  //H5链接
        break;
      case 5:
        path = `/home/rankcenter/coupon`;   //领券中心
        break;
      case 6:
        path = `/home/rankcenter/sales`;    //销量排行
        break;
      case 7:
        path = `/home/rankcenter/favorites`;   //收藏排行
        break;
      case 8:
        path = `/home/feedback`;   //客服中心
        break;
      case 9:
        // path = `/home/modelgoods/${itemInfo.id}`;    //品牌搜索
        break;
    }
  }
  return path;
}

export default TemplateLinktype;