import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import md5 from 'md5';
import InputItem from "../../components/InputItem/InputItem.js";
import TimeLine from "../../components/TimeLine/TimeLine.js";
import "./ResetPassword.less";
import { getDataFromServer } from '../../httpRequest/Api.js';
import numberImg from "../../images/ic_number.png";
import yanzhengma from "../../images/ic_yanzhegnma.png";
import password from "../../images/ic_code.png";
import errorIcon from "../../images/ic_error.png";

class ResetPassword extends Component {
  constructor(props){
    super(props);
    this.state=({
      errText:"",
      errType:"",
      step: 1,
      reSetPhone:"",
      reSetCode:"",
      pwd1:"",
      pwd2:""
    });
    this.nextStep = this.nextStep.bind(this);
  }

  //错误提示
  errorTip = (errText,errType) =>{
    this.setState({
      errText,
      errType
    })
  }

  //获取输入框的值
  getValue = (value) => {
    this.setState({
      errText: "",
      errType: "",
      [value.keyword]:value.value
    })
  }

  //下一步点击
  async nextStep(){
    let {reSetPhone,reSetCode,step,pwd1,pwd2} = this.state;
    let reqUrl = "";
    let method = "";
    let loginInfo = null;
    let response = null;
    if (step === 1) {
      if (reSetPhone.length < 11) {
        this.errorTip(reSetPhone ? "请输入正确的手机号" : "请输入手机号码" ,"reSetPhone");
        return;
      }
      if (reSetCode.length < 4) {
        this.errorTip(reSetCode ? "请输入正确的验证码" : "请输入验证码","reSetCode");
        return;
      }
      reqUrl = `${baseUrl}/webUser/VerifyPhoneAndCode?phoneNum=${reSetPhone}&code=${reSetCode}&appkey=${appkey}`;
      method = "POST";
      loginInfo = {
        url: reqUrl,
        method
      }
      response = await getDataFromServer(loginInfo);
      if (response.data.result === 0) {
        this.setState({
          step:2
        })
      }else{
        this.errorTip(response.data.msg,"reSetCode");
      }
    }else if(step === 2){
      if (pwd1.length < 6) {
        this.errorTip(pwd1 ? "请输入6-16位密码" : "请输入密码","pwd1");
        return;
      }
      if (pwd2.length < 6) {
        this.errorTip(pwd2 ? "请输入6-16位密码" : "请输入密码","pwd2");
        return;
      }
      if (pwd1 !== pwd2) {
        this.errorTip("两次密码输入不一致请重新输入","pwd2");
        return;
      }
      reqUrl = `${baseUrl}/webUser/findBackPassWord?phoneNum=${reSetPhone}&newPassword=${md5(pwd2).toUpperCase()}&appkey=${appkey}`;
      method = "POST";
      loginInfo = {
        url: reqUrl,
        method
      }
      response = await getDataFromServer(loginInfo);
      if (response.data.result === 0) {
        this.setState({
          step:3
        })
      }else{
        this.errorTip(response.data.msg,"reSetCode");
      }
    }else{
      this.props.history.push('/login');
    }
  }
  
  render() {
    const inputStyle={width:"360px",margin: "13px auto"};
    return (
      <div className="ResetPassword">
        <div className="inner">
          <div className="pageTitle">找回密码</div>
          <div className="resetLine">
            <TimeLine
              titles={["验证手机号","设置新密码","找回成功"]} 
              nowTitle={ this.state.step } 
            />
          </div>
          {/*第一步*/}
          {
            this.state.step === 1 && 
            <div className="inputBox">
              <div className="item">
                <InputItem isErr={this.state.errType === "reSetPhone"} keyword="reSetPhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
                {
                  this.state.errType === "reSetPhone" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
                }
              </div>
              <br/>
              <div className="item">
                <InputItem isErr={this.state.errType === "reSetCode"} codeType="resetpw" errorTip={this.errorTip} style={inputStyle} keyword="reSetCode" type="text" maxLength="6" icon={yanzhengma} holderText="请输入验证码" phone={{reSetPhone:this.state.reSetPhone}} isCode="true" getValue={this.getValue}/>
                {
                  this.state.errType === "reSetCode" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
                }
              </div>
            </div>
          }
          {/*第二步*/}
          {
            this.state.step === 2 && 
            <div className="inputBox">
              <div className="item">
                <InputItem isErr={this.state.errType === "pwd1"} style={inputStyle} keyword="pwd1" type="password" maxLength="16" icon={password} holderText="请设置6-16位字母或数字新密码" needPrewview="true" getValue={this.getValue}/>
                {
                  this.state.errType === "pwd1" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
                }
              </div>
              <br/>
              <div className="item">
                <InputItem isErr={this.state.errType === "pwd2"} style={inputStyle} keyword="pwd2" type="password" maxLength="16" icon={password} holderText="请再次确认密码" needPrewview="true" getValue={this.getValue}/>
                {
                  this.state.errType === "pwd2" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
                }
              </div>
            </div>
          }
          {/*第三步*/}
          {
            this.state.step === 3 && 
            <div className="inputBox">
                
            </div>
          }
          <div className="btn" onClick={this.nextStep}>{this.state.step === 3 ? "立即登录" : "下一步"}</div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;