import React,{CommeComponentnt} from "react";
import "./GetCouponPopView.less"
import getCouponImg from "../../images/pop_bg_coupontitle.png"

class GetCouponPopView extends React.Component{

	CouponItem = (coupon,index) =>{
		return(
			<div className="get_coupon_item" key={index}>
				<div className="get_coupon_item_price_content">
					<div className="price_tag">￥</div>
					<div className="get_coupon_price">{coupon.price}</div>
				</div>
				<div className="get_coupon_description">{coupon.decription}</div>
			</div>
		);
	}
		render(){
			let couponList = [{price:50,decription:"满250可用"},
												{price:199,decription:"无条件使用"},
												{price:50,decription:"满230可用"},
												{price:10,decription:"全场可用"},
												{price:50,decription:"满250可用"},
												{price:50,decription:"满250可用"}];

        return(
            <div className="get_coupon_pop_back">
							<div className="get_coupon_content_back">
								<div className="get_coupon_content_red_bg">
									<div className="get_coupon_top_title">优惠券可在个人中心优惠券列表中查看</div>
									<div className="get_coupon_content_scroll">
										{
											couponList.map((coupon,index) =>{
												return this.CouponItem(coupon,index)
											})
										}
									</div>
									<div className="know_btn">知道了</div>
								</div>
								<img className="get_coupon_top_img" src={getCouponImg}/>
							</div>
            </div>
        );
    }
}

export default GetCouponPopView;