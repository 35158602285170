import React, { Component } from 'react';
import { Link,Redirect } from "react-router-dom";
import "./OrderDetail.less";
import {connect} from 'react-redux';
import { Tooltip,message,Statistic } from 'antd';
import { getDataFromServer } from '../../httpRequest/Api.js';
import loginAction from "../../actions/loginAction.js";
import Sidebar from "../../components/Sidebar/Sidebar";
import DeleteAddressPopView from "../../components/DeleteAddressPopView/DeleteAddressPopView.js";
import ProgressBar from "../../components/ProgressBar/ProgressBar.js";
import ApplyForRefundBox from "../../components/ApplyForRefundBox/ApplyForRefundBox.js";

const closeIcon = require("../../images/pop_usercenter_close_nor.png");
import HONGBAO from "../../images/ic_hongbao.png";
import ERWEIMA from '../../images/hkd-erweima.png';
import CANCEL from "../../images/ic_warning_big.png";
import TIME from "../../images/time_big.png";

const { Countdown } = Statistic;
const {toggleLogin} = loginAction;

@connect(
  ({loginReducer,homeReducer}) => ({
    userLoginInfo: loginReducer.userLoginInfo,
    systemData: homeReducer.systemData
  }),{toggleLogin}
)

class OrderDetail extends Component{
  constructor(props){
    super(props)
    this.state = {
      orderDetail:{},//订单详情
      orderLogistics:{},//订单状态详情(虚拟物流)
      ascorderLogistics:{},//原始物流顺序
      statusNum:5,//状态个数
      saleAfterType:1,
      orderId:"",
      goodinfoId:"",
      title:"",
      image:"",
      quantity:"",
      overSeaRefundSwitch:0,//直邮退款开关
      overSeaAfterSaleSwitch: 0,//直邮售后开关
    }
    this.getOrderDetail = this.getOrderDetail.bind(this);
  }
  componentDidMount(){
    if(this.props.match.params.orderId){
      this.getOrderDetail(this.props.match.params.orderId)
    }
    this.getRefundReason();
    message.config({
      top: 2000,
      duration: 1,
      maxCount: 1
    });
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  //再次购买
  buyAgain = () =>{
    this.postBuycart(this.props.match.params.orderId);
  }

  //申请退款后刷新列表
  refreshOrderDetail = (overSeaRefundSwitch) =>{
    this.getOrderDetail(this.props.match.params.orderId);
    this.setState({
      overSeaRefundSwitch
    })
  }

  //申请退款
  applyForRefund = () =>{
    this.sureOp.ApplyForRefundBox.style.display = "block";
  }

  //打开申请售后
  applyForAfterSales = (orderId,goodinfoId,title,image,quantity) =>{
    this.applyForAfterSalesBox.style.display = "block";
    this.setState({
      orderId:orderId,
      goodinfoId:goodinfoId,
      title:title,
      image:image,
      quantity:quantity
    })
  }

  //写评论
  writeReview = (goods,orderId) =>{
    this.props.history.push({pathname:"/home/review/" + orderId});
    let obj = JSON.stringify(goods);
    localStorage.setItem("goods",obj);
  }

  //分享红包
  sharePacket = () =>{
    this.setState({
      isDelete:true,
    });
  }

  //确认分享红包弹框
  hiddenDeleteView = (isDelete) =>{
    if(isDelete){
    }
    this.setState({
      isDelete:false,
    });
  }

  //确定选择的申请售后类型
  sure = () =>{
    let { orderId,goodinfoId,saleAfterType,title,image,quantity } = this.state;
    this.props.history.push({pathname:"/home/applyforserve/" + orderId})
    let obj = {
      orderId:orderId,
      title:title,
      image:image,
      quantity:quantity,
      orderId:orderId,
      goodinfoId:goodinfoId,
      saleAfterType:saleAfterType
    }
    let afterGoodsInfo = JSON.stringify(obj);
    localStorage.setItem("afterGoodsInfo",afterGoodsInfo)
  }

  //关闭申请售后弹框
  closeBox = () =>{
    this.applyForAfterSalesBox.style.display = "none";
  }

  //售后类型改变
  typeChange = (event) =>{
    this.setState({
      saleAfterType:event.target.value
    })
  }

  //去支付
  goPay = (id) =>{
    this.props.history.push("/home/paycenter/" + id);
  }

  //获取订单详情
 async getOrderDetail(orderId){
  let loginInfo = {
    url:baseUrl + "/order/overseadetail?appkey=" + appkey + "&orderId=" + orderId + "&portType=2" ,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    let Data = JSON.parse(JSON.stringify(data.data.orderLogistics));
    this.setState({
      orderDetail:data.data.order,
      ascorderLogistics:Data,
      orderLogistics:data.data.orderLogistics.reverse(),
    })
  }
 }

 //获取退款理由
 async getRefundReason(){
  let loginInfo = {
    url:baseUrl + "/refund/querybyapptab?appkey=" + appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      overSeaReason:data.data.data.overSeaReason,
      overSeaRefundSwitch:data.data.data.overSeaRefundSwitch,
      overSeaAfterSaleSwitch:data.data.data.overSeaAfterSaleSwitch
    })
  }
}

//  //获取物流信息
//  async getLogisticInfo(orderId,LogisticCode){
//   let loginInfo = {
//     url:baseUrl + "/api/wuliunew?appkey=" + appkey + "&orderId=" + orderId + "&LogisticCode=" + LogisticCode + "&portType=2&appTab=2" ,
//     method: 'GET',
//     data: {}
//   }
//   let data = await getDataFromServer(loginInfo);
//   if(data.data.result === 0){
//     this.setState({
//       reaOrderLogistics:data.data.data.traces
//     })
//   }
//  }

 //再次购买，加入购物车
 async postBuycart(orderId){
  let loginInfo = {
    url:baseUrl + "/v1.6/user/shopping/again?appkey=" + appkey + "&orderId=" + orderId,
    method: 'POST',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    message.success(<span className="msgText">{data.data.msg}</span>);
    this.props.history.push({pathname:"/home/buycart"});
  }else{
    message.error(<span className="msgText">{data.data.msg}</span>);
  }
 }
  render(){
    //登录拦截
    if(!this.props.userLoginInfo){
      return(<Redirect to="/login/user/loginCode"/>);
     }
    let { orderDetail,orderLogistics,overSeaRefundSwitch,overSeaAfterSaleSwitch,overSeaReason,ascorderLogistics } = this.state;
    let show = {};
    let hide = {display:"none"};
    let timeStyle = {fontSize:"14px",color:"#E41135"};
    let date = new Date(orderDetail.createTime);
    let deadline = date.getTime(date) + 1000*60*30;
    let orderStatus = "";
    let nowTitle = 0;
    if(orderLogistics.length){
      if(ascorderLogistics.length === 1) {
        orderStatus = "已付款";
        nowTitle = 1;
      }else if(ascorderLogistics.length === 2){
        orderStatus = "港颜出单";
        nowTitle = 2;
      }else if(ascorderLogistics.length === 3){
       orderStatus = "已拣货";
       nowTitle = 3;
      }else if(ascorderLogistics.length >= 4){
       orderStatus = "已发货";
       nowTitle = 4;
       if(orderDetail.status === 25){
        orderStatus = "已完成";
        nowTitle = 5;
       }
      }
    }else{
      switch (orderDetail.status) {
        case 1:
          orderStatus = "未支付";
          break;
        case 26:
          orderStatus = "已取消";
          break;
        default:
          break;
      }
    }
    if(orderDetail.refundStatus === 2){
      orderStatus = "交易已取消";
    }
    const Icon = () =>{
      return(
        <div className="leftTitle1">
          <p><span className="left">余额：</span><span className="right">-￥{orderDetail.rebateValue}</span></p>
          <p><span className="left">优惠券：</span><span className="right">-￥{orderDetail.promotionRMBSave}</span></p>
          <p><span className="left">活动促销：</span><span className="right">-￥{orderDetail.activityRMBSave}</span></p>
          <p><span className="left">折扣卡：</span><span className="right">-￥{orderDetail.rebateCardPrice}</span></p>
        </div>
      )
    }
    //物流判断
    const  LogisticsCon = () => {
    //   if(orderDetail.hkwaybillNo){
    //     return(
    //       <div className="content">
    //         <ul>
    //           {reaOrderLogistics.map((item,index) =>{
    //             if(index === 0){
    //               return(
    //                 <li key={index} className="frist"><span className="time">{item.acceptTime}</span><span className="text">{item.acceptStation}</span></li>
    //               )
    //             }else{
    //               return(
    //                 <li key={index} className={index === reaOrderLogistics.length-1?"last":""}><span className="time">{item.acceptTime}</span><span className="text">{item.acceptStation}</span></li>
    //               )
    //             }
    //           })}
    //         </ul>
    //       </div>
    //      )
    //  }else{

      if(orderLogistics.length){
        return(
         <div className="content">
           <ul>
             {orderLogistics.map((item,index) =>{
               if(index === 0){
                return(
                  <li key={index} className="frist"><span className="time">{item.acceptTime}</span><span className="text">{item.acceptStation}</span></li>
                )
              }else{
                return(
                  <li key={index} className={index === orderLogistics.length-1?"last":""}><span className="time">{item.acceptTime}</span><span className="text">{item.acceptStation}</span></li>
                )
              }
             })}
           </ul>
         </div>
        )
      }else{
       return(
       <div className="content">
         <div className="noInfo"><img src={ERWEIMA} alt=""/><span>当前订单暂无物流信息</span><span>扫码下载港颜App，实时查看物流状态！</span></div>
       </div>)
       }
    //  }
    }

    return(
      <div className="orderDetail">
        {this.state.isDelete && <DeleteAddressPopView tip="恭喜你获得现金随机红包！前往应用商店搜索港颜，在订单详情即可领取哦！" hiddenDeleteView={this.hiddenDeleteView}/>}
        <ApplyForRefundBox ref={r => this.sureOp = r} orderId={this.props.match.params.orderId} userId={this.props.userLoginInfo.id} refreshPage={this.refreshOrderDetail} overSeaReason={overSeaReason}/>
        <div className="inner">
          <Sidebar isHomePage={false} />
          <div className="guideTitle"><span className="homePage"> <Link to={"/home"}>港颜首页</Link> </span><span className="userCenter"> > <Link to={"/home/usercenter/order"}>个人中心</Link> </span><span className="orderDetail"> > 订单详情</span></div>
          <div className="detailBox">
            <div className="title1">订单详情</div>
            <div className="orderStatus clearfix">
              <div className="status_left">
                 <span className="text">订单编号：{orderDetail.outOrderNo}</span>
                 <span className="text">下单时间：{orderDetail.createTime}</span>
                 {orderStatus === "交易已取消" || orderDetail.refundStatus === 1?<span className="text">申请退款时间：{orderDetail.applyRefundTime}</span>:""}
                 {orderStatus === "交易已取消"?<span className="text">退款完成时间：{orderDetail.dealRefundTime}</span>:""}
                 <span className="status">{orderStatus}</span>
                 {orderDetail.refundStatus === 1 && <span className="smallText">申请退款受理中，预计需要1-3个工作日反馈结果，请耐心等待。如有问题请联系微信客服hokoface11</span>}
                 {orderLogistics.length ?<div className="status_bottom clearfix">
                 {orderDetail.refundStatus === 0 && overSeaRefundSwitch === 1 && orderDetail.printFlag === 0 && <span className="refund" onClick={this.applyForRefund} style={orderDetail.status === 2 || orderDetail.status === 7 || orderDetail.status === 19 || orderDetail.status === 20?show:hide}><i>退</i>申请退款</span>}
                  {orderStatus === "交易已取消"?"":<span className="share" onClick={this.sharePacket}><img src={HONGBAO} />点击领取现金红包</span>}
                 </div>:""}
              </div>
              <div className="status_right">
                {
                  orderLogistics.length <= 0 ||  orderStatus === "交易已取消" ? <div className="orderCancal" style={orderLogistics.length <= 0 ? {display:"none"} : {}}>
                    <p className="refundText">退款信息：退款审核成功，款项已退回，请注意查收(如银行处理需1-3个工作日，请耐心等待)</p>
                    <div className="buyAgain" onClick={this.buyAgain}>再次购买</div>
                  </div> : <ProgressBar
                    titles={["已付款","港颜出单","已拣货","已发货","完成"]}
                    nowTitle={ nowTitle }
                    statusNum={this.state.statusNum}
                    />
                 }
                 {
                  orderStatus === "已取消"?<div className="orderCancal">
                    <img src={CANCEL} alt=""/>
                    <p className="text">订单已取消</p>
                    <div className="buyAgain" onClick={this.buyAgain}>再次购买</div>
                  </div>:""
                 }
                 {
                  orderStatus === "未支付"?<div className="non-payment">
                    <img src={TIME} alt=""/>
                    <div className="text">您的订单已提交</div>
                    <div className="longtext">请在剩余<Countdown value={deadline} valueStyle={timeStyle} format=" m 分 s 秒" />内完成支付，如果超时订单自动取消 </div>
                    <div className="payment" onClick={this.goPay.bind(this,orderDetail.id)}>去付款</div>
                  </div>:""
                 }
              </div>
            </div>
            <div className="address">
              <div className="addressInfo">
                <span className="receiver">收件人信息</span>
                <span className="text">收货人：{orderDetail.name}</span>
                <div className="text"><span>收货地址：</span><span className="receAddress">{orderDetail.province}{orderDetail.city}{orderDetail.country}{orderDetail.addrDetail}</span></div>
                <span className="text">手机号码：{orderDetail.phone}</span>
              </div>
              <div className="logistics">
                 <div className="titleitem"><span className="title">物流信息</span><span className="logscompany" style={orderDetail.waybillNo?show:hide}>【物流公司：<span className="green">香港邮政</span>】</span><span className="logisticsnum" style={orderDetail.waybillNo?show:hide}>【运单编号：{orderDetail.waybillNo}】</span></div>
                 {
                  orderDetail.refundStatus === 2?
                  <div className="content">
                      <div className="noInfo"><img src={ERWEIMA} alt=""/><span>当前订单暂无物流信息</span><span>扫码下载港颜App，实时查看物流状态！</span></div>
                  </div>
                  :<LogisticsCon />
                  }
              </div>
            </div>
            <div className="orderInfo">
              <div className="tableTitle">
                <ul className="clearfix">
                  <li className="tableTitleItem long">商品信息</li>
                  <li className="tableTitleItem">数量</li>
                  <li className="tableTitleItem">价格</li>
                  <li className="tableTitleItem">操作</li>
                </ul>
              </div>
              <div className="tableItem">
                <table>
                  <tbody>
                  {orderDetail.orderGoods && orderDetail.orderGoods.map(item =>{
                    return(
                      <tr key={item.id}>
                        <td className='goodItem img'><Link to={`/home/goodsdetail/${item.goodinfoId}`} target="_blank"><img src={item.image} alt=""/></Link></td>
                        <td className='goodItem title'><Link to={`/home/goodsdetail/${item.goodinfoId}`} target="_blank">{item.title}</Link></td>
                        <td className='goodItem count'>x{item.quantity}</td>
                        <td className='goodItem price'>￥{item.priceRMB}</td>
                        <td className='goodItem handle'>
                          <span className="evaluate" onClick={this.writeReview.bind(this,orderDetail.orderGoods,item.orderId)} style={item.commentFlag === 0 && orderDetail.status === 9?{}:{display:"none"}}>评价</span>
                          {overSeaAfterSaleSwitch === 1 && <span className="applyrefund" onClick={this.applyForAfterSales.bind(this,orderDetail.id,item.goodinfoId,item.title,item.image,item.quantity)} style={item.saleAfterFlag === 0 && orderDetail.status === 9?{}:{display:"none"}}>申请售后</span>}
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
              <div className="orderCost clearfix">
                <div className="leftContent">
                  <p>￥{orderDetail.totalRMBPrice}</p>
                  <p>-￥{orderDetail.rebateValue + orderDetail.promotionRMBSave + orderDetail.activityRMBSave + orderDetail.rebateCardPrice}</p>
                  <p>￥{orderDetail.overseaRMBCost}</p>
                  <p>￥{orderDetail.taxCost}</p>
                  <p>￥{orderDetail.freightRMB}</p>
                  <p className="red">￥{orderDetail.actualRMBPrice}</p>
                </div>
                <div className="explain">
                  <p><span className="icon none">?</span></p>
                  <p ref="coupon"><Tooltip getPopupContainer={() => this.refs.coupon} placement="bottom" title={Icon} arrowPointAtCenter="true"><span className="icon">?</span></Tooltip></p>
                  <p><Tooltip placement="bottom" title={`根据支付宝相关规定，每笔订单收取定金的${this.props.systemData ? this.props.systemData.overseaCost * 100 : ""}%的手续费，如有疑问请联系客服`} arrowPointAtCenter="true"><span className="icon">?</span></Tooltip></p>
                  <p><Tooltip placement="bottom" title="税费信息：根据国家政策规定，跨境订单税费以商品实际交易价格（包括商品售价、运费）计算" arrowPointAtCenter="true"><span className="icon">?</span></Tooltip></p>
                  <p><Tooltip placement="topLeft" title={this.props.systemData ? this.props.systemData.postagedes : ""} arrowPointAtCenter="true">
                    <span className="icon">?</span>
                  </Tooltip></p>
                  <p></p>
                </div>
                <div className="leftTitle">
                  <p>商品总金额：</p>
                  <p>活动优惠：</p>
                  <p>支付宝手续费：</p>
                  <p>税费：</p>
                  <p>运费：</p>
                  <p className="red">实付金额：</p>
                </div>
              </div>
            </div>
          </div>
          <div className="applyForAfterSalesBox" ref={r => this.applyForAfterSalesBox = r}>
            <div className="sureAndCancelInner">
              <div className="title">
                <span className="boxName">申请售后</span>
                <img src={closeIcon} alt="" className="closeBtn" onClick={this.closeBox}/>
              </div>
              <div className="section">
                <div className="reason">
                  <span className="red">*</span>申请类型:
                  <select className="select" value={this.state.saleAfterType} onChange={this.typeChange}>
                    <option value="1">退款退货</option>
                  </select>
                </div>
                <div className="opBtn">
                  <span className="sure" onClick={this.sure}>确定</span>
                  <span className="cancel" onClick={this.closeBox}>取消</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderDetail;
