import React,{Component} from "react";
import {connect} from 'react-redux';
import { Redirect,Link } from "react-router-dom";
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./MoreOfficialNews.less"
import arrowRightImg from "../../images/ic_lookfor_detail.png"

const officialNewsType = "官方消息"; //官方消息
const postDirectNewsType = "直邮消息";//直邮消息
const rebateNewsType = "返利消息";//返利消息

@connect(
  state => state.loginReducer
)
class MoreOfficialNews extends React.Component{
	constructor(props){
		super(props);
		this.state={
			newsType:officialNewsType,
		}
	}

	officialOnclick = () =>{
		this.setState({
			newsType:officialNewsType,
		});
	}
	directOnclick = () =>{
		this.setState({
			newsType:postDirectNewsType,
		});
	}
	rebateOnclick = () =>{
		this.setState({
			newsType:rebateNewsType,
		});
	}

	render(){
		if(!this.props.userLoginInfo){
			return (
							<Redirect to="/login/user/loginCode"/>
						)
		}
		return(
			<div className="more_news_super_back">
				<div className="left_slector_back">
					<div className={this.state.newsType == officialNewsType ? "left_slector left_selector_color_red" : "left_slector left_selector_color_gray"} onClick={this.officialOnclick}>官方消息</div>
					<div className="left_division_line"></div>
					<div className={this.state.newsType == postDirectNewsType ? "left_slector left_selector_color_red" : "left_slector left_selector_color_gray" } onClick={this.directOnclick}>直邮消息</div>
					<div className="left_division_line"></div>
					<div className={this.state.newsType == rebateNewsType ? "left_slector left_selector_color_red" : "left_slector left_selector_color_gray"} onClick={this.rebateOnclick}>返利消息</div>
				</div>
				<div className="right_news_list_back">
					<div className="right_news_list_title">{this.state.newsType}</div>
					<div className="right_news_division_line"></div>
					<NewsList newsType={this.state.newsType} userInfo={this.props.userLoginInfo}/>
				</div>
			</div>
		);
	}
}
//消息列表
class NewsList extends React.Component{
	constructor(props){
		super(props);
		this.state={
			officialNews:[],//官方
			postNews:[],//直邮
			rebateNews:[],//返利
		};
		let userInfo = this.props.userInfo
		if(userInfo && userInfo.id){
			this.loadOfficialNews(userInfo.id)
			this.loadPostNews(userInfo.id)
			this.loadRebateNews(userInfo.id)
		}
	}

	//官方消息
	async loadOfficialNews(userId){
		let reqUrl = `${baseUrl}/msg/sys?appTab=2&appkey=${appkey}&userId=${userId}`;
		let method = "GET";
		let officialNewsReq = {
			url:reqUrl,
			method
		}
		let response = await getDataFromServer(officialNewsReq)
		this.setState({
		  officialNews:response.data.data.officialMsgList,
		});
	}
	//直邮消息
	async loadPostNews(userId){
		let reqUrl = `${baseUrl}/msg/logistics?appTab=2&appkey=${appkey}&userId=${userId}`;
		let method = "GET";
		let officialNewsReq = {
			url:reqUrl,
			method
		}
		let response = await getDataFromServer(officialNewsReq)
		this.setState({
			postNews:response.data.msgLogisticsList
		});
	}
	//返利消息
	async loadRebateNews(userId){
		let reqUrl = `${baseUrl}/v1.7/msg/rebate?appTab=2&appkey=${appkey}&userId=${userId}`;
		let method = "GET";
		let officialNewsReq = {
			url:reqUrl,
			method
		}
		let response = await getDataFromServer(officialNewsReq)
		this.setState({
			rebateNews:response.data.data
		});
	}
//官方消息
	OfficialNewsItem = (item,index) =>{

		let title = "";
		if(item.type == 2){
			title = "优惠券";
		}else if(item.type == 1){
			title = "开抢提醒";
		}else if(item.type == 3){
			title = "港颜小喇叭";
		}

		return(
			<div className="news_item_back" key={index}>
				<div className="news_item_title">{item.onTime}</div>
				<div className="item_content_back">
					<div className="item_content_title_back">
						<div className="left_red_rectangle"></div>
						<div className="right_content_title">{title}</div>
					</div>
					<div className="right_conten_division_line"></div>
					{/* type 1开抢提醒    2 优惠券 3 自定义文本消息*/}
					<div className={item.type==2 ? "right_item_content display_block" : "right_item_content display_none"}>{item.title}</div>
					<div className={item.type==3 ? "right_item_content display_block" : "right_item_content display_none"}>{item.text}</div>
					<div className={item.type==1 ? "right_item_content display_block" : "right_item_content display_none"}>
							<div className="news_goods_models_back">
								<img className ="news_goods_img" src={item.msgImage}/>
								<div className="news_goods_des_back">
									<div className="news_goods_title">{item.title}</div>
									<div className="news_price_back">
										<div className="news_goods_price">{item.priceAppLimit}</div>
										<div className="news_original_goods_price">{item.originalPrice}</div>
									</div>
								</div>
							</div>
					</div>
					{(item.type == 1) &&
					<Link to={`/home/goodsdetail/${item.goodsId}`} target="a_blank">
						<div className="look_news_detail">
							<div className="look_dews_detail_title">查看详情</div>
							<img className="look_news_detail_arrow_right" src={arrowRightImg}/>
						</div>
					</Link>
					}
				</div>
			</div>
		);
	}
//直邮消息
	DirectMailItem = (item,index) =>{
		let itemTip = "";
		switch(item.status){
			case 1:
					itemTip = "";
					break;
			case 2:
					itemTip = "";
					break;
			case 3:
					itemTip = "款项已原路返还（如银行处理需1-3个工作日）";
					break;
			case 4:
					itemTip = "";
					break;
			case 5:
					itemTip = "请尽快填写物流单号";
					break;
			case 6:
					itemTip = "";
					break;
			case 7:
				if(item.dealType == 1){
					itemTip = "处理方式：退款";
				}else{
					itemTip = `处理方式：${item.remark}`;
				}
				break;
			default:
					itemTip = "";
					break;
		}

		return(
			<div className="news_item_back" key={index}>
				<div className="news_item_title">{item.noteTime}</div>
				<div className="item_content_back">
					<div className="item_content_title_back">
						<div className="left_red_rectangle"></div>
						<div className="right_content_title">{item.note}</div>
					</div>
					<div className="right_conten_division_line"></div>
					{/* status 1 订单发货  2 订单签收 3退款完成 4 退款未通过  5 售后处理 6 售后未通过 7 售后通过 */}
					<div className="right_item_content">
							<div className="news_goods_models_back">
								<img className ="news_goods_img" src={item.imgpath}/>
								<div className="news_goods_des_back">
									<div className="news_goods_title">{item.title}</div>
									<div className="news_price_back">
										<div className="news_wuliu_num">{(item.status == 1 || item.status == 3) && `运单编号：${item.outOrderNo}`}</div>
										<div className="news_refundMoney_goods_price">{(item.status == 3 || item.status == 5 || item.status == 7) && (`退款金额  ￥${item.refundMoney}`)}</div>
									</div>
								</div>
							</div>
							{(item.status == 3 || item.status == 5 || item.status == 7 ) &&
								<div className="direct_news_bottom_bg">
									<div className="direct_top_division_line"></div>
									<div className="tip_info">{itemTip}</div>
									{(item.status == 3 || item.status == 7 ) &&
									<div className="tip_info">{`完成时间：${item.noteTime}`}</div>
									}
								</div>
							}
						</div>
						<Link to={`/home/orderDetail/${item.orderId}`} target="__blank">
							<div className="look_news_detail">
								<div className="look_dews_detail_title">查看详情</div>
								<img className="look_news_detail_arrow_right" src={arrowRightImg}/>
							</div>
						</Link>
				</div>
			</div>
		);
	}

	//返利消息
	RebateNewsItem = (item,index) =>{
		let title = "";
		let description = "";
		if (item.type == 3) {
			title = "提现信息";
			switch(item.putStatus){
				case 1:
						description = "余额提现申请中";
						break;
				case 2:
						description = `余额提现成功，提现金额为￥${item.putMoney}，请注意查收哦`;
						break;
				case 3:
						description = "余额提现失败，请联系客服核查失败原因";
						break;
				default:
						description = `您转发的折扣卡已被${item.phoneNumber}使用，预计获得${item.rebateValue}的返利值。返利值成功入账后即可使用`;
						break;
			}
		}else if (item.type == 4) {
			title = "邀请信息";
			description = `亲~您的好友${item.phoneNumber}已注册成功，您获得${item.rebateValue}元的奖励值已到账，快去余额看看吧`;
		}else if (item.type == 5) {
			title = "返利值";
			description = `亲~您已注册成功，您获得${item.rebateValue}元的奖励值已到账，快去余额看看吧`;
		}else{//1  2
			title = "返利值";
			description = `您转发的折扣卡已被${item.phoneNumber}使用，预计获得${item.rebateValue}的返利值。返利值成功入账后即可使用`;
		}
		return(
			<div className="news_item_back" key={index}>
				<div className="news_item_title">{item.createTime}</div>
				<div className="item_content_back">
					<div className="item_content_title_back">
						<div className="left_red_rectangle"></div>
						<div className="right_content_title">{title}</div>
					</div>
					<div className="right_conten_division_line"></div>
					<div className="right_item_content">{description}</div>
					{/* <div className="look_news_detail">
						<div className="look_dews_detail_title">查看详情</div>
						<img className="look_news_detail_arrow_right" src={arrowRightImg}/>
					</div> */}
				</div>
			</div>
		);
	}

	render(){
		let {officialNews,postNews,rebateNews} = this.state;
		if (this.props.newsType == officialNewsType && 	officialNews && officialNews.length > 0){
			return(
				<div className="right_news_list_content_back">
					{
					  officialNews.map((item,index) => {
							return this.OfficialNewsItem(item,index)
						})
					}
				</div>
			);
		}else if (this.props.newsType == postDirectNewsType && postNews && postNews.length > 0){
			return(
				<div className="right_news_list_content_back">
					{
							postNews.map((item,index) => {
								return this.DirectMailItem(item,index)
							})
					}
				</div>
			);
		}else	if (this.props.newsType == rebateNewsType){
			return(
				<div className="right_news_list_content_back">
					{
							rebateNews.map((item,index) => {
								return this.RebateNewsItem(item,index)
							})
					}
				</div>
			);
		}else{
			return(
				<div className="right_news_list_content_back"></div>
			);
		}
	}
}

export default MoreOfficialNews;
