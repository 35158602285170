import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import md5 from 'md5';
import Cookies from 'js-cookie';
import loginAction from "../../actions/loginAction";
import InputItem from "../../components/InputItem/InputItem.js";
import "./LoginThirdBind.less";
import { getDataFromServer } from '../../httpRequest/Api.js';
import numberImg from "../../images/ic_number.png";
import yanzhengma from "../../images/ic_yanzhegnma.png";
import password from "../../images/ic_code.png";
import errorIcon from "../../images/ic_error.png";
import selected from "../../images/ic_selected.png";
import unselected from "../../images/ic_unselected.png";

const {toggleRuleClick,successRegister,loginInfo} = loginAction;

@connect(
  state => state.loginReducer,
  {toggleRuleClick,successRegister,loginInfo}
)

class LoginThirdBind extends Component {
  constructor(props){
    super(props);
    this.state=({
      errText:"",
      errType:"",
      bindPhone:"",
      bindCode:"",
      registerPhone: "",
      registerCode: "",
      registerPassword: "",
      type:"register",
      hasSelRule: true, //用户协议是否勾选
      hasBind: "default",
      thirdType: 0,
      openId: ""
    });
    this.btnClick = this.btnClick.bind(this);
  }

  async componentDidMount(){
    let code = this.getUrlPram("code");
    let state = this.getUrlPram("state");
    let reqUrl = "";
    let method = "";
    let loginInfo = null;
    let response = null;
    let thirdType = 0;
    let openId = 0;
    if (state === "qq") {
      reqUrl = `${baseUrl}/webUser/qqLogin?code=${code}&appkey=${appkey}`;
      thirdType = 1;
    }else if(state === "wechat"){
      reqUrl = `${baseUrl}/webUser/wxLogin?code=${code}&appkey=${appkey}`;
      thirdType = 2;
    }
    method = "POST";
    loginInfo = {
      url: reqUrl,
      method
    }
    response = await getDataFromServer(loginInfo);
    if (response.data.result === 5) {
      openId = response.data.openId;
      this.setState({
        hasBind: "noBind",
        thirdType,
        openId
      })
    }else if(response.data.result === 6){
      this.setState({
        hasBind: "codeErr",
        thirdType
      })
    }else if(response.data.result === 0){
      Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/' ,Domain: window.location.hostname});
      this.setState({
        hasBind: "hasBind",
        thirdType
      })
    }
  }

  //解析url
  getUrlPram = (str) => {
    let regCode = new RegExp("(^|&)" + str + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(regCode);
    if (r != null) {
      return unescape(r[2]);
    }
    return "";
  }

  //选择用户协议
  selRule = () => {
    this.setState({
      hasSelRule: !this.state.hasSelRule
    })
  }

  //错误提示
  errorTip = (errText,errType) =>{
    this.setState({
      errText,
      errType
    })
  }

  //获取输入框的值
  getValue = (value) => {
    this.setState({
      errText: "",
      errType: "",
      [value.keyword]:value.value
    })
  }

  //改变状态
  changeType = (type) => {
    if (this.state.type !== type) {
      this.setState({
        type,
        errText: "",
        errType: ""
      })
    }
  }

  //绑定按钮点击
  async btnClick () {
    // this.props.successRegister();
    let {bindPhone,bindCode,registerPhone,registerCode,registerPassword,type,hasSelRule,thirdType,openId} = this.state;
    let reqUrl = "";
    let method = "";
    let loginInfo = null;
    let response = null;
    if (type === "hasAccount") {
      if (bindPhone.length < 11) {
        this.errorTip(bindPhone ? "请输入正确的手机号" : "请输入手机号码" ,"bindPhone");
        return;
      }
      if (bindCode.length < 4) {
        this.errorTip(bindCode ? "请输入正确的验证码" : "请输入验证码","bindCode");
        return;
      }
      reqUrl = `${baseUrl}/webUser/bindAccount?phoneNum=${bindPhone}&type=${thirdType}&bindType=1&openId=${openId}&code=${bindCode}&appkey=${appkey}`;
        method = "POST";
        loginInfo = {
          url: reqUrl,
          method
        }
        response = await getDataFromServer(loginInfo);
        if (response.data.result === 0) {
          Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/' ,Domain: window.location.hostname});
          this.props.loginInfo(response.data.user);
          this.props.history.push('/home');
        }else{
          this.errorTip(response.data.msg,"bindCode");
        }
    }else if (type === "register") {
      if (registerPhone.length < 11) {
        this.errorTip(registerPhone ? "请输入正确的手机号" : "请输入手机号码" ,"registerPhone");
        return;
      }
      if (registerCode.length < 4) {
        this.errorTip(registerCode ? "请输入正确的验证码" : "请输入验证码","registerCode");
        return;
      }
      if (registerPassword.length < 6) {
        this.errorTip(registerPassword ? "请输入不含特殊符号的6-16位密码" : "请输入密码","registerPassword");
        return;
      }
      if (!hasSelRule) {
        this.errorTip("请先接受相关用户协议","");
        return;
      }
      reqUrl = `${baseUrl}/webUser/bindAccount?phoneNum=${registerPhone}&type=${thirdType}&bindType=2&openId=${openId}&code=${registerCode}&password=${registerPassword}&appkey=${appkey}`;
      method = "POST";
      loginInfo = {
        url: reqUrl,
        method
      }
      response = await getDataFromServer(loginInfo);
      if (response.data.result === 0) {
        Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/' ,Domain: window.location.hostname});
        this.props.loginInfo(response.data.user);
        this.props.successRegister();
        this.props.history.push('/home');
      }else{
        this.errorTip(response.data.msg,"registerPassword");
      }
    }
  }

  render() {
    switch (this.state.hasBind) {
      case "codeErr":
        return (
          <Redirect to="/login/user/loginCode"/>
        )
        break;
      case "hasBind":
        return (
          <Redirect to="/home"/>
        )
        break;
    }
    const inputStyle={width:"360px",margin: "13px auto"};
    const {toggleRuleClick} = this.props;
    let hide = {display:"none"};
    return (
      <div className="LoginThirdBind">
        <div className="inner">
          <div className="pageTitle">绑定账号</div>
          <div className="pageSubTitle">您已通过 QQ账号 登录成功，请绑定港颜的账号，以后登录港颜更方便！</div>
          <div className="loginTitle">
            <span onClick={this.changeType.bind(this,"hasAccount")} className={this.state.type === "hasAccount" ? "titleName active" : "titleName"}>绑定已有账号</span>
            <span className="middleLine"></span>
            <span onClick={this.changeType.bind(this,"register")} className={this.state.type === "hasAccount" ? "titleName" : "titleName active"}>新用户注册绑定</span>
          </div>
          <div className="inputBox" style={this.state.type === "hasAccount" ? {} : hide}>
            <div className="item">
              <InputItem isErr={this.state.errType === "bindPhone"} keyword="bindPhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
              {
                this.state.errType === "bindPhone" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
              }
            </div>
            <br/>
            <div className="item">
              <InputItem isErr={this.state.errType === "bindCode"} codeType="login" errorTip={this.errorTip} style={inputStyle} keyword="bindCode" type="text" maxLength="6" icon={yanzhengma} holderText="请输入验证码" phone={{bindPhone:this.state.bindPhone}} isCode="true" getValue={this.getValue}/>
              {
                this.state.errType === "bindCode" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
              }
            </div>
          </div>
          <div className="inputBox" style={this.state.type === "register" ? {} : hide}>
            <div className="item">
              <InputItem isErr={this.state.errType === "registerPhone"} keyword="registerPhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
              {
                this.state.errType === "registerPhone" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
              }
            </div>
            <br/>
            <div className="item">
              <InputItem isErr={this.state.errType === "registerCode"} codeType="register" errorTip={this.errorTip} style={inputStyle} keyword="registerCode" type="text" maxLength="6" icon={yanzhengma} holderText="请输入验证码" phone={{registerPhone:this.state.registerPhone}} isCode="true" getValue={this.getValue}/>
              {
                this.state.errType === "registerCode" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
              }
            </div>
            <br/>
            <div className="item">
              <InputItem style={inputStyle} isErr={this.state.errType === "registerPassword"} keyword="registerPassword" type="password" maxLength="16" icon={password} holderText="请输入6-16位密码" getValue={this.getValue}/>
              {
                this.state.errType === "registerPassword" && <div className="errTip"><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
              }
            </div>
          </div>
          <div className="btn" onClick={this.btnClick}>{this.state.type === "hasAccount" ? "登录绑定" : "注册绑定"}</div>
          {
            this.state.type === "register" &&
            <div className="userRule">
              <img className="selected" onClick={this.selRule} src={this.state.hasSelRule ? selected : unselected} alt=""/>我已阅读并接受：<span className="rule" onClick={toggleRuleClick}>《用户协议与隐私条例》</span>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default LoginThirdBind;
