import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import "./RankCenter.less";
import bg_sales from "../../images/bg_lingquanzhongxin.png";
import bg_collect from "../../images/bg_collect.png";
import fair from "../../images/ic_fair.png";
import collect from "../../images/ic_collection.png";
import coupon from "../../images/ic_coupon.png";
import ProductItem from "../../components/ProductItem/ProductItem";
import CouponItem from "../../components/CouponItem/CouponItem";
import Pager from "../../components/Pager/Pager";
import { getDataFromServer } from '../../httpRequest/Api.js';

@connect(
  state => state.loginReducer
)

class RankCenter extends Component {
  constructor(props){
    super(props)
    this.state={
      goodsList: [],
      couponList: [],
      type: "",
      pageNo: 1,
      pageSize: 20,
      pageCount: 0
    }
    this.getCouponList = this.getCouponList.bind(this);
  }

  componentDidMount(){
    let {pageNo} = this.state;
    let type = this.props.match.params.type;
    switch (type) {
      case "sales":
        this.getGoodsList(pageNo,type);
        break;
      case "favorites":
        this.getGoodsList(pageNo,type);
        break;
      case "coupon":
        if(!this.props.userLoginInfo){
          this.props.history.push("/login/user/loginCode");
        }else{
          this.getCouponList(type);
        }
        break;
    }
  }
  
  
  //获取销量收藏列表
  async getGoodsList (pageNo,type) {
    let loginInfo = {
      url: `${baseUrl}/home/goods/ranking?pageNo=${pageNo}&pageSize=${this.state.pageSize}&type=${type}&appkey=${appkey}`,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        pageNo,
        goodsList: data.data.data,
        pageCount: data.data.pageCount,
        type: type
      })
    }
  }

  //获取页码
  getPage = (page) => {
    let {type} = this.state;
    switch (type) {
      case "sales":
        this.getGoodsList(page,type);
        break;
      case "favorites":
        this.getGoodsList(page,type);
        break;
    }
  }

  //获取优惠券列表
  async getCouponList (type) {
    let loginInfo = {
      url: `${baseUrl}/coupon/center?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        couponList: data.data.data,
        type
      })
    }
  }
 
  render() {
    let {goodsList,couponList,type,pageNo,pageCount} = this.state;
    let titleText = "";
    let titleIcon = "";
    let bg_img = "";
    switch (type) {
      case "coupon":
        titleText = "领券中心";
        titleIcon = coupon;
        bg_img = bg_sales;
        break;
      case "sales":
        titleText = "热销排行榜";
        titleIcon = fair;
        bg_img = bg_sales;
        break;
      case "favorites":
        titleText = "收藏排行榜";
        titleIcon = collect;
        bg_img = bg_collect;
        break;
    }
    return (
      <div className="RankCenter">
        {/*标题*/}
        <div className="titleBox">
          <img src={titleIcon} className="titleIcon" alt=""/>
          <span className="title">{titleText}</span>
          <img src={bg_img} className="bg_img" alt=""/>
        </div>
        <div className="inner">
          {/*销量排行、收藏排行*/}
          <div className="goodsList">
            {
              goodsList.map((item,i) =>{
                return (
                  <li className={( i + 1 ) % 4 === 0 ? "ModelGoodsItem" : "ModelGoodsItem marR"} key={i}>
                    <ProductItem goodsInfo={item} index={i} type={this.props.match.params.type}/>
                  </li>
                )
              })
            }
          </div>
          {/*领券中心*/}
          <div className="goodsList">
            {
              couponList.map((item,i) =>{
                return (
                  <div className={( i + 1 ) % 3 === 1 ? "couponBox marL" : "couponBox"} key={i}>
                    <CouponItem couponInfo={item} updateCoupon={this.getCouponList}/>
                  </div>
                )
              })
            }
          </div>
          <div className="clearfix"></div>
          {/*分页*/}
          {
            type !== "coupon" && 
            <div className="page">
              <Pager total={pageCount} current={pageNo} getPage={this.getPage}/>
            </div>
          }
        </div>
      </div>
    );
  }
}
export default RankCenter;


