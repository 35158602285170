import React,{ Component } from 'react';
import "./ZouMaDeng.less";
import LArrowImg from "../../images/ic_arrow_L_banyuan .png";
import RArrowImg from "../../images/ic_arrow_R_banyuan .png";

class ZouMaDeng extends Component{
  constructor(){
    super()
    this.state = {
      //轮播的信息
      width: '10000px',
      transition: '500ms ease-out',
      transform: 'translatex(0px)',
      countnum: 18,     //图片数量
      curPageIndex: 0, //点击右箭头+1，左箭头减1
      viewcount: 6,    //初始展示图片数量（和div宽度相关） 
      Larrowstate: true,
      Rarrowstate: true,
    }
  }

  //上一张
  handleclickPrev = () =>{
    let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state
    if(curPageIndex === countnum - viewcount){
      this.setState({
          Rarrowstate:!Rarrowstate 
      })
    }
    this.getPage(curPageIndex-1)
  }

  //下一张
  handleclickNext = () =>{
    let { countnum,viewcount,curPageIndex,Larrowstate } = this.state
    if (curPageIndex < 0) {
      curPageIndex = 0
    }
    if(curPageIndex === 0){
      this.setState({
          Larrowstate:true
      })
    }
    this.getPage(curPageIndex + 1)
  }

  getPage = (value) => {
    let { countnum,viewcount,Larrowstate,Rarrowstate} = this.state
    let n = countnum - viewcount
    //一项的宽度
    let itemwidth = 197;
    let transform = 'translatex(-' + value * itemwidth + 'px)'
    value > n || 0 > value || this.setState({transform:transform})
    this.setState({
      curPageIndex: value,
    })
    if (value == 0) {
      this.setState({
        Larrowstate: !Larrowstate
      })
    }
    if (value == n) {
      this.setState({
        Rarrowstate: !Rarrowstate
      })
    }
 }
  render(){
    let { width ,transition ,transform ,Larrowstate ,Rarrowstate} = this.state;
    let LArrowClaName = Larrowstate ? 'arrow_l arrow' : 'hidden';
    let RArrowClaName = Rarrowstate ? 'arrow_r arrow' : 'hidden';
    return(
      <div className="ZouMaDeng">
        <div className="productShow">
          <ul className="InnerContent" style={{ width: width, transition: transition, transform: transform }}>
            {this.props.list.map((item,i) =>{
              return <li key={i}>
              <this.props.Item goodsInfo={item}/>
             </li>
            })}
          </ul>
        </div>
        <div className={LArrowClaName}><img src={LArrowImg}  onClick={this.handleclickPrev} /></div>
        <div className={RArrowClaName}><img src={RArrowImg}  onClick={this.handleclickNext} /></div>
      </div>
    )
  }
}
export default ZouMaDeng;