import React,{Component} from "react";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import Cookies from 'js-cookie';
import "./UserManager.less";
import checkImg from "../../images/ic_checkcircle.png";
import checkErrorImg from "../../images/ic_minuscircle.png";
import avataraImg from "../../images/avatara.png";
import { Upload, Icon, message } from 'antd';

@connect(
  state => state.loginReducer
)

class UserManager extends Component{
  constructor(props){
    super(props);

    var today = new Date();
    let year = today.getFullYear()
    var years = ["--"];
    for(var i=0; i<100;i++){//100年
      let nowY = year - i;
      years.push(nowY);
    }
    let months = ["--",1,2,3,4,5,6,7,8,9,10,11,12];
    let days = ["--",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

    let userInfo = props.userLoginInfo;
    let birthYear = "--";
    let birthMonth = "--";
    let birthDay = "--";
    if (userInfo && userInfo.birthday &&  userInfo.birthday != "") {
      let birthDate = userInfo.birthday;
      let date = new Date(birthDate);
      birthYear = date.getFullYear();
      birthMonth = date.getMonth() + 1
      birthDay = date.getDate()
      days = this.caculateDays(birthYear,birthMonth,days)
    }
    this.state={
      errorTip:"",
      avaImg:this.props.userLoginInfo ? this.props.userLoginInfo.image : "",
      sexIndex:this.props.userLoginInfo ? this.props.userLoginInfo.gender : 2,//1男 0女  2默认保密
      nickName:this.props.userLoginInfo ? this.props.userLoginInfo.nickname : "",
      checkNick:true,
      years:years,
      selectYear:birthYear,
      months:months,
      selectMonth:birthMonth,
      days:days,
      selectDay:birthDay,
      qiniuToken:"",
    }
  }
  componentDidMount(){
    this.qiniuToken();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  changSex = (index) =>{    
    this.setState({
      sexIndex:index,
    });
  }
//年份选择
  handleChangeYear = (e) =>{
    this.setState({
      selectYear:e.target.value,
    },()=>{
      this.caculateDays();
    })
  }
  //月份选择
  handleChangeMonth = (e) =>{
    this.setState({
      selectMonth:e.target.value,
    },()=>{
      this.caculateDays();
    })
  }
  //日期选择
  handleChangeDay = (e) =>{
    this.setState({
      selectDay:e.target.value,
    })
  }
  //各种月份天数计算 年月
  caculateDays = (y,m,ds) =>{
    let days = ds ? ds : this.state.days;
    let month = m ? m : parseInt(this.state.selectMonth);
    let year = y ? y : parseInt(this.state.selectYear);
    if([1,3,5,7,8,10,12].includes(month)){
      days = ["--",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
    }else if ([4,6,9,11].includes(month)){
      days = ["--",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
    }else if (month == 2){
      if ((year % 100 != 0 && year % 4 == 0) || (year % 400 == 0)){
        days = ["--",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29];
      }else{
        days = ["--",1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];
      }
    }
    //初始化 返回天数
    if(ds){
      return days;
    }else{
      //选择年月同步更改
      this.setState({
        days:days,
      });
    }
  }

  //图片上传token生成
 async qiniuToken(){
  let loginInfo = {
    url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      qiniuToken:data.data.uploadToken,
    })
  }
}
 
   getBase64 = (img, callback) =>{
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
   beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  
  handleChange = info => {   
    if (info.file.status === 'uploading') {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // this.getBase64(info.file.originFileObj, imageUrl =>
      //   this.setState({
      //     avaImg:imageUrl,
      //     // loading: false,
      //   }),
      // );
      let hash = info.file.response.hash;
      let imageUrl = "http://img.maigang360.com/" + hash;
      this.setState({
          avaImg:imageUrl,
        });
    }
  };
  handleNickNameChange = (e) =>{
    this.setState({
      nickName:e.target.value
    });
  }
  //头像
 async modifyAvator(){
    let userId = this.props.userLoginInfo.id;
    let image = this.state.avaImg;
    let reqUrl = `${baseUrl}/user/portrait?appTab=2&appkey=${appkey}&userId=${userId}&image=${image}`;
    let method = "POST";
    let modifyAvator={
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(modifyAvator);
    if (response.data.result === 0) {
      Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/',Domain: window.location.hostname });
    }
  }
  //昵称
  async modifyNickName(){
    let userId = this.props.userLoginInfo.id;
    let nickName = this.state.nickName;
    let reqUrl = `${baseUrl}/user/nickname?appTab=2&appkey=${appkey}&userId=${userId}&nickname=${nickName}`;
    let method = "POST";
    let modifyNick={
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(modifyNick);
    if (response.data.result === 0) {
      Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/',Domain: window.location.hostname });
    }
  }
  //性别
  async modifySex(){
    let userId = this.props.userLoginInfo.id;
    let gender = this.state.sexIndex;
    let reqUrl = `${baseUrl}/user/gender?appTab=2&appkey=${appkey}&userId=${userId}&gender=${gender}`;
    let method = "POST";
    let modifygender={
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(modifygender);
    if (response.data.result === 0) {
      Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/',Domain: window.location.hostname });
    }
  }
  //生日
  async modifyBirthDay(){
    let userId = this.props.userLoginInfo.id;
    let birthDay = this.state.selectYear + "-" + this.state.selectMonth + "-" + this.state.selectDay;
    let reqUrl = `${baseUrl}/user/birthday?appTab=2&appkey=${appkey}&userId=${userId}&birthday=${birthDay}`;
    let method = "POST";
    let modifyBirth={
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(modifyBirth);
    if (response.data.result === 0) {
      Cookies.set('loginInfo', response.data.user, { expires: 30, path: '/',Domain: window.location.hostname });
    }
  }
  submitAction = () =>{
    let {avaImg,nickName,sexIndex,selectYear,selectMonth,selectDay} = this.state;
    if(nickName == null || nickName.length < 4){
      this.setState({
        errorTip:"4-20个字符组成",
        checkNick:false,
      });
      return "4-20个字符组成" ;
    }else{
      this.setState({
        errorTip:"",
        checkNick:true,
      });
    }
    //格式正确
    this.modifyAvator();
    this.modifyNickName();
    this.modifySex();
    this.modifyBirthDay();
    message.success(<span className="msgText">修改已提交</span>);

    // if (response.result == 0) {
    // }else{
    //   message.error(<span className="msgText">新增失败</span>);
    // }
  }
  //判断昵称格式
  checkNickName = (nick) => {
    
  }
  render(){
    let sexIndex = this.state.sexIndex;
    let avaImg = this.state.avaImg;
    let nickName = this.state.nickName;
    let checkNick = this.state.checkNick;
    let errorTip = this.state.errorTip;
    
    return(
      <div className="user_manager_bg">
        <div className="head_title">账号管理</div>
        <div className="head_bg">
          <div className="left_title">头像：</div>
          {/* <img className="head_img" src={avaImg ? avaImg : avataraImg}/> */}
          <div className="head_img_bg">
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
            >
              <img src={avaImg ? avaImg : avataraImg } alt="avatar" className="head_img"/>
            </Upload>
          </div>
          <div className ="update">修改</div>
          {/* <input className ="update_input" type="file" accept="image/jpg,image/jpeg,image/png" onChange={this.selectImage}/> */}
        </div>
        <div className="info_bg">
          <div className="left_title">用户名：</div>
          <div className="user_id">{this.props.userLoginInfo.phoneNumber}</div>
        </div>
        <div className="info_bg">
          <div className="left_title">昵称：</div>
          <div className="left_star">*</div>
          <div className="user_name_bg">
            <input className="user_name" defaultValue={nickName} minLength="4" maxLength="20" onChange={this.handleNickNameChange}/>
            <img className="check_img" src={checkNick ? checkImg : checkErrorImg}/>
          </div>
        </div>
        <div className="error_tip">{errorTip}</div>
        {/*性别单选框*/}
        <div className="info_bg">
          <div className="left_title">性别：</div>
          <div className="sex_select_bg">
            <div className="sex_bg" onClick={this.changSex.bind(this,1)}>
              <div className="circle_big">
                <div className={sexIndex == 1 ? "circle_small_select" : "circle_small_nomal"}></div>
              </div>
              <div className="sex_title">男</div>
            </div>
            <div className="sex_bg" onClick={this.changSex.bind(this,0)}>
              <div className="circle_big">
                <div className={sexIndex == 0 ? "circle_small_select" : "circle_small_nomal"}></div>
              </div>
              <div className="sex_title">女</div>
            </div>
            <div className="sex_bg" onClick={this.changSex.bind(this,2)}>
              <div className="circle_big">
                <div className={sexIndex == 2 ? "circle_small_select" : "circle_small_nomal"}></div>
              </div>
              <div className="sex_title">保密</div>
            </div>
          </div>
        </div> 
        {/*年月日选择器*/}
        <div className="info_bg">
          <div className="left_title">出生日期：</div>
          <div className="yyyymmdd_select_bg">
            <select className="yyyymmdd_select" defaultValue={this.state.selectYear} onChange={this.handleChangeYear}>
              {
                this.state.years.map((year,index) =>{
                  return <option key={index}>{year}</option>
                })
              }
            </select>
            <div className="ymd_title">年</div>
            <select className="yyyymmdd_select" defaultValue={this.state.selectMonth} onChange={this.handleChangeMonth}>
             {
               this.state.months.map((month,index)=>{
                return <option key={index}>{month}</option>
               })
             }
            </select>
            <div className="ymd_title">月</div>
            <select className="yyyymmdd_select" defaultValue={this.state.selectDay} onChange={this.handleChangeDay}>
              {
                this.state.days.map((day,index)=>{
                return <option key={index}>{day}</option>
                })
              }            
            </select>
            <div className="ymd_title">日</div>
          </div>
        </div>
        <div className="submit_btn" onClick={this.submitAction}>提交</div>
      </div>
    );
  }
}

export default UserManager;