import React,{ Component } from 'react';
import './InviteGift.less';
import Clipboard from 'clipboard';
import { message } from 'antd';
import {connect} from 'react-redux';
import Cookies from 'js-cookie';
import { getDataFromServer } from '../../httpRequest/Api.js';
import loginAction from "../../actions/loginAction.js";
import InviteBGRImg from "../../images/bg_red_yaoqing .png";
import InviteBGYImg from "../../images/bg_yellow_yaoqing .png";
import InviteBGWImg from "../../images/bg_ white_yaoqing.png";
import InviteClose from "../../images/pop_ic_close_nor.png";
import InviteBGWLImg from "../../images/white_bg.png";
import InviteBGRLImg from "../../images/red_long.png";

let clipboard = null;
const {toggleLogin} = loginAction;

@connect(
  ({loginReducer}) => ({
    userLoginInfo: loginReducer.userLoginInfo
  }),{toggleLogin}
)

class InviteGift extends Component {
  constructor(props){
    super(props)
    this.state = {
      isShowRule:false,
      url:"",
    }
  }

  componentDidMount(){
    let Inviteinfo = this.props.Inviteinfo ?  this.props.Inviteinfo : {};
    if(Inviteinfo.inviteRule){
      let text = "";
      for (let index = 0; index < Inviteinfo.inviteRule.split("\n").length; index++) {
        text += Inviteinfo.inviteRule.split("\n")[index] + "<br/>";
      }
      if(document.getElementById("inviteRule")){
        document.getElementById("inviteRule").innerHTML = text;
      }
    }
    let loginInfo = this.props.userLoginInfo;
    if(loginInfo){
      this.getfenxiangUrl(loginInfo.id);
    }
    clipboard = new Clipboard(".copyBtn");
    this.copyText();
  }

  //展示规则
  showRule = () =>{
    this.setState({
      isShowRule:true
    })
  }

  //关闭邀请有礼
  InviteClose = () =>{
    this.props.onClose();
    this.setState({
      isShowRule:false,
    })
  }

  //分享邀请有礼
  shareToggle = () => {
    if (this.props.userLoginInfo) {
      this.getFanliData();
      clipboard = new Clipboard(".copyBtn");
      this.copyText();
    }else{
      this.props.toggleLogin();
    }
  }

  //复制文本
  copyText = () => {
    clipboard.on('success', function(e) {
      message.success(<span className="msgText">复制成功</span>);
    });
    clipboard.on('error', function(e) {
      message.error(<span className="msgText">复制失败</span>);
    });
  }

  //分类
  async getfenxiangUrl(userId){
    let loginInfo = {
      url:baseUrl + "/invite/setting?appkey=" + appkey + "&userId=" + userId,
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        url:data.data.data.url
      })
    }
  }

  render(){
    let Inviteinfo = this.props.Inviteinfo ?  this.props.Inviteinfo : {};
    let inviteType = this.props.inviteType ? this.props.inviteType : 2 ;
    return (
      <div className="Invitegift clearfix">
        <p className="InviteTitle">邀请好友</p>
        <img src={InviteBGRLImg} alt="" className="InviteBGRImg"/>
        <img src={InviteBGWLImg} alt="" className="InviteBGWImg"/>
        <img src={InviteBGYImg} alt="" className="InviteBGYImg"/>
        <img src={InviteClose} alt="" className="InviteClose" onClick={this.InviteClose}/>
        <div className="getMoney">
          <span className="symbol">￥</span>
          <span className="bigWord">{Inviteinfo.inviteAwards}</span>
          <span>{inviteType === 1?"现金":"优惠券"}</span>
        </div>
        <div className="InviteContent">
          <div className="ContentTitle">
            <span>邀请好友注册得现金红包，邀请越多奖励越多，每邀请1位新用户即￥{Inviteinfo.inviteAwards}</span>
            <span>复制以下链接发送给好友即可邀请</span>
          </div>
          <input type="text" value={this.state.url || ""} readOnly className="shareUrl"/>
          <div className="inviteBottom copyBtn" onClick={this.copyText} data-clipboard-text={this.state.url}>复制链接</div>
          <span className="ViewRules" onClick={this.showRule}>查看邀请规则</span>
          <div className="rulesText">
            {
             inviteType === 1?<div className="text" id="inviteRule">{Inviteinfo.inviteRule}</div>:
             <div className="text">
               <span>
                1. 邀请人通过下方“邀请好友”，分享邀请港颜新用户，新用户下载并注册港颜APP后，邀请人即可获得￥10奖励；<br/>
                2. 新用户在分享页面输入电话，并成功下载注册港颜APP后，邀请人即可在“我的—优惠券”中查看优惠券到账情况，享全场通用无门槛；<br/>
                3. 本优惠券在有效期内，适用于港颜APP全场商品购买，并不能提现；<br/>
                4. 优惠券面额可能发生调整，请以当次活动实际到账面额，购物结算时展示为准。<br/>
              </span>
             </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
export default InviteGift;
