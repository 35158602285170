import React,{ Component } from 'react';
import { Link } from 'react-router-dom';
import "./TemplateD.less";
import { Carousel, Radio } from 'antd';
import ProductTem from "../ProductTem/ProductTem";
import TemplateItem from "../../components/TemplateItem/TemplateItem";

class TemplateD extends Component {
  constructor(props){
    super(props)
    this.state = {
      templateDInfo:props.templateDInfo,
      dotPosition: 'top'
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops){
    if(nextprops.templateDInfo){
      let templateDInfo = nextprops.templateDInfo;
      this.setState({
        templateDInfo
      })
    }
  }
  render(){
    const { templateDInfo ,dotPosition } = this.state;
    let templateDetails = templateDInfo.templateDetailsModels ? templateDInfo.templateDetailsModels : [];
    let brands = templateDInfo.brands ? templateDInfo.brands : [];
    let productList = templateDInfo.dTempShowGoods?templateDInfo.dTempShowGoods:[];
    let newArr = [];
    let pageNum = 3;//单页个数
    let groupNum = Math.ceil(brands.length / pageNum);
    let lackNum = (brands.length % pageNum) ? (brands.length % pageNum) : 3;
    for (let index = 0; index < groupNum; index++) {
      let groupArr = [];
      if (index === groupNum - 1) {
        for (let j = 0; j < lackNum; j++) {
          groupArr.push(brands[index * pageNum + j]); 
        }
      }else{
        for (let j = 0; j < pageNum; j++) {
          groupArr.push(brands[index * pageNum + j]); 
        }
      }
      newArr.push(groupArr);
    }
    return (
      <div className="templateD">
        <div className="inner">
          <div className="recTitle">{templateDInfo?templateDInfo.title:""}</div>
          <div className="recClassify">
            <div className="leftArea">
              <TemplateItem itemInfo={templateDetails.length ? templateDetails[0] : null}/>
            </div>
            <div className="rightArea">
              <div className="section1">
                <TemplateItem itemInfo={templateDetails.length ? templateDetails[1] : null}/>
                <TemplateItem itemInfo={templateDetails.length ? templateDetails[2] : null}/>
                <TemplateItem itemInfo={templateDetails.length ? templateDetails[3] : null}/>
                <TemplateItem itemInfo={templateDetails.length ? templateDetails[4] : null}/>
              </div>
              <div className="section2">
                <div className="brandRec">
                  <p>品牌推荐</p>
                  <div className="clickNext">
                    <Radio.Group
                      onChange={this.handlePositionChange}
                      value={dotPosition}
                      style={{ marginBottom: 8 }}>
                    </Radio.Group>
                  </div>
                </div>
                <Carousel dotPosition={dotPosition} autoplay effect="fade">
                 {newArr.map((item,i) =>{
                   return (
                    <div key={i}>
                      {item.map((brand,j) =>{
                        return(
                        <Link to={'/home/search/' + "brandId=" + brand.id} key={j} target="_blank">
                          <div className="item" >
                            <img src={brand.imgpath} alt=""/>
                            <h2 className="title">{brand.name}</h2>
                          </div>
                      </Link>)
                      })}
                   </div>
                   )
                 })}
                </Carousel>
              </div>
            </div>
          </div>
          <div className="productD clearfix">
            {productList.map((goods,i) =>{
              return(
                <ProductTem goodsInfo={goods} key={i}/>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default TemplateD;