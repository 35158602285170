import * as actionTypes from "../actions/actiontypes";

const initialState = {
  bannerList: [],
  limitBuyData: null,
  homeNavigation: null,
  goodsOn:"",
  cartNum: 0,
  systemData: null,
  giftToggle: false,
  hasGetCoupon: false
};

const homeReducer = (state = initialState, action) => {
  let {bannerList,limitBuyData,homeNavigation,goodsOn,systemData,cartNum,giftToggle,hasGetCoupon} = action;
  switch (action.type) {
    case actionTypes.BANNER_INFO:
      return { ...state, bannerList,limitBuyData,homeNavigation,goodsOn}
    case actionTypes.SYSTEM_SET:
      return { ...state, systemData}
    case actionTypes.CART_NUM_SET:
      return { ...state, cartNum}
    case actionTypes.TOGGLE_GIFT:
      return { ...state, giftToggle}
    case actionTypes.HAS_GET_COUPON:
      return { ...state, hasGetCoupon}
    default:
      return state;
  }
};

export default homeReducer;