import React, {Component} from "react";
import "./CosumesInterests.less";
import bgImg from "../../images/bg_gaozhishu.png";

class CosumesInterests extends Component{
  render(){
    return(
      <div className="cosums_bg">
        <div className="img_bg">
          <img className="img_st" src={bgImg}/>
          <div className="title">消費者權益告知書</div>
          <div className="content">
          尊敬的客户：
          <br/>
              您好！為幫助您更好地選購跨境商品，請您在購買前務必認真、詳細閱讀並完全理解本告知書的全部內容，並對自身風險承擔做出客觀判斷。同意本告知書內容後再下單購買：
          <br/> <br/>
          1.您在港顏上購買的跨境商品等同於境外購買，商品本身可能無中文標籤，如有需要，您可以通過商品詳情頁查看相關商品標籤中文翻譯或聯繫客服。
          <br/>
          2.根據相關法律政策，您選購的跨境商品僅限個人自用，不得進行再次銷售。
          <br/>
          3.您購買的跨境商品符合原產地有關品質、安全、衛生、環保、標識等標準或技術規範要求，可能與我國標準有所不同，由此可能產生的危害、損失或者其他風險，將由您自行承擔。
          <br/>
          4.港顏為自營平臺，商品均保證100%正品。
          <br/> <br/>
          港顏精選全球好貨，嚴控每一個環節，為顧客提供正品保障。
          <br/> <br/>
          感謝您的支持！
          </div>
        </div>
      </div>
    );
  }
}

export default CosumesInterests;
