import React,{ Component } from 'react';
import "./Sidebar.less";
import { Badge } from 'antd';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import InviteGift from "../InviteGift/InviteGift";
import buyCar from "../../images/tool_ic_car_nor.png";
import App from "../../images/tool_ic_app_nor.png";
import PeopleGift from "../../images/tool_ic_gift_nor.png";
import Invite from "../../images/tool_ic_yaoqing.png";
import Top from "../../images/tool_ic_top.png";
import QRcode from "../../images/footer_erweima.png";
import homeAction from '../../actions/homeAction';
import loginAction from "../../actions/loginAction.js";

const { setGiftToggle } = homeAction;
const {toggleLogin} = loginAction;

@connect(({homeReducer,loginReducer}) => ({
    systemData: homeReducer.systemData,
    userLoginInfo: loginReducer.userLoginInfo,
    cartNum: homeReducer.cartNum,
    hasGetCoupon: homeReducer.hasGetCoupon
  }),{setGiftToggle,toggleLogin}
)

class Sidebar extends Component{
  constructor(){
    super()
    this.state = {
      positionType:{position:"absolute",right:"-100px"},
      isChange:false,
      showInviteGift:false
    }
  }
  componentDidMount(){
    //监控屏幕滚动距离
    let lastScrollTop = 0;
    window.addEventListener('scroll',() =>{
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset;
      //判断是否是首页
      if(!this.props.isHomePage){
        //
        if (lastScrollTop >= 185 && scrollTop < 185) {
          this.setState({
            positionType:{position:"absolute",right:"-100px"}
          })
        }else if (lastScrollTop < 185 && scrollTop >= 185){
          this.setState({
            positionType:{position:"fixed",marginLeft: "1210px"}
          })
        }
      }else{
        if (lastScrollTop >= 650 && scrollTop < 650) {
          this.setState({
            positionType:{position:"absolute",right:"-100px"}
          })
        }else if (lastScrollTop < 650 && scrollTop >= 650){
          this.setState({
            positionType:{position:"fixed",marginLeft: "1210px"}
          })
        }
      }
      lastScrollTop = scrollTop;
    })
  }

  //回到顶部
  goTop = () =>{
    let scrollToTop = window.setInterval(() =>{
      let scrollTop = window.pageYOffset;
      if( scrollTop > 0 ){
        window.scrollTo( 0 , scrollTop - 150 );
      }else {
        window.clearInterval( scrollToTop );
      }
    }, 2)
    window.scrollTo( 0 , 0 );
  }

  //打开邀请有礼
  changeInviteGift = () =>{
    if (this.props.userLoginInfo) {
      this.setState({
        showInviteGift:true
      })
    }else{
      this.props.toggleLogin();
    }
  }

  //关闭邀请有礼
  closeInviteGift = () =>{
    this.setState({
      showInviteGift:false
    })
  }

  //开启新人礼包
  showGift = () => {
    this.props.setGiftToggle(true);
  }

  //跳转到购物车
  toCart = () => {
    if (this.props.userLoginInfo) {
      const w=window.open('about:blank');
      w.location.href = `/home/buycart`;
    }else{
      this.props.toggleLogin();
    }
  }

  render(){
    let Inviteinfo = {};
    let openInviteFlag = 0;
    let inviteType = "";
    if(this.props.systemData){
      inviteType = this.props.systemData.inviteType;
      openInviteFlag = this.props.systemData.openInviteFlag;
      Inviteinfo = this.props.systemData.inviteCashSet;
    }
    return(
      <div className="Sidebar" style={this.state.positionType}>
        <div className="linkBox" onClick={this.toCart}>
          <Badge count={this.props.cartNum}>
            <img src={buyCar} alt="" className="linkImg"/>
          </Badge>
          <p className="title">购物车</p>
        </div>
        <div className="linkBox">
          <img src={App} alt="" className="linkImg"/>
          <p className="title">APP</p>
          <div className="QRcode">
            <img src={QRcode} alt=""/>
          </div>
        </div>
        {
          !this.props.hasGetCoupon && 
          <div className="linkBox" onClick={this.showGift}>
            <img src={PeopleGift} alt="" className="linkImg"/>
            <p className="title">新人礼包</p>
          </div>
        }
        {
          openInviteFlag === 1 && <div className="linkBox" onClick={this.changeInviteGift}>
            <img src={Invite} alt="" className="linkImg"/>
            <p className="title">邀请有礼</p>
          </div>
        }
        <div className="linkBox" onClick={this.goTop}>
          <img src={Top} alt="" className="linkImg"/>
          <p className="title">回到顶部</p>
        </div>
        {
          <div className="Invite" style={this.state.showInviteGift?{}:{display:"none"}}>
            <InviteGift onClose={this.closeInviteGift} Inviteinfo={Inviteinfo} inviteType={inviteType}/>
          </div>
        }
      </div>
    )
  }
}
export default Sidebar;