import React,{ Component } from "react";
import "./NavBar.less";
import { Icon } from "antd";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import homeAction from '../../actions/homeAction';
import { getDataFromServer } from '../../httpRequest/Api.js';
import TemplateLinktype from "../../utils/TemplateLinktype";

const { bannerInfo } = homeAction;

@connect(({homeReducer}) => ({
  homeNavigation: homeReducer.homeNavigation
}),{ bannerInfo }
)

class NavBar extends Component {
  constructor(props){
    super(props)
    this.state = {
      navigationInfo:{},
      templateDetails:[],
      classifyList:[]
    }
    this.getClassifyList = this.getClassifyList.bind(this);
    this.TypeIdChange = this.TypeIdChange.bind(this);
  }
  componentDidMount = () =>{
    this.getClassifyList();
  }

  //分类id改变
  TypeIdChange = (typeId) =>{
    this.setState({
      name:"",
      typeid:typeId
     });
  }

  //分类
  async getClassifyList(){
    let loginInfo = {
      url:baseUrl + "/pc/gettypeinfo?appkey=" + appkey,
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        classifyList:data.data.data
      })
    }
  }

  render(){
    const { homeNavigation } = this.props;
    let templateDetails = homeNavigation?homeNavigation.templateDetails?homeNavigation.templateDetails:[]:[];
    return(
      <div className="topTabBox">
        <div className="inner">
          <div className="TopClassify">
            <div className="toptitle">
              所有分類
            </div>
            <ul className={this.props.match.isExact?"topMenu":"topMenu isHomePage"}>
              {
                this.state.classifyList.map(item =>{
                  return(
                    <li key={item.id} style={item.id == 463 || item.id == 279 || item.id == 182?{"display":"none"}:{}}>
                      <div className="MenuInner" onClick={this.TypeIdChange.bind(this,item.id)}><Link to={'/home/search/' + "id=" + item.id } target="_blank" >{item.name}<Icon className="rightIcon" type="right" /></Link></div>
                      <div className="secClassify">
                        {
                          item.twoTypeList.map(twoItem => {
                            return (
                              <dl key={twoItem.id}>
                                <dt onClick={this.TypeIdChange.bind(this,twoItem.id)}><Link to={'/home/search/' + "id=" + twoItem.id } target="_blank">{twoItem.name}</Link></dt>
                                <dd>
                                {
                                  twoItem.threeTypeList.map(threeItem => {
                                    return(
                                      <Link to={'/home/search/' + "id=" + threeItem.id } onClick={this.TypeIdChange.bind(this,threeItem.id)}  key={threeItem.id} target="_blank">{threeItem.name}</Link>
                                    )
                                  })
                                }
                                </dd>
                              </dl>
                            )
                          })
                        }
                      </div>
                    </li>
                )})
              }
            </ul>
          </div>
          <div className="topNav">
            <ul className="Nav">
              <li><Link to={"/home"}>首頁</Link></li>
              {
                templateDetails.map((item,i) =>{
                  let path = TemplateLinktype(item,"template");
                  return (<li key={i}><Link to={path} target="_blank">{item.title}</Link></li>)
                })
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default NavBar;
