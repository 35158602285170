import axios from "axios";
import * as actionTypes from "./actiontypes.js";

const loginAction = {
	//登录注册用户协议
	toggleRuleClick: () => ({
	  type: actionTypes.TOGGLE_RULE
	}),
	//登录注册成功
	successRegister: () => ({
	  type: actionTypes.REGISTER_SUCCESS
	}),
	//登录信息存储
	loginInfo: (userLoginInfo) => ({
		type: actionTypes.LOGIN_INFO,
		userLoginInfo
	}),
	//全局登录弹窗开关
	toggleLogin: () => ({
	  type: actionTypes.SHOW_LOGIN
	})
}

export default loginAction;
