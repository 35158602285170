
import React, {Component} from "react";
import "./HomeContent.less";
import {connect} from 'react-redux';
import homeAction from '../../actions/homeAction';
import LimitBuy from "../../components/LimitBuy/LimitBuy";
import TemplateA from "../../components/TemplateA/TemplateA";
import TemplateB from "../../components/TemplateB/TemplateB";
import TemplateC from "../../components/TemplateC/TemplateC";
import TemplateD from "../../components/TemplateD/TemplateD";
import HotSellInfo from "../../components/HotSellInfo/HotSellInfo.js";
import TemplateHotsale from "../../components/TemplateHotsale/TemplateHotsale.js";
import Sidebar from "../../components/Sidebar/Sidebar";
import { getDataFromServer } from '../../httpRequest/Api.js';

const { bannerInfo } = homeAction;

@connect(state=>state.homeReducer,
  { bannerInfo } )

class HomeContent extends  Component{
  constructor(props){
    super(props)
    this.state = {
      ModuleInfo:[]
    }
  }

  componentDidMount(){
    this.getModuleInfo();
  }

  //获取模板信息
  async getModuleInfo (){
    let loginInfo = {
      url: baseUrl + "/pchome/secondpart?appkey="+appkey,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    this.setState({
      ModuleInfo:data.data.data
    })
  }
  
  render() { 
    const {ModuleInfo} = this.state;
    let showlimitGood = false;
    if(this.props.limitBuyData){
      this.props.limitBuyData.limitGoods.length ? showlimitGood = true : showlimitGood = false;
    }
    return (
      <div className="content">
        <div className="inner">
          <Sidebar isHomePage={true}/>
          {showlimitGood && <LimitBuy/>}
          {
            ModuleInfo.map((item,i) =>{
              switch (item.templateNo) {
                case "A":
                  return <TemplateA key={i} templateAInfo={item}/>;
                  break;
                case "B":
                  return <TemplateB key={i} templateBInfo={item}/>;
                  break;
                case "C":
                  return <TemplateC key={i} templateCInfo={item}/>;
                  break;
                case "D":
                  return <TemplateD key={i} templateDInfo={item}/>;
                  break;
                case "news":
                  return <HotSellInfo key={i} templateNewsInfo={item}/>;
                  break;
              }
            })
          }
          {this.props.goodsOn === 1 && <TemplateHotsale />}
        </div>
      </div>
    );
  }
}

export default HomeContent;