import React,{ Component } from "react";
import {connect} from 'react-redux';
import Cookies from 'js-cookie';
import md5 from 'md5';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import InputItem from "../../components/InputItem/InputItem.js";
import loginAction from "../../actions/loginAction.js";
import "./LoginPage.less";
import loginBanner from "../../images/load_banner.png";
import numberImg from "../../images/ic_number.png";
import yanzhengma from "../../images/ic_yanzhegnma.png";
import password from "../../images/ic_code.png";
import errorIcon from "../../images/ic_error.png";
import selected from "../../images/ic_selected.png";
import unselected from "../../images/ic_unselected.png";
import close from "../../images/ic_delete.png";
import qq from "../../images/ic-qq.png";
import wechat from "../../images/ic-wecat.png";
import { getDataFromServer } from '../../httpRequest/Api.js';

const {toggleRuleClick,successRegister,loginInfo,toggleLogin} = loginAction;

@connect(({loginReducer}) => ({
    showLogin: loginReducer.showLogin
  }),{toggleRuleClick,successRegister,loginInfo,toggleLogin}
)

class LoginPage extends Component {
  constructor(props){
    super();
    this.state=({
      errText: "", //错误提示文案
      errType: "", //错误提示类型
      hasSelRule: true, //用户协议是否勾选
      type: props.match.params.type ? props.match.params.type : "loginCode", //登录框当前属于哪种类型 register注册 login账号密码登录  loginCode验证码登录
      hasSelRem: false,  //是否勾选记住我
      registerPhone: "",  //注册手机号
      registerCode: "",   //注册验证码
      registerPassword: "",  //注册密码
      loginPassword: "",   //登录密码
      loginPhone: "",    //登录手机号
      codePhone: "",    //验证码登录手机号
      loginCode: ""    //验证码登录验证码
    })
    this.LoginBtnClick = this.LoginBtnClick.bind(this);
  }

  //选择用户协议
  selRule = () => {
    this.setState({
      hasSelRule: !this.state.hasSelRule
    })
  }

  //选择记住我
  selRem = () => {
    this.setState({
      hasSelRem: !this.state.hasSelRem
    })
  }

  //获取输入框的值
  getValue = (value) => {
    this.setState({
      errText: "",
      errType: "",
      [value.keyword]:value.value
    })
  }

  //改变状态
  changeType = (type) => {
    if (this.state.type !== type) {
      this.setState({
        type,
        errText: "",
        errType: ""
      })
    }
  }

  //改变登录方式
  loginWayChange = () => {
    let {type} = this.state;
    if (type === "login"){
      type = "loginCode";
    }else{
      type = "login";
    }
    this.setState({
      type,
      errText: "",
      errType: ""
    })
  }

  //错误提示
  errorTip = (errText,errType) =>{
    this.setState({
      errText,
      errType
    })
  }

  //注册接口数据解析
  registerResult = (data) => {
    if (data.result === 0) {
      Cookies.set('loginInfo', data.user, { expires: 30, path: '/',Domain: window.location.hostname });
      // this.props.successRegister();
      this.props.loginInfo(data.user);
      this.props.toggleLogin();
      location.reload();
    }else{
      this.errorTip(data.msg);
    }
  }

  //账号登录数据解析
  loginResult = (data) => {
    if (data.result === 0) {
      Cookies.set('loginInfo', data.user, { expires: 30, path: '/',Domain: window.location.hostname });
      this.props.loginInfo(data.user);
      this.props.toggleLogin();
      location.reload();
    }else{
      this.errorTip(data.msg);
    }
  }

  //验证码登录数据解析
  loginCodeResult = (data) => {
    if (data.result === 0) {
      Cookies.set('loginInfo', data.user, { expires: 30, path: '/',Domain: window.location.hostname });
      this.props.loginInfo(data.user);
      this.props.toggleLogin();
      location.reload();
    }else{
      this.errorTip(data.msg);
    }
  }

  //点击登录注册
  async LoginBtnClick () {
    let {registerPhone,registerCode,registerPassword,loginPassword,loginPhone,codePhone,loginCode,type,hasSelRule} = this.state;
    let reqUrl = "";
    let method = "";
    let loginInfo = null;
    let response = null;
    switch (type) {
      case "register":
        if (registerPhone.length < 11) {
          this.errorTip(registerPhone ? "请输入正确的手机号" : "请输入手机号码" ,"registerPhone");
          return;
        }
        if (registerCode.length < 4) {
          this.errorTip(registerCode ? "请输入正确的验证码" : "请输入验证码","registerCode");
          return;
        }
        if (registerPassword.length < 6) {
          this.errorTip(registerPassword ? "请输入不含特殊符号的6-16位密码" : "请输入密码","registerPassword");
          return;
        }
        if (!hasSelRule) {
          this.errorTip("请先接受相关用户协议","");
          return;
        }
        reqUrl = `${baseUrl}/webUser/register?phoneNum=${registerPhone}&password=${md5(registerPassword).toUpperCase()}&code=${registerCode}&appkey=${appkey}`;
        method = "PUT";
        loginInfo = {
          url: reqUrl,
          method
        }
        response = await getDataFromServer(loginInfo);
        this.registerResult(response.data);
        break;
      case "login":
        if (loginPhone.length < 11) {
          this.errorTip(loginPhone ? "请输入正确的手机号" : "请输入手机号码","loginPhone");
          return;
        }
        if (loginPassword.length < 6) {
          this.errorTip(loginPassword ? "请输入6-16位密码" : "请输入密码","loginPassword");
          return;
        }
        reqUrl = `${baseUrl}/webUser/phoneNumAndPwdLogin?phoneNum=${loginPhone}&password=${md5(loginPassword).toUpperCase()}&appkey=${appkey}`;
        method = "POST";
        loginInfo = {
          url: reqUrl,
          method
        }
        response = await getDataFromServer(loginInfo);
        this.loginResult(response.data);
        break;
      case "loginCode":
        if (codePhone.length < 11) {
          this.errorTip(codePhone ? "请输入正确的手机号" : "请输入手机号码","codePhone");
          return;
        }
        if (loginCode.length < 4) {
          this.errorTip(loginCode ? "请输入正确的验证码" : "请输入验证码","loginCode");
          return;
        }
        reqUrl = `${baseUrl}/webUser/phoneNumFastLogin?phoneNum=${codePhone}&code=${loginCode}&appkey=${appkey}`;
        method = "POST";
        loginInfo = {
          url: reqUrl,
          method
        }
        response = await getDataFromServer(loginInfo);
        this.loginCodeResult(response.data);
        break;
    }
  }

  //关闭登录弹窗
  closeBox = () =>{
    this.props.toggleLogin();
  }

  render(){
    let hide = {display:"none"};
    const qqUrl = `https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101776865&redirect_uri=${thirdHost}/login/bindthird&state=qq`;
    const weUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=wxd986430be30b6cdc&redirect_uri=${thirdHost}/login/bindthird&response_type=code&scope=snsapi_login&state=wechat#wechat_redirect`;
    const inputStyle = {width:"270px"};
    const {toggleRuleClick} = this.props;
    return(
      <div className="LoginPage" style={this.props.showLogin ? {} : hide}>
        <div className="loginBox">
          <div className="couponBoxTitle">您还未登录<img src={close} onClick={this.closeBox} className="close" alt=""/></div>
          <div className="loginTitle">
            <span onClick={this.changeType.bind(this,"login")} className={this.state.type === "login" || this.state.type === "loginCode" ? "titleName borderR active" : "titleName borderR"}>用户登录</span>
            <span onClick={this.changeType.bind(this,"register")} className={this.state.type === "login" || this.state.type === "loginCode" ? "titleName" : "titleName active"}>用户注册</span>
          </div>
          {/*注册*/}
          <div style={this.state.type === "register" ? {} : hide}>
            <InputItem isErr={this.state.errType === "registerPhone"} keyword="registerPhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
            <InputItem isErr={this.state.errType === "registerCode"} codeType="register" errorTip={this.errorTip} style={inputStyle} keyword="registerCode" type="text" maxLength="6" icon={yanzhengma} holderText="请输入验证码" phone={{registerPhone:this.state.registerPhone}} isCode="true" getValue={this.getValue}/>
            <InputItem style={inputStyle} isErr={this.state.errType === "registerPassword"} keyword="registerPassword" type="password" maxLength="16" icon={password} holderText="请输入6-16位密码" getValue={this.getValue}/>
          </div>
          {/*密码登录*/}
          <div style={this.state.type === "login" ? {} : hide}>
            <InputItem isErr={this.state.errType === "loginPhone"} keyword="loginPhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
            <InputItem isErr={this.state.errType === "loginPassword"} style={inputStyle} keyword="loginPassword" type="password" maxLength="16" icon={password} holderText="请输入6-16位密码" getValue={this.getValue}/>
          </div>
          {/*短信登录*/}
          <div style={this.state.type === "loginCode" ? {} : hide}>
            <InputItem isErr={this.state.errType === "codePhone"} keyword="codePhone" style={inputStyle} type="text" maxLength="11" icon={numberImg} holderText="请输入手机号码" getValue={this.getValue}/>
            <InputItem isErr={this.state.errType === "loginCode"} codeType="login" phone={{codePhone:this.state.codePhone}} errorTip={this.errorTip} style={inputStyle} keyword="loginCode" type="text" maxLength="6" icon={yanzhengma} holderText="请输入验证码" isCode="true" getValue={this.getValue} />
          </div>
          <div className="errTip">
            {
              this.state.errText && <div><img className="errIcon" src={errorIcon} alt=""/>{this.state.errText}</div>
            }
          </div>
          <div className="btn" onClick={this.LoginBtnClick}>
            {this.state.type === "register" ? "注册" : "登录"}
          </div>
          {
            this.state.type !== "register" && 
            <div className="loginWay">
              <span className="loginWayChange" onClick={this.loginWayChange}>{this.state.type === "login" ? "短信登录" : "密码登录"}</span>
              {
                this.state.type === "login" && 
                <div className="loginRight">
                  <span onClick={this.selRem}><img className="selected" src={this.state.hasSelRem ? selected : unselected} alt=""/>记住我 </span>|
                  <span> <Link to="/login/reset" target="_blank">忘记密码？</Link></span>
                </div>
              }
            </div>
          }
          {
            this.state.type === "register" &&
            <div className="userRule">
              <img className="selected" onClick={this.selRule} src={this.state.hasSelRule ? selected : unselected} alt=""/>我已阅读并接受：<span className="rule" onClick={toggleRuleClick}>《用户协议与隐私条例》</span>
            </div>
          }
          {
            this.state.type !== "register" &&
            <div className="loginThird">
              <span className="thirdItem">
                <a href={qqUrl} target="_blank"><img src={qq} alt=""/> QQ登录</a>
              </span>
              <span className="thirdItem">
                <a href={weUrl} target="_blank"><img src={wechat} alt=""/> 微信登录</a>
              </span>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default LoginPage;