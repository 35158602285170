import React, { Component } from 'react';
import './ApplyForServe.less';
import { Link,Redirect } from "react-router-dom";
import { getDataFromServer } from '../../httpRequest/Api.js';
import { Upload, Icon, Modal } from 'antd';
import { connect } from 'react-redux';
import loginAction from "../../actions/loginAction.js";
import noneImg from "../../images/img_zhanweifu.png";

const { toggleLogin } = loginAction;

@connect(
  state => state.loginReducer,{ toggleLogin }
)

class ApplyForServe extends Component{
  constructor(props){
    super(props)
    this.state = {
      goodsNum:0,
      reasonId:"",//原因ID
      description:"",//内容描述
      afterSaleReason:[],//售后退款理由
      previewVisible: false,
      previewImage: '',
      qiniuToken:"",
      fileList: [],
      orderType:6,
      afterGoodsInfo:{}
    }
  }
  componentDidMount(){
    this.getRefundReason();
    this.qiniuToken();
    let afterGoodsInfo = JSON.parse(localStorage.getItem("afterGoodsInfo"));
    this.setState({
      afterGoodsInfo,
      goodsNum:afterGoodsInfo.quantity
    })
  }

  //商品数量改变
  changeNum = (event) =>{
    if(event.target.value > this.state.afterGoodsInfo.quantity){
      this.setState({
        goodsNum:this.state.afterGoodsInfo.quantity
      })
    }else{
      this.setState({
        goodsNum:event.target.value
      })
    }
  }

  //编辑数量
  numEdit = (type) => {
    let {goodsNum} = this.state;
    if (type === "add") {
      goodsNum++;
    }else if (type === "dec"){
      goodsNum--;
    }
    if (parseInt(goodsNum) < 1 || parseInt(goodsNum) > this.state.afterGoodsInfo.quantity) {
      return;
    }
    this.setState({
      goodsNum
    })
  }

  //退款理由改变
  reasonChange = (event) =>{
    this.setState({
      reasonId:event.target.value
    })
  }

  //问题描述改变
  desChange = (event) =>{
    if(event.target.value.length < 500){
      this.setState({
        description:event.target.value,
      })
    }
  }

  //图片上传token生成
 async qiniuToken(){
    let loginInfo = {
      url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        qiniuToken:data.data.uploadToken,
      })
    }
  }

  handleCancel = () =>{
    this.setState({ previewVisible: false })
  };

  getBase64 =(file) =>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //图片预览
  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  //改变图片
  handleChange = ({ fileList }) => this.setState({ fileList });

  //提交申请
  submitApply = () =>{
    let { fileList,goodsNum,reasonId,description,afterSaleReason,afterGoodsInfo  } = this.state;
    let orderId = afterGoodsInfo.orderId;
    let goodinfoId = afterGoodsInfo.goodinfoId;
    let saleAfterType = afterGoodsInfo.saleAfterType;
    let reason = "";
    afterSaleReason.forEach(item => {
      if(item.id == reasonId){
        reason = item.reason;
      }
    });

    let newArr = [];
    let flag = true;
    fileList.forEach(item =>{
      if(item.response){
        let URL = "http://img.maigang360.com/" + item.response.hash;
        newArr.push(URL);
        flag = true;
      }else{
        flag = false;
      }
    })

    this.applyForServe(this.props.userLoginInfo.id,orderId,goodinfoId,goodsNum,reasonId,reason,saleAfterType,description,newArr);
  }

  //申请售后
  async applyForServe(userId,orderId,goodinfoId,goodsNum,reasonId,reason,saleAfterType,description,imgs){
    let loginInfo = {
      url:baseUrl + "/refund/applysaleafter?appkey=" + appkey  + "&userId=" + userId + "&orderId=" + orderId + "&goodinfoId=" + goodinfoId +  "&quanty=" + goodsNum + "&reasonId="+ reasonId + "&reason=" + reason + "&saleAfterType=" + saleAfterType + "&description=" + description + "&imgs=" + imgs,
      method: 'POST',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.props.history.push({pathname:"/home/usercenter/" + this.state.orderType});
    }
  }

  //获取退款理由
  async getRefundReason(){
    let loginInfo = {
      url:baseUrl + "/refund/querybyapptab?appkey=" + appkey + "&portType=2" ,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        afterSaleReason:data.data.data.overSeaReason,
      })
    }
  }
  render(){
    //登录拦截
    if(!this.props.userLoginInfo){
      return(<Redirect to="/login/user/loginCode"/>);
     }
    let { fileList,previewVisible,previewImage,goodsNum,description,afterGoodsInfo } = this.state;
    let show = {"display":"inline-block"};
    let hide = {"display":"none"};
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传照片</div>
        <span className="limit">最多只能上传5张</span>
      </div>
    );
    return(
      <div className="applyForServe">
        <div className="inner">
          <div className="guideTitle"><span className="homePage"><Link to={"/home"}>港颜首页</Link></span><span className="userCenter"> > <Link to={"/home/usercenter/order"}>个人中心</Link></span><span className="orderDetail"> > 申请售后</span></div>
          <div className="detailBox">
            <div className="title1">申请售后</div>
            <div className="refundgoods">
               <div className="img"><img src={afterGoodsInfo.image} alt=""/></div>
               <div className="title"><span>{afterGoodsInfo.title}</span></div>
               <div className="count">x{afterGoodsInfo.quantity}</div>
               <div>售后数量：</div>
               <div className="numControl">
                  <div className={parseInt(goodsNum) <= 1 ? "btn disabled" : "btn"} onClick={this.numEdit.bind(this,"dec")}>-</div>
                  <input type="text" maxLength="4" value={goodsNum} onChange={this.changeNum.bind(this)} className="num"/>
                  <div className={parseInt(goodsNum) >= 9999 ? "btn disabled" : "btn"} onClick={this.numEdit.bind(this,"add")}>+</div>
               </div>
            </div>
            <div className="servereason">
              <span className="red">*</span>退款原因:
              <select className="select" value={this.state.reasonId} onChange={this.reasonChange}>
                <option value="" className="first">请选择退款原因</option>
                {this.state.afterSaleReason.map(item =>{
                  return(<option value={item.id} key={item.id} text={item.reason}>{item.reason}</option>)
                })}
              </select>
            </div>
            <div className="describe">
              <span className="title"><i className="red">*</i>问题描述: </span>
              <textarea className="text" id="" cols="5" rows="5" value={description} onChange={this.desChange}></textarea>
              <span className="limit">500字以内</span>
            </div>
            <div className="uploading">
              <span className="title"> 上传图片: </span>
              <div className="uploadImg clearfix">
                <Upload
                  action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </div>
            </div>
            <div className="submitBtn" onClick={this.submitApply}>提交</div>
          </div>
        </div>
      </div>
    )
  }
}

export default ApplyForServe;
