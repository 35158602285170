import React,{ Component } from 'react';
import { Link } from "react-router-dom";
import "./TemplateC.less";
import TemplateItem from "../../components/TemplateItem/TemplateItem";
import TemplateLinktype from "../../utils/TemplateLinktype";

 const TemplateC= ({templateCInfo}) =>{
  let templateDetails = templateCInfo.templateDetailsModels;
  return (
    <div className="templateC">
      <div className="inner">
        <div className="recTitle">{templateCInfo.title}</div>
        <div className="recClassify">
          <div className="leftArea">
            <TemplateItem itemInfo={templateDetails[0]}/>
          </div>
          <div className="rightArea">
            <ul>
              {templateDetails.map((item,i) =>{
                let path = TemplateLinktype(item,"template");
                return(
                  <li className="item" key={i} style={i==0?{display:"none"}:{}}>
                    <Link to={path} target={path === "/home" ? "" : "_blank"}>
                      <img src={item.templateImg} alt="" className="itemImg"/>
                      <h2 className="title1">{item.title}</h2>
                      <h2 className="subTitle">{item.subTitle}</h2>
                      <div className="mask">
                        <h2 className="title2">{item.title}</h2>
                        <span className="clickTo">点击进入</span>
                      </div>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateC;