import * as actionTypes from "../actions/actiontypes";

const initialState = {
  ruleToggle: false,
  showLogin: false,
  isRegisterSuc: false,
  deadLine: 0,
  userLoginInfo: null
};

const loginReducer = (state = initialState, action) => {
  let {ruleToggle,isRegisterSuc,deadLine,showLogin} = state;
  let {userLoginInfo} = action;
  switch (action.type) {
    case actionTypes.TOGGLE_RULE:
      return { ...state, ruleToggle: !ruleToggle}
    case actionTypes.SHOW_LOGIN:
      return { ...state, showLogin: !showLogin}
    case actionTypes.LOGIN_INFO:
      return { ...state, userLoginInfo}
    case actionTypes.REGISTER_SUCCESS:
      if (!isRegisterSuc) {
        deadLine = new Date().getTime() + 3 * 1000;
      }
      return { ...state, isRegisterSuc: !isRegisterSuc, deadLine }
    default:
      return state;
  }
};

export default loginReducer;
