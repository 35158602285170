import React, {Component} from 'react';
import { Link } from "react-router-dom";
import './Banner.less';
import {connect} from 'react-redux';
import { CSSTransitionGroup} from 'react-transition-group';
import prevImage from "../../images/banner_ic_arrow_L.png";
import nextImage from "../../images/banner_ic_arrow_R.png";
import ZiYing from "../../images/ic_ziying.png";
import ZhiYou from "../../images/ic_zhiyou.png";
import Zheng from "../../images/ic_zheng.png";
import Pei from "../../images/ic_pei.png";
import homeAction from "../../actions/homeAction";
import TemplateLinktype from "../../utils/TemplateLinktype";

const { bannerInfo } = homeAction;

@connect(state => state.homeReducer,
	{ bannerInfo }
)

class Banner extends Component{
  constructor(props){
    super(props)
    this.state={
			imageSrc:[],
			currentIndex: 0,
    }

		this.timer = null;
		this.prevImage = this.prevImage.bind(this);
		this.nextImage = this.nextImage.bind(this);
		this.selectImage = this.selectImage.bind(this);
		this.updateIndex = this.updateIndex.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			this.nextImage();
		},4000)
	}

	//上一张
	prevImage = () =>{
		let currentIndex = this.state.currentIndex;
		let len = this.props.bannerList.length;

		currentIndex = (currentIndex - 1) >= 0 ?
				(currentIndex - 1) % len : len - 1;
		this.updateIndex(currentIndex, len);
	}

	// 下一张
	nextImage = () =>{
		let currentIndex = this.state.currentIndex;
		let len = this.props.bannerList.length;

		currentIndex = (currentIndex + 1) % len;
		this.updateIndex(currentIndex, len);
	}

  //点击小圆点更新照片
	selectImage = (index) =>{
		let len = this.props.bannerList.length;
		this.updateIndex(index, len);
	}

	/**
	 * @param  {number} index 将要展示图片的索引
	 * @param  {number} len   展示图片总张数
	 * @param  {number} delay 动画持续时间
	 */
	updateIndex(index, len, delay=5000) {
		/* 清除定时器 */
		this.timer && clearInterval(this.timer);
		this.setState({
			currentIndex: index
		});
		this.timer = setInterval(
        () => {
          var currentIndex = this.state.currentIndex;
            this.setState({
              currentIndex: (currentIndex + 1) % len
            });
        },
        delay
    );
	}

	//组件卸载时，清理定时器
	componentWillUnmount() {
		this.timer && clearInterval(this.timer);
	}
  render(){
		let { currentIndex } = this.state;
		let imageSrc = [];
		// currentIndex = isNaN(currentIndex) ?  0 : currentIndex;
		if(this.props.bannerList.length){
			imageSrc = this.props.bannerList;
		}
		let ele = (
			<CarouselImage
				imageSrc={imageSrc}
				currentIndex={currentIndex}
				enterDelay={1500}
				leaveDelay={1500}
				component={"li"}
				name={"carousel-image-item"}
			/>
		)

    return(
      <div className="Banner">
        <div className="carousel">
				{ele}
				<CarouselNav
					carouselNavItems={imageSrc}
					currentIndex={currentIndex}
					selectImage={this.selectImage}
				/>
        {/* 左右切换的按钮组件 */}
				<div className="button-group">
			   <span className='button-left' onClick={this.prevImage}><img src={prevImage} alt=""/></span>
			   <span className='button-right' onClick={this.nextImage}><img src={nextImage} alt=""/></span>
		    </div>
			</div>
      <div className="contentWrap">
        <ul className="inner">
          <li><img src={ZiYing} alt=""/><span>港顏自營</span></li>
          <li><img src={Zheng} alt=""/><span>正品保障</span></li>
          <li><img src={Pei} alt=""/><span>假一賠十</span></li>
          <li><img src={ZhiYou} alt=""/><span>香港直郵</span></li>
        </ul>
      </div>
     </div>
    )
  }
}

export default Banner;

function CarouselImage(props) {
	let {imageSrc, currentIndex, enterDelay, leaveDelay, name, component} = props;

	return (
		<ul className="carousel-image">
			<CSSTransitionGroup
				component={component}
				transitionName={name}
				transitionEnterTimeout={enterDelay}
				transitionLeaveTimeout={leaveDelay}
				className={name}>
				{imageSrc.length && <Link to={TemplateLinktype(imageSrc[currentIndex],"banner")} target={TemplateLinktype(imageSrc[currentIndex],"banner") === "/home" ? "" : "_blank"} 	key={imageSrc[currentIndex].bannerImage} ><img
					src={imageSrc[currentIndex].bannerImage}
				/></Link> }
			</CSSTransitionGroup>
		</ul>
	);
}

function CarouselNav(props) {
	let {carouselNavItems, currentIndex, selectImage} = props;
	return (
		<ul className="carousel-nav">
			{
				carouselNavItems.map((item, i) =>{
					if(i === currentIndex) {
						return <li className="carousel-nav-item carousel-nav-item-active" key={i} onMouseOver={selectImage.bind(this,i)}></li>
					} else {
						return <li className="carousel-nav-item" key={i} onMouseOver={selectImage.bind(this,i)}></li>
					}
				})
			}
		</ul>
	);
}

