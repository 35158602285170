import React,{Component} from "react";
import "./HelpCenterRightInner.less";

class HelpCenterRightInner extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			showSection:0,
			showRow:0,
			leftTitles:[],
		}
	}
	UNSAFE_componentWillMount(){
		this.setState({
			showSection:this.props.showSection,
			showRow:this.props.showRow,
			leftTitles:this.props.leftTitles,
		});
	}

	UNSAFE_componentWillReceiveProps(props){
		this.setState({
			showSection:props.showSection,
			showRow:props.showRow,
			leftTitles:props.leftTitles,
		});
	}
	selectContent = (section,row) =>{
		if(section == 0 && row == 0){//购物流程
			return <HowToShopping/>
		}else if(section == 0 && row == 1){//优惠券
			return <HelpCoupon/>
		}else	if(section == 1 && row == 0){//售后问题
			return <AfterSaleProblem/>
		}else	if(section == 1 && row == 1){//退货政策
			return <RefundRule/>
		}else	if(section == 1 && row == 2){//取消订单
			return <CancelOrder/>
		}else	if(section == 2 && row == 0){//物流配送
			return <Logistics/>
		}else	if(section == 2 && row == 1){//物流跟踪
			return <LogisticsTrack/>
		}else	if(section == 3 && row == 0){//登录注册
			return <LoginRegerster/>
		}else	if(section == 3 && row == 1){//实名认证
			return <HelpRealName/>
		}else	if(section == 4 && row == 0){//联系我们
			return <ConnectUs/>
		}else{
			return <div></div>
		}
	}
	render(){
		let section = this.state.showSection;
		let row = this.state.showRow;
		let leftTitles = this.state.leftTitles;
		return(
			<div className="help_center_content_bg">
				<div className="help_r_title">{leftTitles[section].smallTitles[row]}</div>
				<div className="help_r_d_line"></div>
				{
					this.selectContent(section,row)
				}
			</div>
		);
	}
}

class HowToShopping extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">如何進行購物?</div>
				</div>
				<div className="help_content">
					1.	查看商品資訊後，點擊頁面下方的加入購物車按鈕，就可以進入購物車頁面，在這裏顧客可以查看已經挑選好的商品，也可以刪除某個商品或修改它的數量，確認好要購買的商品後，單擊確認訂單即可。
					<br/><br/>
					2.港顏目前支持香港直郵、門店自提、香港本地順豐郵寄、大批發採購等方式
					<br/><br/>
					a.選擇直郵，點擊下方確認訂單後，會要求輸入收貨地址，在確認地址後會提示顧客進行實名認證（此步驟爲海關要求，不驗證將無法正常購物），之後點擊提交訂單，完成支付等待收貨即可。正常情況三天內發貨，7-15個工作日會收到貨。
					<br/><br/>
					b.遵照海關總署相關規定，為保證港颜用戶獲得更佳直郵體驗，請各位用戶認真遵守以下注意事項：
					<br/>
					(1).根據海關要求必須實名認證；
					<br/>
					(2).收件人姓名需與實名認證認證資訊（姓名）一致;
					<br/>
					(3).收件人與支付人資訊要求壹致，避免海關扣件；
					<br/>
					(4).直郵訂單每個身份ID每單消費不得超過2000人民幣;
					<br/>
					(5).直郵訂單每個身份ID每年消費金額不得超過2萬人民幣;
					<br/>
					(6).直郵訂單每筆訂單重量不能超過3.5KG。
					<br/>
					(7).60天內，同一個收件人、收件地址及身份資訊，下單次數不能超過十單。
					<br/>
					(8).港顏商城現僅支持支付寶支付。
					<br/>
					c.門店自提：顧客需要選擇門店取貨位址及提貨人資訊，並選擇是否使用優惠券、餘額抵扣，支付款額可選擇全款或者支付一定比例的定金（剩餘訂單金額到門店支付即可），之後確認支付金額後，系統生成到店取貨碼，同時我們也會以短信方式提示顧客取貨資訊，顧客只需在取貨時間到店出示取貨碼進行取貨即可。
					<br/>
					選擇門店自提方式，請注意取貨時間：下單當天14:00前下單，19 :00後可取貨；14:00後下單，次日14:00後可取貨。下單成功後30日內逾期未取貨，所購物品將不再保留，同時款額也不予退還。本次交易視為顧客主動取消。請顧客把握好取貨時間，不以取貨時間為理由退貨退款 。
					<br/>
					d.香港本地寄運方式：
					<br/>
					1，選擇下自提訂單
					<br/>
					2， 請在確認訂單界面，收貨人：XXX，寄順豐。（備註寄順豐）
					<br/>
					3，付款選擇全款支付
					<br/>
					4，順豐到付，準備收貨
					<br/>
					e.大批發採購模式：
					<br/>
					大批發採購微信號：hokoface11
					<br/>
					感謝各位紅口袋用戶的理解配合，祝您購物愉快！
				</div>
			</div>
		);
	}
}

class HelpCoupon extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">关于优惠券的帮助有哪些?</div>
				</div>
				<div className="help_content">
					1.什麼是港顏的優惠券？
				<br/>
					是具有使用有效期和對應優惠金額的電子優惠券，僅用於客戶在港顏提交訂單時抵減應支付商品金額（除運費、稅費外），不能兌現或用於其他用途
				<br/><br/>
					2.如何使用優惠券？
				<br/>
					客戶已有的優惠券可在優惠券頁面或提交訂單頁面中查看。如果在提交訂單時有可使用的優惠券可直接在結算時使用。
				<br/><br/>
				3.優惠券使用規則
				<br/><br/>
					a.優惠券不能兌換現金，優惠金額不能開具發票；
				<br/><br/>
					b優惠券應在券面載明的有效期內使用，過期作廢；
				<br/><br/>
					c.每筆訂單僅能使用一張優惠券，多張優惠券不可合併使用；
				<br/><br/>
					d.優惠券對同一用戶領取優惠券有規定時，如您違反該規定，港顏有權將您已使用優惠券的訂單關閉、並按交易失敗處理，您已實際支付的款項將進行退款，優惠券不予退還。同一用戶是指：根據用戶登錄或使用的港顏帳號，港顏根據其關聯資訊判斷實際為同一用戶。關聯資訊包括但不限於：同一手機號、同一支付帳號、同一身份證號碼或同一設備號；
				<br/><br/>
					e.如港顏已發放的優惠券存在顯失公平等不符合運營目的的情形（包括但不限於發券類別失誤、價格配置失誤），港顏有權將您的優惠券或使用優惠劵的訂單進行凍結或關閉；您已領取未使用的優惠劵將進行凍結；已使用優惠券並支付的訂單，將按交易失敗處理，您已實際支付的款項將進行退款，優惠劵不予退還；
				<br/><br/>
					f.當一筆訂單中包含多個符合使用優惠券條件的商品時，港顏將優惠券按比例抵扣到每個符合條件的商品金額中；
				<br/><br/>
					g.如使用滿減類優惠券的訂單發生售後退貨，優惠券不予退還；如使用直接抵扣類現金券的訂單發生售後退貨，現金券按照退貨商品的金額比例退還。此外，如有其他明確不予退還優惠券的情形時則不予退還；
				<br/><br/>
					h.港顏將不定期對各類優惠券類型和使用規則進行調整，請關注商品詳情頁、活動規則、優惠券使用規則及其他提示。
				<br/><br/>
					4.港顏優惠券規則最終解釋權歸港顏所有。
				</div>
			</div>
		);
	};
}

class AfterSaleProblem extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">關於售後問題的幫助有哪些?</div>
				</div>
				<div className="help_content">
					1. 港顏的進口商品是正品嗎？
				<br/>
					所有港顏售賣的商品，均為原廠正貨，100%正品，港顏嚴格規定商品最少有超過3-6個月的【使用期】，禁止出售假貨。
				<br/><br/>
					2.購買的商品出現品質問題如何處理？
					<br/>
					客戶如在港顏購物後對商品品質有任何疑問，請及時聯繫官方微信客服。
				<br/><br/>
					3.在港顏批量購買有沒有優惠？
				<br/>
					為感謝廣大顧客的理解和支持，港顏會不定期發佈各種優惠活動，請密切關注港顏APP首頁的活動資訊。如有批發意向，請聯繫大批採購微信號。
				<br/><br/>
					4.在港顏購買的商品可以退換貨嗎？
				<br/>
					根據海關總署相關規定，非品質問題，港顏售出的商品不支持退貨；如商品有品質問題，請及時與客服聯繫。
				<br/><br/>
					5.為什麼有的國外包裝很簡單，連外盒、封口都沒有？
				<br/>
					商品的包裝，國外更注重環保和便攜性，而不是包裝如何奢華，大部分國際一線品牌、奢侈品的包裝也很簡單，比如產自歐美、日韓的化妝品，大部分無外盒，不塑封，開口處也沒有封口貼。
				<br/><br/>
					6.國外商品包裝上的保質期、生產日期怎麼看？
				<br/>
					國外的商品包裝上一般不會單獨列出保質期是多長，一般只會在產品包裝上標注生產日期或有效日期，也有只標注出廠批號沒有標日期的情況，比如日本和歐洲的洗護品、化妝品，只有批號沒有標日期（有些品牌可以根據批號讀取日期，在此不做一一列舉）。但請放心，港顏銷售的國外商品都是符合規定的合格產品。有些噴印的日期，在運輸過程中因為摩擦碰撞很容易糊掉或被碰掉，是正常現象。
				<br/><br/>
					下麵列舉幾個國家一般情況下的日期讀取方法：
				<br/>
					*非一般情況下的日期讀取，可諮詢客服
				<br/><br/>
					韓國：
				<br/>
					默認沒有韓文或字母提示的日期一般產生產日期（生產日期和有效日期同時標注時，日期較大的產有效日期），日期格式是“年/月/日”，或“年/月”。也有的是沿用英語系國家的標注方式。 韓文：제조（製造/生產） 韓文：까지（截至）；기한（期限）
				<br/><br/>
					英語：MFG（製造/生產）
				<br/><br/>
					日本：
				<br/>
					日本食品標注的是有效日期，日期格式是“年/月/日”，或“年/月”。 “賞味期限”是指可以美味地享用食品的期限，即便過了該期限，食品仍可以食用。 “消費期限”是指一旦過了該期限，就可能發生腐爛變質，最好不要食
				</div>
			</div>
		);
	};
}

class RefundRule extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">關於退貨政策的幫助有哪些?</div>
				</div>
				<div className="help_content">
					港顏所銷售的海外商品均采自原廠正貨，堅持正品保障。從港顏購買的跨境商品（根據海關總署相關規定）不支持退換貨，如有特殊情況，請及時聯繫我們的客服人員。
				</div>
			</div>
		);
	};
}

class CancelOrder extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">關於取消訂單的幫助有哪些?</div>
				</div>
				<div className="help_content">
				1.當訂單狀態為未支付，如果您想取消訂單，請在“我的訂單”頁面操作欄中點擊取消，即可取消訂單。訂單取消申請一旦提交成功，將無法進行任何修改或恢複，請您謹慎操作。
				<br/><br/>
				2.當訂單已付款或已發貨，將不支持取消或者修改訂單操作。
				<br/><br/>
				3.如需更改收貨地址，請聯繫客服。
				</div>
			</div>
		);
	};
}

class Logistics extends Component{
	render(){
		return(
			<div className="help_content_bg">
			<div className="help_content_title_bg">
				<div className="help_content_cirlce"></div>
				<div className="help_content_title">關於物流配送的幫助有哪些?</div>
			</div>
			<div className="help_content">
			目前港顏支持香港直郵發貨、門店自提、香港本土順豐郵寄、大批發採購等方式
			<br/><br/>
			物流時效：
			<br/>
			1.通過直郵發出的貨品，自物流公司接收包裹至包裹派送到您的手中，需要7-15個工作日（送香港物流倉+清關+國內派送）。
			<br/>
			2.香港本地順豐郵寄，下單后48小時內發貨，順豐到付。
			<br/><br/>
			運費標準
			<br/>
			香港直郵運費首重HKD88/1000g，續重HKD36.5/500g。
			<br/>
			香港直郵時效一般為7-15個工作日，偏遠地區會略有延遲。
			<br/>
			商品已發貨後不支持修改地址。
			</div>
		</div>
		);
	};
}

class LogisticsTrack extends Component{
	render(){
		return(
			<div className="help_content_bg">
			<div className="help_content_title_bg">
				<div className="help_content_cirlce"></div>
				<div className="help_content_title">關於物流跟蹤的幫助有哪些?</div>
			</div>
			<div className="help_content">
			您可以登錄您的港顏app帳戶，在“我的訂單”—訂單詳情頁面中查看您的訂單狀態，具體物流資訊請聯繫客服（即將上線，敬請期待）
			<br/><br/>
			部分物流資訊系統顯示會略有延遲，如訂單已顯示為發貨狀態，但無物流資訊顯示，請您稍等24小時左右，即可查詢到。如24小時後還無法查詢到物流資訊，請聯繫港顏客服。
			<br/><br/>
			請您簽收貨品時，儘量在快遞人員面前檢查商品（如果快遞人員堅持先簽收再驗貨，可以在簽收之後立刻檢查）。
			</div>
		</div>
		);
	};
}

class LoginRegerster extends Component{
	render(){
		return(
			<div className="help_content_bg">
			<div className="help_content_title_bg">
				<div className="help_content_cirlce"></div>
				<div className="help_content_title">如何註冊登錄?</div>
			</div>
			<div className="help_content">
			1.註冊
			<br/>
			點擊註冊，輸入手機號，獲取驗證碼並輸入，即可註冊為會員。顧客也可使用QQ或微信號碼註冊，註冊完成後，按照提示就可以登陸APP首頁了。
			<br/><br/>
			2.登錄
			<br/>
			在登錄介面，顧客輸入正確手機號及密碼，即可登陸APP。又或輸入手機號，獲取驗證碼，輸入正確即可登陸APP。
			<br/><br/>
			3. 忘記密碼
			<br/>
			忘記密碼，請點擊找回密碼選項，按照提示輸入帳戶資訊然後單擊提交，獲取驗證碼後便可修改
			</div>
		</div>
		);
	};
}

class HelpRealName extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">關於實名認證的幫助有哪些?</div>
				</div>
				<div className="help_content">
				1.為什麼要進行實名認證？
				<br/><br/>
				a.根據海關總署公告2016年第26號（關於跨境電子商務零售進出口商品有關監管事宜的公告），電子商務企業應當對購買跨境電子商務零售進口商品的個人（訂購人）身份資訊進行核實，訂購人與支付人應當為同一人，並向海關提供由國家主管部門認證的身份有效資訊。
				<br/><br/>
				b.港顏保證用戶的實名認證資訊僅作海關清關用途，將被嚴格保密。
				<br/><br/>
				2.如何進行實名認證？
				<br/><br/>
				a.在“個人中心” - “用戶資料” - “實名認證”頁面進行實名認證：按照要求填寫姓名與身份證號，保存成功即完成認證啦！
				<br/><br/>
				如果您購買的商品需要實名認證，而您沒有實名認證資訊，提交訂單時，系統會提醒您進行訂購人/收貨人實名認證，實名認證通過並支付完成後，24小時內倉庫人員安排發貨，訂單商品即可進入海關進行清關。
				</div>
			</div>
		);
	};
}

class ConnectUs extends Component{
	render(){
		return(
			<div className="help_content_bg">
				<div className="help_content_title_bg">
					<div className="help_content_cirlce"></div>
					<div className="help_content_title">how to contact us 怎麼聯繫我們?</div>
				</div>
				<div className="help_content">
					Wholesale business cooperation 批發業務合作：
					<br/>
					WeChat 微信：hokoface11
				<br/>
					Whatsapp:+852-62152638
				<br/>
				<br/>
					Supplier cooperation供應商合作：
				<br/>
					WeChat 微信：hokoface11
				<br/>
					Whatsapp: +852-62152638
				<br/>
				<br/>
				Retail after sales 零售售後：
				<br/>
				Telephone 客服電話：+852-68428868
				<br/>
				客服微信：hokobeauty005
				<br/>
				<br/>
					Email 郵箱：hokoface@yahoo.com
				<br/>
					如有任何問題，歡迎通過以上各種方式與我們聯繫。
				<br/>
				 If you have any questions, welcome to contact us    
				<br/>
				 through the above various ways.
				</div>
			</div>
		);
	};
}

export default HelpCenterRightInner;
