import React, { Component } from 'react';
import "./SearchPage.less";
import {connect} from 'react-redux';
import ListNone from "../../components/ListNone/ListNone.js";
import ProductItem from "../../components/ProductItem/ProductItem.js";
import Pager from "../../components/Pager/Pager";
import Sidebar from "../../components/Sidebar/Sidebar";
import { getDataFromServer } from '../../httpRequest/Api.js';
import MORE from "../../images/ic_plus_smaller.png";
import UNFOLD from "../../images/ic_down.png";
import PICKUP from "../../images/ic_up.png";
import DOWN from "../../images/ic_jiantou_down.png";
import UP from "../../images/ic_jiantou_up.png";
import { Icon } from 'antd';

class SearchPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      brand:"",
      type:"",
      brandId:'',//品牌id
      typeUnfold:false,//是否展开分类
      typeid:'',//分类id
      promotionId:-1,//活动Id(-1代表没活动)
      orderName:"",//排序名称
      name:"",//搜索标题
      orderType:"",//排序类型
      priceMin:'',//最低价格
      priceMax:'',//最高价格
      goodInfoList:[],
      brandlist:[],//品牌列表
      typelist:[],//分类列表
      pageCount:"",
      pageNo:1,
      name:"",
      brandEWs:[],
      msg:"",
      result:"",
      onmouseBrand:"",//鼠标移入的品牌
      pageNo: 1,
      pageCount: 0,
      brandMoreSelect:false,//品牌是否多选
      brandUnfold:false,//是否展开品牌
      brandTemSelectList:[],//选择的品牌id
      brandSelectList:[],//确定选择的品牌
      brandNameList:[],//选择的品牌
      isShowBrand:true,//品牌的显示
      isShowType:true,//分类的显示
    }
  }
  componentDidMount = () =>{
    let { name,typeid,brandId,orderName,orderType,priceMin,priceMax,promotionId } = this.state;
    if(this.props.match.params.name.indexOf("name")!=-1){
      name = this.props.match.params.name.split("=")[1];
    }
    if(this.props.match.params.name.indexOf("id")!=-1){
      typeid = this.props.match.params.name.split("=")[1];
    }
    if(this.props.match.params.name.indexOf("brandId")!=-1){
      brandId = this.props.match.params.name.split("=")[1];
    }
    if(this.props.match.params.name.indexOf("promotionId")!=-1){
      promotionId = this.props.match.params.name.split("=")[1];
    }
    this.getSearchPageGoodList(name,typeid,brandId,orderName,orderType,priceMin,priceMax,promotionId,1);
    this.setState({
      name,
      typeid,
      brandId,
      promotionId
    })
  }

  componentWillReceiveProps(nextProps){
    let { name,typeid,brandId,orderName,orderType,priceMin,priceMax,promotionId } = this.state;

    if(nextProps.match.params){
      if(nextProps.match.params.name.indexOf("name")!=-1){
        name = nextProps.match.params.name.split("=")[1];
      }
      if(nextProps.match.params.name.indexOf("id")!=-1){
        typeid = nextProps.match.params.name.split("=")[1];
      }
      if(this.props.match.params.name.indexOf("brandId")!=-1){
        brandId = this.props.match.params.name.split("=")[1];
      }
      if(this.props.match.params.name.indexOf("promotionId")!=-1){
        promotionId = this.props.match.params.name.split("=")[1];
      }
      this.getSearchPageGoodList(name,typeid,brandId,orderName,orderType,"","",promotionId,1);
      this.setState({
        priceMin:'',
        priceMax:'',
      })
    }
  }

  componentWillUnmount (){
    this.setState = (state,callback) => {
      return;
    }
  }

  //点击单个品牌和多选品牌
  seclectBrand = (brandId,brandname) =>{
    let { brandMoreSelect ,brandTemSelectList ,typeid, name ,promotionId } = this.state;
    if(brandMoreSelect){
      if(brandTemSelectList.indexOf(brandId) === -1){
        brandTemSelectList.push(brandId);
      }else{
        brandTemSelectList.splice(brandTemSelectList.findIndex(item => brandId ==item),1);
      }
      this.setState({
        brandTemSelectList
      })
    }else{
      this.setState({
        brandId,
        brand:brandname,
        isShowBrand:false,
        priceMin:"",
        priceMax:"",
        orderName:"",
        orderType:""
      });
      this.getSearchPageGoodList(name,typeid,brandId,"","","","",promotionId,1);
    }
  }

  //确认选择多个品牌
  saveSelectBrand = () =>{
    let { isShowBrand ,brandTemSelectList ,typeid ,name ,brandlist ,promotionId } = this.state;
    let newArr = [];
    brandlist.find(item =>{
      if(brandTemSelectList.indexOf(item.id) !== -1){
        newArr.push(item.name);
      }
    })
    this.setState({
      isShowBrand:false,
      brandNameList:newArr,
      brandSelectList:brandTemSelectList,
      priceMin:"",
      priceMax:"",
      orderName:"",
      orderType:""
    });
    this.getSearchPageGoodList(name,typeid,brandTemSelectList,"","","","",promotionId,1);
  }

  //移除选择的品牌
  removeBrand = () =>{
    this.getSearchPageGoodList(this.state.name,this.state.typeid,"","","","","",this.state.promotionId,1);
    this.setState({
      brandId:"",
      brand:"",
      isShowBrand:true,
      brandNameList:[],
      brandMoreSelect:false,
      brandTemSelectList:[],
      brandSelectList:[],
      brandUnfold:false,
      priceMin:"",
      priceMax:"",
      orderName:"",
      orderType:""
    })
  }

  //展开品牌和收起品牌
  unfoldChange = (unfoldType) =>{
    let { brandUnfold ,typeUnfold} = this.state;
    if(unfoldType === "brand"){
      this.setState({
        brandUnfold:!brandUnfold
      })
    }else if(unfoldType === "type"){
      this.setState({
        typeUnfold:!typeUnfold
      })
    }
  }

  //点击分类
  seclectType = (typeid,name) =>{
    this.setState({
      typeid,
      type:name,
      isShowType:false
    });
    this.getSearchPageGoodList(this.state.name,typeid,"","","","","",this.state.promotionId,1)
  }

  //移除选择的分类
  removeType = () =>{
    this.setState({
      typeid:"",
      type:"",
      priceMin:"",
      priceMax:"",
      orderName:"",
      orderType:"",
      isShowType:true
    });
    this.getSearchPageGoodList(this.state.name,"","","","","","",this.state.promotionId,1)
  }

  //价格改变
  priceChange = (priceType,event) =>{
    if(priceType === "priceMin"){
      this.setState({
        priceMin:event.target.value
      })
    }else if(priceType === "priceMax"){
      this.setState({
        priceMax:event.target.value
      })
    }
  }

  //点击多选
  multiSelect = () =>{
    this.setState({
      brandMoreSelect:true,
      brandUnfold:true
    })
  }

  //取消多选
  cancelMultiSelect = () =>{
    this.setState({
      brandMoreSelect:false,
      brandUnfold:false,
    })
  }

  //根据字母显示品牌
  changeBrand = (brandEW) =>{
    let { onmouseBrand } = this.state;
    this.setState({
      onmouseBrand:brandEW
    })
  }

  //确认价格范围
  priceSearch = () =>{
    let { typeid ,brandId ,brandSelectList ,orderName ,orderType ,priceMin ,priceMax ,name ,promotionId } = this.state;
    //判断是多选品牌还是单选
    if(brandSelectList.length){
      this.getSearchPageGoodList(name,typeid,brandSelectList,orderName,orderType,priceMin,priceMax,promotionId,1);
    }else{
      this.getSearchPageGoodList(name,typeid,brandId,orderName,orderType,priceMin,priceMax,promotionId,1);
    }
  }

  //清除价格
  clearPrice = () =>{
    this.setState({
      priceMax:"",
      priceMin:""
    })
  }

  //点击综合、销量、价格
  getRankValue = (type) =>{
    let { orderName ,orderType ,typeid ,priceMax ,priceMin,brandId,brandSelectList ,promotionId} = this.state;
    if(type === "synthesis"){
      orderName = "";
      orderType = "";
    }else if(type === "salesNumber"){
      orderName = "sales";
      if(orderType === ""){
        orderType = "asc";
      }else if(orderType === "asc"){
        orderType = "desc";
      }else if(orderType === "desc"){
        orderType = "asc"
      }
    }else if(type === "price"){
      orderName = "price";
      if(orderType === ""){
        orderType = "asc";
      }else if(orderType === "asc"){
        orderType = "desc";
      }else if(orderType === "desc"){
        orderType = "asc";
      }
    }
    this.setState({
      orderName,
      orderType,
    });

    //判断是多选品牌还是单选
    if(brandSelectList.length){
      this.getSearchPageGoodList(this.state.name,typeid,brandSelectList,orderName,orderType,priceMin,priceMax,promotionId,1);
    }else{
      this.getSearchPageGoodList(this.state.name,typeid,brandId,orderName,orderType,priceMin,priceMax,promotionId,1);
    }
  }

  //产品搜索
  async getSearchPageGoodList (name,typeIds,brandIds,orderName,orderType,priceMin,priceMax,promotionId,pageNo){
    let loginInfo = {
      url:baseUrl + "/goodinfo/websearch?appkey=" + appkey + "&name=" + name + "&typeIds=" + typeIds + "&brandIds=" + brandIds  + "&pageNo=" + pageNo +"&pageSize=20" + "&orderName=" + orderName + "&orderType=" + orderType + "&priceMin=" + priceMin + "&priceMax=" + priceMax + "&promotionId=" + promotionId + "&promotionType=0",
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
      this.setState({
        goodInfoList:data.data.goodInfoList,
        brandlist:data.data.brandlist,
        typelist:data.data.typelist,
        pageCount:data.data.pageCount,
        pageNo:data.data.pageNo,
        name:name,
        brandEWs:data.data.brandEWs,
        msg:data.data.msg,
        result:data.data.result
      });
    }else if(data.data.result === 5){
      this.setState({
        goodInfoList:data.data.goodInfoList,
        brandlist:data.data.brandlist,
        typelist:data.data.typelist,
        pageCount:data.data.pageCount,
        pageNo:data.data.pageNo,
        name:name,
        brandEWs:data.data.brandEWs,
        msg:data.data.msg,
        result:data.data.result
      });
    }
  }

  //获取页码
  getPage = (page) => {
    let { name ,typeid ,brandId ,brandSelectList ,orderName ,orderType ,priceMin ,priceMax ,promotionId } = this.state;
    this.getSearchPageGoodList(name,typeid,"",orderName,orderType,priceMin,priceMax,promotionId,page);
  }

  render() {
    let { priceMin ,priceMax ,brandUnfold ,onmouseBrand ,brandMoreSelect ,typeUnfold ,brand ,type ,isShowBrand ,isShowType ,brandNameList ,brandSelectList,brandTemSelectList ,orderType ,orderName , goodInfoList, typelist, brandEWs , msg , result , brandlist ,pageCount ,pageNo } = this.state;
    let show = {};
    let hide = {display:"none"};
    if(brandNameList.length){
      brandNameList.forEach(item =>{
        brand += item + "、";
      })
      brand = brand.slice(0,-2);
    }

    if(result === 5){
      isShowBrand = false;
      isShowType = false;
    }
    if(!brandlist.length){
      isShowBrand = false;
    }
    if(!typelist.length){
      isShowType = false;
    }
    return (
      <div className="searchPage">
        <div className="inner">
          <Sidebar isHomePage={false} />
          <div className="SearchCondition">
            <div className="choseCondition">
              <span className="fixedText">全部结果 <i className="partition">></i> </span>
              <span className="SelectBox" style={brand.length?show:hide}><span className="value" onClick={this.removeBrand.bind(this)}>品牌 : <i>{brand}</i><Icon type="close"  className="icon" /></span><span className="content" style={brandMoreSelect?show:hide}>{brand}</span></span>
              <span className="SelectBox" style={type.length?show:hide}><i className="partition" style={brand.length?show:hide}>></i><span className="value" onClick={this.removeType.bind(this)}>分类 : <i>{type}</i><Icon type="close" className="icon" /></span></span>
              <span className="fixedText" style={name.length?show:hide}><i className="partition" style={brand.length ||type.length?show:hide}>></i>{name}</span>
            </div>
            <div className="noproduct" style={result === 5?show:hide}>
              <div className="text"><span>抱歉！没有找的</span><span className="noproductText">"{msg}"。</span >的相关商品，我们为您推荐以下商品。</div>
            </div>
            <div className="conditionContent" style={isShowBrand || isShowType ?show:hide}>
              <div className="brandbox" style={isShowBrand?show:hide}>
                <div className="brandboxwrap">
                  <div className="name">品牌</div>
                  <ul className="letterBox" style={brandUnfold?show:hide}>
                    <li onMouseOver={this.changeBrand.bind(this,"")}>全部</li>
                    {brandEWs.map((item,i) =>{
                      return <li key={i} onMouseOver={this.changeBrand.bind(this,item)}>{item}</li>
                    })}
                  </ul>
                  <div className={brandUnfold?"hdbrands autoheight":"hdbrands"}>
                    <div className="brandcontent">
                       <div className="brands">
                        {brandlist.map((item,i) =>{
                          if(onmouseBrand === item.brandEW){
                            return <span key={i} onClick={this.seclectBrand.bind(this,item.id,item.name)} className={brandTemSelectList.indexOf(item.id) === -1 ?"multiItem":"multiItem multi-active"}>{item.name}</span>
                          }else if(onmouseBrand === ""){
                            return <span key={i} onClick={this.seclectBrand.bind(this,item.id,item.name)} className={brandTemSelectList.indexOf(item.id) === -1 ?"multiItem":"multiItem multi-active"}>{item.name}</span>
                          }
                        })}
                       </div>
                    </div>
                    <div className="button-group" style={brandMoreSelect?show:hide}>
                      <div className={brandTemSelectList.length?"saveBtn ative":"saveBtn"} onClick={this.saveSelectBrand}>确定</div>
                      <div className="cancelBtn" onClick={this.cancelMultiSelect}>取消</div>
                    </div>
                  </div>
                  <div className="morewrap" style={brandMoreSelect?hide:show}>
                    <div className="multiSelect" onClick={this.multiSelect}><Icon type="plus" className="plus"/>多选</div>
                    <div className="unfold" onClick={this.unfoldChange.bind(this,"brand")} >
                      {brandUnfold?<span>收起 <Icon type="up" className="downUp"/></span>:<span>展开 <Icon type="down" className="downUp"/></span>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="typebox" style={isShowType?show:hide}>
                <div className="typeboxwrap">
                  <div className="name">分类</div>
                  <div className={typeUnfold?"hdtype autoheight":"hdtype"}>
                    <div className={typelist && typelist.length > 6?"typecontent":"typecontent mintypecontent"}>
                      <div className="types">
                        { typelist && typelist.map((item,i) =>{
                          return <span key={i} onClick={this.seclectType.bind(this,item.id,item.name)} className="multiItem">{item.name}</span>
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="morewrap" style={ typelist && typelist.length > 6?show:hide}>
                    <div className="unfold" onClick={this.unfoldChange.bind(this,"type")} >
                      {typeUnfold?<span>收起 <Icon type="up" className="downUp"/></span>:<span>展开 <Icon type="down" className="downUp"/></span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rankCondition" style={result === 5?hide:show}>
              <div className="left">
                <div className={orderName === ""?"ative":""} onClick={this.getRankValue.bind(this,"synthesis")}>综合</div>
                <div className={orderName === "sales"?"ative":""} onClick={this.getRankValue.bind(this,"salesNumber")}>销量<img src={orderType === "asc"?UP:DOWN} alt=""/></div>
                <div className={orderName === "price"?"ative":""} onClick={this.getRankValue.bind(this,"price")}>价格<img src={orderType === "desc"?DOWN:UP} alt="" /></div>
              </div>
              <div className="right">
                <div className="priceBox">
                  <div className="priceInputs">
                    <div className="input"><input type="text" value={priceMin} onChange={this.priceChange.bind(this,"priceMin")} placeholder="¥"/> <i>—</i> <input type="text" value={priceMax} onChange={this.priceChange.bind(this,"priceMax")} placeholder="¥"/></div>
                    <div className="btn">
                    <span className="clear" onClick={this.clearPrice}>清空</span><span className="sure" onClick={this.priceSearch}>确定</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product clearfix">
          {
            goodInfoList && goodInfoList.map((item,i) =>{
              return (
                <li className={( i + 1 ) % 4 === 0 ? "ModelGoodsItem" : "ModelGoodsItem marR"} key={i}>
                  <ProductItem goodsInfo={item}/>
                </li>
              )
            })
          }
          </div>
          <ListNone list={goodInfoList} text={msg}/>
          <div className="page">
            <Pager total={pageCount} current={pageNo} getPage={this.getPage}/>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchPage;
