import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import Cookies from 'js-cookie';
import "./Header.less";
/* import headHKDApp from "../../images/head_hkd_app.png"; 红口袋logo*/
import headHKDApp from "../../images/footer_erweima.png";
import dropDownGray from "../../images/ic_more_gray.png";
import dropDownRed from "../../images/ic_more_red.png";
import newsFL from "../../images/ic_news_fanli.png";
import newsTX from "../../images/ic_news_tixian.png";
import newsXX from "../../images/ic_news_xiaoxi.png";
import newsYHQ from "../../images/ic_news_youhuiquan.png";
import newsZKK from "../../images/ic_news_zhekouka.png";
import lookforDetail from "../../images/ic_lookfor_detail.png";
import emptyNotice from "../../images/ic_notice_blank.png";

import loginAction from "../../actions/loginAction.js";
const {loginInfo,toggleLogin } = loginAction;

@connect(
  state => state.loginReducer,
  {loginInfo,toggleLogin}
)

class Header extends React.Component{
  constructor(props){
    super(props);
    if (Cookies.get('loginInfo')) {
      props.loginInfo(JSON.parse(Cookies.get('loginInfo')));
    }
    this.state = {
      drop_hover:false,//个人中心下拉菜单是否悬停
      news_count:0,//消息数
    };
  }
  componentDidMount(){
    this.loadNewsCount()
  }

  handleDropMouseOver = (e) =>{
    this.setState({
      drop_hover:true
    })
  }

  handleDropMouseOut = () =>{
    this.setState({
      drop_hover:false
    })
  }

  //获取官方消息数
  async loadNewsCount(){
    if (Cookies.get('loginInfo')) {
      let userInfo = JSON.parse(Cookies.get('loginInfo'));
      if (userInfo){
        let userId = userInfo.id;
        let reqUrl = `${baseUrl}/msg0?appTab=2&appkey=${appkey}&userId=${userId}`;
        let method = "GET";
        let newsReq = {
          url:reqUrl,
          method
        }
        let response = await getDataFromServer(newsReq)
        this.setState({
          news_count:response.data.count,
        });
      }
    }
  }

  //退出登录
  loginOutClick = () =>{
    Cookies.remove('loginInfo', {Domain: window.location.hostname,Path: "/"});
    Cookies.remove('feedback', {Domain: window.location.hostname,Path: "/"});
    this.props.loginInfo(null);
    location.reload();
  }

  LoginOrLoginout = (msgNum) =>{
    if (this.props.userLoginInfo){
      return(
        <div className="fl">
          <span className="marginR handle mouseOver">{this.props.userLoginInfo.nickname}</span>
          <span className="marginR handle mouseOver" onClick={this.loginOutClick}>退出</span>
          <div className="marginR dropDownMouseOver">
            <span className="mouseOver handle" onClick={this.toFeedback.bind(this,"moreofficialnews")}>消息</span>
            {(msgNum > 0) && <div className="fr news_num">{msgNum}</div>}
            {/*官方消息  下拉框暂时隐藏*/}
            {/* <NewsDropList news_num={msgNum}/> */}
          </div>
        </div>
      );
    }else{
      return(
        <div className="fl">
          <span className="marginR"><Link className="handle mouseOver" to="/login/user/loginCode">登录</Link></span>
          <span className="marginR"><Link className="handle mouseOver" to="/login/user/register">免费注册</Link></span>
        </div>
      );
    }
  }

  DropDownItemList = () =>{
    let itemList=[{name:"我的余额",type:"rebatevalue"},
                  {name:"优惠券",type:"coupon"},
                  {name:"我的折扣卡",type:"rebatecard"},
                  {name:"账户管理",type:"usermanager"},
                  {name:"实名认证",type:"realname"},
                  {name:"收货地址",type:"address"},
                  {name:"我的收藏",type:"mycollection"},
                  {name:"我的足迹",type:"myfoot"}];
    return(
      <div className="dropDownList">
        {itemList.map((item,index) => {
          return <div key={index} className="dropItem" onClick={this.toOrder.bind(this,item.type)}>{item.name}</div>
        })}
      </div>
    )
  }

  //跳转到个人中心
  toOrder = (type) => {
    if (this.props.userLoginInfo) {
      const w=window.open('about:blank');
      w.location.href = `/home/usercenter/${type}`;
    }else{
      this.props.toggleLogin();
    }
  }

  //跳转到意见反馈和消息
  toFeedback = (type) =>{
    if (this.props.userLoginInfo) {
      const w=window.open('about:blank');
      w.location.href = `/home/${type}`;
    }else{
      this.props.toggleLogin();
    }
  }

  render(){
    return (
      <div className="top_header">
        <div className="inner">
          <div className="leftInner">
          <span className="welcome"><Link className="handle mouseOver" to="/home">欢迎来到港颜~</Link></span>
          {this.LoginOrLoginout(this.state.news_count)}
          <div className="marginR mouseOver">
            <span className="handle">港颜APP</span>
            <img id="headHkdQrcode" className="topQrCode" src={headHKDApp}/>
          </div>

          </div>
          <div className="rightInner">
          <span className="marginR handle mouseOver" onClick={this.toOrder.bind(this,"order")}>我的订单</span>
          <div className="marginR dropDownMouseOver" onMouseOver={this.handleDropMouseOver} onMouseLeave={this.handleDropMouseOut}>
            {/*默认我的订单*/}
            <span className="handle mouseOver" onClick={this.toOrder.bind(this,"order")}>个人中心</span>
            <img className="dropDownImg" src={this.state.drop_hover ? dropDownRed : dropDownGray }/>
            {this.DropDownItemList()}
           </div>
          <span className="marginR"><Link className="handle mouseOver" target="__blank" to="/home/helpcenter/shopping">帮助中心</Link></span>
          <span className="marginR handle mouseOver" onClick={this.toFeedback.bind(this,"feedback")}>意见反馈</span>
          <span className="marginR"><Link className="handle mouseOver" target="__blank" to="/home/cosumesInterests">消费者权益</Link></span>
          </div>
        </div>
      </div>
    );
  }
}

class NewsDropList extends React.Component{
  constructor(props){
    super(props);
  }

  NewsTypeItem = (item,type,index) =>{
    if(type == "1"){ //官方消息
      return(
        <div className="news_item" key={index}>
          <img className="news_item_img" src={newsXX}/>
          <div className="content two_line">{item.title}</div>
          <div className="new_item_bootom_line"></div>
        </div>
      );
    }else if(type == "2"){//直邮消息
      return(
        <div className="news_item" key={index}>
          <img className="news_item_img" src={newsYHQ}/>
          <div className="content">{item.title}</div>
          <div className="sign_for_status">已签收</div>
          <div className="new_item_bootom_line"></div>
        </div>
      );
    }else if(type == "3"){//返利消息
      return(
        <div className="news_item" key={index}>
           <img className="news_item_img" src={newsFL}/>
          <div className="content">{item.title}</div>
          <div className="new_item_bootom_line"></div>
        </div>
      );
    }else {
      return(
        <div className="news_item">

        </div>
      );
    }
  }
  NewsTypeSection = (item,index) =>{
    if(item.type == "1"){ //官方消息
      return(
        <div className="news_section" key={index}>
          <div className="section_margin_top">
            <div className="section_left_line"></div>
            <div className="section_right_title">{item.sectionTitle}</div>
          </div>
          {
            item.itemList.map((it,index) =>{
              return this.NewsTypeItem(it,"1",index)
            })
          }
        </div>
      );
    }else if(item.type == "2"){//直邮消息
      return(
        <div className="news_section" key={index}>
          <div className="section_margin_top">
            <div className="section_left_line"></div>
            <div className="section_right_title">{item.sectionTitle}</div>
          </div>
          {
            item.itemList.map((it,index) =>{
              return this.NewsTypeItem(it,"2",index)
            })
          }
        </div>
      );
    }else if(item.type == "3"){//返利消息
      return(
        <div className="news_section" key={index}>
          <div className="section_margin_top">
            <div className="section_left_line"></div>
            <div className="section_right_title">{item.sectionTitle}</div>
          </div>
          {
            item.itemList.map((it,index) =>{
              return this.NewsTypeItem(it,"3",index)
            })
          }
        </div>
      );
    }else if(item.type == "10"){//查看更多消息
      return(
        <div className="news_section" key={index}>
          <div className="more_msg">
            <div className="more_msg_title"><Link to="/home/moreofficialnews">{item.sectionTitle}</Link></div>
          </div>
        </div>

      );
    }else{
      return(
        <div></div>
      );
    }
  }

  render(){
    let newsList=[
      {type:"1",sectionTitle:"官方消息",itemList:[{url:"",title:"你收到一张10优惠券，一张20元优惠券"},
                                                 {url:"",title:"你收到一张10优惠券，一张20元优惠券"}]},
      {type:"2",sectionTitle:"直邮消息",itemList:[{url:"",title:"订单号：19001254565"},
                                                 {url:"",title:"订单号：19001254565"}]},
      {type:"3",sectionTitle:"返利消息",itemList:[{url:"",title:"您转发的折扣卡已经被用户189****8715使用，预计获得￥255.0的返利值"},
                                                 {url:"",title:"您转发的折扣卡已经被用户189****8715使用，预计获得￥255.0的返利值"}]},
      {type:"10",sectionTitle:"查看更多消息",itemList:[]},
    ]
    if(this.props.news_num > 0){
      return(
        <div className="newsDropDownList">
          {
            newsList.map((item,index) =>{
              return this.NewsTypeSection(item,index)
            })
          }
        </div>
      );
    }else{
      return(
        <div className="empty_dropdown_news">
          <img className="empty_news_img" src={emptyNotice} />
          <div className="empty_news_title">暂时没有新消息哦</div>
          <div className="history_msg_title">查看历史消息</div>
        </div>
      );
    }
  }
}

export default Header;
