import React, {Component} from "react";
import {connect} from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Statistic,message } from 'antd';
import moment from 'moment';
const { Countdown } = Statistic;
import "./PayZFB.less";
import ic_uccess_big from "../../images/ic_uccess_big.png";
import alipay_logo from "../../images/alipay_logo.png";
import ic_saoyisao from "../../images/ic_saoyisao.png";

import { getDataFromServer } from '../../httpRequest/Api.js';

class PayZFB extends Component {
  constructor(props){
    super(props);
    this.state={

    }
  }

  componentDidMount(){
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
    this.timer = setInterval(
      () => {
        this.getPayResult();
      },
      2500
    );
  }

  //获取用户有没付款成功
  async getPayResult(){
    let url = `${baseUrl}/webUser/scanReturnUrls?appkey=${appkey}&orderId=${this.props.location.state.orderInfo.order.id}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.props.history.push({pathname: "/home/payfinish",state: {orderInfo: this.props.location.state.orderInfo}});
    }else if(data.data.result === 3){
      message.error(<span className="msgText">订单异常</span>);
      this.props.history.push(`/home/usercenter/order`);
    }
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer);
  }

  render() {
    return (
      <div className="PayZFB">
        <div className="zfbPay">
          <div className="inner">
            <img src={alipay_logo} alt="" className="zfbLogo"/><span className="myPay">我的收银台</span>
          </div>
        </div>
        <div className="inner">
          <div className="moneyBox">【港颜】订单编号{this.props.location.state.orderInfo.order.outOrderNo} <span className="money">{this.props.location.state.orderInfo.order.depositRMBPrice}元</span></div>
          <div className="qCodeBox">
            扫一扫付款（元）<br/>
            <div className="money">{this.props.location.state.orderInfo.order.depositRMBPrice}</div>
            <div className="qCode">
              <iframe src={this.props.location.state.payUrl} className="h5Content"></iframe>
              <div className="saoyisao">
                <img src={ic_saoyisao} alt=""/>
                <div className="saoyisaoText">
                  打开手机支付宝<br/>
                  扫一扫继续付款
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PayZFB;
