import React,{Component} from "react";
import "./NewUserCouponPopView.less";
import { message } from 'antd';
import {connect} from 'react-redux';
import closeGift from "../../images/ic_close_gift_nor.png";
import { getDataFromServer } from '../../httpRequest/Api.js';
import homeAction from '../../actions/homeAction';
import loginAction from "../../actions/loginAction.js";
const { setGiftToggle,setHasGetCoupon } = homeAction;
const {toggleLogin} = loginAction;

@connect(({loginReducer,homeReducer}) => ({
		userLoginInfo: loginReducer.userLoginInfo,
    giftToggle: homeReducer.giftToggle
  }),{setGiftToggle,setHasGetCoupon,toggleLogin}
)

class NewUserCouponPopView extends Component{
	constructor(){
    super();
    this.state = {
			couponImg: ""
    }
    this.getNewCouponImg = this.getNewCouponImg.bind(this);
    this.isGetCoupon = this.isGetCoupon.bind(this);
    this.getNewCoupon = this.getNewCoupon.bind(this);
  }

  componentDidMount(){
  	message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
		this.getNewCouponImg();
  }

  //获取新人礼包图片
  async getNewCouponImg(){
		let loginInfo = {
      url:`${baseUrl}/v1.3/coupon/new?appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
      this.setState({
      	couponImg: data.data.data
      },() => {
      	if (this.props.userLoginInfo) {
					this.isGetCoupon();
      	}else{
      		if (this.props.location.pathname === "/home") {
	      		this.props.setGiftToggle(true);
      		}
      	}
      })
    }
  }

  //判断是否领取了新人礼包
  async isGetCoupon(){
		let loginInfo = {
      url:`${baseUrl}/user/newpacks/${this.props.userLoginInfo.id}?appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
      if (this.props.match.path === "/home") {
    		this.props.setGiftToggle(true);
  		}
    }else{
    	this.props.setGiftToggle(false);
  		this.props.setHasGetCoupon(true);
    }
  }

  //关闭新人礼包
  closeGiftAlert = () => {
		this.props.setGiftToggle(false);
  }

  //领取新人礼包
  async getNewCoupon(){
  	if (this.props.userLoginInfo) {
			let loginInfo = {
	      url:`${baseUrl}/user/coupon?appkey=${appkey}&userId=${this.props.userLoginInfo.id}`,
	      method: 'PUT'
	    }
	    let data = await getDataFromServer(loginInfo);
	    if (data.data.result === 0) {
	      message.success(<span className="msgText">领取成功</span>);
        this.props.setGiftToggle(false);
        this.props.setHasGetCoupon(true);
	    }else if (data.data.result === 1) {
	      message.error(<span className="msgText">领取失败</span>);
	    }else if (data.data.result === 3) {
	    	message.error(<span className="msgText">你已领过该礼包~~</span>);
	    	this.props.setGiftToggle(false);
	  		this.props.setHasGetCoupon(true);
	    }else{
				message.error(<span className="msgText">{data.data.msg}</span>);
	    }
    }else{
      this.props.toggleLogin();
    }
  }

  render(){
    return(
      <div className="pop_back" style={this.props.giftToggle  && this.props.location.pathname === "/home" ? {display:"none"} : {display:"none"}}>
				<div className="content_back">
					<div className="coupon_img_back">
						<img className="coupon_img" src={this.state.couponImg}/>
						<div className="get_coupon_back">
							<div className="get_coupon" onClick={this.getNewCoupon}>立即领取</div>
						</div>
					</div>
					<img className="close_btn" src={closeGift} onClick={this.closeGiftAlert}/>
				</div>
			</div>
    );
  }
}

export default NewUserCouponPopView;