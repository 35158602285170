import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import { Statistic } from 'antd';
import moment from 'moment';
import "./LimitBuyDetail.less";
import sanjiao from "../../images/triangle.png";
import clock from "../../images/ic_cloke.png";
import fair from "../../images/ic_fair.png";
import collect from "../../images/ic_collection.png";
import coupon from "../../images/ic_coupon.png";
import ProductItem from "../../components/ProductItem/ProductItem";
import ProductItemLimit from "../../components/ProductItemLimit/ProductItemLimit";
import Pager from "../../components/Pager/Pager";
import { getDataFromServer } from '../../httpRequest/Api.js';
const { Countdown } = Statistic;

@connect(
  state => state.loginReducer
)

class LimitBuyDetail extends Component {
  constructor(props){
    super(props)
    this.state={
      goodsList: [],
      timesList: [],
      nowStatus: "",
      pageNo: 1,
      pageSize: 20,
      pageCount: 0,
      nowTime: new Date().getTime(),
      id: 0 
    }
    this.getTemplateInfo = this.getTemplateInfo.bind(this);
    this.getTimesGoods = this.getTimesGoods.bind(this);
  }

  componentDidMount(){
    this.getTemplateInfo();
  }
  
  //获取场次信息
  async getTemplateInfo (){
    let loginInfo = {
      url: `${baseUrl}/limitbuy/times?templateId=${this.props.match.params.id}&appkey=${appkey}`,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      //计算默认渲染哪个场次
      let timesList = data.data.data.timesInfo;
      let statusArr = [],selectTimesIndex = 0;
      for (var i = 0; i < timesList.length; i++) {
        let status = this.limitBuyStatus(timesList[i].limitBuyTimes.beginTime,timesList[i].limitBuyTimes.endTime,data.data.nowtime);
        statusArr.push(status);
      }
      let q1 = statusArr.findIndex(item => item.indexOf("抢购中") !== -1);
      let q2 = statusArr.findIndex(item => item.indexOf("即将开抢") !== -1);
      if (q1 !== -1) {
        selectTimesIndex = q1;
      }else if (q2 !== -1) {
        selectTimesIndex = q2;
      }else{
        selectTimesIndex = statusArr.length - 1;
      }
      this.setState({
        timesList,
        nowTime: data.data.nowtime,
        id: timesList[selectTimesIndex].limitBuyTimes.id,
        nowStatus: statusArr[selectTimesIndex]
      },() => {
        this.getTimesGoods(this.state.id,this.state.nowStatus);
      })
    }
  }

  //解析场次状态
  limitBuyStatus = (startTime,endTime,nowTime = this.state.nowTime) =>{
    let status = "";
    let deadLine = 0;
    if (nowTime > moment(startTime).valueOf() && nowTime < moment(endTime).valueOf()) {
      status = "抢购中";
      deadLine = moment(endTime).valueOf();
    }else if (nowTime < moment(startTime).valueOf()) {
      status = "即将开抢";
      deadLine = moment(startTime).valueOf();
    }else if (nowTime > moment(endTime).valueOf()) {
      status = "已开抢";
    }
    return status + "_" + deadLine;
  }

  //请求场次商品
  async getTimesGoods (id,nowStatus){
    let {pageNo, pageSize} = this.state;
    let loginInfo = {
      url: `${baseUrl}/v1.6/limitbuy/times/goods?timesId=${id}&appkey=${appkey}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&pageNo=${pageNo}&pageSize=${pageSize}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        id,
        nowStatus,
        goodsList: data.data.data
      })
    } 
  }
 
  render() {
    let {goodsList,timesList,pageNo,pageCount,id,nowStatus} = this.state;
    let nowStatusTime = nowStatus.split("_")[1] * 1;
    nowStatus = nowStatus.split("_")[0];
    return (
      <div className="LimitBuyDetail">
        <div className="limitInfo">
          <img src={clock} className="titleIcon" alt=""/>
          <span className="title">限时抢购</span>
          {
            nowStatus !== "已开抢" && 
            <div className="daojisbox">
              <div className="huodongTime">{nowStatus === "抢购中" ? "距活动结束还剩" : "距开始"}</div>
              <div className="daojishiBox">
                <div className="maohao r">:</div>
                <div className="maohao l">:</div>
                <Countdown value={nowStatusTime ? nowStatusTime : 0} onFinish={this.onFinish} valueStyle={{background:"#000",color:"#fff",display:"inline-block",padding: "0 5px 0 5px",height: "32px"}} format={"HH ：mm   ：ss"}/>
              </div>
            </div>
          }
        </div>
        <div className="inner">
          {/*限时抢购场次*/}
          <div className="limitBuyRoundBox">
            {
              timesList.map((item,i) => {
                let isActive = id === item.limitBuyTimes.id;
                return (
                  <div className={isActive ? "roundItem active" : "roundItem"} key={i} style={{width: 100 / timesList.length + "%"}} onClick={this.getTimesGoods.bind(this,item.limitBuyTimes.id,this.limitBuyStatus(item.limitBuyTimes.beginTime,item.limitBuyTimes.endTime))}>
                    <div className="roundTime">{item.limitBuyTimes.timesName}</div>
                    <div className="roundStatus">{this.limitBuyStatus(item.limitBuyTimes.beginTime,item.limitBuyTimes.endTime).split("_")[0]}</div>
                    {isActive && <img className="sanjiao" src={sanjiao} alt=""/>}
                  </div>
                )
              })
            }
          </div>
          {/*限时抢购商品*/}
          <div className="goodsList">
            {
              goodsList.map((item,i) => {
                return (
                  <li className={(i + 1) % 4 === 0 ? "ModelGoodsItem" : "ModelGoodsItem marR"} key={i}>
                    <ProductItemLimit goodsInfo={item} timesId={id} nowStatus={nowStatus} updateList={this.getTemplateInfo}/>
                  </li>
                )
              })
            }
            <div className="clearfix"></div>
          </div>
          {/*<div className="page">
            <Pager total={pageCount} current={pageNo}/>
          </div>*/}
        </div>
      </div>
    );
  }
}
export default LimitBuyDetail;


