import React,{Component} from "react";
import {connect} from 'react-redux';
import { Upload, Icon, message } from 'antd';
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./AddressManager.less";
import AddNewAddressPopView from "../AddNewAddressPopView/AddNewAddressPopView.js";
import DeleteAddressPopView from "../DeleteAddressPopView/DeleteAddressPopView.js";

@connect(
  state => state.loginReducer
)
class AddressManager extends Component{

  constructor(props){
    super(props); 
    this.state={
      addressList:[],
      addNew:false,
      showDelete:false,//删除提示弹窗
      proviceList:[],
      cityList:[],
      countryList:[],
      selectProvince:-1,//省 代码
      selectCity:-1,//市 代码
      selectCountry:-1,//县区  代码
      addressId:-1,//-1新增地址  其他id表示编辑状态
      editAddress:null,//编辑中的地址
      deleteIndex:0,//删除的地址下标
    }
  }
  componentDidMount(){
    this.loadAddress()
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  async loadAddress(){
    let userInfo = this.props.userLoginInfo;
    if(userInfo){
      let userId = userInfo.id;
      let reqUrl =  `${baseUrl}/address?appTab=2&appkey=${appkey}&userId=${userId}`;
      let method = "GET";
      let address = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(address);
      this.setState({
        addressList:response.data.addressList
      });
    }
    
  }
 
  addNewAction = () =>{    
    this.setState({
      addNew:true,
      addressId:-1
    });
  }
  editAction = (index) =>{
    let address =  this.state.addressList[index]
    let addressId = address.id;
    this.setState({
      addNew:true,
      addressId:addressId,
      editAddress:address,
    });
  }
  showDeleteView = (deleteIndex) =>{
    this.setState({
      showDelete:true,
      deleteIndex:deleteIndex,
    });
  }
  hiddenDeleteView = (isDelete) =>{
    this.setState({
      showDelete:false,
    });
    if(isDelete){
      this.deleteAddress(this.state.deleteIndex);
    }
  }
  async deleteAddress(index){
    let address =  this.state.addressList[index]
    let addressId = address.id;
    let userInfo = this.props.userLoginInfo;
    if(userInfo){
      let userId = userInfo.id;
      let reqUrl =  `${baseUrl}/address?appTab=2&appkey=${appkey}&userId=${userId}&id=${addressId}`;
      let method = "DELETE";
      let address = {
        url:reqUrl,
        method 
      }
      let response = await getDataFromServer(address);
      this.loadAddress();
    }
  }
  hiddenPopView = (isSubmit,addressInfo) =>{
    this.setState({
      addNew:false,                                                                                                               
    });
    if(isSubmit){
      this.editeAddress(addressInfo);
    }
  }
  //编辑地址   如果有id就是编辑  没有则是新增
  async editeAddress(addressInfo){
    let {name,phone,province,city,county,addressDesc,isDefault} = addressInfo;
    let userId = this.props.userLoginInfo.id;
    let defaultType = 0;
    if (isDefault) {
      defaultType = 1; 
    }
    let reqUrl = `${baseUrl}/address?appTab=2&appkey=${appkey}&userId=${userId}&name=${name}&phoneNum=${phone}&province=${province}&city=${city}&county=${county}&detail=${addressDesc}&isDefault=${defaultType}&postcode=`;
    let method = "PUT";
    //地址id   编辑的时候才有  不然默认-1
    let addressId = this.state.addressId;
    if(addressId != -1){
      reqUrl = `${reqUrl}&id=${addressId}`;
      method = "POST";
    }
    let result = {
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(result);
    this.loadAddress();
    if (response.data.result == 0) {
      message.success(<span className="msgText">提交成功</span>);
    }else{
      message.error(<span className="msgText">提交失败</span>);
    }
  }
  setDefault = (index) =>{
    let address =  this.state.addressList[index]
    let addressId = address.id;
    this.setState({
      addressId:addressId,
      editAddress:address,
    },this.setDefaultAddress);
  }
  async setDefaultAddress(){
    let userId = this.props.userLoginInfo.id;
    let addressId = this.state.addressId;
    let reqUrl = `${baseUrl}/address/default?appTab=2&appkey=${appkey}&userId=${userId}&id=${addressId}`;
    let method = "POST";
    let setDefault={
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(setDefault);
    this.setState({
      addressList:response.data.addressList
    });
  }
  render(){
    let addressList = this.state.addressList;
    let editAddress = this.state.editAddress;
    let addressId = this.state.addressId;
    return(
      <div className="address_manager_bg">
        <div className="address_top_ttile">收货地址</div>
        <div className="add_new_address_btn_bg">
          <div className="add_new_address_btn" onClick={this.addNewAction}>+新增收货地址</div>
        </div>
        {/*地址列表*/}
        {
        (addressList && addressList.length > 0) &&  <div className="address_list_bg">
          <div className="address_list_title_bg">
            <div className="address_person_title">收货人</div>
            <div className="address_title">收货地址</div>
            <div className="address_phone_num_title">手机号码</div>
            <div className="address_opration_title">操作</div>
          </div>
          {
            addressList.map((address,index) => {
              return(
                <div key={index}>
                  <div className="item_top_line"></div>
                  <div className="address_item_bg" >
                    <div className="address_person">{address.name}</div>
                    <div className="address">{`${address.province}${address.city}${address.county}${address.detail}`}</div>
                    <div className="address_phone_num">{address.phone}</div>
                    <div className="address_opration">
                      <div className="edit_btn" onClick={this.editAction.bind(this,index)}>编辑</div>
                      <div className="mid_line"></div>
                      <div className="edit_btn" onClick={this.showDeleteView.bind(this,index)}>删除</div>
                      <div className={address.isDefault == 1 ? "default_btn_true":"default_btn_false"} onClick={this.setDefault.bind(this,index)}>{address.isDefault == 1 ? "默认" : "设为默认"}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        }
        {/*地址为空*/}
        {
          (addressList == null || addressList.length == 0) && <div></div>
        }
        {
          this.state.addNew && <AddNewAddressPopView addressId={addressId} editAddress={editAddress} hiddenPopView={this.hiddenPopView}/>
        }
        {
          this.state.showDelete && <DeleteAddressPopView hiddenDeleteView={this.hiddenDeleteView}/>
        }
      </div>
    );
  }
}

export default AddressManager;