import React,{Component} from "react";
import "./HelpCenter.less";
import HelpCeneterRightInner from "../../components/HelpCenterRightInner/HelpCenterRightInner.js";
import menuMoreDown from "../../images/ic_more_down.png";
import menuMoreUp from "../../images/ic_more_up.png";

class HelpCenter extends React.Component{
	constructor(props){
		super(props);
		let sectiontIndex = 0;
		let rowIndex = 0;
		let type = this.props.match.params.type;
		if (type == "shopping") {
			sectiontIndex = 0;
			rowIndex = 0;
		}else if(type == "coupon"){
			sectiontIndex = 0;
			rowIndex = 1;
		}else if(type == "aftersale"){
			sectiontIndex = 1;
			rowIndex = 0;
		}else if(type == "refundrule"){
			sectiontIndex = 1;
			rowIndex = 1;
		}else if(type == "cancelorder"){
			sectiontIndex = 1;
			rowIndex = 2;
		}else if(type == "logistic"){
			sectiontIndex = 2;
			rowIndex = 0;
		}else if(type == "logistictrack"){
			sectiontIndex = 2;
			rowIndex = 1;
		}else if(type == "loginregester"){
			sectiontIndex = 3;
			rowIndex = 0;
		}else if(type == "realname"){
			sectiontIndex = 3;
			rowIndex = 1;
		}else if(type == "connectus"){
			sectiontIndex = 4;
			rowIndex = 0;
		}
		this.state = {
			sectiontIndex:sectiontIndex,
			rowIndex:rowIndex,
			leftTitles:[{bigTitle:"订单类",smallTitles:["购物流程","优惠券"],turnOn:(sectiontIndex == 0 ? true:false)},
									{bigTitle:"售后类",smallTitles:["售后问题","退货政策","取消订单"],turnOn:(sectiontIndex == 1 ? true:false)},
									{bigTitle:"物流类",smallTitles:["物流配送","物流跟踪"],turnOn:(sectiontIndex == 2 ? true:false)},
									{bigTitle:"账户类",smallTitles:["登录注册","实名认证"],turnOn:(sectiontIndex == 3 ? true:false)},
									{bigTitle:"聊天类",smallTitles:["联系我们"],turnOn:(sectiontIndex == 3 ? true:false)}],
		}
	}

	bigTitleSelectClick = (section) =>{
		let leftTitles = this.state.leftTitles;
		leftTitles[section].turnOn = !leftTitles[section].turnOn;
		this.setState({
			leftTitles:leftTitles,
		});
	}
	smallTitleSelectClick = (section,row) =>{
		this.setState({
			rowIndex:row,
			sectiontIndex:section,
		});
	}

	render(){
		let leftTitles = this.state.leftTitles;
		let rowIndex = this.state.rowIndex;
		let sectiontIndex = this.state.sectiontIndex;
		return(
			<div className="help_center_super_bg">
				<div className="help_center_left_bg">
					{
						leftTitles.map((leftTitle,index) =>{
							return(
								<div className="help_center_left_item_bg" key={index}>
									<div className="help_center_left_big_title_bg" onClick={this.bigTitleSelectClick.bind(this,index)} >
										<div className="help_center_nomal_title_color help_center_left_big_title">{leftTitle.bigTitle}</div>
										<img className="help_center_more_img" src={leftTitle.turnOn ? menuMoreUp :menuMoreDown }/>
									</div>
									<div className={leftTitle.turnOn == false ? "display_none" : "display_block"}>
										{
											leftTitle.smallTitles.map((smallTitle,i) =>{
												return <div className={(rowIndex==i && sectiontIndex==index) ? "help_center_left_small_title help_center_red_title_color" : "help_center_left_small_title help_center_nomal_title_color"} onClick={this.smallTitleSelectClick.bind(this,index,i)} key={i}>{smallTitle}</div>
											})
										}
									</div>
								</div>
							)
						})
					}
				</div>
				<div className="help_center_right_bg">
					<HelpCeneterRightInner showRow={rowIndex} showSection={sectiontIndex} leftTitles={leftTitles}/>
				</div>
			</div>
		);
	}
}

export default HelpCenter;