import React, {Component} from "react";
import {connect} from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Statistic } from 'antd';
const { Countdown } = Statistic;
import loginAction from "../../actions/loginAction.js";
import UserRule from "../../components/UserRule/UserRule.js";
import LoginContent from "../../components/LoginContent/LoginContent.js";
import "./Login.less";
import logo from "../../images/logo.png";
import close from "../../images/ic_delete.png";
import zheng from "../../images/head_ic_zheng.png";
import ziying from "../../images/head_ic_ziying.png";
import zhiyou from "../../images/head_ic_zhiyou.png";
import dijia from "../../images/head_ic_dijia_big.png";
import success from "../../images/pop_ic_success_signup.png";

const {successRegister} = loginAction;

@connect(
  state => state.loginReducer,
  {successRegister}
)

class Login extends Component {
  constructor(props){
    super(props);
  }

  //头部区域子项
  HeaderItem = (item,i) => {
    return (
      <div className="headerItem" key={i}>
        <img className="headerItemImg" src={item.url} alt=""/>
        <div className="headerItemText">
          <div className="headerItemTextTop">{item.textTop}</div>
          <div className="headerItemTextBot">{item.textBottom}</div>
        </div>
      </div>
    );
  }

  //关闭注册成功弹窗
  registerFinish = () => {
    this.props.successRegister();
    this.props.history.push('/');
  }

  //注册成功弹窗
  RegisterSuccess = () => {
    const {isRegisterSuc,successRegister,deadLine} = this.props;
    return (
      isRegisterSuc && <div className="blackBg">
        <div className="resultBox">
          <div className="resultTitle">
            <span className="title">提示</span>
            <img src={close} onClick={this.registerFinish} alt=""/>
          </div>
          <img className="sucIcon" src={success} alt=""/>
          <div className="sucTitle">恭喜您注册成功</div>
          <div className="sucSubTitle"><Countdown value={deadLine} onFinish={this.registerFinish} format="s秒后自动跳到首页"/></div>
          <div className="goHome" onClick={this.registerFinish}>去逛逛</div>
        </div>
      </div>
    )
  }

  //注册尾部
  Footer = () => {
    return (
      <div className="loginFooter">
        <span>©2015-2020 香港嗨洋實業有限公司</span>
        <span className="footerRight"><a rel="nofollow" href="http://www.beian.miit.gov.cn" target="_blank">粵ICP备15069512號</a></span>
        <div id="login_container"></div>
      </div>
    )
  }

  render() {
    let promiseList = [
      {url:zheng,textTop:"100%正品保障",textBottom:"正品保障 假一赔十"},
      {url:ziying,textTop:"港颜自营",textBottom:"香港企业 全场自营"},
      {url:zhiyou,textTop:"香港直邮",textBottom:"全球直采 急速配货"},
      {url:dijia,textTop:"底价保障",textBottom:"天天低价 畅达无忧"}
    ]
    return (
      <div className="login">
        {/*注册成功弹窗*/}
        <Route path="/login" component={this.RegisterSuccess} />
        {/*用户协议*/}
        <Route path="/login" component={UserRule} />
        <div className="loginHeader">
          <div className="inner">
            <Link to="/home"><img src={logo} className="loginLogo" alt=""/></Link>
            {
              promiseList.map((item,i) => {
                return this.HeaderItem(item,i);
              })
            }
          </div>
        </div>
        <Route exact path="/login/user/:type" component={LoginContent} />
        <Route exact path="/login/user/:type" component={this.Footer} />
      </div>
    );
  }
}

export default Login;
