import React , {Component} from 'react';
import './ReturnInfoBox.less';
import { getDataFromServer } from '../../httpRequest/Api.js';
import { message } from 'antd';
const closeIcon = require("../../images/pop_usercenter_close_nor.png");

class ReturnInfoBox extends Component{
  constructor(props){
    super(props);
    this.state = ({
      sender:"",//回寄人
      sendPhoneNum:"",//寄件人联系电话
      logistics:"",//物流公司
      logisticsNo:"",//运单号
      isSdNtioce:false,//(回寄人)
      isSPNtioce:false,//(电话)
      isLsNtioce:false,//(物流)
      isLgNtioce:false,//(运单号)
    });
  }
  componentDidMount(){
    if(this.props.refundInfo.logisticsNo){
      this.setState({
        sender:this.props.refundInfo.sender,
        sendPhoneNum:this.props.refundInfo.sendPhoneNum,
        logistics:this.props.refundInfo.logistics,
        logisticsNo:this.props.refundInfo.logisticsNo
      })
    }
  }
  //退款理由改变
  inputChange = (type,event) =>{
    if(type === "sender"){
      if(event.target.value.length){
        this.setState({
          isSdNtioce:false
        })
      }else{
        this.setState({
          isSdNtioce:true
        })
      }
    }else if(type === "sendPhoneNum"){
      if(event.target.value.length){
        this.setState({
          isSPNtioce:false
        })
      }else{
        this.setState({
          isSPNtioce:true
        })
      }
    }else if(type === "logistics"){
      if(event.target.value.length){
        this.setState({
          isLsNtioce:false
        })
      }else{
        this.setState({
          isLsNtioce:true
        })
      }
    }else if(type === "logisticsNo"){
      if(event.target.value.length){
        this.setState({
          isLgNtioce:false
        })
      }else{
        this.setState({
          isLgNtioce:true
        })
      }
    }
    this.setState({
      [type]:event.target.value
    })
  }
  //确定申请退货
  sure = () =>{
    let { sender,sendPhoneNum,logistics,logisticsNo} = this.state;
    if(sender && sendPhoneNum && logistics && logisticsNo && this.props.saleAfterId){
      this.applyForRefund(this.props.saleAfterId,logistics,logisticsNo,sender,sendPhoneNum)
    }
  }
  //提交信息
  async applyForRefund(saleAfterId,logistics,logisticsNo,sender,sendPhoneNum){
    let loginInfo = {
      url:baseUrl + "/refund/modifylogistics?appkey=" + appkey + "&saleAfterId=" + saleAfterId + "&logistics=" + logistics + "&logisticsNo=" + logisticsNo + "&sender=" + sender + "&sendPhoneNum=" + sendPhoneNum,
      method: 'POST',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.returnInfoBox.style.display = "none";
      message.success(<span className="msgText">{data.data.msg}</span>);
      this.props.refresh();
    }else{
      message.error(<span className="msgText">{data.data.msg}</span>);
    }
  }
  //关闭弹框
  closeBox = () =>{
    this.returnInfoBox.style.display = "none";
    if(this.props.refundInfo.logisticsNo){
      this.setState({
        sender:this.props.refundInfo.sender,
        sendPhoneNum:this.props.refundInfo.sendPhoneNum,
        logistics:this.props.refundInfo.logistics,
        logisticsNo:this.props.refundInfo.logisticsNo
      })
    }
    if(this.props.refundInfo.sender){
      this.setState({
        isSdNtioce:false
      })
    }
    if(this.props.refundInfo.sendPhoneNum){
      this.setState({
        isSPNtioce:false
      })
    }
    if(this.props.refundInfo.logistics){
      this.setState({
        isLsNtioce:false
      })
    }
    if(this.props.refundInfo.logisticsNo){
      this.setState({
        isLgNtioce:false
      })
    }
  }
  render(){
    let { isLgNtioce,isLsNtioce,isSPNtioce,isSdNtioce,sender,sendPhoneNum,logistics,logisticsNo } = this.state;
    let show = {"display":"inline-block"};
    let hide = {"display":"none"};
    return(
      <div className="returnInfoBox" ref={r => this.returnInfoBox = r}>
        <div className="sureAndCancelInner">
          <div className="title1">
            <span className="boxName">回寄信息</span>
            <img src={closeIcon} alt="" className="closeBtn" onClick={this.closeBox}/>
          </div>
          <div className="section">
             <div className={isLsNtioce?"logistics":"logistics input"}><span className="title"><i>*</i>回寄物流：</span> <input type="text" value={logistics} onChange={this.inputChange.bind(this,"logistics")} placeholder="请输入回寄物流"/><span className="notice" style={isLsNtioce?show:hide}><span className="remove"><i></i></span>请填写正确的回寄物流</span></div>
             <div className={isLgNtioce?"logisticsNO":"logisticsNO input"}><span className="title"><i>*</i>物流单号：</span> <input type="text" value={logisticsNo} onChange={this.inputChange.bind(this,"logisticsNo")} placeholder="请输入物流单号"/><span className="notice" style={isLgNtioce?show:hide}><span className="remove"><i></i></span>请填写正确的物流单号</span></div>
             <div className={isSdNtioce?"sender":"sender input"}><span className="title"><i>*</i>寄件人：</span> <input type="text" value={sender} onChange={this.inputChange.bind(this,"sender")} placeholder="请输入回寄人"/><span className="notice" style={isSdNtioce?show:hide}><span className="remove" ><i></i></span>请填写正确的寄件人姓名</span></div>
             <div className={isSPNtioce?"phone":"phone input"}><span className="title"><i>*</i>寄件人电话：</span> <input type="text" value={sendPhoneNum} onChange={this.inputChange.bind(this,"sendPhoneNum")} placeholder="请输入寄件人电话"/><span className="notice" style={isSPNtioce?show:hide}><span className="remove"><i></i></span>请填写正确的寄件人电话</span></div>
            <div className="opBtn">
              <span className="sure" onClick={this.sure}>确定</span>
              <span className="cancel" onClick={this.closeBox}>取消</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReturnInfoBox;