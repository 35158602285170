import React,{ Component } from 'react';
import "./HotSellInfo.less"
import LArrowImg from "../../images/ic_arrow_L_yuan.png";
import RArrowImg from "../../images/ic_arrow_R_yuan.png";
import More from "../../images/ic_more_grey.png";

export default class HotSellInfo extends Component{
  constructor(props){
    super()
    this.state = {
      width: '10000px',
      transition: '500ms ease-out',
      transform: 'translatex(0px)',
      countnum: props.templateNewsInfo.templateDetailsModels.length,     //图片数量
      curPageIndex: 0, //点击右箭头+1，左箭头减1
      viewcount: 3,    //初始展示图片数量（和div宽度相关） 
      Larrowstate: true,
      Rarrowstate: true,
      templateNewsInfo: props.templateNewsInfo
    }
  }
  
  //上一张
  handleclickPrev = () =>{
    let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state;
    if(curPageIndex === countnum - viewcount){
        this.setState({
            Rarrowstate:!Rarrowstate
        })
    }
    this.getPage(curPageIndex-1);
  }

  //下一张
  handleclickNext = () =>{
    let { countnum,viewcount,curPageIndex,Rarrowstate } = this.state;
    if (curPageIndex < 0) {
        curPageIndex = 0;
    }
    if(curPageIndex === 0){
        this.setState({
            Larrowstate:true
        })
    }
    this.getPage(curPageIndex + 1);
  }

  //去热卖资讯详情
  goHotSelldetail = (url) =>{
    window.location.href = url;
  }

  getPage(value) {
    let { countnum,viewcount,Larrowstate,Rarrowstate} = this.state
    let n = countnum - viewcount
    //一项的宽度
    let itemwidth = 400;
    let transform = 'translatex(-' + value * itemwidth + 'px)';
    value > n || 0 > value || this.setState({transform:transform});
    this.setState({
        curPageIndex: value,
    })
    if (value == 0) {
        this.setState({
            Larrowstate: !Larrowstate
        })
    }
    if (value == n) {
        this.setState({
            Rarrowstate: !Rarrowstate
        })
    }
 }
  render(){
    let { width ,transition ,transform ,Larrowstate ,Rarrowstate ,templateNewsInfo} = this.state;
    let LArrowClaName = Larrowstate ? 'arrow_l' : 'hidden';
    let RArrowClaName = Rarrowstate ? 'arrow_r' : 'hidden';
    let templateDetails = templateNewsInfo.templateDetailsModels?templateNewsInfo.templateDetailsModels:[];
    return (
      <div className="HotSellInfo">
        <div className="inner">
          <div className="recTitle"><span className="theme">热卖资讯</span><span className="more">查看更多资讯<img src={More} alt=""/></span></div>
          <div className="recClassify">
            <div className="limitProduct">
              <div className="productShow">
                <ul className="innerContent" style={{ width: width, transition: transition, transform: transform }}>
                  {templateDetails.map((item,i)=>{
                    return(
                      <li key={i} onClick={this.goHotSelldetail.bind(this,item.url)}>
                        <div className="conimg"><img src={item.templateImg} alt=""/></div>
                        <div className="conText">
                          <h2 className="title">{item.title}</h2>
                          <p className="text">{item.subTitle}</p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
                <div className={LArrowClaName}><img src={LArrowImg}  onClick={this.handleclickPrev} /></div>
                <div className={RArrowClaName}><img src={RArrowImg}  onClick={this.handleclickNext} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}