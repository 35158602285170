import React,{Component} from "react";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./MyRebateCard.less";
import rebateCardBG from "../../images/bg_rebate_card.png";
import rebateCardBGgrey from "../../images/bg_rebate_card_grey.png";

const useCard = "可用折扣卡";
const noUseCard = "已失效";

@connect(
  state => state.loginReducer
)

class MyRebateCard extends Component{
  constructor(props){
    super(props);
    this.state={
      selectType:useCard,
      unusedCard:[],//未使用
      invalidCard:[]//已失效
    };
  }
  componentDidMount(){
    this.LoadRebateCardList("unused");
    this.LoadRebateCardList("invalid");
  }
  //type unused 未使用  invalid 已使用
  async LoadRebateCardList(type){
    let loginInfo = this.props.userLoginInfo;
    if(loginInfo){
      let userId = loginInfo.id;
      let reqUrl = `${baseUrl}/v1.0/rebate/card?appTab=2&appkey=${appkey}&userId=${userId}&type=${type}`;
      let method = "GET";
      let cardList = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(cardList);
      if(type == "unused"){
        this.setState({
          unusedCard:response.data.data.rebateCardList
        });
      }else if(type == "invalid"){
        this.setState({
          invalidCard:response.data.data.rebateCardList
        });
      } 
    }
  }
  
  useCardClick = () =>{
    this.setState({
      selectType:useCard,
    });
  }
  noUseCardClick = () =>{
    this.setState({
      selectType:noUseCard,
    });
  }
  //折扣卡裂变
  rebateCardContent = (type,unusedCard,invalidCard) =>{
    if(type == useCard){
      if(unusedCard != null && unusedCard.length > 0){
        return(
          <div className="mid_card_bg">
          {  
            unusedCard.map((rebateCard,index) =>{
              return(
                <div className="coupon_item_bg" key={index}>
                  <img className="rebate_card_bg" src={rebateCardBG} />
                  <div className="rebate_content_bg">
                    <div className="rebate_title_bg">
                      <div className="rebate_title_left">减</div>
                      <div className="rebate_title_mid">{rebateCard.savePercent * 100}</div>
                      <div className="rebate_title_right">%</div>
                    </div>
                    <div className="rebate_tip">（订单总额立享折扣）</div>
                    <div className="rebate_valid_date">{`有效期至：${rebateCard.validTime}`}</div>
                    <div className="rebate_valid_desc">{`来自${rebateCard.phoneNumber}的分享`}</div>
                  </div>
                </div>
              )
            })
          }
          </div>
        );
      }else{
        return (<div className="no_data">暂无可用折扣卡哦~</div>);
      }
    }else{
      if(invalidCard != null && invalidCard.length > 0){
        return(
          <div className="mid_card_bg">
          {  
            invalidCard.map((rebateCard,index) =>{
              return(
                <div className="coupon_item_bg" key={index}>
                  <img className="rebate_card_bg" src={rebateCardBGgrey} />
                  <div className="rebate_content_bg">
                    <div className="rebate_title_bg">
                      <div className="rebate_title_left">减</div>
                      <div className="rebate_title_mid">{rebateCard.savePercent * 100}</div>
                      <div className="rebate_title_right">%</div>
                    </div>
                    <div className="rebate_tip">（订单总额立享折扣）</div>
                    <div className="rebate_valid_date">{`有效期至：${rebateCard.validTime}`}</div>
                    <div className="rebate_valid_desc_white">{`来自${rebateCard.phoneNumber}的分享`}</div>
                  </div>
                </div>
              )
            })
          }
          </div>
        );
      }else{
        return (<div className="no_data">暂无已失效折扣卡哦~</div>);
      }
    }
  }

  render(){
    let unusedCard = this.state.unusedCard;
    let invalidCard = this.state.invalidCard;
    let type = this.state.selectType;

    return(
      <div className="user_center_card_bg">
        <div className="card_select_bg">
          <div className="card_select_item_bg" onClick={this.useCardClick}>
            <div className={type == useCard ? "select_item_title title_red_color" : "select_item_title title_no_color"}>可用折扣卡</div>
            <div className={type == useCard ? "select_item_rec rec_red_color" : "select_item_rec rec_no_color"}></div>
          </div>
          <div className="card_select_item_bg" onClick={this.noUseCardClick}>
            <div className={type == noUseCard ? "select_item_title title_red_color" : "select_item_title title_no_color"}>已失效</div>
            <div className={type == noUseCard ? "select_item_rec rec_red_color" : "select_item_rec rec_no_color"}></div>
          </div>
        </div>
        {/*折扣列表*/}
        {this.rebateCardContent(type,unusedCard,invalidCard)}
      </div>
    );
  }
}

export default MyRebateCard;