import React,{Component} from "react";
import "./DeleteAddressPopView.less";
import close from "../../images/pop_usercenter_close_nor.png";
import tip from "../../images/ic_warning.png";

class DeleteAddressPopView extends Component{
  constructor(props){
    super(props);
    let tip = props.tip;
    if(tip == null || tip == ""){
      tip = "是否删除地址";
    }
    this.state={
      tipTtile:tip,
    }
  }
  hiddenDeleView = (isDelete) =>{
    this.props.hiddenDeleteView(isDelete);
  }
  render(){
    let tipTtile = this.state.tipTtile;
   return(
     <div className="delete_address_pop_bg">
       <div className="white_bg">
        <div className="del_address_title_bg">
          <div className="del_address_title">提示</div>
          <img className="close" src={close} onClick={this.hiddenDeleView.bind(this,false)}/>
        </div>
        <div className="top_line"></div>
        <div className="mid_content_bg">
          <img src={tip} className="mid_img"/>
          <div className="mid_tip">{tipTtile}</div>
        </div>
        <div className="bottom_btn_bg">
          <div className="red_btn" onClick={this.hiddenDeleView.bind(this,true)}>确认</div>
          <div className="balck_btn" onClick={this.hiddenDeleView.bind(this,false)}>取消</div>
        </div>
       </div>
     </div>
   );
  }
}

export default DeleteAddressPopView;