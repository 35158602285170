import React, {Component} from "react";
import {connect} from 'react-redux';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Statistic,message } from 'antd';
import moment from 'moment';
const { Countdown } = Statistic;
import "./PayCenter.less";
import ic_uccess_big from "../../images/ic_uccess_big.png";
import paylogo_zfb from "../../images/paylogo_zfb.png";

import { getDataFromServer } from '../../httpRequest/Api.js';

class PayCenter extends Component {
  constructor(props){
    super(props);
    this.state={
      orderInfo: null
    }
    this.toPay = this.toPay.bind(this);
  }

  componentDidMount(){
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
    this.getOrderDetail(this.props.match.params.orderId);
  }

  //获取订单详情
  async getOrderDetail(orderId){
    let url = `${baseUrl}/order/overseadetail?appkey=${appkey}&orderId=${orderId}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        orderInfo: data.data
      })
    }
  }

  //去支付点击
  async toPay(){
    let url = `${baseUrl}/api/scanQrcodeForPayment?appkey=${appkey}&orderId=${this.props.match.params.orderId}&paymentType=1`;
    let goodsInfo = {
      url,
      method: 'POST'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.props.history.push({pathname: "/home/payzfb",state: {payUrl: data.data.url,orderInfo: this.state.orderInfo}})
    }else{
      message.error(<span className="msgText">订单异常</span>);
      this.props.history.push(`/home/usercenter/order`);
    }
  }

  render() {
    let {orderInfo} = this.state;
    let goods = "";
    let wareHouse = "";
    let deadLine = 0;
    let totalcount = 0;
    let address = "";
    let name = "";
    let phone = "";
    let money = 0;
    if (orderInfo) {
      goods = orderInfo.wareHouseOrderGoods[0].orderGoods[0].title;
      wareHouse = orderInfo.wareHouseOrderGoods[0].wareHouseName;
      deadLine = moment(orderInfo.order.createTime).valueOf() + 30 * 60 * 1000;
      totalcount = orderInfo.totalcount;
      address = orderInfo.order.province + orderInfo.order.city + orderInfo.order.country + orderInfo.order.addrDetail;
      name = orderInfo.order.name;
      phone = orderInfo.order.phone;
      money = orderInfo.order.actualRMBPrice;
    }
    return (
      <div className="PayCenter">
        <div className="inner">
          <div className="orderContent">
            <div className="orderContentImg"><img src={ic_uccess_big} alt=""/></div>
            <div className="orderContentRight">
              <div className="orderStatus">订单已成功提交，就差最后一步啦！</div>
              <div className="orderTime">请您在提交订单后 <Countdown value={deadLine} onFinish={this.onFinish}  format={"m分s秒"}/> 内完成付款，逾时系统将自动取消订单</div>
              <div className="userInfo">
                {goods} 等{totalcount}件商品 &nbsp;&nbsp;&nbsp;| &nbsp;&nbsp;&nbsp;{wareHouse.split("-")[0]}<br/>
                {address} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; {name}&nbsp;&nbsp; {phone}
              </div>
            </div>
          </div>
          <div className="payBox">
            <div className="actPay">实付金额：<span className="money">￥{money}</span></div>
            <div className="payList">
              <div className="payListItem">
                <input type="radio" checked={true}/><img src={paylogo_zfb} className="payListItemIcon" alt=""/>支付宝
              </div>
            </div>
            <div className="payNow" onClick={this.toPay}>立即支付</div>
          </div>
        </div>
      </div>
    );
  }
}

export default PayCenter;