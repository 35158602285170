import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./ModelGoods.less";
import sanjiao from "../../images/triangle.png";
import fair from "../../images/ic_fair.png";
import collect from "../../images/ic_collection.png";
import coupon from "../../images/ic_coupon.png";
import ProductItem from "../../components/ProductItem/ProductItem";
import ProductItemLimit from "../../components/ProductItemLimit/ProductItemLimit";
import Pager from "../../components/Pager/Pager";
import { getDataFromServer } from '../../httpRequest/Api.js';

class ModelGoods extends Component {
  constructor(props){
    super(props)
    this.state={
      goodsList: [],
      linkType: "",
      image: "",
      pageNo: 1,
      pageCount: 0
    }
  }

  componentDidMount(){
    this.getTemplateInfo();
  }

  //获取banner,首页导航,限时抢购信息
  async getTemplateInfo (){
    let loginInfo = {
      url: `${baseUrl}/home/goods?id=${this.props.match.params.id.split("_")[1]}&type=${this.props.match.params.id.split("_")[0]}&appkey=${appkey}`,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      document.title = data.data.data.pageName ? data.data.data.pageName : "港颜商城";
      this.setState({
        goodsList: data.data.data.goodsList,
        linkType: data.data.data.linkType,
        image: data.data.data.image,
        pageNo: data.data.pageNo,
        pageCount: data.data.pageCount
      })
    }
  }

  render() {
    let {goodsList,linkType,title,image,pageNo,pageCount} = this.state;
    return (
      <div className="ModelGoods">
        {/*大图*/}
        {
          linkType !== 4 &&
          <img className="topImg" src={image} alt=""/>
        }
        <div className="inner">
          {/*多个商品、销量排行、收藏排行*/}
          {
            linkType == 3 &&
            <div className="goodsList">
              {
                goodsList.map((item,i) =>{
                  return (
                    <li className={( i + 1 ) % 4 === 0 ? "ModelGoodsItem" : "ModelGoodsItem marR"} key={i}>
                      <ProductItem goodsInfo={item}/>
                    </li>
                  )
                })
              }
            </div>
          }
          <div className="clearfix"></div>
          {/*分页*/}
          {/*{
            linkType == 3 &&
            <div className="page">
              <Pager total={pageCount} current={pageNo}/>
            </div>
          }*/}
          {
            linkType == 4 &&
            <iframe src={image} className="h5Content"></iframe>
          }
        </div>
      </div>
    );
  }
}
export default ModelGoods;


