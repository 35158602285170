import React,{ Component } from 'react';
import "./TemplateHotsale.less";
import { getDataFromServer } from '../../httpRequest/Api.js';
import ProductItem from "../../components/ProductItem/ProductItem.js";

class TemplateHotsale extends Component{
  constructor(props){
    super(props)
    this.state = ({
      goodsList:[],
      pageNo:1
    })
  }

  componentDidMount(){
    let { pageNo } = this.state;
    this.getGoodsList(1);
    window.addEventListener('scroll',()=>{
      let scrollTop = 0;
      let scrollHeight = 0;
      scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 获取文档元素的内容垂直滚动的像素数
      scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      let windowHeight = 0;

      // 判断当前文档的渲染模式是混杂模式还是"标准模式"
      windowHeight = (document.compatMode === 'CSS1Compat'? document.documentElement.clientHeight :document.body.clientHeight);
      
      console.log(scrollTop,windowHeight,scrollHeight);
      
      if(scrollTop + windowHeight + 900 > scrollHeight) {
        pageNo += 1;
        this.getGoodsList(pageNo);
      }
     }
    )
  }

  //请求首页热门产品
  async getGoodsList(pageNo){
    let loginInfo = {
      url:baseUrl + "/home/goodsList?appkey=" + appkey + "&pageSize=20" + "&pageNo=" + pageNo,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result == 0){
      this.setState({
        goodsList:this.state.goodsList.concat(data.data.goods)
      })
    }
  }

  render(){
    return (
      <div className="templateHotsale">
        <div className="inner">
          <div className="recTitle" ref="rectitle">推荐商品</div>
          <div className="recClassify clearfix">
          {
             this.state.goodsList && this.state.goodsList.map((item,i) =>{
                return (
                  <li className={( i + 1 ) % 4 === 0 ? "ModelGoodsItem" : "ModelGoodsItem marR"} key={i}>
                    <ProductItem goodsInfo={item}/>
                  </li>
                )})
            }
          </div>
        </div>
      </div>
    )
  }
}

export default TemplateHotsale;