import React, { Component } from 'react';
import "./RefundOrderDetail.less";
import { Link,Redirect } from "react-router-dom";
import { getDataFromServer } from '../../httpRequest/Api.js';
import loginAction from "../../actions/loginAction.js";
import ProgressBar from "../../components/ProgressBar/ProgressBar.js";
import ApplyForRefundBox from "../../components/ApplyForRefundBox/ApplyForRefundBox.js";
import {connect} from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import ReturnInfoBox from "../../components/ReturnInfoBox/ReturnInfoBox.js";

const {toggleLogin} = loginAction;

@connect(
  state => state.loginReducer,{toggleLogin}
)

class RefundOrderDetail extends Component{
  constructor(props){
    super(props)
    this.state = {
      orderDetail:{},//订单详情
      statusNum:3,
      afterSaleAddress:"",//回寄地址
    }
  }
  componentDidMount(){
    if(this.props.match.params.orderId){
      this.getRefundOrderDetail(this.props.match.params.orderId)
    }
    this.getRefundAddress();
  }

  //申请退款
  applyForRefund = () =>{
    this.sureOp.ApplyForRefundBox.style.display = "block";
  }

  //打开回寄信息弹窗
  openReturnInfo = () =>{
    this.ReturnBox.returnInfoBox.style.display = "block";
  }

  //修改物流后刷新
  refresh = () =>{
    if(this.props.match.params.orderId){
      this.getRefundOrderDetail(this.props.match.params.orderId)
    }
  }
  //获取回寄地址
  async getRefundAddress(){
    let loginInfo = {
      url:baseUrl + "/refund/querybyapptab?appkey=" + appkey + "&portType=2" ,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        afterSaleAddress:data.data.data.afterSaleAddress,
      })
    }
  }

  //获取订单详情
 async getRefundOrderDetail(orderId){
  let loginInfo = {
    url:baseUrl + "/refund/querysaleafterdetail?appkey=" + appkey + "&saleAfterId=" + orderId,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      orderDetail:data.data.data,
    })
  }
 }
  render(){
    //登录拦截
    if(!this.props.userLoginInfo){
      return(<Redirect to="/login/user/loginCode"/>);
     }
    let { orderDetail,afterSaleAddress } = this.state;
    let show = {"display":"inline-block"};
    let hide = {"display":"none"};
    let orderStatus = "";
    let nowTitle = 0;
    let text = "";
        switch (orderDetail.saleAfterFlag) {
        case 0:
          orderStatus = "提交申请";
          nowTitle = 1;
          text = "售后受理中，預計需要1-3個工作日反饋审核結果，請耐心等待，如有問題請聯繫客服;"
          break;
        case 1:
          if(orderDetail.logisticsNo){
            orderStatus = "受理中";
            nowTitle = 2;
            text = "审核通过，请尽快把物品寄到港颜指定地址，并且填好快递单号！如有疑问请 联系客服;"
          }else{
            orderStatus = "等待处理";
            nowTitle = 2;
            text = "审核通过，请尽快把物品寄到港颜指定地址，并且填好快递单号！如有疑问请 联系客服;"
          }
          break;
        case 2:
          orderStatus = "完成";
          nowTitle = 3;
          break;
        case 3:
          orderStatus = "审核不通过";
          nowTitle = 4;
          text = orderDetail.solution;
          break;
      }
      let refundInfo = {
        sender:orderDetail.sender,
        sendPhoneNum:orderDetail.sendPhoneNum,
        logistics:orderDetail.logistics,
        logisticsNo:orderDetail.logisticsNo
      };
    return(
      <div className="refundOrderDetail">
        <ApplyForRefundBox ref={r => this.sureOp = r} orderId={this.props.match.params.orderId} userId={this.props.userLoginInfo.id}/>
        {orderDetail.id && <ReturnInfoBox ref={r => this.ReturnBox = r} saleAfterId={orderDetail.id} refundInfo={refundInfo} refresh={this.refresh}/>}
        <div className="inner">
          <Sidebar isHomePage={false} />
          <div className="guideTitle"><span className="homePage"><Link to={"/home"}>港颜首页</Link></span><span className="userCenter"> > <Link to={"/home/usercenter/order"}>个人中心</Link></span><span className="orderDetail"> > 售后单详情</span></div>
          <div className="detailBox">
            <div className="title1">售后单详情</div>
            <div className="orderStatus">
             <ProgressBar
                titles={["提交申请","港颜受理","完成"]}
                nowTitle={ nowTitle }
                statusNum={this.state.statusNum}
                />
            </div>
            <div className="refundorder clearfix">
              <div className="refundStatus">
                <span className="orderNum">售后单号: &nbsp;&nbsp;&nbsp;&nbsp;{orderDetail.saleAfterOrderNo}</span>
                <span className="status">{orderStatus}</span>
                <span className="text">{text}</span>
                <span className="refundTime">申请售后时间：{orderDetail.createTime}</span>
              </div>
              <div className="refundInfo clearfix">
                <div className="leftTitle">
                  <div className="title">售后信息</div>
                  <div className="refundType">售后类型：<span className="item">{orderDetail.saleAfterType === 1?"退货退款":"其他"}</span></div>
                  <div className="refundreason">售后原因：<span className="item">{orderDetail.reason}</span></div>
                  <div className="describe">问题描述：<span className="item">{orderDetail.description}</span>
                   <span className="refundImgs">
                    {orderDetail.imgs && orderDetail.imgs.map(imgItem =>{
                      return(
                        <img key={imgItem.id} src={imgItem.imgPath} alt=""/>
                      )
                    })}
                   </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="returnInfo" style={orderStatus === "审核不通过"?hide:show}>
              <div className="left">
                <div className="title"><p>回寄信息</p></div>
                <p>寄件人：&nbsp;&nbsp;&nbsp;&nbsp;<span className="item">{orderDetail.sender}</span></p>
                <p>寄件电话：<span className="item">{orderDetail.sendPhoneNum}</span></p>
                <p>回寄快递：<span className="item">{orderDetail.logistics}</span></p>
                <p>物流单号：{orderStatus === "等待处理"?<span className="item fillBtn" onClick={this.openReturnInfo}>{orderDetail.logisticsNo?"修改":"填写"}</span>:<span className="item">{orderDetail.logisticsNo}</span>}</p>
                <p>填写时间：<span className="item">{orderDetail.sendTime}</span></p>
                <p>回寄地址：<span className="item">{afterSaleAddress}</span></p>
              </div>
              <div className="right" style={orderStatus === "完成"?show:hide}>
                <div className="title"><p>处理信息</p></div>
                <p>处理方式：<span className="item">{orderDetail.dealType === 1?"退货退款":"其他"}</span></p>
                <p>退款金额：<span className="item">{orderDetail.total}</span></p>
                <p>处理时间：<span className="item">{orderDetail.modifyTime}</span></p>
              </div>
            </div>
            <div className="orderInfo">
              <div className="tableTitle">
                <ul className="clearfix">
                  <li className="tableTitleItem long">商品信息</li>
                  <li className="tableTitleItem">数量</li>
                  <li className="tableTitleItem">价格</li>
                  <li className="tableTitleItem"></li>
                </ul>
              </div>
              <div className="tableItem">
                <table>
                  <tbody>
                    {orderDetail.goods && orderDetail.goods.map(item =>{
                      return(
                        <tr key={item.id} style={orderDetail.goods.length === 1?{borderBottom:"none"}:{}}>
                        <td className='goodItem img'><img src={item.imgPath} alt=""/></td>
                        <td className='goodItem title'><span>{item.title}</span></td>
                        <td className='goodItem count'>x{item.quantity}</td>
                        <td className='goodItem price'>￥{item.price}</td>
                        <td className='goodItem handle'></td>
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RefundOrderDetail;
