import React, {Component} from "react";
import "./Home.less";
import HomeContent from "../../components/HomeContent/HomeContent";
import Banner from "../../components/Banner/Banner";
import PopGetCoupon from "../../components/GetCouponPopView/GetCouponPopView";


class Home extends Component {
  constructor(props){
    super(props)
    this.state={

    }
  }

  render() {
    return (
      <div className="Home">
        <Banner />
        <HomeContent/>
        {/* <PopGetCoupon /> */}
      </div>
    );
  }
}

export default Home;

