import React, { Component } from 'react';
import { Link,withRouter,Redirect } from "react-router-dom";
import "./UserOrder.less";
import {connect} from 'react-redux';
import moment from 'moment';
import { message,Statistic } from 'antd';
import { getDataFromServer } from '../../httpRequest/Api.js';
import Pager from '../../components/Pager/Pager';
import ListNone from '../../components/ListNone/ListNone';
import loginAction from "../../actions/loginAction.js";
import ReturnInfoBox from "../../components/ReturnInfoBox/ReturnInfoBox.js";
import DeleteAddressPopView from "../../components/DeleteAddressPopView/DeleteAddressPopView.js";
import ApplyForRefundBox from "../../components/ApplyForRefundBox/ApplyForRefundBox.js";
import CancelOrderBox from "../../components/CancelOrderBox/CancelOrderBox.js";
import down from "../../images/ic_more_grey.png";
import SEARCH from "../../images/ic_search.png";
import DELWHITE from '../../images/ic_del_zuijinsousuo.png';
import DELRED from '../../images/ic_del_zuijinsousuo_hover.png';
import TIME from "../../images/time.png";
import NoOrder from "../../images/defaultpage_noorde.png";

const { Countdown } = Statistic;
const {toggleLogin} = loginAction;

@connect(
  state => state.loginReducer,{toggleLogin}
)

class userOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderType:1,//订单类型
      status:"",//订单状态
      refundStatus:"",//退货订单状态
      searchName:"",//搜索词
      totalPage:0,
      nowPage:1,
      orderId:"",
      orderList:[],//订单列表
      serOrderList:[],//售后订单列表
      startTime:'',
      endTime:'',
      orders:"",//订单数量
      toPay:"",//待付款订单数
      toShip:"",//待发货订单数
      toSign:"",//待签收订单数
      toCommit:"",//待评价订单数
      refundInfo:{},//物流单号信息
      returnOrderId:"",//售后订单id
      overSeaRefundSwitch:0,//直邮退款开关
      overSeaAfterSaleSwitch: 0,//直邮售后开关
      overSeaReason:[],//退款理由
    }
  }
  componentDidMount(){
    if(this.props.type.type == 6){
      this.getOrderList(this.state.orderType,this.props.userLoginInfo.id,"","",1);
      this.setState({
        orderType:1
      })
    }else{
      this.getOrderList(this.state.orderType,this.props.userLoginInfo.id,"","",1);
    }
    this.getBubbleCount(this.props.userLoginInfo.id);
    this.getRefundReason();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  
  //获取页码
  getPage=(page)=>{
    if(this.state.orderType == 6){
      this.getAfetrSaleOrderList(this.props.userLoginInfo.id,"","",page);
    }else{
      this.getOrderList(this.state.orderType, this.props.userLoginInfo.id,this.state.startTime,this.state.endTime,page);
    }
    this.setState({
      nowPage:page
    })
  }

  //订单类型选项卡点击
  orderTypeTab(orderType){
    this.setState({
      orderType:orderType,
      nowPage:1
    });
    if(orderType == 6){
      this.getAfetrSaleOrderList(this.props.userLoginInfo.id,"","",1);
    }else{
      this.getOrderList(orderType,this.props.userLoginInfo.id,"","",1);
    }
  }

  //搜索订单号和商品名称变化
  searchNameChange = (event) =>{
    this.setState({
      searchName:event.target.value,
    })
  }
  
  //文本搜索
  getSearchOrder = () =>{
    this.ordersearch(this.props.userLoginInfo.id,this.state.searchName,1);
  }

  //按下enter键搜索
  search = (event) =>{
    if(event.keyCode === 13){
      this.ordersearch(this.props.userLoginInfo.id,searchName,1);
    }
  }
  //点击按时间查询订单
  getTimeOrder = (type) =>{
    let { orderType } = this.state;
    console.log(this.state.orderType);
    let startTime = "";
    let endTime = "";
    let date = new Date;
    let nowTime = date.getTime();

    if(type === "all"){
      startTime = "";
      endTime = "";
      if(orderType == 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,startTime,endTime,1);
      }else{
        this.getOrderList(orderType,this.props.userLoginInfo.id,startTime,endTime,1);
      }
    }else if(type === "three"){
      startTime = moment().subtract('months',3).format('YYYY-MM-DD'); ;
      endTime = moment(nowTime).format("YYYY-MM-DD");
      if(orderType == 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,startTime,endTime,1);
      }else{
        this.getOrderList(orderType,this.props.userLoginInfo.id,startTime,endTime,1);
      }
    }else if(type === "2020"){
      startTime = moment("2020-01-01").format("YYYY-MM-DD");
      endTime = moment(nowTime).format("YYYY-MM-DD");
      if(orderType == 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,startTime,endTime,1);
      }else{
        this.getOrderList(orderType,this.props.userLoginInfo.id,startTime,endTime,1);
      }
    }else if(type === "2019"){
      startTime = moment("2019-01-01").format("YYYY-MM-DD");
      endTime = moment("2019-12-31").format("YYYY-MM-DD");
      if(orderType == 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,startTime,endTime,1);
      }else{
        this.getOrderList(orderType,this.props.userLoginInfo.id,startTime,endTime,1);
      }
    }else if(type === "2018"){
      startTime = moment("2019-01-01").format("YYYY-MM-DD");
      endTime = moment("2018-12-31").format("YYYY-MM-DD");
      if(orderType == 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,startTime,endTime,1);
      }else{
        this.getOrderList(orderType,this.props.userLoginInfo.id,startTime,endTime,1);
      }
    }

    this.setState({
      startTime,
      endTime
    })
  }

  //申请退款
  applyForRefund = (orderId) =>{
    this.sureOp.ApplyForRefundBox.style.display = "block";
    this.setState({
      orderId:orderId
    })
  }

  //申请退款后刷新列表
  refreshOrderList = () =>{
    this.getOrderList(this.state.orderType,this.props.userLoginInfo.id,this.state.startTime,this.state.endTime,this.state.nowPage);
    this.getBubbleCount(this.props.userLoginInfo.id);
  }

  //写评论
  writeReview = (goods,orderId) =>{
    this.props.history.push({pathname:"/home/review/" + orderId});
    let obj = JSON.stringify(goods);
    localStorage.setItem("goods",obj);
  }

  //再次购买
  buyAgain = (orderId) =>{
    this.postBuycart(orderId)
  }

  //打开回寄信息弹窗
  openReturnInfo = (returnOrderId) =>{
    this.setState({
      returnOrderId
    },()=>{
      this.ReturnBox.returnInfoBox.style.display = "block";
      this.getRefundOrderDetail(returnOrderId)
    })
  }
  
  //修改物流后刷新
  refresh = () =>{
    this.getAfetrSaleOrderList(this.props.userLoginInfo.id,"","",this.state.nowPage);
  }

  //删除订单
  removeOrder = (orderId) =>{
    this.setState({
      orderId:orderId,
      isDelete:true,
    });
  }

  //确认删除订单弹框
  hiddenDeleteView = (isDelete) =>{
    if(isDelete){
      this.deleteOrder(this.state.orderId);
    }
    this.setState({
      isDelete:false,
    });
  }

  //取消订单 
  cancelOrder = (orderId) =>{
    this.celOrder.CancelOrderBox.style.display = "block";
    this.setState({
      orderId
    })
  }

  //去支付
  goPay = (id) =>{
    this.props.history.push("/home/paycenter/" + id);
  }
  
  //确认收货
async confirmRece(orderId){
    let loginInfo = {
      url:baseUrl + "/order/updatestatus?orderStatus=9&appkey=" + appkey + "&orderId=" + orderId,
      method: 'POST',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      message.success(<span className="msgText">{data.data.msg}</span>);
      this.getOrderList(this.state.orderType,this.props.userLoginInfo.id,this.state.startTime,this.state.endTime,this.state.nowPage)
    }else{
      message.error(<span className="msgText">{data.data.msg}</span>);
    }
  }

  
  //删除订单请求
  async deleteOrder(orderId){
    let loginInfo = {
      url:baseUrl + "/order/delete?appkey=" + appkey + "&orderId=" + orderId,
      method: 'DELETE',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      message.success(<span className="msgText">{data.data.msg}</span>);
      if(this.state.orderType === 6){
        this.getAfetrSaleOrderList(this.props.userLoginInfo.id,this.state.startTime,this.state.endTime,this.state.nowPage);
      }else{
        this.getOrderList(this.state.orderType,this.props.userLoginInfo.id,this.state.startTime,this.state.endTime,this.state.nowPage);
      }
    }else{
      message.error(<span className="msgText">{data.data.msg}</span>);
    }
   }

  //获取订单列表
 async getOrderList(orderType,userId,startTime,endTime,pageNo){
  let loginInfo = {
    url:baseUrl + "/order/useroversealist?appkey=" + appkey + "&orderType=" + orderType + "&pageNo=" + pageNo +"&pageSize=20" + "&userId=" + userId + "&startTime=" + startTime + "&endTime=" + endTime,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      totalPage:data.data.pageCount,
      nowPage:data.data.pageNo,
      orderList:data.data.orderlist,
    })
  }
 }
 
 //订单搜索
 async ordersearch(userId,search,pageNo){
  let loginInfo = {
    url:baseUrl + "/order/ordersearch?appkey=" + appkey + "&pageNo=" + pageNo +"&pageSize=20" + "&userId=" + userId + "&search=" + search + "&type=1",
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    console.log(data.data);
    this.setState({
      totalPage:data.data.pageCount,
      nowPage:data.data.pageNo,
      orderList:data.data.data,
    })
  }else{
   message.warning(<span className="msgText">{data.data.msg}</span>);
  }
 }

 //获取售后订单
 async getAfetrSaleOrderList(userId,startTime,endTime,pageNo){
  let loginInfo = {
    url:baseUrl + "/refund/querysaleafetrorders?appkey=" + appkey + "&pageNo=" + pageNo +"&pageSize=20" + "&userId=" + userId +"&startTime=" + startTime + "&endTime=" + endTime,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      totalPage:data.data.pageCount,
      nowPage:data.data.pageNo,
      serOrderList:data.data.data,
    })
  }
 }

 //获取退款理由
 async getRefundReason(){
  let loginInfo = {
    url:baseUrl + "/refund/querybyapptab?appkey=" + appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      overSeaReason:data.data.data.overSeaReason,
      overSeaRefundSwitch:data.data.data.overSeaRefundSwitch,
      overSeaAfterSaleSwitch:data.data.data.overSeaAfterSaleSwitch
    })
  }
}
 
 //获取售后订单详情
 async getRefundOrderDetail(orderId){
  let loginInfo = {
    url:baseUrl + "/refund/querysaleafterdetail?appkey=" + appkey + "&saleAfterId=" + orderId,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    let refundInfo = {
      sender:data.data.data.sender,
      sendPhoneNum:data.data.data.sendPhoneNum,
      logistics:data.data.data.logistics,
      logisticsNo:data.data.data.logisticsNo
    };
    this.setState({
      refundInfo,
    })
  }
 }

 //获取订单气泡数量
 async getBubbleCount(userId){
   let loginInfo = {
    url:baseUrl + "/user/data?appkey=" + appkey + "&userId=" + userId +"&appTab=2",
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      orders:data.data.data.orders,
      toPay:data.data.data.toPay,
      toShip:data.data.data.toShip,
      toSign:data.data.data.toSign,
      toCommit:data.data.data.toCommit
    })
  }
 }

 //再次购买，加入购物车
 async postBuycart(orderId){
  let loginInfo = {
    url:baseUrl + "/v1.6/user/shopping/again?appkey=" + appkey + "&orderId=" + orderId,
    method: 'POST',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    message.success(<span className="msgText">{data.data.msg}</span>);
    this.props.history.push({pathname:"/home/buycart"});
  }else{
    message.error(<span className="msgText">{data.data.msg}</span>);
  }
 }
  render() {
    //登录拦截
    if(!this.props.userLoginInfo){
      return(<Redirect to="/login/user/loginCode"/>);
     }
    let { orderType,orders,toPay,toShip,toSign,toCommit,returnOrderId,refundInfo,orderId,overSeaRefundSwitch,overSeaReason } = this.state;
    let styleAct = {"color":"#E41135","borderBottom":"2px solid #E41135",};
    let show = {};
    let hide = {display:"none"};
    let timeStyle = {fontSize:"14px",color:"#666666"};
    return (
      <div className="userOrder">
        <CancelOrderBox ref={r => this.celOrder = r} orderId={orderId} refreshPage={this.refreshOrderList}/>
        {this.state.isDelete && <DeleteAddressPopView tip="您是否要删除该订单?" hiddenDeleteView={this.hiddenDeleteView}/>}
        <ApplyForRefundBox ref={r => this.sureOp = r} orderId={this.state.orderId} userId={this.props.userLoginInfo.id} refreshPage={this.refreshOrderList} overSeaReason={overSeaReason}/>
        {returnOrderId && <ReturnInfoBox ref={r => this.ReturnBox = r} saleAfterId={returnOrderId} refundInfo={refundInfo} refresh={this.refresh}/>}
          <div className="selTypeTab">
            <ul className="clearfix">
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"1")} style={orderType=="1"?styleAct:{}}>全部订单<span style={{"display":"none"}} className="tag">{orders}</span></li>
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"2")} style={orderType=="2"?styleAct:{}}>待付款<span style={toPay?{}:{"display":"none"}} className="tag">{toPay}</span></li> 
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"3")} style={orderType=="3"?styleAct:{}}>待发货<span style={toShip?{}:{"display":"none"}} className="tag">{toShip}</span></li>
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"4")} style={orderType=="4"?styleAct:{}}>待收货<span style={toSign?{}:{"display":"none"}} className="tag">{toSign}</span></li>
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"5")} style={orderType=="5"?styleAct:{}}>待评价<span style={toCommit?{}:{"display":"none"}} className="tag">{toCommit}</span></li>
              <li className="tabItem" onClick={this.orderTypeTab.bind(this,"6")} style={orderType=="6"?styleAct:{}}>售后</li>
              <li className="inputItem" style={orderType=="6"?hide:show}><input type="text" value={this.state.searchName} onChange={this.searchNameChange} onKeyDown={this.search} className="searchInput" placeholder="商品名称/订单号"/><span className="searchBtn" onClick={this.getSearchOrder}><img src={SEARCH} alt=""/></span></li>
            </ul>
          </div>
          <div className="tableTitle">
            <ul className="clearfix">
              <li className="tableTitleItem searchTitleItem">
                <div className="searchTime">
                  <span onClick={this.getTimeOrder.bind(this,"all")}>全部<img src={down} alt=""/></span>
                  <span onClick={this.getTimeOrder.bind(this,"three")}>近三个月的订单</span>
                  <span onClick={this.getTimeOrder.bind(this,"2020")}>今年内订单</span>
                  <span onClick={this.getTimeOrder.bind(this,"2019")}>2019年订单</span>
                  <span onClick={this.getTimeOrder.bind(this,"2018")}>2018年订单</span>
                </div>
              </li>
              <li className="tableTitleItem">收货人</li>
              <li className="tableTitleItem">金额</li>
              <li className="tableTitleItem">状态</li>
              <li className="tableTitleItem handleTitleItem">操作</li>
            </ul>
          </div>
          <div className="tableItem" style={this.state.orderType == 6 ?hide:show}>
            { this.state.orderList.map((item,index) =>{
              //订单状态
              let orderStatus = "";
              switch (item.status) {
                case 1:
                  orderStatus = "未付款";
                  break;
                case 2:
                case 7:
                case 19:
                case 20:
                  if(item.refundStatus === 2){
                    orderStatus = "交易已取消";
                  }else{
                    orderStatus = "待发货";
                  }
                  break;
                case 8:
                  orderStatus = "已发货";
                  break;
                case 9:
                  orderStatus = "已签收";
                  break;
                case 10:
                  orderStatus = "申请退货退款";
                  break;
                case 11:
                  orderStatus = "拒绝退货退款";
                  break;
                case 12:
                  orderStatus = "同意退货退款";
                  break;
                case 13:
                  orderStatus = "已退货退款";
                  break;
                case 14:
                  orderStatus = "申请退款";
                  break;
                case 15:
                  orderStatus = "拒绝退款";
                  break;
                case 16:
                  orderStatus = "同意退款";
                  break;
                case 17:
                  orderStatus = "已退款";
                  break;
                case 18:
                  orderStatus = "退单完成";
                  break;
                case 28:
                  orderStatus = "申请换货";
                  break;
                case 29:
                  orderStatus = "拒绝换货";
                  break;
                case 30:
                  orderStatus = "同意换货";
                  break;
                case 31:
                  orderStatus = "已换货";
                  break;
                case 25:
                  orderStatus = "已完成";
                  break;
                case 26:
                  orderStatus = "已取消";
                  break;
                default:
                  break;
              }
              return(
                <table key={item.id} className="myOdr_tab">
                  <tbody className="myOdr_tb1">
                    <tr>
                      <th className="oInfo bbNone clearfix" colSpan="5"><span className="orderTime">{item.createTime}</span><Link to={`/home/orderDetail/${item.id}`}><span className="orderNum">订单号: &nbsp;{item.outOrderNo}</span></Link><span className="delOrder" onClick={this.removeOrder.bind(this,item.id)}><img src={DELWHITE} alt="" className="delwhite"/><img src={DELRED} alt="" className="delred"/></span></th>
                    </tr>
                    {
                      item.orderGoods.map((goods,goodsIndex) =>{
                        let date = new Date(item.createTime);
                        let deadline = date.getTime(date) + 1000*60*30;
                        if(goodsIndex === 0){
                          return (
                            <tr className="aOrder" key={goods.id}>
                              <td className="aOrder-items">
                                <table className="myOdr_tb2">
                                  <tbody>
                                    <tr>
                                      <td className="img"><Link to={`/home/goodsdetail/${goods.goodinfoId}`} target="_blank"><img src={goods.image} alt=""/></Link></td>
                                      <td className="goodsTitle"><span><Link to={`/home/goodsdetail/${goods.goodinfoId}`} target="_blank">{goods.title}</Link></span></td>
                                      <td className="amount"><span>x{goods.quantity}</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="rbBorder" rowSpan={item.orderGoods.length}>{item.name}</td>
                              <td className="rbBorder" rowSpan={item.orderGoods.length}>￥<span className="price">{item.depositRMBPrice}</span><span className="text">{item.refundStatus === 1 ? "退款受理":""}</span></td>
                              <td className="rbBorder" rowSpan={item.orderGoods.length}><span className="orderStatus">{orderStatus}</span><span className="orderdetail"><Link to={`/home/orderDetail/${item.id}`} target="a_blank">订单详情</Link></span></td>
                              <td className="rbBorder handleOrder" rowSpan={item.orderGoods.length}>
                                <span className="countDown" style={item.status === 1?show:hide}><img src={TIME} alt=""/>剩余 <span className="timeText"><Countdown value={deadline} valueStyle={timeStyle} format=" m 分 s 秒" /></span></span>
                                <span className="btn" style={item.status === 1?show:hide} onClick={this.goPay.bind(this,item.id)}>去支付</span>
                                <span className="btn" style={item.status === 1?hide:show} onClick={this.buyAgain.bind(this,item.id)}>再次购买</span>
                                <span className="btn" style={item.status === 8?show:hide} onClick={this.confirmRece.bind(this,item.id)}>确认收货</span>
                                <span className="text" style={item.status === 1?show:hide} onClick={this.cancelOrder.bind(this,item.id)}>取消订单</span>
                                <span className="btn" style={item.status === 9?show:hide} onClick={this.writeReview.bind(this,item.orderGoods,item.id)}>去评价</span>
                                <span className="text" style={item.status === 1 || item.status === 26 || item.refundStatus === 1 ? hide:show}><Link to={"/home/orderDetail/" + item.id} target="a_blank">物流跟踪</Link></span>
                                {item.refundStatus === 0 && overSeaRefundSwitch === 1 && item.printFlag === 0?<span className="text" style={item.status === 2 || item.status === 7 || item.status === 19 || item.status === 20?show:hide} onClick={this.applyForRefund.bind(this,item.id)}>申请退款</span>:""}
                              </td>
                            </tr>
                          )
                        }else{
                          return (
                            <tr className="aOrder" key={goods.id}>
                              <td className="aOrder-items">
                                <table className="myOdr_tb2">
                                  <tbody>
                                    <tr>
                                      <td className="img"><Link to={`/home/goodsdetail/${goods.goodinfoId}`} target="_blank"><img src={goods.image} alt=""/></Link></td>
                                      <td className="goodsTitle"><span><Link to={`/home/goodsdetail/${goods.goodinfoId}`} target="_blank">{goods.title}</Link></span></td>
                                      <td className="amount"><span>x{goods.quantity}</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )
                        }
                      })
                    }
                  </tbody>
                </table>
            )})}
            <Pager total={this.state.totalPage} current={this.state.nowPage} getPage={this.getPage}/>
          </div>
          {this.state.orderType != 6 && <div className="noOrderBox" style={this.state.orderList.length?hide:show}><div className="noOrder"><img src={NoOrder} alt=""/><p>啊哦~还没有相关订单呢</p></div></div>}
          
          <div className="tableItem" style={this.state.orderType == 6 ?show:hide}>
            { this.state.serOrderList.map((item,index) =>{
              //订单状态
              let orderStatus = "";
              if(item.saleAfterFlag == 0){
                orderStatus = "待审核";
              }else if(item.saleAfterFlag == 1){
                if(item.logisticsNo){
                  orderStatus = "已受理";
                }else{
                  orderStatus = "等待处理";
                }
              }else if(item.saleAfterFlag == 2){
                orderStatus = "已完成";
              }else if(item.saleAfterFlag == 3){
                orderStatus = "审核不通过";
              }
              return(
                <table key={item.id} className="myOdr_tab">
                  <tbody className="myOdr_tb1">
                    <tr>
                      <th className="oInfo bbNone clearfix" colSpan="5"><span className="orderTime">{item.createTime}</span><span className="orderNum">订单号: &nbsp;{item.saleAfterOrderNo}</span><span className="delOrder" onClick={this.removeOrder.bind(this,item.id)}><img src={DELWHITE} alt="" className="delwhite"/><img src={DELRED} alt="" className="delred"/></span></th>
                    </tr>
                    {
                      item.goods.map((goods,goodsIndex) =>{
                        if(goodsIndex === 0){
                          return (
                            <tr className="aOrder" key={goods.goodsId}>
                              <td className="aOrder-items">
                                <table className="myOdr_tb2">
                                  <tbody>
                                    <tr>
                                      <td className="img"><img src={goods.imgPath} alt=""/></td>
                                      <td className="goodsTitle"><span>{goods.title}</span></td>
                                      <td className="amount"><span>x{goods.quantity}</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="rbBorder" rowSpan={item.goods.length}>{item.name}</td>
                              <td className="rbBorder" rowSpan={item.goods.length}>￥<span className="price">{goods.price}</span></td>
                              <td className="rbBorder" rowSpan={item.goods.length}><span className="orderStatus">{orderStatus}</span><span className="orderdetail"><Link to={`/home/refundorderDetail/${item.id}`} target="a_blank">售后订单详情</Link></span></td>
                              <td className="rbBorder handleOrder" rowSpan={item.goods.length}>
                                <span className="btn long" onClick={this.openReturnInfo.bind(this,item.id)} style={orderStatus === "等待处理"?show:hide}>填写物流单号</span>
                              </td>
                            </tr>
                          )
                        }else{
                          return (
                            <tr className="aOrder" key={goods.id}>
                              <td className="aOrder-items">
                                <table className="myOdr_tb2">
                                  <tbody>
                                    <tr>
                                      <td className="img"><img src={goods.image} alt=""/></td>
                                      <td className="goodsTitle"><span>{goods.title}</span></td>
                                      <td className="amount"><span>x{goods.quantity}</span></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )
                        }
                      })
                    }
                  </tbody>
                </table>
            )})}
            <Pager total={this.state.totalPage} current={this.state.nowPage} getPage={this.getPage}/>
          </div>
          {this.state.orderType == 6 && <div className="noOrderBox" style={this.state.serOrderList.length?hide:show}><div className="noOrder"><img src={NoOrder} alt=""/><p>啊哦~还没有相关订单呢</p></div></div>}
      </div>
    );
  }
}

export default withRouter(userOrder);