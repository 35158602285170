import React,{ Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import TemplateLinktype from "../../utils/TemplateLinktype";

const TemplateItem = ({itemInfo}) => {
  let path = TemplateLinktype(itemInfo,"template");
  if (itemInfo) {
    return (
      <div className="item">
        <Link to={path} target={path === "/home" ? "" : "_blank"}>
          <img src={itemInfo.templateImg} alt="" className="itemImg"/>
          <h2 style={{cursor:"pointer"}} className="mainTitle">{itemInfo.title?itemInfo.title:""}</h2>
          <p style={{cursor:"pointer"}} className="subTitle">{itemInfo.subTitle?itemInfo.subTitle:""}</p>
        </Link>
      </div>
    )
  }else{
    return "";
  }
}

export default TemplateItem;