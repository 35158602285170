import React, { Component } from 'react';
import './Review.less';
import { Link,Redirect } from "react-router-dom";
import { getDataFromServer } from '../../httpRequest/Api.js';
import { Upload, Icon, Modal ,Rate ,message } from 'antd';
import { connect } from 'react-redux';
import loginAction from "../../actions/loginAction.js";
import Sidebar from "../../components/Sidebar/Sidebar";

const { toggleLogin } = loginAction;

@connect(
  state => state.loginReducer,{ toggleLogin }
)

class Review extends Component{
  constructor(props){
    super(props)
    this.state = {
      orderId:"",//订单Id
      goodinfoId:"",//商品Id
      qiniuToken:"",
      goods:[],
      notice:false
    }
  }
  componentDidMount(){
    this.getRefundReason();
    this.qiniuToken();
    let goods = JSON.parse(localStorage.getItem("goods"));
    goods.forEach((item,index) =>{
      item.comment = "";
      item.stars = 100;
      item.fileList = [];
      item.previewVisible = false;
      item.previewImage = "";
    })
    this.setState({
      goods
    })
  }

  //评分改变
  starChange = (index,value) =>{
    let { goods } = this.state;
    goods[index].stars = 20*value;
    this.setState({
      goods
    })
  }

  //评论改变
  commentChange = (index,event) =>{
    let { goods } = this.state;

    if(event.target.value.length){
      goods[index].comment = event.target.value;
      this.setState({
        notice:false,
        goods,
      })
    }else{
      if(event.target.value.length < 300){
        goods[index].comment = event.target.value;
        this.setState({
          goods,
          notice:true,
        })
      }
    }
  }

  //图片上传token生成
 async qiniuToken(){
    let loginInfo = {
      url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        qiniuToken:data.data.uploadToken,
      })
    }
  }

  handleCancel = (index) =>{
    let { goods } = this.state;
    goods[index].previewVisible = false;
    this.setState({
      goods
    })
  };

  getBase64 =(file) =>{
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  //图片预览
  handlePreview = async (index,file) => {
    let { goods } = this.state;
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    goods[index].previewImage = file.url || file.preview;
    goods[index].previewVisible = true;
    this.setState({
      goods
    });
  };

  //改变图片
  handleChange = ( index,{ fileList }) => {
    let { goods } = this.state;
    console.log(fileList);
    goods[index].fileList = fileList;
    this.setState({
      goods
     })
  };

  //提交评论
  submitReview = (goods,index) =>{
    if (goods.comment.length != 0) {
      let newArr = [];
      let flag = true;
      goods.fileList.forEach(item => {
        if(item.response){
          let URL = "http://img.maigang360.com/" + item.response.hash;
          newArr.push(URL);
          flag = true;
        }else{
          flag = false;
        }
      });
      if(flag){
        this.postReview(this.props.userLoginInfo.id,this.props.match.params.orderId,goods.goodinfoId,goods.comment,goods.stars,newArr,index)
      }else{
        message.error(<span className="msgText">图片上传中,请上传完再提交</span>);
      }
    }
  }

  //提交评论请求
  async postReview(userId,orderId,goodinfoId,content,stars,images,index){
    let { goods } = this.state;
    let loginInfo = {
      url:baseUrl + "/comment/add?appkey=" + appkey + "&userId=" + userId + "&orderId=" + orderId + "&goodinfoId=" + goodinfoId + "&content=" + content + "&stars=" + stars + "&images=" + images,
      method: 'PUT',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      goods[index].commentFlag = 1;
      localStorage.setItem("goods",JSON.stringify(goods));
      this.setState({
        goods
      });
      message.success(<span className="msgText">{data.data.msg}</span>);
      //判断是否全部评论完毕
      let isAllComment = false;
      goods.forEach(item =>{
        if(item.commentFlag === 1){
          isAllComment = true;
        }else{
          isAllComment = false
        }
      })

      if(isAllComment){
        this.props.history.push({pathname:"/home/usercenter/order"});
      }
    }
  }

  //获取退款理由
  async getRefundReason(){
    let loginInfo = {
      url:baseUrl + "/refund/querybyapptab?appkey=" + appkey + "&portType=2" ,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        afterSaleReason:data.data.data.overSeaReason,
      })
    }
  }

  render(){
    //登录拦截
    if(!this.props.userLoginInfo){
      return(<Redirect to="/login/user/loginCode"/>);
     }
    let { goods,notice } = this.state;
    let show = {"display":"inline-block"};
    let hide = {"display":"none"};
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传照片</div>
        <span className="limit">最多只能上传5张</span>
      </div>
    );
    return(
      <div className="review">
        <div className="inner">
          <Sidebar isHomePage={false} />
          <div className="guideTitle"><span className="homePage"><Link to={"/home"}>港颜首页</Link></span><span className="userCenter"> > <Link to={"/home/usercenter/order"}>个人中心</Link></span><span className="orderDetail"> > 评价</span></div>
          <div className="detailBox">
            <div className="title1">评价</div>
            {goods.map((item,index) =>{
              return(
                <div className="goodsreview clearfix" key={item.id} style={item.commentFlag === 1?hide:show}>
                  <div className="goodsInfo">
                    <div className="img"><img src={item.image} alt=""/></div>
                    <div className="title">{item.title}</div>
                    <div>￥{item.totalPriceRMB}</div>
                  </div>
                  <div className="comment">
                    <div className="grade">
                      <span className="title">商品评分:</span><Rate allowClear={false} defaultValue={5} onChange={this.starChange.bind(this,index)}/>
                    </div>
                    <div className="text">
                      <div className="title"><span>评价商品：</span><span className="red">(必填)</span></div>
                      <textarea cols="5" rows="10" value={item.comment} onChange={this.commentChange.bind(this,index)} placeholder="请输入300字以内的评价~"></textarea>
                      <span className="notice" style={notice?show:hide}><span className="remove"><i></i></span>请填写详细评价</span>
                    </div>
                    <div className="uploading">
                      <span className="title"><i className="red">*</i>上传图片: </span>
                      <div className="uploadImg clearfix">
                        <Upload
                          action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                          listType="picture-card"
                          fileList={item.fileList}
                          onPreview={this.handlePreview.bind(this,index)}
                          onChange={this.handleChange.bind(this,index)}
                        >
                          {item.fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                        <Modal visible={item.previewVisible} footer={null} onCancel={this.handleCancel.bind(this,index)}>
                          <img alt="example" style={{ width: '100%' }} src={item.previewImage} />
                        </Modal>
                      </div>
                    </div>
                    <div className="submitBtn" onClick={this.submitReview.bind(this,item,index)}>发表</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Review;
