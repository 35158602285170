let promiseArr = [];
let needListern = true;

const loading = {
	//添加promise
	addPromise: function (promise) {
		promiseArr.push(promise);
		if (needListern) {
			document.getElementById('loading').style.display = "block";
			needListern = false;
			setTimeout(loading.listernPromise, 500);
		}
	},
	//清空promise数组
	clearPromise: function() {
		promiseArr = []
	},
	//监听所有promise是否完成
	listernPromise:() => {
		Promise.all(promiseArr).then(function(values) {
			needListern = true;
			document.getElementById('loading').style.display = "none";
			loading.clearPromise();
		});
	}
}

export default loading;