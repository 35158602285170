import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import loginReducer from "../reducers/loginReducer";
import homeReducer from "../reducers/homeReducer";

export const store = createStore(
  combineReducers({ loginReducer,homeReducer }),
  applyMiddleware(thunk, logger)
);