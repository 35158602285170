import axios from 'axios';
import loading from './loading.js';
import {notification} from 'antd';

const key = 'keepOnlyOne';
const {addPromise} = loading;

/**
 *  接口请求数据时执行的方法
 *  接受参数为请求的路径url、请求接口配置参数configObj
 *
 * @param {String} url            用户传入的请求路径
 * @param {Object} configObj        用户传入的接口参数
 */
export function getDataFromServer(configObj) {
	//用户传入的接口配置参数
	let {
		url,
		method = 'GET',
		params = {},
		data = {},
		timeout = 16000
	} = configObj;
	/**
	 * 返回的Promise对象含有then、catch方法
	 */
	const promise = new Promise(function (resolve, reject) {
		axios({
			url: url,
			method: method,
			params: params,
			data: data,
			timeout: timeout,
			headers: {
				'Content-Type': 'application/json',
			}
		}).then(function (response) {
			if(response){
				if (response.data) {
					resolve(response);
				}else {
					notification.error({
						key,
						message: '操作失败',
						description: '返回的数据格式有误'
					});
					resolve(response);
				}
			}else {
				//处理特殊的情况就是response返回什么也没有
				notification.error({
					key,
					message: '操作失败',
					description: '服务器错误'
				});
				resolve(response);
			}
		}).catch(function (error) {
			notification.error({
				key,
				message: '操作失败',
				description: '网络异常,请稍后重试'
			});
			reject(error);
		})
	})
	// loading(promise);
	return promise;
}