import React,{Component} from "react";
import { Redirect } from "react-router-dom";
import { getDataFromServer } from '../../httpRequest/Api.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import "./FeedBack.less"
import { Upload, Icon, Modal ,Rate ,message } from 'antd';
import checkImg from "../../images/ic_success_nor.png";

@connect(
  state => state.loginReducer
)

class FeedBack extends Component{
  constructor(props){
    super(props);
    this.state={
      qiniuToken:"",
      picList:[],
      previewVisible:false,
      previewImage:"",
      content:"",
      contentL:0,//反馈文字长度
      submitSuccess:false,//提交完成
    }
  }

  componentDidMount(){
    this.qiniuToken();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  //图片上传token生成
 async qiniuToken(){
  let loginInfo = {
    url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      qiniuToken:data.data.uploadToken,
    })
  }
}

getBase64 =(file) =>{
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

 //图片预览
 handlePreview = async (file) => {
  let {previewImage,previewVisible } = this.state;
  if (!file.url && !file.preview) {
    file.preview = await this.getBase64(file.originFileObj);
  }
  previewImage = file.url || file.preview;
  previewVisible = true;
  this.setState({
    previewVisible:previewVisible,
    previewImage:previewImage,
  });
};

//改变图片
handleChange = ({fileList}) => {
  let { picList } = this.state;
  picList = fileList
  console.log(fileList);
  this.setState({
    picList:picList,
   })
};
handleCancel = () =>{
  let { previewVisible } = this.state;
  previewVisible = false;
  this.setState({
    previewVisible,
  })
};
handleTextOnchange = (e) =>{
  let content = e.target.value;
  let contentL = content.length;
  this.setState({
    content:content,
    contentL:contentL,
  });
}
//提交反馈
async submitFeed(){
  let { content,picList} = this.state;
  let userInfo = this.props.userLoginInfo;
  let newArr = [];
  let isuploadDone = true;
  picList.forEach(item => {
    if (item.response) {
      let URL = "http://img.maigang360.com/" + (item.response ? item.response.hash : "");
      newArr.push(URL);
    }else{
      isuploadDone = false;
    }
  });
  if (!isuploadDone) {
    message.error(<span className="msgText">图片上传中，请稍后再提交</span>);
    return;
  }
  if (userInfo) {
    let feedback = {
      url:baseUrl + "/feedback?appkey=" + appkey + "&userId=" + userInfo.id + "&content=" + content + "&images=" + newArr,
      method: 'PUT',
      data: {}
    }
    let data = await getDataFromServer(feedback);
    if(data.data.result == 0){
      this.setState({
        submitSuccess:true,
      })
      message.success(<span className="msgText">反馈提交成功</span>);
    }else{
      message.error(<span className="msgText">反馈提交失败</span>);
    }
  }
}
submitAction = () =>{
  let { content,picList } = this.state;
  if (content && content != "" && picList && picList.length > 0) {
    this.submitFeed()
  }
}
  render(){

    if(!this.props.userLoginInfo){
      return (
              <Redirect to="/login/user/loginCode"/>
            )
    }

    let {picList,previewVisible,previewImage,contentL,submitSuccess} = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传照片</div>
        <span className="limit">最多只能上传6张</span>
      </div>
    );
    return(
      <div className="feedback_bg">
        <div className="white_bg">
          <div className="title">意见反馈</div>
          <div className="text_bg">
            <textarea className="text_content" maxLength="500" onChange={this.handleTextOnchange} placeholder="对我们的网站、APP、商品、服务，您还有什么意见吗？您希望在港颜上买到什么？请告诉我们"></textarea>
            <div className="text_num">{`${contentL}/500字`}</div>
          </div>
          <div className="select_pic_bg">
            <Upload
              action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
              listType="picture-card"
              fileList={picList}
              onPreview={this.handlePreview}
              onChange={this.handleChange}
            >
              {picList.length >= 6 ? null : uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </div>
          <div className="submit" onClick={this.submitAction}>提交</div>
        </div>
        {submitSuccess &&
        <div className="success_bg">
          <div className="title">意见反馈</div>
          <img  className="check" src={checkImg}/>
          <div className="success_tip">您的反馈已记录，感谢您的支持！我们会不断努力，为您提供更优质的服务。</div>
          <div><Link className="back_home" to="/home">返回首页</Link></div>
        </div>
        }
      </div>
    );
  }
}

export default FeedBack;
