import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { store } from "./store/store.js";

ReactDOM.render(
	<Provider store = { store }>
    <App />
  </Provider>, document.getElementById('root')
);
serviceWorker.unregister();
