import React, {Component} from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Statistic } from 'antd';
import  "./ProductItem.less";
import tag from "../../images/ic_baoshui.png";
import qiangguang from "../../images/tag_yiqiangguang_goods.png";

@connect(state => state.homeReducer)

class ProductItem extends Component{
  render (){
    const {goodsInfo,index,systemData,type} = this.props;
    let stock = goodsInfo.virtualFlag === 1 ? goodsInfo.virtualStock : goodsInfo.warestock1;
    let originalPriceText = "";  //原价文本
    let originalPrice = 0;  //原价
    if (systemData) {
      if (systemData.copywritingEnable !== 1) {
        originalPriceText = systemData.copywritingText;
      }
    }
    if (goodsInfo.limitBuyFlag === 1) {
      originalPrice = goodsInfo.originalPrice;
      if(goodsInfo.limitStatus === 2){
        originalPriceText = "抢购价";
      }else if(goodsInfo.limitStatus === 1){
        originalPriceText = "原价";
      }
    }else{
      originalPrice = goodsInfo.b2cOriginalPrice;
    }
    let price = goodsInfo.priceB2COverseaVAT ? goodsInfo.priceB2COverseaVAT : goodsInfo.priceOverseaVAT;
    return (
      <Link to={`/home/goodsdetail/${this.props.goodsInfo.id}`} target="_blank">
        <div className="ProductItem">
          {/*包税标签*/}
          {
            goodsInfo.dutyFreeFlag === 1 &&
            <img src={tag} alt="" className="tag"/>
          }
          {/*排行标签*/}
          {
            index <= 2 && 
            <div className="sortTagBox">
              {index + 1}
            </div>
          }
          <div className="Img">
            <div className="label"><img src={goodsInfo.imgpath} alt=""/></div>
            {
              goodsInfo.goodsLabels && goodsInfo.goodsLabels.map((item,index) =>{
                let zindexStyle = {zIndex:"-" + index};
                return <div key={index} className="label" style={zindexStyle}><img src={item.labelImg} alt=""/><span className="labelPrice" style={item.labelType === 1?{display:"none"}:{}}><Statistic prefix="￥" value={item.priceB2COverseaVATTheir}  precision={1} groupSeparator="" /></span></div>
              })
            }
            {/*抢光*/}
            {
              stock <= 0 &&
              <img src={qiangguang} alt="" className="stockNone"/>
            }
          </div>
          <div className="proContent">
            {
            goodsInfo.country && <div className="country">
              <img src={goodsInfo.country.logo} alt="" className="countryImg"/>
              {goodsInfo.country.cnName}
            </div>
            }
            <div className="price">
              <span className="symbol">￥</span>
              <span className="number">{price}</span>
              {
                originalPrice > 0 &&
                <span className="discount">{ (price * 10 / originalPrice).toFixed(1) }折</span>
              }
              {
                originalPrice > 0 &&
                <span className={goodsInfo.limitStatus === 1 ? "oriPrice lineThrough" : "oriPrice"}>{originalPriceText} ￥{originalPrice}</span>
              }
            </div>
            <div className="Title">{goodsInfo.title}</div>
            <div className="promotionBox">
              {
                goodsInfo.promotionTags.map((item,i) => {
                  return (
                    <div className="promotionItem" key={i}>
                      {item.tagName}
                    </div>
                  )
                })
              }
            </div>
            <div className="saleComment">
              <span className="sale">{type === "favorites" ? `收藏${goodsInfo.favorites}` : `销量${goodsInfo.sales}`}</span>
              <span className="comment">评论 {goodsInfo.comments}</span>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default ProductItem;