import React,{Component} from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import loginAction from "../../actions/loginAction.js";
import { Tooltip,message,Statistic,Rate } from 'antd';
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./MyCollection.less";
import Pager from "../../components/Pager/Pager";
import warning from "../../images/ic_warning_small.png";
import saleOut from "../../images/tag_yiqiangguang_foot.png";

const {toggleLogin} = loginAction;

@connect(
  ({homeReducer,loginReducer}) => ({
    systemData: homeReducer.systemData,
    userLoginInfo: loginReducer.userLoginInfo
  }),{toggleLogin}
)

class MyCollection extends Component{
  constructor(props){
    super(props);
    this.state={
      selePageIndex:1,
      pageSize:15,
      pageNo:1,
      itemIndex:0,
      showMask:false,
      goodsCollectList:[],//收藏列表
      totalPage:0,
    };
  }
  componentDidMount(){
    this.loadCollection();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  async loadCollection(){
    let loginInfo = this.props.userLoginInfo;
    let {pageNo,pageSize} = this.state;
    if(loginInfo){
        let userId = loginInfo.id;
        let reqUrl = `${baseUrl}/goodcollect/listbyuser?appTab=2&appkey=${appkey}&userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
        let method = "GET";
        let collectionList= {
          url:reqUrl,
          method
      }
      let response = await getDataFromServer(collectionList);
      this.setState({
        goodsCollectList:response.data.goodcollectlist,
        totalPage:response.data.pageCount,
      });
    }
  }
  async cancelCollection(index){
    let loginInfo = this.props.userLoginInfo;
    let goodsCollectList = this.state.goodsCollectList
    if(loginInfo){
      let goodInfoId = goodsCollectList[index].goodInfo.id;
      let userId = loginInfo.id;
      let reqUrl = `${baseUrl}/goodcollect/cancel?appTab=2&appkey=${appkey}&userId=${userId}&goodinfoId=${goodInfoId}`;
      let method = "POST";
      let cancelCollection= {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(cancelCollection);
      this.loadCollection();
    }
  }
  //添加购物车
  async addCart(goodsInfo){
    let stock = 0;
    if (goodsInfo.virtualFlag == 1){
      stock = goodsInfo.virtualStock;
    }else{
      stock = goodsInfo.warestock1;
    }
    let goodsId = goodsInfo.id;
    if (stock <= 0) {
      message.error(<span className="msgText">对不起，该商品已抢光</span>);
    }else{
      if (this.props.userLoginInfo) {
        let url = `${baseUrl}/v1.0/b2c/user/shopping?appkey=${appkey}&goodsId=${goodsId}&quantity=1&promotionId=-1&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let request = {
          url,
          method: 'PUT'
        }
        let data = await getDataFromServer(request);
        if (data.data.result === 0) {
          message.success(<span className="msgText">添加成功</span>);
        }else if (data.data.result === 3) {
          message.error(<span className="msgText">库存不足</span>);
        }else if (data.data.result === 4) {
          message.error(<span className="msgText">超过限量{this.props.systemData ? this.props.systemData.overSeaLimitNum : ""}件</span>);
        }else if (data.data.result === 5) {
          message.error(<span className="msgText">对不起，该商品已抢光</span>);
        }else {
          message.error(<span className="msgText">{data.data.msg}</span>);
        }
      }else{
        this.props.toggleLogin();
      }
    }
  }
  selectPage = (index) =>{
    this.setState({
      selePageIndex:index,
    });
  }
  showMask = (index) =>{
    this.setState({
      itemIndex:index,
      showMask:true,
    });
  }
  removeCollection = (isRemove,index) =>{
    if(isRemove){
      this.cancelCollection(index);
    }
    this.setState({
      showMask:false,
    });
  }
  getPage = (page) =>{
    this.setState({
      pageNo:page
    },this.loadCollection);
  }
  render(){
    let {goodsCollectList,totalPage,pageNo,itemIndex,showMask} = this.state;

    return(
      <div className="my_collction_bg">
        <div className="my_collction_ttile">我的收藏</div>
        {/*收藏列表*/}
        {(goodsCollectList && goodsCollectList.length > 0) && <div className="my_collection_list_bg clearfix">
          {
            goodsCollectList.map((goods,index) =>{
              let originPrice = 0.0;
              let originalPriceText = ""; 
              if (goods.goodInfo.limitBuyFlag == 1){ //进行中的限时购
                originPrice = goods.goodInfo.originalPrice;
                originalPriceText = "原价 ￥" + originPrice; 
              }else if(goods.goodInfo.limitBuyFlag == 2){//未进行的限时购
                originPrice = goods.goodInfo.originalPrice;
                originalPriceText = "抢购价 ￥" + originPrice; 
              }else if (goods.goodInfo.price < goods.goodInfo.b2cOriginalPrice){
                originPrice = goods.goodInfo.b2cOriginalPrice;
                let textSwitch = this.props.systemData ? this.props.systemData.copywritingEnable : 1;
                let text = this.props.systemData ? this.props.systemData.copywritingText : "";
                if (textSwitch == 0) {
                  originalPriceText = text + " ￥" + originPrice;
                }else{
                  originalPriceText = " ￥" + originPrice;
                }
              }
              let discount = 10.0;
              if(originPrice > goods.goodInfo.price){
                discount = goods.goodInfo.price / originPrice * 10;
                discount = discount.toFixed(1);
              }
              let hasStock = true;
              if ((goods.goodInfo.virtualFlag == 1 && goods.goodInfo.virtualStock <= 0) || (goods.goodInfo.virtualFlag != 1 && goods.goodInfo.warestock1 <= 0)){
                hasStock = false;
              }
              return(
                <div className="collection_item_bg" key={index}>
                  <div className="content_bg">
                    <div className="goods_img_bg">
                      <Link to={`/home/goodsdetail/${goods.goodInfo.id}`} target="_blank">
                        <img className="goods_img" src={goods.goodInfo.imgpath}/>
                        {!hasStock && <img className="goods_img" src={saleOut} />}
                      </Link>
                      <div className="add_shop_cart_bg">
                        <div className="add_shop_cart" onClick={this.addCart.bind(this,goods.goodInfo)}>加入购物车</div>
                        <div className="delete" onClick={this.showMask.bind(this,index)}>删除</div>
                      </div>
                    </div>
                    <div className="country_bg">
                      <img className="country_img" src={goods.goodInfo.country.logo}/>
                      <div className="coutry_name">{goods.goodInfo.country.cnName}</div>
                    </div>
                    <div className="name">{goods.goodInfo.title}</div>
                    <div className="tag_bg">
                      {(goods.goodInfo.promotionTags && goods.goodInfo.promotionTags.length > 0) &&
                      <span className="activity_tag">{goods.goodInfo.promotionTags[0].tagName}</span>
                      }
                    </div>
                    <div className="price_bg">
                      <div className="price">{"￥" + goods.goodInfo.price}</div>
                      {discount != 10.0 && <div className="dicount">{`${discount}折`}</div>}
                    </div>
                      {originPrice != 0.0 && <div className={goods.goodInfo.limitBuyFlag == 1 ? "special_price_gray" : "special_price"}>{originalPriceText}</div>}
                  </div>
                  {/*移除收藏提示*/}
                  {
                    (itemIndex == index && showMask) && 
                      <div className="conten_mask">
                        <div className="mask_tip_bg">
                          <img className="tip_img" src={warning}/>
                          <div className="tip_info">是否移除收藏</div>
                        </div>
                        <div className="btns_bg">
                          <div className="sure_btn" onClick={this.removeCollection.bind(this,true,index)}>确认</div>
                          <div className="cancel_btn" onClick={this.removeCollection.bind(this,false,index)}>取消</div>
                        </div>
                      </div>
                  }
                </div>
              )
            })
          }
        </div>} 
        {/*页码*/}
        { (goodsCollectList != null && goodsCollectList.length > 0) && <div className="page_bg">
          <Pager total={totalPage} current={pageNo} getPage={this.getPage}/>
        </div>}
      </div>
    );
  }
}

export default MyCollection;