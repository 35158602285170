import React,{Component} from "react";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./RealNamePopView.less";
import uploadImg from "../../images/Image_upload_big.png";
import { Upload, Icon, message } from 'antd';

@connect(
  state => state.loginReducer
)
class RealNamePopView extends Component{
  constructor(props){
    super(props);
    this.state={
      realname:props.realInfo.realname,
      idcard:props.realInfo.idcard,
      img1:props.realInfo.img1,
      img2:props.realInfo.img2,
      id:props.realInfo.id,
      qiniuToken:"",
    }
  }
  componentDidMount(){
    this.qiniuToken();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  realNameOnchange = (e) =>{
    this.setState({
      realname:e.target.value,
    });
  }
  idNumOnchange = (e) =>{
    this.setState({
      idcard:e.target.value,
    });
  }
  //图片上传token生成
 async qiniuToken(){
  let loginInfo = {
    url:baseUrl+"/qiniu/uploadtoken?appkey="+appkey,
    method: 'GET',
    data: {}
  }
  let data = await getDataFromServer(loginInfo);
  if(data.data.result === 0){
    this.setState({
      qiniuToken:data.data.uploadToken,
    })
  }
}
  getBase64 = (img, callback) =>{
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
   beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  
  handleimgChange1 = info => {   
    if (info.file.status === 'uploading') {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let hash = info.file.response.hash;
      let imageUrl = "http://img.maigang360.com/" + hash;
      this.setState({
          img1:imageUrl,
        });
    }
  };
  handleimgChange2 = info => {   
    if (info.file.status === 'uploading') {
      // this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      let hash = info.file.response.hash;
      let imageUrl = "http://img.maigang360.com/" + hash;
      this.setState({
          img2:imageUrl,
        });
    }
  };
  async editRealName(){
    let userInfo = this.props.userLoginInfo;
    if (userInfo) {
      let userId = userInfo.id;
      let {id,img1,img2} = this.state;
      let reqUrl =  `${baseUrl}/v1.5/user/auth?appTab=2&appkey=${appkey}&userId=${userId}&img1=${img1}&img2=${img2}&id=${id}`;
      let method = "POST";
      let editRealName = {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(editRealName);
      if (response.data.result == 0) {
        message.success(<span className="msgText">编辑成功</span>);
      }else{
        message.error(<span className="msgText">编辑失败</span>);
      } 
      this.props.hiddeAddpopView(true);
    }
  }
  cancelACtion = () =>{
    this.props.hiddeAddpopView(false);
  }
  sureAction = () =>{
    let {realname,idcard} = this.state;
    if(realname == null || realname == ""){
      console.log("请输入正确的名字")
    }else if (idcard == null || idcard ==""){
      console.log("请输入正确的身份证号")
    }else{
      console.log("上传身份证信息");
      this.editRealName();
    }
  }
  render(){

    let {realname,idcard,img1,img2} = this.state;
    return(
      <div className="pop_view_bg">
        <div className="white_back">
          <div className="real_name_title">实名认证</div>
          <div className="top_line"></div>
          <div className="info_bg">
            <div className="left_title">真实姓名：</div>
            <div className="left_star">*</div>
            <div className="user_name">{realname}</div>
            {/* <input className="user_name" placeholder="请输入您的真实姓名" defaultValue={realname ? realname : ""} onChange={this.realNameOnchange}/> */}
          </div>
          <div className="info_bg">
            <div className="left_title">身份证号：</div>
            <div className="left_star">*</div>
            <div className="user_name">{idcard}</div>
            {/* <input className="user_name" placeholder="请输入姓名对应的身份证" defaultValue={idcard ? idcard : ""} onChange={this.idNumOnchange}/> */}
          </div>
          <div className="photo_bg">
            <div className="left_title">身份证照片：</div>
            <div className="updload_bg">
              <div className="item_bg">
              <div className="upload_img_bg">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleimgChange1}
                  >
                    <img src={(img1 && img1 != "") ? img1 : uploadImg } className="upload_img"/>
                  </Upload>
                </div>
                {/* <img className="upload_img" src={(img1 && img1 != "") ? img1 : uploadImg}/> */}
                <div className="upload_title">带人像面</div>
              </div>
              <div className="item_bg">
                <div className="upload_img_bg">
                  <Upload
                    name="file"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={"http://up-z2.qiniu.com?token=" + this.state.qiniuToken}
                    beforeUpload={this.beforeUpload}
                    onChange={this.handleimgChange2}
                  >
                    <img src={(img2 && img2 != "") ? img2 : uploadImg } className="upload_img"/>
                  </Upload>
                </div>
                {/* <img className="upload_img" src={(img2 && img2 != "") ? img2 :uploadImg}/> */}
                <div className="upload_title">国旗像面</div>
              </div>
            </div>
            <div className="bottom_tip_bg">
              <div className="tip_info">注：</div>
              <div className="tip_info">1. 需上传清晰的身份证正面、反面共2张照片</div>
              <div className="tip_info">2. 照片不超过5MB，支持格式jpg，jpeg，png</div>
            </div>
          </div>
          <div className="bottom_btn_bg">
            <div className="red_btn" onClick={this.sureAction}>确认</div>
            <div className="balck_btn" onClick={this.cancelACtion}>取消</div>
          </div>
        </div>
      </div>
    );
  }
}

export default RealNamePopView;