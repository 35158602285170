import React, {Component} from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Statistic } from 'antd';
import  "./ProductItemRecormend.less";
import tag from "../../images/ic_baoshui.png";
import qiangguang from "../../images/tag_yiqiangguang_goods.png";

@connect(state => state.homeReducer)

class ProductItemRecormend extends Component{

  render (){
    const {goodsInfo,systemData} = this.props;
    let stock = goodsInfo.virtualFlag === 1 ? goodsInfo.virtualStock : goodsInfo.warestock1;
    let originalPriceText = "";  //原价文本
    let originalPrice = 0;  //原价
    if (systemData) {
      if (systemData.copywritingEnable !== 1) {
        originalPriceText = systemData.copywritingText;
      }
    }
    if (goodsInfo.limitBuyFlag === 1) {
      originalPrice = goodsInfo.originalPrice;
      if(goodsInfo.limitStatus === 2){
        originalPriceText = "抢购价";
      }else if(goodsInfo.limitStatus === 1){
        originalPriceText = "原价";
      }
    }else{
      originalPrice = goodsInfo.b2cOriginalPrice;
    }
    return (
      <Link to={`/home/goodsdetail/${this.props.goodsInfo.goodsId ? this.props.goodsInfo.goodsId : this.props.goodsInfo.id}`} target="_blank">
        <div className="ProductItemRecormend">
          {/*包税标签*/}
          {
            goodsInfo.dutyFreeFlag === 1 &&
            <img src={tag} alt="" className="tag"/>
          }
          <div className="Img">
            <div className="label"><img src={goodsInfo.imgpath} className="proImg" alt=""/></div>
            {
              goodsInfo.goodsLabels && goodsInfo.goodsLabels.map((item,index) =>{
                let zindexStyle = {zIndex:"-" + index};
                return <div key={index} className="label" style={zindexStyle}><img src={item.labelImg} className="proImg" alt=""/><span className="labelPrice" style={item.labelType === 1?{display:"none"}:{}}><Statistic prefix="￥" value={item.priceB2COverseaVATTheir}  precision={1} groupSeparator="" /></span></div>
              })
            }
            {/*抢光*/}
            {
              stock <= 0 &&
              <img src={qiangguang} alt="" className="stockNone"/>
            }
          </div>
          <div className="name">{goodsInfo.title}</div>
          <div className="price_bg">
            <div className="price">￥{goodsInfo.priceB2COverseaVAT ? goodsInfo.priceB2COverseaVAT : goodsInfo.priceOverseaVAT}</div>
            {
              originalPrice > 0 &&
              <div className="dicount">{ goodsInfo.priceB2COverseaVAT ? (goodsInfo.priceB2COverseaVAT * 10 / originalPrice).toFixed(1) : (goodsInfo.priceOverseaVAT * 10 / originalPrice).toFixed(1) }折</div>
            }
          </div>
          {
            originalPrice > 0 ?
            <div className="special_price">{originalPriceText} ￥{originalPrice}</div> : <div className="special_price"></div>
          }
          <div className="comment">{goodsInfo.comments}人 已评价</div>
        </div>
      </Link>
    )
  }
}

export default ProductItemRecormend;