import React, {Component} from "react";
import "./Buycart.less";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { Tooltip,message } from 'antd';
import iconInfo from "../../images/ic_Info_blue.png";
import moreDown from "../../images/ic_more_down.png";
import moreUp from "../../images/ic_more_up.png";
import close from "../../images/ic_delete.png";
import tool_ic_car_big from "../../images/tool_ic_car_big.png";
import qiangguang from "../../images/tag_yiqiangguang_goods.png";
import { getDataFromServer } from '../../httpRequest/Api.js';
import Uitil from '../../utils/Uitil.js';
import CouponItemSmall from "../../components/CouponItemSmall/CouponItemSmall";
import homeAction from '../../actions/homeAction';
import loginAction from "../../actions/loginAction.js";
const {toggleLogin} = loginAction;
const { setCartNum } = homeAction;
let isFirst = true;
let clientHeight = 0;
let jiesuanBoxH = 0;

@connect(
  state => state.loginReducer,
  {setCartNum,toggleLogin}
)

class Buycart extends Component {
  constructor(props){
    super(props)
    this.state={
			shoppingList: [],
			allCouponList: [],
			giftList: [],
			checked: false
    }
    this.addCollect = this.addCollect.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.allCoupon = this.allCoupon.bind(this);
    this.goPay = this.goPay.bind(this);
    this.sureChangePromotion = this.sureChangePromotion.bind(this);
  }

  componentDidMount(){
  	window.addEventListener('scroll',this.onScroll);
  	message.config({
      top: 300,
      duration: 1,
      maxCount: 1,
    });
    if(!this.props.userLoginInfo){
			this.props.history.push("/login/user/loginCode");
		}else{
	    this.getCartList();
		}
  }

  // 组件卸载时
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  //滚动事件处理
  onScroll(){
		let jiesuanFixed = this.refs.jiesuanFixed;
  	if (isFirst) {
			isFirst = false;
			clientHeight = document.body.clientHeight;
			if (document.getElementById("jiesuanBox")) {
				jiesuanBoxH = document.getElementById("jiesuanBox").offsetTop + 185;
			}
		}
    let scrollTop = document.documentElement.scrollTop || window.pageYOffset;
    if (jiesuanFixed) {
    	if ((scrollTop + clientHeight) >= jiesuanBoxH) {
	    	jiesuanFixed.style.display = "none";
	    }else{
				jiesuanFixed.style.display = "block";
	    }
    }
  }

  //获取购物车
  async getCartList(){
  	let loginInfo = {
      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
    	this.props.setCartNum(data.data.data.total);
			this.setState({
				shoppingList: data.data.data.shoppingList,
				checked: false
			})
    }
  }

  //过滤已选择的购物车ID
  filterSelId = () => {
  	let {shoppingList} = this.state;
  	let ids = "";
  	let goodsId = "";
  	for (let i = 0; i < shoppingList.length; i++) {
			for (var j = 0; j < shoppingList[i].shoppingLists.length; j++) {
				for (var k = 0; k < shoppingList[i].shoppingLists[j].shoppingCartModel.length; k++) {
					if (shoppingList[i].shoppingLists[j].shoppingCartModel[k].checked) {
						ids += shoppingList[i].shoppingLists[j].shoppingCartModel[k].id + ",";
						goodsId += shoppingList[i].shoppingLists[j].shoppingCartModel[k].goodsId + ",";
					}
				}
			}
		}
		if (ids.length > 0) {
			ids = ids.substring(0,ids.length - 1);
			goodsId = goodsId.substring(0,goodsId.length - 1);
		}
		return {ids,goodsId};
  }

  //添加收藏夹
  async addCollect (item){
  	if (this.props.userLoginInfo) {
			let loginInfo = null;
	  	let goodsId = this.filterSelId().goodsId;
	  	if (item) {
	  		loginInfo = {
		      url: `${baseUrl}/goodcollect/addlist?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&goodinfoIds=${item.goodsId}&appkey=${appkey}`,
		      method: 'PUT'
		    }
	  	}else{
	  		if (goodsId.length) {
					loginInfo = {
			      url: `${baseUrl}/goodcollect/addlist?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&goodinfoIds=${goodsId}&appkey=${appkey}`,
			      method: 'PUT'
			    }
	  		}else{
	  			message.error(<span className="msgText">请勾选商品</span>);
	  			return;
	  		}
	  	}
	    let data = await getDataFromServer(loginInfo);
	    if (data.data.result === 0) {
				message.success(<span className="msgText">收藏成功</span>);
	    }
  	}else{
			this.props.toggleLogin();
  	}
  }
	
	//金额排序比较器
  compare = (obj1, obj2) => {
    var val1 = obj1.price;
    var val2 = obj2.price;
    if (val1 > val2) {
        return -1;
    } else if (val1 < val2) {
        return 1;
    } else {
        return 0;
    }            
	} 

  //活动金额计算
  calMoney = (promotion) => {
		let {shoppingCartModel,promotionCart} = promotion;
		let {saleAlias,saleNumber} = promotionCart;
		let discount = promotionCart.discount1;
		let hasSaveMoney = "";
		let save = 0;
		let count = 0;
		let price = 0;  
		let minPrice = 0;   //该项活动中的最小的商品金额
		let isFull = false;  //是否满足该活动条件
		let afterDiscountMoney = 0;  //折扣优惠金额
		let hasSel = false; // 是否选择赠品
		let hasSelNum = 0; // 选择赠品数量
		shoppingCartModel = shoppingCartModel.filter(item => item.checked);
		shoppingCartModel.sort(this.compare);
		for (let i = 0; i < shoppingCartModel.length; i++) {
			if (shoppingCartModel[i].checked) {
				count += shoppingCartModel[i].quantity;
				price += shoppingCartModel[i].price * shoppingCartModel[i].quantity;
				if (saleAlias === "MSLDZ" || saleAlias === "MJEDZ") {
					afterDiscountMoney += Math.ceil(shoppingCartModel[i].price * (10 - discount) * shoppingCartModel[i].quantity) / 10;
				}
			}
		}

		if (shoppingCartModel.length > 0) {
			minPrice = shoppingCartModel[shoppingCartModel.length - 1].price;
		}

		switch (saleAlias) {
			// 满数量打折
			case "MSLDZ":
				if (count >= saleNumber) {
					hasSaveMoney = "已减" + afterDiscountMoney.toFixed(2) + "元";
					isFull = true;
					save = afterDiscountMoney;
				} else {
					hasSaveMoney = "还差" + (saleNumber - count) + "件";
					isFull = false;
				}
				break;
			// 满金额打折
			case "MJEDZ":
				if (price >= saleNumber) {
					hasSaveMoney = "已减" + afterDiscountMoney.toFixed(2) + "元";
					save = afterDiscountMoney;
					isFull = true;
				} else {
					hasSaveMoney = "还差" + (saleNumber - price).toFixed(2) + "元";
					isFull = false;
				}
				break;
			// 金额满减免
			case "JEMJM":
				if (price >= saleNumber) {
					hasSaveMoney = "已减" + discount + "元";
					save = discount;
					isFull = true;
				} else {
					hasSaveMoney = "还差" + (saleNumber - price).toFixed(2) + "元";
					isFull = false;
				}
				break;
			// 买N送N
			case "MNSN":
				if (count >= saleNumber) {
					hasSaveMoney = "已减" + (discount * minPrice).toFixed(2) + "元";
					save = discount * minPrice;
					isFull = true;
				} else {
					hasSaveMoney = "还差" + (saleNumber - count) + "件";
					isFull = false;
				}
				break;
			// N减N元
			case "NJNZK":
				if (count >= saleNumber) {
					hasSaveMoney = "已减" + discount + "元";
					save = discount;
					isFull = true;
				} else {
					hasSaveMoney = "还差" + (saleNumber - count) + "件";
					isFull = false;
				}
				break;
			// N件N元
			case "NJNY":
				if (count >= saleNumber) {
					let discountTime = 0;
					discountTime = Math.floor(count / saleNumber);
					let moneyOld = 0;
					let proNumOld = 0;
					let proNumNew = 0;
					for (let i = 0; i < shoppingCartModel.length; i++) {
						proNumNew += shoppingCartModel[i].quantity;
						if (proNumNew >= (discountTime * saleNumber)) {
							moneyOld += (discountTime * saleNumber - proNumOld)
									* shoppingCartModel[i].price;
							break;
						} else {
							moneyOld += shoppingCartModel[i].quantity
									* shoppingCartModel[i].price;
							proNumOld += shoppingCartModel[i].quantity;
						}
					}
					hasSaveMoney = "已减" + (moneyOld - discountTime	* discount).toFixed(2) + "元";
					save = moneyOld - discountTime	* discount;
					isFull = true;
				} else {
					hasSaveMoney = "还差" + (saleNumber - count) + "件";
					isFull = false;
				}
				break;
			case "SLMS":
				// 数量满送
				for (let i = 0; i < shoppingCartModel.length; i++) {
					if (shoppingCartModel[i].originalFlag == 1) {
						hasSel = true;
						hasSelNum += shoppingCartModel[i].quantity;
						save += shoppingCartModel[i].quantity * shoppingCartModel[i].price;
					}
				}
				if (count >= saleNumber) {
					isFull = true;
					if (hasSel) {
						if (hasSelNum > discount) {
							hasSaveMoney = "赠品数量超出";
						} else {
							hasSaveMoney = "已选赠品";
						}
					} else {
						hasSaveMoney = "可选赠品";
					}
				} else {
					hasSaveMoney = "还差" + (saleNumber - count) + "件";
					isFull = false;
					save = 0;
				}
				break;
			case "JEMS":
				//金额满送
				for (let i = 0; i < shoppingCartModel.length; i++) {
					if (shoppingCartModel[i].originalFlag == 1) {
						hasSel = true;
						hasSelNum += shoppingCartModel[i].quantity;
						save += shoppingCartModel[i].quantity * shoppingCartModel[i].price;
					}
				}
				if (price >= saleNumber) {
					isFull = true;
					if (hasSel) {
						if (hasSelNum > discount) {
							hasSaveMoney = "赠品数量超出";
						} else {
							hasSaveMoney = "已选赠品";
						}
					} else {
						hasSaveMoney = "可选赠品";
					}
				} else {
					hasSaveMoney = "还差" + (saleNumber - price).toFixed(2) + "元";
					isFull = false;
					save = 0;
				}
				break;
		}
		return {hasSaveMoney,isFull,hasSel,save,price,count}
  }

  //非活动金额计算
  calMoneyNormal = (item) => {
		let {shoppingCartModel} = item;
		let count = 0;
		let price = 0;  
		shoppingCartModel = shoppingCartModel.filter(item => item.checked);
		for (let i = 0; i < shoppingCartModel.length; i++) {
			if (shoppingCartModel[i].checked) {
				count += shoppingCartModel[i].quantity;
				price += shoppingCartModel[i].price * shoppingCartModel[i].quantity;
			}
		}
		return {count,price};
  }

  //总金额计算
  calMoneyAll = () => {
		let {shoppingList} = this.state;
		let allMoney = 0;
		let allCount = 0;
		let allSave = 0;
		let all = 0;
		for (let i = 0; i < shoppingList.length; i++) {
			for (var j = 0; j < shoppingList[i].shoppingLists.length; j++) {
				if (shoppingList[i].shoppingLists[j].promotionCart) {
					//活动计算
					allMoney += this.calMoney(shoppingList[i].shoppingLists[j]).price;
					allCount += this.calMoney(shoppingList[i].shoppingLists[j]).count;
					allSave += this.calMoney(shoppingList[i].shoppingLists[j]).save;
				}else{
					//非活动计算
					allMoney += this.calMoneyNormal(shoppingList[i].shoppingLists[j]).price;
					allCount += this.calMoneyNormal(shoppingList[i].shoppingLists[j]).count;
				}
			}
		}
		all = allMoney - allSave;
		return {allMoney,allCount,allSave,all};
  }

  //领券开关
  async allCoupon(item){
  	if (this.props.userLoginInfo) {
	  	let {shoppingList} = this.state;
	  	let url = `${baseUrl}/coupon/center?appkey=${appkey}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
	    let goodsInfo = {
	      url,
	      method: 'GET'
	    }
	    if (!item.couponToggle) {
	    	for (let i = 0; i < shoppingList.length; i++) {
					for (var j = 0; j < shoppingList[i].shoppingLists.length; j++) {
						shoppingList[i].shoppingLists[j].couponToggle = false;
						shoppingList[i].shoppingLists[j].giftToggle = false;
					}
				}
	    }
	    let data = await getDataFromServer(goodsInfo);
	    if (data.data.result === 0) {
				item.couponToggle = !item.couponToggle;
				this.setState({
					allCouponList: data.data.data,
					shoppingList
				})
			}
  	}else{
  		this.props.toggleLogin();
  	}
	}
	
	//去凑单点击
	toCoudan = (promotionId) =>{
		this.props.history.push({pathname:'/home/search/' + "promotionId=" + promotionId});
	}
	
  //领赠品开关
  async allGift(item){
  	if (this.props.userLoginInfo) {
  		let {shoppingList} = this.state;
	  	let url = `${baseUrl}/goodinfo/appsearchpromotion?name=&typeIds=&brandIds=&pageNo=1&promotionType=1&pageSize=30&promotionId=${item.promotionCart.promotionId}&appkey=${appkey}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
	    let goodsInfo = {
	      url,
	      method: 'GET'
	    }
	    if (!item.giftToggle) {
	    	for (let i = 0; i < shoppingList.length; i++) {
					for (var j = 0; j < shoppingList[i].shoppingLists.length; j++) {
						shoppingList[i].shoppingLists[j].giftToggle = false;
						shoppingList[i].shoppingLists[j].couponToggle = false;
					}
				}
	    }
	    let data = await getDataFromServer(goodsInfo);
	    if (data.data.result === 0) {
				item.giftToggle = !item.giftToggle;
				this.setState({
					giftList: data.data.goodInfoList,
					shoppingList
				})
			}	
  	}else{
			this.props.toggleLogin();
  	}
  }

  //添加赠品
  async addGift(item) {
  	if (this.props.userLoginInfo) {
  		let {shoppingList,giftList} = this.state;
	  	let goodsId = "";
	  	for (var i = 0; i < giftList.length; i++) {
	  		if (giftList[i].checked) {
	  			goodsId = giftList[i].id;
	  			break;
	  		}
	  	}
	  	if (goodsId) {
		  	let url = `${baseUrl}/v1.0/b2c/user/shopping?quantity=1&shipWay=0&goodsId=${goodsId}&promotionId=${item.promotionCart.promotionId}&appkey=${appkey}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
		    let goodsInfo = {
		      url,
		      method: 'PUT'
		    }
		    let data = await getDataFromServer(goodsInfo);
		    if (data.data.result === 0) {
					this.getCartList();
				}
	    }else{
	    	message.error(<span className="msgText">请选择商品</span>);
	    }
  	}else{
			this.props.toggleLogin();
  	}
  }

  //赠品勾选
  giftChange = (item) => {
  	let {giftList} = this.state;
  	for (var i = 0; i < giftList.length; i++) {
  		giftList[i].checked = false;
  	}
  	item.checked = true;
  	this.setState({
  		giftList
  	})
  }

  //活动数据解析
  activityData = (promotion) => {
  	let {allCouponList,giftList} = this.state;
  	let couponEle = (
			<div className="goodsBoxTitle">
				<div className="couponSmall" onClick={this.allCoupon.bind(this,promotion)}>
					<div className="banyuan left"></div>
					优惠券 <img src={promotion.couponToggle ? moreUp : moreDown} className="close" alt=""/>
					<div className="banyuan right"></div>
				</div> 当前可领优惠券
				{
          promotion.couponToggle && 
          <div className="couponBox">
            <div className="couponBoxTitle">我的优惠券<img src={close} onClick={this.allCoupon.bind(this,promotion)} className="close" alt=""/></div>
            <div className="couponContent">
              {
                allCouponList.map((item,i) =>{
                  return (
                    <CouponItemSmall key={i} couponInfo={item} updateCoupon={this.getCartList.bind(this)}/>
                  )
                })
              }
            </div>
          </div>
        }
			</div>
  	);
  	const promotionEle = (promotion,isGift) => {
  		let activity = promotion.promotionCart;
  		let canSelGift = isGift && this.calMoney(promotion).isFull;
			return (
				<div className="goodsBoxTitle">
					<div className={this.calMoney(promotion).isFull ? "promotionTag goodsBoxTitleItem isFull" : "promotionTag goodsBoxTitleItem"}>{activity.saleTagName}</div>
					<div className="promotionDesc goodsBoxTitleItem">{activity.tagName},{this.calMoney(promotion).hasSaveMoney}</div>
					{
						isGift && 
						<Tooltip placement="topLeft" title="超出活动赠品数量后将按照商品原价计算" arrowPointAtCenter="true">
              <img src={iconInfo} alt="" className="promotionDescTip goodsBoxTitleItem"/>
            </Tooltip>
          }
					{
						canSelGift && 
						<div className="promotionGetGift goodsBoxTitleItem"><span onClick={this.allGift.bind(this,promotion)}>选取赠品</span>
							{
			          promotion.giftToggle && 
			          <div className="giftBox">
			            <div className="giftBoxTitle">选取赠品<span className="tip">(超出活动赠品数量后将按照商品原价计算)</span><img src={close} onClick={this.allGift.bind(this,promotion)} className="close" alt=""/></div>
			            <div className="giftContent">
			              {
			                giftList.map((item,i) =>{
			                  return (
			                    <div className="giftItem" key={i}>
														<input type="radio" checked={item.checked ? true : false} onChange={this.giftChange.bind(this,item)} name={"gift" + promotion.promotionCart.promotionId}/>
														<img src={item.imgpath} alt=""/>
														<div className="giftRight">
															<div className="giftTitle">{item.title}</div>
															<div className="giftPrice">￥{item.priceB2COverseaVAT}</div>
														</div>
			                    </div>
			                  )
			                })
			              }
			            </div>
			            <div className="bottomBtn">
										<div className="btn sure" onClick={this.addGift.bind(this,promotion)}>确认</div>
										<div className="btn cancle" onClick={this.allGift.bind(this,promotion)}>取消</div>
		              </div>
			          </div>
			        }
						</div>
					}
					<div className="promotionCoudan goodsBoxTitleItem" onClick={this.toCoudan.bind(this,promotion.promotionCart.promotionId)}>{this.calMoney(promotion).isFull ? "再逛逛" : "去凑单"}></div>
				</div>
			)
  	};
  	if (promotion.promotionCart) {
  		let {saleAlias} = promotion.promotionCart;
  		if (saleAlias !== "SLMS" && saleAlias !== "JEMS" && saleAlias !== "WHD") {
				return promotionEle(promotion,false);
  		}else if (saleAlias === "WHD") {
				if (promotion.couponFlag === 1) {
					return couponEle;
				}
  		}else{
				return promotionEle(promotion,true);
  		}
  	}else{
  		if (promotion.couponFlag === 1) {
				return couponEle;
			}
  	}
  }

  //全选
  selectAll = (e) => {
  	let {shoppingList} = this.state;
  	let checked = e.target.checked;
    shoppingList.forEach(item => {
      item.checked = checked;
      item.shoppingLists.forEach(item1 => {
      	item1.shoppingCartModel.forEach(item2 => {
	        item2.checked = checked;
	      });
      });
    });
    this.setState({
    	shoppingList,
			checked
    })
  }

  //子项全选
  selectItemAll = (item,e) => {
  	let {shoppingList,checked} = this.state;
  	let checked1 = e.target.checked;
  	item.checked = checked1;
    item.shoppingLists.forEach(item1 => {
      item1.shoppingCartModel.forEach(item2 => {
        item2.checked = checked1;
      });
    });
    if (!item.checked) {
      checked = false;
    } else {
      checked = shoppingList.reduce((prev, item) => prev && item.checked, true);
    }
    this.setState({
    	shoppingList,
			checked
    })
  }

  //单项选择
  selectItem = (item, parent, e) => {
  	let {shoppingList,checked} = this.state;
  	let checked2 = e.target.checked;
  	item.checked = checked2;
    if (!checked2) {
      parent.checked = checked = false;
    } else {
      parent.checked = parent.shoppingLists.every(item => item.shoppingCartModel.every(item1 => item1.checked));
      if (parent.checked) {
        checked = shoppingList.reduce((prev, item) => prev && item.checked, true);
      } else {
        checked = false;
      }
    }
    this.setState({
    	shoppingList,
			checked
    })
  }

  //购物车数量提示
  cartNumTip = (item,list,text) =>{
  	for (let i = 0; i < list.length; i++) {
  		for (let j = 0; j < list[i].shoppingLists.length; j++) {
  			for (var k = 0; k <  list[i].shoppingLists[j].shoppingCartModel.length; k++) {
  				if (list[i].shoppingLists[j].shoppingCartModel[k].id === item.id) {
  					item.quantity = list[i].shoppingLists[j].shoppingCartModel[k].quantity;
  					item.stockText = text;
						item.checked = true;
						this.setState({
				    	shoppingList: this.state.shoppingList
				    })
				    break;
  				}
  			}
  		}
  	}
  }

  //输入数量
  async changeNum (item,changeType,e) {
  	if (this.props.userLoginInfo) {
	    let value = Uitil.intRE(e.target.value);
	    if (changeType === "change") {
		    item.quantity = value;
	    	this.setState({
		    	shoppingList: this.state.shoppingList
		    })
	    }else{
				let {id,promotionId} = item;
		    if (value) {
		    	let loginInfo = {
			      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&quantity=${value}&id=${id}&promotionId=${promotionId}&appkey=${appkey}`,
			      method: 'POST'
			    }
			    let data = await getDataFromServer(loginInfo);
			    this.props.setCartNum(data.data.data.total);
			    if (data.data.result === 0) {
			    	let list = data.data.data.shoppingList;
			    	this.cartNumTip(item,list,"");
			    }else if (data.data.result === 3){
						let list = data.data.data.shoppingList;
			    	this.cartNumTip(item,list,"库存不足");
			    }else if (data.data.result === 4 || data.data.result === 10){
						let list = data.data.data.shoppingList;
			    	this.cartNumTip(item,list,"该商品限购" + item.salesFeatueres.limitQty + "件");
			    }
		    }
	    }
	  }else{
	  	this.props.toggleLogin();
	  }
  }

  //加减数量
  async numEdit (item,type) {
  	if (this.props.userLoginInfo) {
	    let goodsNum = item.quantity;
	    if (type === "add") {
	      goodsNum++;
	    }else if (type === "dec"){
	      goodsNum--;
	    }
	    // if (parseInt(goodsNum) < 1 || parseInt(goodsNum) > (item.salesFeatueres.limitQty ? item.salesFeatueres.limitQty : 9999)) {
	    //   return;
	    // }
	    if (parseInt(goodsNum) < 1) {
	      return;
	    }
			let {id,promotionId} = item;
	    let loginInfo = {
	      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&quantity=${goodsNum}&id=${id}&promotionId=${promotionId}&appkey=${appkey}`,
	      method: 'POST'
	    }
	    let data = await getDataFromServer(loginInfo);
	    this.props.setCartNum(data.data.data.total);
	    if (data.data.result === 0) {
	    	let list = data.data.data.shoppingList;
	    	this.cartNumTip(item,list,"");
	    }else if (data.data.result === 3){
				let list = data.data.data.shoppingList;
	    	this.cartNumTip(item,list,"库存不足");
	    }else if (data.data.result === 4 || data.data.result === 10){
				let list = data.data.data.shoppingList;
	    	this.cartNumTip(item,list,"该商品限购" + item.salesFeatueres.limitQty + "件");
	    }
	  }else{
	  	this.props.toggleLogin();
	  }
  }

  //确定更换购物项活动
  async sureChangePromotion(item){
  	if (this.props.userLoginInfo) {
			let {id,promotionId,quantity} = item;
	  	let loginInfo = {
	      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&quantity=${quantity}&id=${id}&promotionId=${promotionId}&appkey=${appkey}`,
	      method: 'POST'
	    }
	    let data = await getDataFromServer(loginInfo);
	    if (data.data.result === 0) {
	    	this.getCartList();
	    }else if (data.data.result === 3){
				this.getCartList();
	    }else if (data.data.result === 4 || data.data.result === 10){
				this.getCartList();
	    }
	  }else{
	  	this.props.toggleLogin();
	  }
  }

  //删除购物车
  async deleteCart(item){
  	if (this.props.userLoginInfo) {
			let loginInfo = null;
	  	let ids = this.filterSelId().ids;
	  	if (item) {
	  		loginInfo = {
		      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&ids=${item.id}&appkey=${appkey}`,
		      method: 'DELETE'
		    }
	  	}else{
	  		if (ids.length) {
					loginInfo = {
			      url: `${baseUrl}/v1.2/b2c/user/shopping?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&ids=${ids}&appkey=${appkey}`,
			      method: 'DELETE'
			    }
	  		}else{
	  			message.error(<span className="msgText">请勾选商品</span>);
	  			return;
	  		}
	  	}
	    let data = await getDataFromServer(loginInfo);
	    if (data.data.result === 0) {
				message.success(<span className="msgText">删除成功</span>);
		    this.getCartList();
	    }
	  }else{
	  	this.props.toggleLogin();
	  }
  } 

  //初始化活动切换开关状态
  initPromotionOpen = (item,oldId) => {
  	if (!item.changePromotionToggle) {
  		let {shoppingList} = this.state;
	  	for (let i = 0; i < shoppingList.length; i++) {
				for (var j = 0; j < shoppingList[i].shoppingLists.length; j++) {
					for (var k = 0; k < shoppingList[i].shoppingLists[j].shoppingCartModel.length; k++) {
						shoppingList[i].shoppingLists[j].shoppingCartModel[k].changePromotionToggle = false;
					}
				}
			}
			this.setState({
	  		shoppingList
	  	},this.openPromotion(item,oldId))
  	}else{
			this.openPromotion(item,oldId);
  	}
  }

  //开启活动切换
  openPromotion = (item,oldId) => {
  	let {shoppingList} = this.state;
  	item.changePromotionToggle = !item.changePromotionToggle;
  	item.promotionId = oldId;
  	this.setState({
  		shoppingList
  	})
  }

  //切换活动
  changeItemPromotion = (item,e) => {
  	let {shoppingList} = this.state;
  	item.promotionId = e.target.value;
  	this.setState({
  		shoppingList
  	})
  }

  //去结算
  async goPay(){
  	if(this.props.userLoginInfo){
  		if (this.filterSelId().ids) {
				let loginInfo = {
		      url: `${baseUrl}/user/shopping/check?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&ids=${this.filterSelId().ids}&appkey=${appkey}`,
		      method: 'GET'
		    }
		    let data = await getDataFromServer(loginInfo);
		    if (data.data.result === 0) {
		    	this.props.history.push({ pathname: "/home/submitorder", state: this.filterSelId().ids });
		    }else if (data.data.result === 1) {
		    	message.error(<span className="msgText">对不起，您挑选的商品中有已抢光或库存不足的商品</span>);
		    }else if (data.data.result === 2) {
		    	message.error(<span className="msgText">超重啦~选购商品已超过限定重量</span>);
		    }else if (data.data.result === 4) {
		    	message.error(<span className="msgText">超过直邮的限量</span>);
		    }else if (data.data.result === 9) {
		    	message.error(<span className="msgText">选择的商品中有未到抢购时间的商品</span>);
		    }else if (data.data.result === 10) {
		    	message.error(<span className="msgText">选择的商品中有超过限购数量上限的商品</span>);
		    }else if (data.data.result === 6) {
		    	message.error(<span className="msgText">您选择的商品中有已售完的商品</span>);
		    }
		  }else{
		  	message.error(<span className="msgText">请勾选去结算的商品</span>);
		  }
	  }else{
	  	this.props.toggleLogin();
	  }
  }

  //去逛逛
  toHome = () => {
  	this.props.history.push("/home");
  }

  render() {
  	let {shoppingList,checked} = this.state;
    return (
      <div className="Buycart">
				<div className="inner">
					{shoppingList.length > 0 &&
						<div className="hasCartGoods">
							<div className="cartTitle">我的购物车</div>
							<div className="cartTableHeader">
								<div className="headerSel headerItem">
									<input type="checkbox" checked={checked || false} onChange={this.selectAll}/>全选
								</div>
								<div className="headerItem goodsName">商品</div>
								<div className="headerItem goodsPrice">单价</div>
								<div className="headerItem goodsNum">数量</div>
								<div className="headerItem goodsAccount">小计</div>
								<div className="headerItem goodsEdit">操作</div>
							</div>
							{
								shoppingList.map((item,i) => {
									return (
										<div className="cartItem" key={i}>
											<div className="itemAllSel"><input type="checkbox" checked={item.checked || false} onChange={this.selectItemAll.bind(this,item)}/>{item.warehouseName}</div>
											{
												item.shoppingLists.map((childItem,j) => {
													return (
														<div className="goodsBox" key={j}>
															{this.activityData(childItem)}
															{
																childItem.shoppingCartModel.map((grandchild,k) => {
																	let text = grandchild.salesFeatueres.limitQty !== 0 ? "该商品限购" + grandchild.salesFeatueres.limitQty + "件" : "";
																	text = grandchild.stockText ? grandchild.stockText : text;
																	text = text === "该商品限购0件" ? "该商品限购" + grandchild.quantity + "件" : text;
																	let hasPromotion = grandchild.promotionTags.length > 0;
																	let promotionId = grandchild.promotionId;
																	let stock = grandchild.warestock1;
																	let oldId = -1;
																	if (childItem.promotionCart) {
																		oldId = childItem.promotionCart.promotionId
																	}
																	if (grandchild.virtualFlag === 1) {
														        stock = grandchild.virtualStock;
														      }
																	return (
																		<div className={grandchild.checked ? "goodsItem selected" : "goodsItem"} key={k}>
																			<input type="checkbox" className="item" checked={grandchild.checked || false} onChange={this.selectItem.bind(this,grandchild,item)}/>
																			<Link to={`/home/goodsdetail/${grandchild.goodsId}`} target="_blank">
																				<div className="item goodsImg">
																					<img className="img" src={grandchild.imgpath} alt=""/>
																					{stock <= 0 && <img src={qiangguang} className="qiangguang" alt=""/>}
																				</div>
																				<div className="goodsTitle item goodsName">{grandchild.title}</div>
																			</Link>
																			<div className="priceBox item goodsPrice">
																				<div className="price">￥{grandchild.price}</div>
																				{hasPromotion && <div onClick={this.initPromotionOpen.bind(this,grandchild,oldId)} className={grandchild.changePromotionToggle ? "goodsPromotion open" : "goodsPromotion"}>促销<img src={grandchild.changePromotionToggle ? moreUp : moreDown} className="close" alt=""/></div>}
																				<div className={grandchild.changePromotionToggle ? "promotionChangeBox openPro" : "promotionChangeBox"}>
																					{
																						grandchild.promotionTags.map((promotionItem,x) => {
																							return (
																								<div className="promotionItem" key={x}>
																									<label><input value={promotionItem.id} type="radio" onChange={this.changeItemPromotion.bind(this,grandchild)} checked={promotionId == promotionItem.id}  name={grandchild.id + "" + k}/>{promotionItem.tagName}</label>
																								</div>
																							)
																						})
																					}
																					<div className="promotionItem">
																						<label><input value="-1" type="radio" onChange={this.changeItemPromotion.bind(this,grandchild)} checked={promotionId == -1} name={grandchild.id + "" + k}/>不使用优惠</label>
																					</div>
																					<div className="canclePromotion promotionBtn" onClick={this.initPromotionOpen.bind(this,grandchild,oldId)}>取消</div>
																					<div className="surePromotion promotionBtn" onClick={this.sureChangePromotion.bind(this,grandchild)}>确定</div>
																				</div>
																			</div>
																			<div className="numControl item goodsNum">
											                  <div>
																					<div className={parseInt(grandchild.quantity) <= 1 ? "btn disabled" : "btn"} onClick={this.numEdit.bind(this,grandchild,"dec")}>-</div>
												                  <input type="text" maxLength="4" value={grandchild.quantity} onChange={this.changeNum.bind(this,grandchild,"change")} onBlur={this.changeNum.bind(this,grandchild,"blur")} className="num"/>
												                  <div className={parseInt(grandchild.quantity) >=9999 ? "btn disabled" : "btn"} onClick={text && grandchild.salesFeatueres.limitQty === 0 ? null : this.numEdit.bind(this,grandchild,"add")}>+</div>
												                  <div className="clearfix"></div>
											                  </div>
											                  {text && <div className="numTip">{text}</div>}
											                </div>
																			<div className="accountBox item goodsAccount">
																				<div className="accountPrice">￥{(grandchild.price * grandchild.quantity).toFixed(2)}</div>
																				<div className="tagPrice">预估税费￥{(grandchild.tax * grandchild.quantity).toFixed(2)}</div>
																			</div>
																			<div className="editBox item goodsEdit">
																				<div className="delete" onClick={this.deleteCart.bind(this,grandchild)}>删除</div>
																				<div className="collect" onClick={this.addCollect.bind(this,grandchild)}>移至我的收藏夹</div>
																			</div>
																		</div>
																	)
																})
															}
														</div>
													)
												})
											}
										</div>
									)
								})
							}
							<div className="jiesuanBox" id="jiesuanBox">
								<label className="selAllJiesuan">
									<input type="checkbox" checked={checked || false} onChange={this.selectAll}/>全选
								</label>
								<div className="deleteSel" onClick={this.deleteCart.bind(this,"")}>删除选中的商品</div>
								<div className="collectSel" onClick={this.addCollect.bind(this,"")}>移入我的收藏</div>
								<div className={this.calMoneyAll().allCount > 0 ? "goPay" : "goPay disabled"} onClick={this.goPay}>去结算</div>
								<div className="moneyBox">
									<div className="moneyBoxTop">已选<font color = '#E41135'>{this.calMoneyAll().allCount}</font>件<span className="marginL">合计：</span><span className="red big">￥{this.calMoneyAll().all.toFixed(2)}</span></div>
									<div className="moneyBoxBottom">已减：<span className="red">￥{this.calMoneyAll().allSave.toFixed(2)}</span><span className="marginL">总计：<span className="red">￥{this.calMoneyAll().allMoney.toFixed(2)}</span></span></div>
								</div>
							</div>
						</div>
					}
					{
						shoppingList.length <= 0 &&
						<div className="noGoods">
							<img src={tool_ic_car_big} alt=""/>
							<div className="noTextBox">
								<div className="noText">购物车空空荡荡，{this.props.userLoginInfo ? (<span className="goSee" onClick={this.toHome}>去逛逛</span>) : "登录后将显示您之前加入的商品"}</div>
								{
									!this.props.userLoginInfo &&
									<div className="noTextBtn"><div className="goLogin">登录</div><span className="goSee" onClick={this.toHome}>去逛逛</span></div>
								}
							</div>
						</div>
					}
				</div>
				{
					shoppingList.length > 0 &&
					<div className="jiesuanFixed" ref="jiesuanFixed">
						<div className="inner">
							<div className="jiesuanBox" id="jiesuanBox">
								<label className="selAllJiesuan">
									<input type="checkbox" checked={checked || false} onChange={this.selectAll}/>全选
								</label>
								<div className="deleteSel" onClick={this.deleteCart.bind(this,"")}>删除选中的商品</div>
								<div className="collectSel" onClick={this.addCollect.bind(this,"")}>移入我的收藏</div>
								<div className={this.calMoneyAll().allCount > 0 ? "goPay" : "goPay disabled"} onClick={this.goPay}>去结算</div>
								<div className="moneyBox">
									<div className="moneyBoxTop">已选<font color = '#E41135'>{this.calMoneyAll().allCount}</font>件<span className="marginL">合计：</span><span className="red big">￥{this.calMoneyAll().all.toFixed(2)}</span></div>
									<div className="moneyBoxBottom">已减：<span className="red">￥{this.calMoneyAll().allSave.toFixed(2)}</span><span className="marginL">总计：<span className="red">￥{this.calMoneyAll().allMoney.toFixed(2)}</span></span></div>
								</div>
							</div>
						</div>
					</div>
				}
      </div>
    );
  }
}

export default Buycart;

