import React,{Component} from "react";
import {connect} from "react-redux";
import { getDataFromServer } from '../../httpRequest/Api.js';
import {Tooltip,Button} from 'antd';
import "./MyRebateValue.less";
import reabeteValueBG from "../../images/bg_wodeyue.png";
import whWhite from "../../images/ic_wenhao.png";

const rewardRecord = "奖励记录";
const payRecord = "支出记录";

@connect(
  state => state.loginReducer
)

class MyRebateValue extends Component{
  constructor(props){
    super(props);
    this.state={
      selectType:rewardRecord,
      obtainRebateList:[],//奖励
      useRebateList:[],//支出
      totalReabeteValue:0.0,
      rebateRule:"",
    }
  }

  componentDidMount(){
    this.LoadRebateValue();
    this.loadRebateValueRule();
  }

  async LoadRebateValue() {
    let loginInfo = this.props.userLoginInfo;
    if(loginInfo){
      let userId = loginInfo.id;
      let reqUrl = `${baseUrl}/rebaterecord/list?appTab=2&appkey=${appkey}&userId=${userId}`;
      let method = "GET";
      let rebateList = {
        url: reqUrl,
        method
      }
      let response = await getDataFromServer(rebateList);
      this.setState({
        obtainRebateList:response.data.obtainRebateList,
        useRebateList:response.data.useRebateList,
        totalReabeteValue:response.data.rebateValue,
      });
    }
  }

  async loadRebateValueRule(){
    let reqUrl = `${baseUrl}/v1.0/rebate/rules?appTab=2&appkey=${appkey}`;
    let method = "GET";
    let rebateRule = {
      url: reqUrl,
      method
    }
    let response = await getDataFromServer(rebateRule);
    this.setState({
      rebateRule:response.data.data,
    });
  }

  rewardRecordClick = () =>{
    this.setState({
      selectType:rewardRecord
    });
  }

  payRecordClick = () =>{
    this.setState({
      selectType:payRecord,
    });
  }

  //内容列表
  ContentList = (selectType,obtainRebateList,useRebateList) =>{
    if(selectType == rewardRecord){
      if(obtainRebateList != null && obtainRebateList.length > 0){
        return(
          <div className="rebate_list_content_bg">
            {
              obtainRebateList.map((rebate,index) =>{
                let typeDesc = "";
                if(rebate.type == 4){
                  typeDesc = "邀请好友" + rebate.phoneNumber + "注册";
                }else if (rebate.type == 5){
                  typeDesc = "新用户注册";
                }else if (rebate.type == 6){
                  typeDesc = "分享抢红包";
                }else {
                  typeDesc = "好友" + rebate.phoneNumber + "下单成功";
                }
                let date = new Date(rebate.createTime);
                let dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                return(
                  <div className="rebate_item_bg" key={index}>
                    <div className="info_bg">
                      <div className="time">{dateTime}</div>
                      <div className="rebate_value">{"+" + rebate.rebateValue}</div>
                      <div className="rebate_desc">{typeDesc}</div>
                    </div>
                    <div className="item_spe_line"></div>
                  </div>
                )
              })
            }
          </div>
        );
      }else{
        return(
          <div className="no_data">暂无奖励记录~</div>
        );
      }
    }else{
      if(useRebateList != null && useRebateList.length > 0){
        return(
          <div className="rebate_list_content_bg">
          {
            useRebateList.map((rebate,index) =>{
              let typeDesc = "";
              if (rebate.type == 3){
                if(rebate.putStatus == 1){
                  typeDesc = "提现至" + rebate.phoneNumber + "（提现中）";
                }else if(rebate.putStatus == 2){
                  typeDesc = "提现至" + rebate.phoneNumber;
                } else if (rebate.putStatus == 3 || rebate.putStatus == 4){
                  typeDesc = "提现至" + rebate.phoneNumber + "（提现失败）";
                }
              }else{
                typeDesc = "下单消费";
              }
              let date = new Date(rebate.createTime);
              let dateTime = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
              return(
                <div className="rebate_item_bg" key={index}>
                  <div className="info_bg">
                    <div className="time">{dateTime}</div>
                    <div className="rebate_value">{"-" + rebate.rebateValue}</div>
                    <div className="rebate_desc">{typeDesc}</div>
                  </div>
                  <div className="item_spe_line"></div>
                </div>
              )
            })
          }
          </div>
        );
      }else{
        return(
          <div className="no_data">暂无支出记录~</div>
        );
      }
    }
  }

  render(){

    let {rebateRule,selectType,obtainRebateList,useRebateList,totalReabeteValue} = this.state;

    const text = <span>{rebateRule}</span>;

    return(
      <div className="my_reabate_value_bg">
        <div className="top_title">我的余额</div>
        <div className="top_img_bg">
          <img className="top_img" src={reabeteValueBG}/>
          <div className="top_rule_bg">
            <img className="top_rule_img" src={whWhite}/>
            <Tooltip placement="bottomRight" title={text}>
            <div className="top_rule_title">余额使用规则</div>
            </Tooltip>
          </div>
          <div className="surplus_rebate_bg">
            <div className="value_tag">￥</div>
            <div className="rebate_value">{totalReabeteValue}</div>
          </div>
          <div className="tip_desc">（无门槛，可叠加）</div>
        </div>
        {/*列表*/}
        <div className="rebate_list_bg">
          <div className="list_select_bg">
            <div className={selectType == rewardRecord ? "select_item_select_bg" : "select_item_nomal_bg"} onClick={this.rewardRecordClick}>
              <div className="select_rec_line"></div>
              奖励记录</div>
            <div className={selectType == payRecord ? "select_item_select_bg" : "select_item_nomal_bg"} onClick={this.payRecordClick}>
              <div className="select_rec_line"></div>
              支出记录</div>
          </div>
          {/*内容*/}
          {this.ContentList(selectType,obtainRebateList,useRebateList)}
        </div>
      </div>
    );                  
  }
}

export default MyRebateValue;