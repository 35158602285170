import React,{ Component } from "react";
import "./UserRule.less";
import loginAction from "../../actions/loginAction.js";
import { connect } from 'react-redux';

const {toggleRuleClick} = loginAction;

@connect(
  state => state.loginReducer,
  {toggleRuleClick}
)

class UserRule extends Component {
  render (){
    const hide = {display:"none"};
    const {ruleToggle,toggleRuleClick} = this.props;
    return(
      <div className="blackBg" style={ruleToggle ? {display:"block"} : hide}>
        <div className="userRuleBox">
          <div className="userRuleTitle">港颜用户协议与隐私政策</div>
          <div className="userRuleContent">
            <div className="container">
        <p style={{textIndent: "22px", fontSize: "11px"}}>本协议是您与港颜商城所有者香港百妆化妆品有限公司
（以下简称为:香港百妆;）之间就港颜商城服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击;同意并继续;按钮后，本协定即构成对双方有约束力的法律档。</p>
        <div>
            <div className="gy_header">第1条 本商城服务条款的确认和接纳</div>
            <ul className="gy_list">
                <li>1.1本商城的各项电子服务的所有权和运作权归香港百妆所有。使用者同意所有注册协定条款并完成注册程式，才能成为本商城的正式用户。用户确认：本协定条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</li>
                <li>1.2使用者点击同意本协议的，即视为使用者确认自己具有享受本商城服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。</li>
                <li>1.3如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本商城。</li>
                <li>1.4香港百妆保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭使用者帐户、清除或编辑内容或取消订单的权利。</li>
            </ul>
        </div>
        <div>
            <div className="gy_header">第2条 本商城服务</div>
            <ul className="gy_list">
                <li>2.1香港百妆通过互联网依法为使用者提供互联网资讯等服务，使用者在完全同意本协议及本商城规定的情况下，方有权使用本商城的相关服务。</li>
                <li>2.2使用者必须自行准备如下设备和承担如下开支：（1）上网设备，包括并不限于电脑或者其他上网终端、数据机及其他必备的上网装置；（2）上网开支，包括并不限于网路接入费、上网设备租用费、手机流量费等。</li>
            </ul>
        </div>
        <div>
            <div className="gy_header">第3条 使用者资讯</div>
            <ul className="gy_list">
                <li>3.1用户应自行诚信向本商城提供注册资料，使用者同意其提供的注册资料真实、准确、完整、合法有效，使用者注册资料如有变动的，应及时更新其注册资料。如果使用者提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且香港百妆保留终止使用者使用本商城各项服务的权利。</li>
                <li>3.2用户在本商城进行流览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联繫电话、电子邮箱等隐私资讯的，本商城将予以严格保密，除非得到用户的授权或法律另有规定，本商城不会向外界披露使用者隐私资讯。</li>
                <li>
                    3.3用户注册成功后，将产生用户名和密码等帐户资讯，您可以根据本商城规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户帐号或存在安全性漏洞的情况，请立即通知本商城并向公安机关报案。
                </li>
                <li>3.4用户同意，香港百妆拥有通过邮件、短信电话等形式，向在本商城注册、购物使用者、收货人发送订单资讯、促销活动等告知资讯的权利。</li>
                <li>3.5用户不得将在本商城注册获得的帐户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</li>
                <li>3.6用户同意，香港百妆有权使用使用者的注册资讯、用户名、密码等资讯，登录进入使用者的注册帐户，进行证据保全，包括但不限于公证、见证等。</li>
            </ul>
        </div>
        <div>
            <div className="gy_header">第4条 用户依法言行义务</div>
            <div className="gy_content">本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：
                <div>
                    <ul className="gy_list">
                        <li>(1)不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</li>
                        <li>(2)从中国大陆向境外传输资料资讯时必须符合中国有关法规；</li>
                        <li>(3)不得利用本商城从事洗钱、窃取商业秘密、窃取个人资讯等违法犯罪活动；</li>
                        <li>(4)不得干扰本商城的正常运转，不得侵入本商城及国家电脑资讯系统；</li>
                        <li>(5)不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱駡性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等资讯资料；</li>
                        <li>(6)不得传输或发表损害国家社会公共利益和涉及国家安全的资讯资料或言论；</li>
                        <li>(7)不得教唆他人从事本条所禁止的行为；</li>
                        <li>(8)不得利用在本商城注册的帐户进行牟利性经营活动；</li>
                        <li>(9)不得发佈任何侵犯他人著作权、商标权等智慧财产权或合法权利的内容； 用户应不时关注并遵守本商城不时公佈或修改的各类合法规则规定。 本商城保有删除站内各类不符合法律政策或不真实的资讯内容而无须通知使用者的权利。若用户未遵守以上规定的，本商城有权作出独立判断并採取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="gy_header">第5条 商品资讯</div>
                    <div className="gy_content">
                        本商城上的商品价格、数量、是否有货等商品资讯随时都有可能发生变动，本商城不作特别通知。由于本商城上商品资讯的数量极其庞大，虽然本商城会尽最大努力保证您所流览商品资讯的准确性，但由于众所周知的互联网技术因素等客观原因存在，本商城网页显示的资讯可能会有一定的滞后性或差错，对此情形您知悉并理解；香港百妆欢迎纠错，并会视情况给予纠错者一定的奖励。为表述便利，商品和服务简称为;商品;或;货物;
                    </div>
                </div>
                <div>
                    <div className="gy_header">第6条 订单</div>
                    <ul className="gy_list">
                        <li>6.1在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联繫位址、电话、收货人等资讯。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。</li>
                        <li>6.2除法律另有强制性规定外，双方约定如下：本商城上销售方展示的商品和价格等资讯仅仅是交易资讯的发佈，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联繫方式、收货位址等内容；系统生成的订单资讯是电脑资讯系统根据您填写的内容自动生成的资料，仅是您向销售方发出的交易诉求；销售方收到您的订单资讯后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（ 以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了交易关係；如果您在一份订单裡订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了交易关係；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立交易关係。您可以随时登录您在本商城注册的帐户，查询您的订单状态。</li>
                        <li>6.3由于市场变化及各种以合理商业努力难以控制的因素的影响，本商城无法保证您提交的订单资讯中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。</li>
                    </ul>
                </div>
                <div>
                    <div className="gy_header">第7条 配送</div>
                    <ul className="gy_list">
                        <li>7.1销售方将会把商品（货物）送到您所指定的收货地址，所有在本商城上列出的送货时间为参考时间，参考时间的计算是根据库存状况正常的处理过程和送货时间、送货地点的基础上估计得出的。</li>
                        <li>7.2因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任： （1）用户提供的信息错误、地址不详细等原因导致的； （2）货物送达后无人签收，导致无法配送或延迟配送的； （3）情势变更因素导致的； （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
                        </li>
                    </ul>
                </div>
                <div>
                    <div className="gy_header">第8条 所有权及知识产权条款</div>
                    <ul className="gy_list">
                        <li>8.1使用者一旦接受本协议，即表明该使用者主动将其在任何时间段在本商城发表的任何形式的资讯内容（包括但不限于客户评价、客户谘询、各类话题文章等资讯内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：複製权、发行权、出租权、展览权、表演权、放映权、广播权、资讯网路传播权、摄製权、改编权、翻译权、彙编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给香港百妆所有，用户同意香港百妆有权就任何主体侵权而单独提起诉讼。</li>
                        <li>本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及香港基本法相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在港颜商城上发佈的任何受著作权法保护的作品内容，无论该等内容形成于本协定订立前还是本协定订立后。</li>
                        <li>8.3使用者同意并已充分瞭解本协议的条款，承诺不将已发表于本商城的资讯，以任何形式发佈或授权其它主体以任何方式使用（包括但不限于在各类本商城、媒体上使用）。</li>
                        <li>8.4香港百妆是本商城的製作者, 拥有此本商城内容及资源的著作权等合法权利, 受中华人民共和国及中国香港特别行政区法律保护, 有权不时地对本协定及本商城的内容进行修改，并在本商城张贴，无须另行通知用户。在法律允许的最大限度范围内，香港百妆对本协定及本商城内容拥有解释权。。</li>
                        <li>8.5除法律另有强制性规定外，未经香港百妆明确的特别书面许可, 任何单位或个人不得以任何方式非法地全部或部分複製、转载、引用、连结、抓取或以其他方式使用本商城的资讯内容，否则，香港百妆有权追究其法律责任。</li>
                        <li>本商城所刊登的资料资讯（诸如文字、图表、标识、按钮图示、图像、音效档片段、数位下载、资料编辑和软体），均是香港百妆或其内容提供者的财产，受中国和国际版权法的保护。本商城上所有内容的彙编是香港百妆的排他财产，受中国和国际版权法的保护。本商城上所有软体都是香港百妆或其关联公司或其软体供应商的财产，受中国和国际版权法的保护。</li>
                    </ul>
                </div>
                <div>
                    <div className="gy_header">第9条 责任限制及不承诺担保</div>
                    <div className="gy_content">
                        除非另有明确的书面说明,本商城及其所包含的或以其它方式通过本商城提供给您的全部资讯、内容、材料、产品（包括软体）和服务，均是在按现状和按现有的基础上提供的。 除非另有明确的书面说明, 香港百妆不对本商城的运营及其包含在本商城上的资讯、内容、材料、产品（包括软体）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国及中国香港特别行政区法律另有规定的以外）。 香港百妆不担保本商城所包含的或以其它方式通过本商城提供给您的全部资讯、内容、材料、产品（包括软体）和服务、其伺服器或从本商城发出的电子信件、资讯没有病毒或其他有害成分。 如因不可抗力或其它本商城无法控制的原因使本商城销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的资讯、记录等，香港百妆会合理地尽力协助处理善后事宜。
                    </div>
                </div>
                <div>
                    <div className="gy_header">第10条 协议更新及用户关注义务</div>
                    <div className="gy_content">根据国家法律法规变化及本商城运营需要，香港百妆有权对本协定条款不时地进行修改，修改后的协议一旦被张贴在本商城上即生效，并代替原来的协议。使用者可随时登录查阅最新协定； 用户有义务不时关注并阅读最新版的协议及本商城公告。如使用者不同意更新后的协议，可以且应立即停止接受港颜商城依据本协定提供的服务；如使用者继续使用本商城提供的服务的，即视为同意更新后的协议。香港百妆建议您在使用本商城之前阅读本协定及本商城的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</div>
                </div>
                <div>
                    <div className="gy_header">第11条 法律管辖和适用</div>
                    <div className="gy_content">本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区及中国特别行政区适用之有效法律（但不包括其衝突法规则）。 如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协定内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的香港特别行政区法院提起诉讼。</div>
                </div>
                <div>
                    <div className="gy_header">第12条 其他</div>
                    <ul className="gy_list">
                        <li>12.1香港百妆尊重用户和消费者的合法权利，本协议及本商城上发佈的各类规则、声明等其他内容，均是为了更好的、更加便利的为使用者和消费者提供服务。本商城欢迎用户和社会各界提出意见和建议，香港百妆将虚心接受并适时修改本协议及本商城上的各类规则。</li>
                        <li>12.2本协定内容中以黑体、加粗、底线、斜体等方式显著标识的条款，请使用者著重阅读。</li>
                        <li>12.3您点击本协定下方的;同意并继续;按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协定的全部内容。同意并继续。</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <h1 style={{textAlign: "center", marginTop: "20px" , fontSize: "15px"}}>隐私政策</h1>
        <p style={{textIndent: "22px", fontSize: "11px"}}>港颜商城（以下或称“我们”）注重保护使用者个人资讯及个人隐私。本隐私政策解释了使用者（“您”）个人资讯收集（以下或称“资讯”）和使用的有关情况，本隐私政策适用于本商城向您提供的所有相关服务（包括但不限于电子商务、网路资讯、网路社交、互联网金融服务等，以下称“本商城服务”或“服务”）。如果您不同意本隐私政策的任何内容，您应立即停止使用本商城服务。当您使用本商城提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人资讯。</p>
        <div>
            <div className="gy_header">一、您个人资讯的收集</div>
            <div className="gy_content">我们收集资讯是为了向您提供更好以及更个性化的服务，并努力提高您的用户体验。我们收集资讯的种类如下：</div>
            <ul className="gy_list">
                <li>1、您向我们提供的资讯 当您注册本商城帐户及您在使用相关本商城服务时填写、提交及/或其他任何方式提供的资讯，包括您的姓名、性别、出生年月日、身份证号码、电话号码、电子邮箱、收货位址、或网银线上帐号、银行卡资讯及相关附加资讯（如您位址中的所在的省份和城市、邮递区号等）。您可以选择不提供某一或某些资讯，但是这样可能使您无法使用本商城的许多特色服务。请您理解，我们使用您提供的资讯是为了回应您的要求，为您在本商城购物或享受服务提供便利，完善本商城以及与您进行资讯沟通。另外，我们可能会将您所提供的资讯与您的本商城帐户关联，用以识别您的身份。
                </li>
                <li>2、我们在您使用服务过程中获得的资讯 为了提高服务品质和使用者体验，我们会留存您使用服务以及使用方式的相关资讯，这类资讯包括： （1）您的流览器和电脑上的资讯。在您访问本商城或使用本商城服务时，本商城系统自动接收并记录的您的流览器和电脑上的资讯（包括但不限于您的IP位址、流览器的类型、使用的语言、访问日期和时间、软硬体特徵资讯及您需求的网页记录等资料）。 （2）您的位置资讯。当您下载或使用本商城、其关联方及合作伙伴开发的应用程式（例如本商城），或访问移动网页使用本商城服务时，本商城可能会读取您的位置（大多数移动设备将允许您关闭定位服务，具体建议您联繫您的移动设备的服务商或生产商）。 （3）您的行为或交易资讯。本商城可能会记录您访问本商城或使用本商城服务时所进行的操作以及您在本商城上进行交易的相关资讯。 除上述资讯外，我们还可能为了提供服务及改进服务品质的合理需要而获得的您的其他资讯，包括您与我们的客服团队联繫时您提供的相关资讯，您参与问卷调查时向我们发送的问卷答覆资讯，以及您与本商城的关联方、本商城合作伙伴之间互动时我们获得的相关资讯。 同时，为提高您使用本商城提供的服务的安全性，更准确地预防钓鱼本商城欺诈和木马病毒，我们可能会通过瞭解一些您的网路使用习惯、您常用的软体资讯等手段来判断您帐户的风险，并可能会记录一些我们认为有风险的连结（“URL”）。
                </li>
                <li>3、来自协力厂商的资讯 指在您注册本商城帐户和使用服务过程中，您授权的本商城可向本商城的关联方、合作伙伴所收集的相关资讯，以及您授权的本商城的关联方、合作伙伴可向本商城分享相关的资讯。这些资讯包括但不限于您的身份资讯、行为资讯、交易资讯、设备资讯等，本商城会将此类资讯汇总，用于説明本商城向您提供更好以及更加个性化的服务或更好的预防互联网欺诈。 您瞭解并同意，以下资讯不适用本隐私政策： （1）您在使用本商城提供的搜索服务时输入的关键字资讯； （2）信用评价、违反法律法规规定或违反本商城平台规则行为及本商城已对您採取的措施； （3）应法律法规要求需公示的企业名称等相关工商注册资讯以及自然人经营者的资讯。 （4）其他您与本商城或本商城的关联方所签署的协定（包括线上签署的电子协定，例如《本商城使用者注册协定》）以及本商城平台规则中明确约定或提示您不适用本隐私政策的与您有关的资讯。
                </li>
            </ul>
        </div>
        <div>
            <div className="gy_header">二、我们对您个人资讯的管理和使用</div>
            <div className="gy_content">为向您提供服务、提升我们的服务品质以及优化您的服务体验，我们会在符合法律规定或根据您授权的情况下使用您的个人资讯，并主要用于下列用途：</div>
            <ul className="gy_list">
                <li>1、向您提供您使用的各项服务，并维护、改进这些服务。</li>
                <li>2、向您推荐您可能感兴趣的内容，包括但不限于向您发出产品和服务资讯，或通过系统向您展示个性化的协力厂商推广资讯，或在征得您同意的情况下与本商城的合作伙伴共用资讯以便他们向您发送有关其产品和服务的资讯。如您不希望接收上述资讯，可通过相应的退订功能进行退订。</li>
                <li>3、我们可能使用您的个人资讯以验证身份、预防、发现、调查欺诈、危害安全、非法或违反与我们或其关联方协定、政策或规则的行为，以保护您、其他本商城用户，或我们或其关联方的合法权益。</li>
                <li>4、我们可能会将来自某项服务的个人资讯与来自其他服务所获得的资讯结合起来，用于为了给您提供更加个性化的服务使用，例如为让您通过购物拥有更广泛的社交圈而使用、共用或披露您的资讯。</li>
                <li>5、我们会对我们的服务使用情况进行统计，并可能会与公众或协力厂商分享这些统计资讯，以展示我们的产品或服务的整体使用趋势。但这些统计资讯不包含您的任何身份识别资讯。</li>
                <li>6、让您参与有关我们产品及服务的调查。</li>
                <li>7、经您同意或授权的其他用途。</li>
            </ul>
        </div>
        <div>
            <div className="gy_header">三、您个人资讯的分享</div>
            <div className="gy_content">您的个人资讯是我们为您提供服务的重要部分，我们会遵循法律规定对您的资讯承担保密义务。除以下情形外，我们不会将您的个人资讯披露给协力厂商：</div>
            <ul className="gy_list">
                <li>1、征得您的同意或授权。</li>
                <li>2、根据法律法规的规定或行政或司法机构的要求。</li>
                <li>3、出于实现“我们对您个人资讯的管理和使用”部分所述目的，或为履行我们在《本商城使用者注册协议》或本隐私政策中的义务和行使我们的权利，向本商城的关联方、合作伙伴或代表本商城履行某项职能的协力厂商（例如代表我们发出推送通知的通讯服务商、处理银行卡的支付机构等）分享您的个人资讯。</li>
                <li>4、如您是适格的智慧财产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能产生的权利纠纷。</li>
                <li>5、只有共用您的资讯，才能提供您需要的服务，或处理您与他人的纠纷或争议。</li>
                <li>6、您出现违反中国有关法律、法规规定或者违反您与本商城签署的相关协议（包括线上签署的电子协定）或违反相关本商城平台规则时需要向协力厂商披露的情形。</li>
                <li>7、为维护本商城及其关联方或其他本商城用户的合法权益。 随著我们业务的发展，我们及我们的关联方有可能进行合併、收购、资产转让或类似的交易，您的个人资讯有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
                </li>
            </ul>
        </div>
        <div>
            <div className="gy_header">四、您个人资讯的安全</div>
            <div className="gy_content">本商城严格保护您的个人资讯安全。我们使用各种制度、安全技术和程式等措施来保护您的个人资讯不被未经授权的访问、篡改、披露或破坏。如果您对我们的个人资讯保护有任何疑问，请联繫我们的客服。 在通过本商城与协力厂商进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人资讯，如联络方式或者邮政位址等。请您妥善保护自己的个人资讯，仅在必要的情形下向他人提供。如您发现自己的个人资讯洩密，尤其是你的帐户及密码发生洩露，请您立即联络我们的客服，以便我们採取相应措施。
            </div>
        </div>
        <div>
            <div className="gy_header">五、访问和更新您的个人资讯</div>
            <div className="gy_content">您可以查阅或更新您提交给本商城的个人资讯。一般情况下，您可随时流览、修改自己提交的资讯，但出于安全性和身份识别的考虑，您可能无法修改注册本商城使用者或某种特定服务时提供的初始注册资讯或其他验证资讯。如某种特定服务要求您无法修改您所提供的初始资讯，我们会在您注册或准备使用该种服务时给予您相应的提示。</div>
        </div>
        <div>
            <div className="gy_header">六、Cookie 及网路 Beacon的使用</div>
            <ul className="gy_list">
                <li>1、Cookie的使用Cookie是由网页伺服器存放在您的访问设备上的文字档。指定给您的Cookie 是唯一的，它只能被将Cookie发佈给您的域中的Web伺服器读取。 本商城使用 Cookie 来説明您实现您的连线体验的个性化，使您在本商城及其关联方获得更轻鬆的访问体验。例如，Cookie 会帮助您在后续访问本商城时调用您的资讯，简化记录您填写个人资讯（例如一键登录等）的流程；为您提供安全购物的偏好设置；帮助您优化对广告的选择与互动；保护您的资料安全等。 您有权接受或拒绝 Cookie。大多数流览器会自动接受Cookie，但您通常可根据自己的需要来修改流览器的设置以拒绝 Cookie。如果选择拒绝 Cookie，那麽您可能无法完全体验所访问的本商城或某些服务的全部功能。
                </li>
                <li>2、网路Beacon的使用本商城网页上常会包含一些电子图像（称为"单图元" GIF 档或 "网路 beacon"），它们可以説明本商城计算流览网页的用户或访问某些cookie。本商城使用网路beacon的方式有：（1）本商城通过在本商城上使用网路beacon，计算使用者访问数量，并通过访问 cookie 辨认注册用户。 （2）本商城通过得到的cookie资讯，可以在本商城提供个性化服务。
                </li>
            </ul>
        </div>
        <div>
            <div className="gy_header">七、未成年人的个人资讯保护</div>
            <div className="gy_content">本商城非常重视对未成年人个人资讯的保护。若您是18周岁以下的未成年人，在使用本商城服务前，应事先取得您家长或法定监护人的书面同意。本商城根据国家相关法律法规的规定保护未成年人的个人资讯。</div>
        </div>
        <div>
            <div className="gy_header">八、通知和修订</div>
            <div className="gy_content">为给你提供更好的服务，本商城的业务将不时变化，本隐私政策也将随之调整。本商城会通过在本商城、移动端上发出更新版本并提醒您相关内容的更新，也请您访问本商城以便及时瞭解最新的隐私政策。如果您对于本隐私政策或在使用本商城服务时对于您的个人资讯或隐私情况有任何问题，请联繫本商城客服并作充分描述，本商城将尽力解决。同意并继续</div>
        </div>
    </div>
          </div>
          <div className="userRuleKnow" onClick={toggleRuleClick}>
            知道了
          </div>
        </div>
      </div>
    )
  }
}

export default UserRule;
