import React,{Component} from "react";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import "./AddNewAddressPopView.less";
import close from "../../images/pop_usercenter_close_nor.png";
import selected from "../../images/set_address_default.png";

@connect(
  state => state.loginReducer
)

class AddNewAddressPopView extends Component{
  constructor(props){
    super(props);
    let address = this.props.editAddress;
    let name = "";
    let phone = "";
    let isDefault = false;
    let title = "";
    let provinceCode = -1;
    let cityCode = -1;
    let province = "";
    let city = "";
    let county = "";
    let addressDesc = "";
    if (address && this.props.addressId != -1){
      name = address.name;
      phone = address.phone;
      isDefault = address.isDefault == 1;
      title = "编辑收货地址";
      provinceCode = this.props.editAddress.provinceCode;
      cityCode = this.props.editAddress.cityCode;
      province = this.props.editAddress.province;
      city = this.props.editAddress.city;
      county = this.props.editAddress.county;
      addressDesc = this.props.editAddress.detail
    }
    this.state={
      name:name,//名字
      phone:phone,//电话
      province:province,//省
      city:city,//市
      county:county,//县区
      addressDesc:addressDesc,//详细地址
      isDefault:isDefault,
      provinceList:[{name:"请选择",code:"-1",id:-1}],
      cityList:[{name:"请选择",code:"-1",id:-1}],
      countryList:[{name:"请选择",code:"-1",id:-1}],
      provinceCode:provinceCode,//省 代码
      cityCode:cityCode,//市 代码
      coutyCode:-1,//县区  代码
      title:title,
    }
    this.loadProvince();
  }
  componentDidMount(){
  }
  async loadProvince(){
    let reqUrl = `${baseUrl}/province?appTab=2&appkey=${appkey}`;
    let method = "GET";
    let province = {
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(province);
    let provinceList = response.data.provinceList;
    if(provinceList && provinceList.length > 0){
      provinceList.unshift({name:"请选择",code:"-1",id:-1});
      this.setState({
        provinceList:provinceList
      });
      let provinceCode = this.state.provinceCode;
      if (provinceCode == null || provinceCode == -1) {
        provinceCode = provinceList[1].code;
      }
      //let defaultProvinceCode = provinceList[1].code;
      this.loadCity(provinceCode);
    }
  }
  async loadCity(provinceCode){
    let requrl = `${baseUrl}/city?appTab=2&appkey=${appkey}&provinceCode=${provinceCode}`;
    let method = "GET";
    let city = {
      url:requrl,
      method
    }
    let response = await getDataFromServer(city);
    let cityList = response.data.cityList;
    if(cityList && cityList.length > 0){
      cityList.unshift({name:"请选择",code:"-1",id:-1});
      this.setState({
        cityList:cityList
      });
      let cityCode = this.state.cityCode;
      if (cityCode == null || cityCode == -1) {
        cityCode = response.data.cityList[1].code;
      }
      this.loadCountry(cityCode);
    }
  }
  async loadCountry(cityCode){
    let requrl = `${baseUrl}/county?appTab=2&appkey=${appkey}&cityCode=${cityCode}`;
    let method = "GET";
    let county = {
      url:requrl,
      method
    }
    let response = await getDataFromServer(county);
    let countyList = response.data.countyList;
    if(countyList && countyList.length > 0){
      countyList.unshift({name:"请选择",code:"-1",id:-1});
      this.setState({
        countryList:countyList
      });
    }
  }
  
  hiddenSelf = (isSubmit) =>{
    if (isSubmit) {
      let {name,phone,province,city,county,addressDesc,isDefault} = this.state;
      if(name == null || name == ""){
        console.log("请输入正确的用户名");
      }else if(phone == null || phone == ""){
        console.log("请输入正确的手机号");
      }else if (province == null || province == "" || city == null || city == "" || county == null || county == ""){
        console.log("请输入正确的地址");
      }else if(addressDesc == null || addressDesc == ""){
        console.log("请输入详细地址");
      }else{
        let addressInfo = {name:name,phone:phone,province:province,city:city,county:county,addressDesc:addressDesc,isDefault:isDefault};
        this.props.hiddenPopView(isSubmit,addressInfo);
      }
    }else{
      this.props.hiddenPopView(isSubmit,{});
    }
  }
  closeAction = () =>{
    this.props.hiddenPopView(false,{});
  }
  handleNameChange = (e) =>{
    this.setState({
      name:e.target.value
    });
  }
  handlePhoneChange = (e) =>{
    this.setState({
      phone:e.target.value
    });
  }
  handleAddressDescChange = (e) =>{
    this.setState({
      addressDesc:e.target.value
    });
  }
  selectProvinceOnchange = (e) => {
    let index = e.target.selectedIndex;
    if(index > 0){
      let provinceCode = this.state.provinceList[index].code;
      let name = this.state.provinceList[index].name;
      this.setState({
        provinceCode:provinceCode,
        province:name,
        cityCode:-1,
        city:"",
        coutyCode:-1,
        county:"",
      },()=>{this.loadCity(provinceCode)});
    }
  }
  selectCityOnchange = (e) => {
    let index = e.target.selectedIndex;
    if(index > 0){
      let cityCode = this.state.cityList[index].code;
      let name = this.state.cityList[index].name;
      this.setState({
        cityCode:cityCode,
        city:name,
        coutyCode:-1,
        county:"",
      },()=>{this.loadCountry(cityCode)});
    }
  }
  selectCountyOnchange = (e) => {
    let index = e.target.selectedIndex;
    if(index > 0){
      let coutyCode = this.state.countryList[index].code;
      let name = this.state.countryList[index].name;
      this.setState({
        coutyCode:coutyCode,
        county:name
      });
    }
  }
  setDefault = () =>{
    let isDefault = !this.state.isDefault;
    this.setState({
      isDefault:isDefault
    });
  }
  render(){
    let {provinceList,cityList,countryList,name,phone,isDefault,title,addressDesc,province,city,county} = this.state;
    return(
      <div className="add_new_address_pop_bg">
        <div className="white_bg">
          <div className="add_address_title_bg">
            <div className="add_address_title">{title}</div>
            <img className="close" src={close} onClick={this.closeAction}/>
          </div>
          <div className="top_line"></div>
          <div className="info_bg">
            <div className="left_title">收货人：</div>
            <div className="left_star">*</div>
            <input className="user_name" placeholder="请输入姓名" defaultValue={name} onChange={this.handleNameChange}/>
          </div>
          <div className="info_bg">
            <div className="left_title">手机号码：</div>
            <div className="left_star">*</div>
            <input className="user_name" placeholder="请输入手机号码" defaultValue={phone} onChange={this.handlePhoneChange}/>
          </div>
          <div className="info_bg">
            <div className="left_title">选择地区：</div>
            <div className="left_star">*</div>
            <div className="select_bg">
              <select className="select" value={(province && province != "") ? province : "请选择"} onChange={this.selectProvinceOnchange}>
                {
                  provinceList.map((province,index) =>{
                   return <option key={index}>{province.name}</option>
                  })
                }
              </select>
              <select className="select" value={(city && city != "") ? city : "请选择"} onChange={this.selectCityOnchange}>
                {
                  cityList.map((city,index) =>{
                    return <option key={index}>{city.name}</option>
                   })
                }
              </select> 
              <select className="select" value={(county && county != "") ? county : "请选择"} onChange={this.selectCountyOnchange}>
                {
                  countryList.map((county,index) =>{
                    return <option key={index}>{county.name}</option>
                    })
                }
              </select>
            </div>
          </div>
          <div className="address_detail_bg">
            <div className="left_title">详细地址：</div>
            <div className="left_star">*</div>
            <div className="address_input_bg">
              <textarea className="address_textarea" placeholder="详细地址、街道、门牌号等" value={addressDesc} onChange={this.handleAddressDescChange}></textarea>
            </div>
          </div>
          <div className="set_default_bg">
            <div className="select_defalt_bg" onClick={this.setDefault}>
             {isDefault &&  <img className="select_defalt" src={selected}></img>}
            </div>
            <div className="set_defalt_lab">设为默认</div>
          </div>
          <div className="bottom_btn_bg">
            <div className="red_btn" onClick={this.hiddenSelf.bind(this,true)}>确认</div>
            <div className="balck_btn" onClick={this.hiddenSelf.bind(this,false)}>取消</div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddNewAddressPopView ;