import React,{Component} from "react";
import "./UserCenterBottomGoods.less";
import prew from "../../images/qianggou_ic_arrow_L.png";
import next from "../../images/qianggou_ic_arrow_R.png";


class UserCenterBottomGoods extends Component{
  constructor(props){
    super(props);
  }

  nextAction = () =>{
    console.log("userCenter next click")
  }
  prewAction = () =>{
    console.log("userCenter prew click")
  }
  render(){
    let goodsList = [{goodImg:"",countryImg:"",countryName:"日本",name:"圣罗兰 YSL 圣罗兰细管纯口红13 限定版",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"156人 已评价"},
                     {goodImg:"",countryImg:"",countryName:"瑞士",name:"Beauty BuffetScentio Q10牛奶洗面奶 100克...",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"256人 已评价"},
                     {goodImg:"",countryImg:"",countryName:"西班牙",name:"2019年新版 SKII 日本SK2 入门级女神 75ml ",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"256人 已评价"},
                     {goodImg:"",countryImg:"",countryName:"日本",name:"圣罗兰 YSL 圣罗兰细管纯口红13 限定版",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"256人 已评价"},
                     {goodImg:"",countryImg:"",countryName:"日本",name:"圣罗兰 YSL 圣罗兰细管纯口红13 限定版",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"256人 已评价"},
                     {goodImg:"",countryImg:"",countryName:"日本",name:"圣罗兰 YSL 圣罗兰细管纯口红13 限定版",price:"309.0",orginalPrice:"专柜价￥499.0",comment:"256人 已评价"}];

    return(
      <div className="bottom_goods_bg">
        <div className="bottom_goods_title">热门推荐</div>
        <div className="item_list_bg">
          {
            goodsList.map((goods,index) =>{
              return(
                <div className="item_bg" key={index}>
                  <img className="goods_img"/>
                  <div className="name">{goods.name}</div>
                    <div className="price_bg">
                      <div className="price">{"￥" + goods.price}</div>
                      <div className="dicount">4.5折</div>
                    </div>
                    <div className="special_price">{goods.orginalPrice}</div>
                    <div className="comment">{goods.comment}</div>
                </div>
              )
            })
          }
        </div>
        <img className="item_left_img" src={prew} onClick={this.prewAction}/>
        <img className="item_right_img" src={next} onClick={this.nextAction}/>
      </div>
    );
  }
}

export default UserCenterBottomGoods;