import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./Footer.less";
import footerIconZP from "../../images/footer_ic_zheng.png";
import footerIconZYing from "../../images/footer_ic_ziying_big.png";
import footerIconZYou from "../../images/footer_ic_zhiyou_big.png"
import footerIconDJ from "../../images/footer_ic_dijia_big.png"
import footerQrCode from "../../images/footer_erweima.png"

class Footer extends React.Component{
    FooterTopItem = (item,index) => {
      return(
      <div className="top_item" key={index}>
        <img className="iconImg"src={item.url} />
        <div className="titleLabel">{item.topTitle}</div>
        <div className="subTitleLable title_hover">{item.subTitle}</div>
       </div>
      );
    }
    FooterBottomItem = (item,index) =>{
      return(
        <div className="mid_item" key={index}>
          <div className="mid_big_title">{item.bigTitle}</div>
        {
          item.smallTitles.map((smallTitle,index1) => {
            return (
           <div  key={index1}>
            {(smallTitle.type == "about") &&
              <a className="mid_small_title title_hover" target="__blank" href={smallTitle.link}>{smallTitle.title}</a> }
            {!(smallTitle.type == "about") &&
              <Link className="mid_small_title title_hover"  target="__blank" to={smallTitle.link}>{smallTitle.title}</Link>
            }
            </div>
         )})
        }
        </div>
      );
    }
    render(){
      let itemList = [{url:footerIconZP,topTitle:"100%正品保障",subTitle:"正品保障 假一賠十"},
                      {url:footerIconZYing,topTitle:"港顏自營",subTitle:"香港企業 全場自營"},
                      {url:footerIconZYou,topTitle:"香港直郵",subTitle:"全球直購 極速配貨"},
                      {url:footerIconDJ,topTitle:"低價保障",subTitle:"天天低價 暢達無憂"}]
      let bottomTitleList = [
        {bigTitle:"港顏保障",smallTitles:[{title:"消費者權益",link:"/home/cosumesInterests"}]},
        {bigTitle:"購物指南",smallTitles:[{title:"登記注冊",link:"/home/helpcenter/loginregester"},
                                        {title:"購物流程",link:"/home/helpcenter/shopping"},
                                        {title:"優惠券",link:"/home/helpcenter/coupon"},
                                        {title: "實名認證",link:"/home/helpcenter/realname"}]},
        {bigTitle:"物流配送",smallTitles:[{title:"物流配送",link:"/home/helpcenter/logistic"},
                                        {title:"物流跟蹤",link:"/home/helpcenter/logistictrack"}]},
        {bigTitle:"售後服務",smallTitles:[{title:"售後問題",link:"/home/helpcenter/aftersale"},
                                        {title:"退貨問題",link:"/home/helpcenter/refundrule"},
                                        {title:"取消訂單",link:"/home/helpcenter/cancelorder"}]},
        {bigTitle:"關於我們",smallTitles:[{title:"關於港顏",link:"http://www.hokoface.com",type:"about"},
                                        {title:"聯系我們",link:"/home/helpcenter/connectus"}]}
      ]
      return (
        <div className="bottom_footer">
          <div className="top_inner">
            {
              itemList.map((item,index) => {
                return this.FooterTopItem(item,index)
              })
            }
          </div>
          <div className="mid_line"></div>
          <div className="mid_inner">
            {
              bottomTitleList.map((item,index) => {
                return this.FooterBottomItem(item,index)
              })
            }
            <div className="mid_item">
              <img className="mid_qrcode_img" src={footerQrCode}/>
            </div>
          </div>
          <div className="bottom_line"></div>
          <div className="bottom_inner">
            <div className="bottom_tip_left">©2015-2020 HONGKONG HIOCEAN COMPANY LIMITED</div>
            {/* <div className="bottom_tip_right"><a rel="nofollow" href="http://www.beian.miit.gov.cn" target="_blank">粵ICP备15069512号</a></div> */}
          </div>
        </div>
      );
    }
}
export default Footer;
