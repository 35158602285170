import React,{Component} from "react";
import Pager from "../../components/Pager/Pager";
import { Link } from "react-router-dom";
import { Tooltip,message,Statistic,Rate } from 'antd';
import loginAction from "../../actions/loginAction.js";
import "./MyFoot.less";
import {connect} from "react-redux";
import { getDataFromServer } from '../../httpRequest/Api.js';
import warning from "../../images/ic_warning_small.png";
import saleOut from "../../images/tag_yiqiangguang_foot.png";

const {toggleLogin} = loginAction;

@connect(
  ({homeReducer,loginReducer}) => ({
    systemData: homeReducer.systemData,
    userLoginInfo: loginReducer.userLoginInfo
  }),{toggleLogin}
)

class MyFoot extends Component{
  constructor(props){
    super(props);
    this.state={
      itemSection:0,
      itemIndex:0,
      showMask:false,
      pageNo:1,
      pageSize:20,
      footList:[],
      totalPage:12,
    };
  }
  componentDidMount(){
    this.loadFootList()
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }
  async loadFootList(){
    let loginInfo = this.props.userLoginInfo;
    let {pageNo , pageSize} = this.state;
    if(loginInfo){
      let userId = loginInfo.id;
      let reqUrl = `${baseUrl}/V1.6/footprint?appTab=2&appkey=${appkey}&userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`;
      let method = "GET";
      let footList= {
        url:reqUrl,
        method
      }
      let response = await getDataFromServer(footList);
      this.setState({
        footList:response.data.data,
        totalPage:response.data.pageCount,
      });
    }
  }
  async removeFoot(){
    let ids = [];
    let {footList , itemSection , itemIndex} = this.state;
    if (footList != null && footList.length > 0){
      let goods = footList[itemSection].goodsList[itemIndex];
      ids.push(goods.id);
    }
    let reqUrl = `${baseUrl}/V1.6/footprint?appTab=2&appkey=${appkey}&ids=${ids}`;
    let method = "DELETE";
    let remoRes= {
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(remoRes);
    this.setState({
      pageNo:1
    }, this.loadFootList);
   
  }
  //添加购物车
  async addCart(goodsInfo){
    let stock = 0;
    if (goodsInfo.virtualFlag == 1){
      stock = goodsInfo.virtualStock;
    }else{
      stock = goodsInfo.warestock1;
    }
    let goodsId = goodsInfo.goodsId;
    if (stock <= 0) {
      message.error(<span className="msgText">对不起，该商品已抢光</span>);
    }else{
      if (this.props.userLoginInfo) {
        let url = `${baseUrl}/v1.0/b2c/user/shopping?appkey=${appkey}&goodsId=${goodsId}&quantity=1&promotionId=-1&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let request = {
          url,
          method: 'PUT'
        }
        let data = await getDataFromServer(request);
        if (data.data.result === 0) {
          message.success(<span className="msgText">添加成功</span>);
        }else if (data.data.result === 3) {
          message.error(<span className="msgText">库存不足</span>);
        }else if (data.data.result === 4) {
          message.error(<span className="msgText">超过限量{this.props.systemData ? this.props.systemData.overSeaLimitNum : ""}件</span>);
        }else if (data.data.result === 5) {
          message.error(<span className="msgText">对不起，该商品已抢光</span>);
        }else {
          message.error(<span className="msgText">{data.data.msg}</span>);
        }
      }else{
        this.props.toggleLogin();
      }
    }
  }
  showMask = (section,index) =>{
    this.setState({
      itemSection:section,
      itemIndex:index,
      showMask:true,
    });
  }
  removeCollection = (isRemove) =>{
    if(isRemove){
      this.removeFoot();
    }
    this.setState({
      showMask:false,
    });
  }
  getPage = (page) =>{
    this.setState({
      pageNo:page
    },this.loadFootList);
  }

  getDateString = (datestr) =>{
    var predate = new Date(datestr);
    var time1 = predate.getTime();

    var nowDate = new Date();
    var year = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1;
    var strDate = nowDate.getDate();
    if(month > 1 && month < 10){
      month = "0" + month;
    }
    if(strDate > 0 && strDate < 10){
      strDate = "0" + strDate;
    }
    var currentDateStr = year + "-" + month + "-" + strDate + " " + "00:00:00";
    var currentDate = new Date(currentDateStr);
    var nowTime = currentDate.getTime();
    if(time1 >= nowTime){
      return "今天";
    }else if(time1 < nowTime && time1 >= (nowTime - 3600 * 24 * 1000)){
      return "昨天";
    }else{
      return  datestr;
    }
  }
  render(){
    let itemSection = this.state.itemSection;
    let itemIndex = this.state.itemIndex;
    let showMask = this.state.showMask;
    let footList = this.state.footList;
    let pageNo = this.state.pageNo;
    let totalPage = this.state.totalPage;

    return(
      <div className="my_collction_bg">
        <div className="my_collction_ttile">我的足迹</div>
        {/*足迹列表*/}
        { (footList != null && footList.length > 0) && <div className="my_collection_list_bg clearfix">
          {
            footList.map((foot,section)=>{
              let datestr = this.getDateString(foot.browseDate);
              let time = foot.browseDate.substr(5,5);
              let showtime = true;
              if(datestr == foot.browseDate){
                datestr = foot.browseDate.substr(5,5);
                showtime = false;
              } 
              return(
                <div className="section_bg" key={section}>
                  <div className="section_title_bg">
                    <div className="spe_line"></div>
                    <div className="today">{datestr}</div>
                    <div className={showtime ? "time" : "time_display_none"}>{time}</div>
                  </div>
                  {
                    foot.goodsList.map((goods,index)=>{
                      let originPrice = 0.0;
                      let originalPriceText = ""; 
                      if (goods.limitBuyFlag == 1){
                        originPrice = goods.originalPrice;
                        originalPriceText = "原价 ￥" + originPrice; 
                      }else if (goods.limitBuyFlag == 2){
                        originPrice = goods.originalPrice;
                        originalPriceText = "抢购价 ￥" + originPrice; 
                      }else if(goods.priceOverseaVAT < goods.b2cOriginalPrice){
                        originPrice = goods.b2cOriginalPrice;
                        let textSwitch = this.props.systemData ? this.props.systemData.copywritingEnable : 1;
                        let text = this.props.systemData ? this.props.systemData.copywritingText : "";
                        if (textSwitch == 0) {
                          originalPriceText = text + " ￥" + originPrice;
                        }else{
                          originalPriceText = " ￥" + originPrice;
                        }
                      }
                      let discount = 10.0;
                      if(originPrice > goods.priceOverseaVAT){
                        discount = goods.priceOverseaVAT / originPrice * 10;
                        discount = discount.toFixed(1);
                      }
                      let hasStock = true;
                      if ((goods.virtualFlag == 1 && goods.virtualStock <= 0) || (goods.virtualFlag != 1 && goods.warestock1 <= 0)){
                        hasStock = false;
                      }
                      return(
                        <div className="collection_item_bg" key={index}>
                          <div className="content_bg">
                            <div className="goods_img_bg">
                              <Link to={`/home/goodsdetail/${goods.goodsId}`} target="_blank">
                                <img className="goods_img" src={goods.imgpath}/>
                                {!hasStock && <img className="goods_img" src={saleOut} />}
                              </Link>
                              <div className="add_shop_cart_bg">
                                <div className="add_shop_cart" onClick={this.addCart.bind(this,goods)}>加入购物车</div>
                                <div className="delete" onClick={this.showMask.bind(this,section,index)}>删除</div>
                              </div>
                            </div>
                            <div className="country_bg">
                              <img className="country_img" src={goods.country.logo}/>
                              <div className="coutry_name">{goods.country.cnName}</div>
                            </div>
                            <div className="name">{goods.title}</div>
                            <div className="tag_bg">
                              {(goods.promotionTags && goods.promotionTags.length > 0) &&
                              <span className="activity_tag">{goods.promotionTags[0].tagName}</span>
                              }
                            </div>
                            <div className="price_bg">
                              <div className="price">{"￥" + goods.priceOverseaVAT}</div>
                              {(discount != 10.0) && <div className="dicount">{`${discount}折`}</div>}
                            </div>
                            {(originPrice != 0.0) && <div className={goods.limitBuyFlag == 1 ? "special_price_gray" : "special_price"}>{originalPriceText}</div>}
                          </div>
                          {/*移除足迹提示*/}
                          {
                            (itemIndex == index && itemSection == section && showMask) && (
                              <div className="conten_mask">
                                <div className="mask_tip_bg">
                                  <img className="tip_img" src={warning}/>
                                  <div className="tip_info">是否移除足迹</div>
                                </div>
                                <div className="btns_bg">
                                  <div className="sure_btn" onClick={this.removeCollection.bind(this,true)}>确认</div>
                                  <div className="cancel_btn" onClick={this.removeCollection.bind(this,false)}>取消</div>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>}
        {/*页码*/}
        { (footList != null && footList.length > 0) && <div className="page_bg">
          <Pager total={totalPage} current={pageNo} getPage={this.getPage}/>
        </div>}
      </div>
    );
  }
}

export default MyFoot;