import React,{ Component } from 'react';
import "./TemplateB.less";
import TemplateItem from "../../components/TemplateItem/TemplateItem";

const TemplateB = ({templateBInfo}) =>{
  let templateDetails = templateBInfo.templateDetailsModels;
  return (
    <div className="templateB">
      <div className="inner">
        <div className="recTitle">{templateBInfo.title}</div>
        <div className="recClassify">
          <div className="leftArea">
            <TemplateItem itemInfo={templateDetails[0]}/>
          </div>
          <div className="rightArea">
            <div className="section1">
              <TemplateItem itemInfo={templateDetails[1]}/>
              <TemplateItem itemInfo={templateDetails[2]}/>
            </div>
            <div className="section2">
              <TemplateItem itemInfo={templateDetails[3]}/>
              <TemplateItem itemInfo={templateDetails[4]}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TemplateB;