import React, {Component} from "react";
import "./HomeNavigation.less";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { getDataFromServer } from '../../httpRequest/Api.js';
import homeAction from '../../actions/homeAction';
import loginAction from "../../actions/loginAction.js";

import navLogo from "../../images/logo.png"
import navShopCart from "../../images/head_ic_cart.png"
import searchBtn from "../../images/ic_search.png";
import DELWHITE from "../../images/ic_del_zuijinsousuo.png";
import DELRED from "../../images/ic_del_zuijinsousuo_hover.png";
import Small from "../../images/logo_small.png";

const { setCartNum } = homeAction;
const {toggleLogin} = loginAction;

@connect(({loginReducer,homeReducer}) => ({
    userLoginInfo: loginReducer.userLoginInfo,
    systemData: homeReducer.systemData,
    cartNum: homeReducer.cartNum
  }),{setCartNum,toggleLogin}
)

class HomeNavigation extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      searchHistoryList:[],//搜索记录
      searchLenovoList:[],//搜索联想
      isInHistoryBox:false,//是否在记录Box中
      isFocus:false,//是否获得焦点
      isLenovo:true,//是显示搜索联想还是记录
      tagTitles:[],
      userHotKeyWords:[],
      name:"",
    }

    this.getHotSearchWord = this.getHotSearchWord.bind(this);
    // this.getlenovoGoodList = this.getlenovoGoodList.bind(this);
    this.productSearch = this.productSearch.bind(this);
    this.historySearch = this.historySearch.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount = () => {
    //取出上次的记录
    let json = localStorage.getItem("searchHistory");
    let arr = [];
    if(json){
      arr = JSON.parse(json);
    }
    this.setState({
      searchHistoryList:arr
    })

    if (this.props.userLoginInfo) {
      this.getCartNum();
    }

    if(this.props.location.pathname.indexOf("name")!=-1){
      let name  = this.props.location.pathname.split("=")[1];
      this.setState({
        name:name,
      })
    }

    this.getHotSearchWord();
    // this.getkeyword(math)

    // //监控屏幕滚动距离
    // let lastScrollTop = 0;
    // window.addEventListener('scroll',() =>{
    //   let scrollTop = document.documentElement.scrollTop || window.pageYOffset;
    //   //判断是否是首页
    //   if (lastScrollTop >= 30 && scrollTop < 30) {
    //     this.setState({
    //       positionType:{position:"absolute",}
    //     })
    //   }else if (lastScrollTop < 30 && scrollTop >= 30){
    //     this.setState({
    //       positionType:{position:"fixed",top:"0px"}
    //     })
    //   }
    //   lastScrollTop = scrollTop;
    // })
  }

  //获取购物车数量
  async getCartNum(){
    let loginInfo = {
      url:`${baseUrl}/user/shopping/total?userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}&appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
      this.props.setCartNum(data.data.total);
    }
  }

  //点击搜索
  productSearch(){
    let { searchHistoryList,name } = this.state;
    if(name){
      searchHistoryList.unshift(name);
      //去重
      let newArr = [];
      for (let i = 0; i < searchHistoryList.length; i++) {
        if(newArr.indexOf(searchHistoryList[i]) == -1){
          newArr.push(searchHistoryList[i])
        }
      }
      let json = JSON.stringify(newArr);
      localStorage.setItem("searchHistory",json);
      this.setState({
        searchHistoryList:newArr
      })
      this.setState({
        name:name,
       });
    }
  }

  //获得焦点展示记录
  showHistory = () => {
    this.setState({
      isFocus:true
    })
  }

  //失去焦点隐藏记录
  hideHistory = () => {
    this.setState({
      isFocus:false
    })
  }

  //进入记录盒子
  mouseEnterBox = (e) =>{
    this.setState({
      isInHistoryBox:true,
    })
  }

  //鼠标离开记录盒子
  mouseLeaveBox = () =>{
    this.setState({
      isInHistoryBox:false,
    })
  }

  //删除单个记录
  delOne = (index) => {
    let { searchHistoryList } = this.state;
    searchHistoryList.splice(index,1);
    this.setState({
      searchHistoryList
    })
    let json = JSON.stringify(searchHistoryList);
    localStorage.setItem("searchHistory",json);
  }

  //删除所有记录
  delAll = () => {
    localStorage.setItem("searchHistory",[]);
    this.setState({
      searchHistoryList:[]
    })
  }

  //回车搜索
  search =(event) =>{
    if(event.keyCode === 13){
      this.props.history.push({pathname:"/home/search/" + "name=" + event.target.value});
      this.setState({
        name:event.target.value,
      });
    }
  }

  //点击记录搜索
 historySearch = (name) =>{
    this.setState({
      name:name,
    });
  }

  //搜索联想
  inputChange = (event) =>{
    if(event.target.value.length){
      this.getlenovoGoodList(event.target.value);
      this.setState({
        isLenovo:true,
        name:event.target.value
      })
    }else{
      let json = localStorage.getItem("searchHistory");
      let arr = [];
      if(json){
        arr = JSON.parse(json);
      }
      this.setState({
        isLenovo:false,
        searchHistoryList:arr,
        name:event.target.value
      })
    }
  }

  //请求热门搜索关键词
  async getHotSearchWord (){
    let loginInfo = {
      url:baseUrl + "/hotkeyword/applist?appkey=" + appkey + "&pageNo=1",
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      this.setState({
        tagTitles:data.data.hotKeyWords,
        userHotKeyWords:data.data.userHotKeyWords
      })
    }
  }

  //获取搜索联想的数据
  async getlenovoGoodList (prefix){
    let loginInfo = {
      url:baseUrl + "/goodinfo/suggestindex?prefix=" + prefix + "&appkey=" + appkey + "&appTab=2" ,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        searchLenovoList:data.data.suggests
      })
    }
  }

  //获取自动填充搜索关键词
  async getkeyword (pageNo){
    let loginInfo = {
      url:baseUrl + "/keyword/applist?pageNo=" + pageNo + "&appkey=" + appkey + "&appTab=2" ,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result == 0) {
      this.setState({
        searchLenovoList:data.data.suggests
      })
    }
  }

  //热门搜索
  NavTagItem = (item,index) =>{
    if(item.promotionId){
      if(item.promotionId == -1){
        return(
          <div className="activity_tag" key={item.id}>
            {item.tag && <div className="red_tag">{item.tag}</div>}
            <Link to={'/home/search/' + "name=" + item.keyword } key={item.id} ><div className="red_title_tag" onClick={this.historySearch.bind(this,item.keyword)}>{item.keyword}</div></Link>
          </div>
        );
      }else{
        return(
          <Link to={'/home/search/' + "promotionId=" + item.promotionId } key={item.id} >
            <div className="activity_tag" key={item.id}>
              <div className="red_tag">{item.tag}</div>
              <div className="red_title_tag" onClick={this.historySearch.bind(this,item.keyword)}>{item.keyword}</div>
            </div>
          </Link>
        );
      }
    }else{
      return(
        <Link to={'/home/search/' + "name=" + item } key={index} ><div className="ordinary_tag" onClick={this.historySearch.bind(this,item)}>{item}</div></Link>
      );
    }
  }

  //跳转到购物车
  toCart = () => {
    if (this.props.userLoginInfo) {
      const w=window.open('about:blank');
      w.location.href = `/home/buycart`;
    }else{
      this.props.toggleLogin();
    }
  }

  render(){
    let { tagTitles, isInHistoryBox ,isFocus ,isLenovo ,searchHistoryList ,searchLenovoList ,name ,userHotKeyWords } = this.state;
    let searchList = [];
    if(isLenovo){
      searchList = searchLenovoList;
    }else{
      searchList = searchHistoryList;
    }
    return(
      <div className="nav_head" style={this.state.positionType}>
        <div className="inner">
          <Link to="/home"><img src={navLogo} className="nav_logo" alt="" /></Link>
          <div className="nav_mid_inner">
            <div className="search_back_view">
              <img className="search_img" src={searchBtn}/>
              <input className="search_input" ref="searchInput" placeholder="請輸入搜索關鍵字" value={this.state.name} onFocus={this.showHistory} onBlur={this.hideHistory} onChange={this.inputChange} onKeyDown={this.search}></input>
              <Link to={'/home/search/' + "name=" + name } ><div className="search_btn" onClick={this.productSearch}>搜索</div></Link>
            </div>
            <div className="tag_back_view">
              {
                tagTitles.map(item =>{
                  return this.NavTagItem(item)
                })
              }
              {
                userHotKeyWords.map((item,index) =>{
                  return this.NavTagItem(item,index)
                })
              }
            </div>
            {isInHistoryBox || isFocus?
            <div className="searchLenovoBox" onMouseEnter={this.mouseEnterBox} onMouseLeave={this.mouseLeaveBox} style={searchList.length?{}:{display:"none"}}>
              <div className="recentSearch" style={isLenovo?{display:"none"}:{}}>
                <span className="recent">最近搜索</span>
                <span className="delIcon" onClick={this.delAll}><img src={DELWHITE} className="white" alt=""/><img src={DELRED} className="red" alt=""/></span>
              </div>
              <ul className="searchRecord">
              {
                searchList.map((item,index) => {
                return(
                  <li className="searchText" key={index}>
                    <Link to={'/home/search/' + "name=" + item }><span className="text" onClick={this.historySearch.bind(this,item)}>{item}</span></Link><span className="delete" onClick={this.delOne.bind(this,index)} style={isLenovo?{display:"none"}:{}}>删除</span>
                  </li>
                )})
              }
              </ul>
            </div>
            :""}
          </div>
          <div className="nav_right_inner" onClick={this.toCart}>
            <img className="shop_cart_img" src={navShopCart}/>
            <div className="shop_cart_title">購物車</div>
            {this.props.cartNum > 0 && <div className="shop_cart_num">{this.props.cartNum}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default HomeNavigation;
