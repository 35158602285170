import React, {Component} from "react";
import { Statistic } from 'antd';
import "./InputItem.less";
import close from "../../images/ic_delete.png";
import closeEye from "../../images/ic_eye_do.png";
import openEye from "../../images/ic_eye_open.png";
const { Countdown } = Statistic;
import { getDataFromServer } from '../../httpRequest/Api.js';

class InputItem extends Component {
  constructor(props){
    super(props);
    this.state=({
      value: this.props.value ? this.props.value : "",
      isCodeStart: false,
      deadline:0,
      isOpenEye: false
    });
    this.getCode = this.getCode.bind(this);
    this.codeCallBack = this.codeCallBack.bind(this);
  }
  
  //清空输入框
  clearText = () => {
    if (this.props.needPrewview) {
      this.setState({
        isOpenEye: !this.state.isOpenEye
      })
    }else{
      let data = {};
      data.keyword = this.props.keyword;
      data.value =  "";
      this.props.getValue(data);
      this.setState({
        value: ""
      });
    }
  }

  //倒计时结束
  onFinish = () => {
    this.setState({
      isCodeStart: false
    })
  }

  //改变输入框的值
  changeValue = (event) =>{
    let data = {};
    data.keyword = this.props.keyword;
    data.value =  event.target.value;
    this.props.getValue(data);
    this.setState({
      value: event.target.value
    });
  }

  //获取验证码
  getCode() {
    let codeCallBack = this.codeCallBack;
    if (!this.state.isCodeStart) {
      let key = Object.keys(this.props.phone)[0];
      let phone = this.props.phone[key];
      if (phone.length < 11) {
        this.props.errorTip(phone ? "请输入正确的手机号" : "请输入手机号码",Object.keys(this.props.phone)[0]);
      }else{
        let captcha1 = new TencentCaptcha('2047743953', function(res) {codeCallBack(res)});
        captcha1.show(); // 显示验证码
      }
    }
  }

  async codeCallBack(res){
    let key = Object.keys(this.props.phone)[0];
    let phone = this.props.phone[key];
    // res（用户主动关闭验证码）= {ret: 2, ticket: null}
    // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
    if(res.ret === 0){
      let reqUrl = `${baseUrl}/code?phoneNum=${phone}&type=${this.props.codeType}&appkey=${appkey}&ticket=${res.ticket}&randStr=${res.randstr}`;
      let loginInfo = {
        url: reqUrl,
        method: 'POST'
      }
      let response = await getDataFromServer(loginInfo);
      if (response.data.result === 0) {
        let deadline = Date.now() + 1000 * 60;
        this.setState({
          isCodeStart: true,
          deadline
        })
      }else{
        this.props.errorTip(response.data.msg);
      }
    }
  }

  render() {
    let eye = this.state.isOpenEye ? openEye : closeEye;
    let type = this.state.isOpenEye ? "text" : this.props.type;
    return (
      <div className={this.props.isErr ? "InputItem err" : "InputItem"} style={this.props.style}>
        <img className="icon" src={this.props.icon} alt=""/>
        <input value={this.state.value} className="input" maxLength={this.props.maxLength} autoComplete="new-password" type={type} placeholder={this.props.holderText} onChange={this.changeValue}/>
        {
          this.props.isCode &&
          <div className="getCode" onClick={this.getCode}>
            {this.state.isCodeStart ? <Countdown value={this.state.deadline} onFinish={this.onFinish} format="s 秒后重新获取"/> : "获取验证码"}
          </div>
        }
        {
          this.state.value && !this.props.isCode && <img className="clear" onClick={this.clearText} src={this.props.needPrewview ? eye : close} alt=""/>
        }
      </div>
    );
  }
}

export default InputItem;