import React,{Component} from "react";
import { Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import "./UserCenter.less";
import UserOrder from "../../components/UserOrder/UserOrder";
import UserCenterCoupon from "../../components/UserCenterCoupon/UserCenterCoupon.js";
import MyRebateValue from "../../components/MyRebateValue/MyRebateValue.js";
import MyRebateCard from "../../components/MyRebateCard/MyRebateCard.js";
import UserManager from "../../components/UserManager/UserManager.js";
import RealName from "../../components/RealName/RealName.js";
import AddressManager from "../../components/AddressManager/AddressManager.js";
import Mycollection from "../../components/MyCollection/MyCollection.js";
import MyFoot from "../../components/MyFoot/MyFoot.js";
import Sidebar from "../../components/Sidebar/Sidebar";
import UserCenterBottomGoods from "../../components/UserCenterBottomGoods/UserCenterBottomGoods.js";
import ZouMaDeng from "../../components/ZouMaDeng/ZouMaDeng";
import ProductItemRecormend from "../../components/ProductItemRecormend/ProductItemRecormend";
import { getDataFromServer } from '../../httpRequest/Api.js';

@connect(
  state => state.loginReducer
)

class UserCenter extends Component{
  constructor(props){
    super(props);
    let selectSection = 0;
    let selectRow = 0;
    let type = this.props.match.params.type;
    if(type == "UserOrder"){
      selectSection = 0;
      selectRow = 0;
    }else if(type == "rebatevalue"){
      selectSection = 0;
      selectRow = 1;
    }else if(type == "coupon"){
      selectSection = 0;
      selectRow = 2;
    }else if(type == "rebatecard"){
      selectSection = 0;
      selectRow = 3;
    }else if(type == "usermanager"){
      selectSection = 1;
      selectRow = 0;
    }else if(type == "realname"){
      selectSection = 1;
      selectRow = 1;
    }else if(type == "address"){
      selectSection = 1;
      selectRow = 2;
    }else if(type == "mycollection"){
      selectSection = 2;
      selectRow = 0;
    }else if(type == "myfoot"){
      selectSection = 2;
      selectRow = 1;
    }
    
    this.state = {
      selectSection:selectSection,
      selectRow:selectRow,
      recommend:[],
      leftTtiles:{
        bigTitle:"个人中心",
        smallTitles:[
          [{name:"我的订单",view:UserOrder},
           {name:"我的余额",view:MyRebateValue},
           {name:"优惠券",view:UserCenterCoupon},
           {name:"我的折扣卡",view:MyRebateCard}],
          [{name:"账号管理",view:UserManager},
           {name:"实名认证",view:RealName},
           {name:"收货地址",view:AddressManager}],
          [{name:"我的收藏",view:Mycollection},
           {name:"我的足迹",view:MyFoot}]
        ]},
    };
    this.getRecommend = this.getRecommend.bind(this);
  }

  componentDidMount(){
    this.getRecommend();
  }

  //获取推荐商品
  async getRecommend(goodsId){
    let url = `${baseUrl}/order/shoppingCart/Recommend?appkey=${appkey}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        recommend: data.data.goodInfoList
      })
    }
  }

  smalleTitleClick = (section,row) =>{
    this.setState({
      selectSection:section,
      selectRow:row,
    });
  }

  render(){
    if(!this.props.userLoginInfo){
      return (
        <Redirect to="/login/user/loginCode"/>
      )
    }
    let leftTtiles = this.state.leftTtiles;
    let section = this.state.selectSection;
    let row = this.state.selectRow;

    return(
      <div className="user_center_super_bg">
        <div className="inner">
        <Sidebar isHomePage={false} />
          <div className="user_center_top_bg">
            <div className="user_center_left_bg">
              <div className="user_center_left_big_title">{leftTtiles.bigTitle}</div>
              {
                leftTtiles.smallTitles.map((item,index) =>{
                  return(
                    <div className="user_center_section" key={index}>
                    {
                      item.map((smallTitle,i) =>{
                        return(
                          <div className="user_center_left_item_bg" key={i}>
                            <div className={(section == index && row == i) ? "user_center_left_rectangle user_c_rec_display_block" : "user_center_left_rectangle user_c_rec_display_none"}></div>
                            <div className={(section == index && row == i) ? "user_c_small_title user_center_red_color" : "user_c_small_title user_center_nomal_color"} onClick={this.smalleTitleClick.bind(this,index,i)}>{smallTitle.name}</div>
                          </div>
                        )
                      })
                    }
                    </div>
                  )
                })
              }
            
            </div>  
            {
              leftTtiles.smallTitles.map((item,index) =>{
                return(
                  <div className={section == index ? "user_center_right_bg" : "display_none"} key={index}>
                    {
                      item.map((smallTitle,i) =>{
                        return(
                          <div key={i}>
                          {
                            (row == i) && <smallTitle.view  type={this.props.match.params} />
                          }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
          <div className="recormendBox">
            <div className="recormendTitle itemTitle">热门推荐</div>
            <ZouMaDeng list={this.state.recommend} Item={ProductItemRecormend}/>
          </div>
        </div>
      </div>
    );
  }
}

export default UserCenter;