import React , {Component} from 'react';
import './ApplyForRefundBox.less';
import { getDataFromServer } from '../../httpRequest/Api.js';
import { message } from 'antd';
const closeIcon = require("../../images/pop_usercenter_close_nor.png");

class ApplyForRefundBox extends Component{
  constructor(props){
    super(props);
    this.state=({
      reasonId:"",
    });
  }
  componentDidMount(){

  }
  
  //退款理由改变
  reasonChange = (event) =>{
    this.setState({
      reasonId:event.target.value
    })
  }

  //确定申请退货
  sure = () =>{
    let { orderId, userId,overSeaReason } = this.props;
    let reason = "";
    overSeaReason.forEach(item => {
      if(item.id == this.state.reasonId){
        reason = item.reason;
      }
    });
    if(this.state.reasonId){
      this.applyForRefund(this.state.reasonId,userId,orderId,reason);
    }
  }

  //申请退款
  async applyForRefund(reasonId,userId,orderId,reason){
    let loginInfo = {
      url:baseUrl + "/refund/applyrefund?appkey=" + appkey + "&userId=" + userId + "&orderId=" + orderId + "&reasonId=" + reasonId + "&reason=" + reason,
      method: 'POST',
      data: {}
    }
    let data = await getDataFromServer(loginInfo);
    if(data.data.result === 0){
      message.success(<span className="msgText">{data.data.msg}</span>);
      if(this.props.refreshPage){
        this.props.refreshPage();
      }
    }else{
      message.error(<span className="msgText">{data.data.msg}</span>);
    }
    this.ApplyForRefundBox.style.display = "none";
  }

  //关闭弹框
  closeBox = () =>{
    this.ApplyForRefundBox.style.display = "none";
  }
  render(){
    return(
      <div className="ApplyForRefundBox" ref={r => this.ApplyForRefundBox = r}>
        <div className="sureAndCancelInner">
          <div className="title">
            <span className="boxName">申请退款</span>
            <img src={closeIcon} alt="" className="closeBtn" onClick={this.closeBox}/>
          </div>
          <div className="section">
            <div className="reason">
              <span className="red">*</span>退款原因: 
              <select className="select" value={this.state.reasonId} onChange={this.reasonChange}>
                <option value="" className="first">请选择退款原因</option>
                {this.props.overSeaReason && this.props.overSeaReason.map(item =>{
                  return(<option value={item.id} key={item.id} text={item.reason}>{item.reason}</option>)
                })}
              </select>
            </div>
            <div className="alertText">
               <p>温馨提示：</p>
               <span>1.申请退款提交审核过后，订单将会变为取消状态，订单取消成功后，已支付的金额会在1-3个工作日自动退回原支付账号；</span><br/>
               <span>2.订单中使用的优惠券和账户余额会在订单取消成功后退回到您港颜账号中；</span><br/>
               <span>3.因商品已分拣等原因可能会导致取消失败。</span>
            </div>
            <div className="opBtn">
              <span className="sure" onClick={this.sure}>确定</span>
              <span className="cancel" onClick={this.closeBox}>取消</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ApplyForRefundBox;