import React , {Component} from 'react';
import './ProgressBar.less';
const doing = require("../../images/ic_uccess_big.png");
const nodoing = require("../../images/ic_unsuccess_big .png");

export default class ProgressBar extends Component{
  constructor(props){
    super(props);
    this.state=({

    });
  }
  componentDidMount(){
    
  }

  render(){
    return(
      <div className="progressBar">
        <ul>
          {
            this.props.titles.map((item,i) =>{
              let nowTitle = parseInt(this.props.nowTitle);
              let leftImg = "";
              let leftLine = "";
              if(this.props.statusNum == 5){
                 leftImg = 146*i + "px";
                 leftLine = 146*i + 17 + "px";
              }else{
                leftImg = 460*i + "px";
                leftLine = 460*i + 17 + "px";
              }
              
              let styleLine = {};
              let styleImg = {"left":leftImg};
              let styleText = {"left":leftLine};
              if ( i == (this.props.titles.length - 1)) {
                styleLine = {"left":styleLine,"display":"none"}
              }else{
                styleLine = {"left":leftLine}
              }
              if ( i < nowTitle ) {
                return (
                  <li key={i}>
                    <div className="text" style={styleText}>{item}</div>
                    <div className="imgBox">
                      <img src={doing} alt="" className="sel" style={styleImg}/>
                      <div className={this.props.statusNum == 5?"line":"line longline"} style={styleLine}></div>
                    </div>
                  </li>
                )
              }else{
                return (
                  <li key={i}>
                    <div className="text textNor" style={styleText}>{item}</div>
                    <div className="imgBox">
                      <img src={nodoing} alt="" className="sel" style={styleImg}/>
                      <div className={this.props.statusNum == 5?"line lineNor":"line longline lineNor"} style={styleLine}></div>
                    </div>
                  </li>
                )
              }
            })
          }
        </ul>
      </div>
    );
  }
}
