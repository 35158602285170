import axios from "axios";
import * as actionTypes from "./actiontypes.js";

const homeAction = {
	//banner,导航模板和限时抢购数据
	bannerInfo: (bannerList,limitBuyData,homeNavigation,goodsOn) => ({
    type: actionTypes.BANNER_INFO,
    bannerList,
    limitBuyData,
	  homeNavigation,
	  goodsOn
	}),
	//系统配置全局
	setSystemData: (systemData) => ({
		type: actionTypes.SYSTEM_SET,
		systemData
	}),
	//购物车数量
	setCartNum: (cartNum) => ({
		type: actionTypes.CART_NUM_SET,
		cartNum
	}),
	//新人礼包开关
	setGiftToggle: (giftToggle) => ({
		type: actionTypes.TOGGLE_GIFT,
		giftToggle
	}),
	//新人礼包是否被领取
	setHasGetCoupon: (hasGetCoupon) => ({
		type: actionTypes.HAS_GET_COUPON,
		hasGetCoupon
	})
}

export default homeAction;
