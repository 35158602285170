import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {connect} from 'react-redux';
import { Tooltip,message,Statistic,Rate } from 'antd';
import Clipboard from 'clipboard';
import "./GoodsDetail.less";
import Sidebar from "../../components/Sidebar/Sidebar";
import CouponItemSmall from "../../components/CouponItemSmall/CouponItemSmall";
import CommonTab from '../../components/CommonTab/CommonTab';
import ProductItemRecormend from "../../components/ProductItemRecormend/ProductItemRecormend";
import ZouMaDeng from "../../components/ZouMaDeng/ZouMaDeng";
import Pager from "../../components/Pager/Pager";

import ic_arrow_left_disabled from "../../images/ic_arrowe_left_disable.png";
import ic_arrow_left from "../../images/ic_arrowe_left.png";
import ic_arrow_right_disable from "../../images/ic_arrowe_right_disable.png";
import ic_arrow_right from "../../images/ic_arrowe_right.png";
import selected from "../../images/ic_selected_triangle.png";
import cart from "../../images/cart_detail.png";
import collectImg from "../../images/ic_star.png";
import collect_nor from "../../images/ic_star_yishoucang.png";
import close from "../../images/ic_delete.png";
import moreDown from "../../images/ic_more_down.png";
import moreUp from "../../images/ic_more_up.png";
import infoIcon from "../../images/ic_Info_blue.png";
import zhuanfaIcon from "../../images/ic_zhuanfa.png";
import clock from "../../images/ic_cloke.png";
import shandian_del from "../../images/ic_shandian_del.png";
import shandian from "../../images/ic_shandian.png";
import qiangguang from "../../images/tag_yiqiangguang_goods.png";
import ic_notice_blank from "../../images/ic_notice_blank.png";

import { getDataFromServer } from '../../httpRequest/Api.js';
import homeAction from '../../actions/homeAction';
import loginAction from "../../actions/loginAction.js";
import Uitil from '../../utils/Uitil.js';
let QRCode = require('qrcode.react');
let clipboard = null;
const { Countdown } = Statistic;
const { setCartNum } = homeAction;
const { toggleLogin } = loginAction;

@connect(
  ({loginReducer,homeReducer}) => ({
    userLoginInfo: loginReducer.userLoginInfo,
    systemData: homeReducer.systemData
  }),{setCartNum,toggleLogin}
)

class GoodsDetail extends Component {
  constructor(props){
    super(props)
    this.state={
      goodsId: props.match.params.id,
      goodsData: null,
      actImgIndex: 0,  //展示第几张图片
      goodsNum: 1,
      allCouponToggle: false,
      allCouponList: [],
      recommend: [],
      tabList:[
        {tabName:"商品详情",type:"detail",normalNum:0,bubbleNum:0,ciq:""},
        {tabName:"用户评价",type:"comment",normalNum:0,bubbleNum:0,ciq:""}],
      actType: "detail",
      lookData: [],
      commentlist: [],
      nowtime: 0,
      shareFanliData: {},
      collect: false, //是否收藏
      shareFanliOpen: false, //返利开关
      pageNo: 1,   //评论页码
      pageCount: 0,   //评论页数
      pageSize: 20,  //评论每页条数
      goodCount: 0   //好评数
    }
    this.getGoodsDetail = this.getGoodsDetail.bind(this);
    this.allCoupon = this.allCoupon.bind(this);
    this.getFooter = this.getFooter.bind(this);
    this.getFanliData = this.getFanliData.bind(this);
    this.addCart = this.addCart.bind(this);
    this.kaiqiangTip = this.kaiqiangTip.bind(this);
    this.getUserCollect = this.getUserCollect.bind(this);
    this.collectGoods = this.collectGoods.bind(this);
    this.buyNow = this.buyNow.bind(this);
    this.tabClick = this.tabClick.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  componentDidMount(){
    this.getGoodsDetail(this.state.goodsId);
    this.getRecommend(this.state.goodsId);
    this.getFanliData();
    this.getComments();
    message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }

  //判断用户有没有收藏
  async getUserCollect(proId) {
    let url = `${baseUrl}/goodcollect/item?appkey=${appkey}&goodinfoId=${proId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      let collect = false;
      if (data.data.goodCollect) {
        collect = true
      }
      this.setState({
        collect
      })
    }
  }

  //获取评论数
  async getPage(pageN){
    let {pageNo,pageSize,goodsId} = this.state;
    if (pageNo != pageN ) {
      let url = `${baseUrl}/v1.8/comment/goodlist?appkey=${appkey}&pageNo=${pageN}&pageSize=${pageSize}&level=&goodinfoId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
      let goodsInfo = {
        url,
        method: 'GET'
      }
      let data = await getDataFromServer(goodsInfo);
      if (data.data.result === 0) {
        this.setState({
          pageNo: pageN,
          commentlist: data.data.commentlist,
          goodCount: (data.data.goodCount + data.data.middleCount),
          pageCount: data.data.pageCount
        })
      }
    }
  }

  //查询商品详情
  async getGoodsDetail(id){
    let url = `${baseUrl}/goodinfo/appdetail?appkey=${appkey}&id=${id}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
    let goodsInfo = {
      url,
      method: 'GET',
      data: {}
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        goodsData: data.data,
        nowtime: data.data.nowtime
      },() => {
        if (this.props.userLoginInfo) {
          this.getUserCollect(this.state.goodsId);
        }
      })
      if (this.props.userLoginInfo) {
        //添加足迹
        let footprint = {
          url:`${baseUrl}/V1.6/footprint?appkey=${appkey}&goodsId=${id}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`,
          method: 'POST'
        }
        await getDataFromServer(footprint);
      }
    }
  }

  //查询返利分享配置
  async getFanliData(){
    let url = "";
    if (this.props.userLoginInfo) {
      url = `${baseUrl}/v1.0/rebate/share/before?appkey=${appkey}&userId=${this.props.userLoginInfo.id}`;
    }else{
      url = `${baseUrl}/v1.0/rebate/share/before?appkey=${appkey}`;
    }
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        shareFanliData: data.data.data
      })
    }
  }

  //获取足迹
  async getFooter(){
    if (this.props.userLoginInfo) {
      let url = `${baseUrl}/V1.6/footprint?appkey=${appkey}&pageNo=1&pageSize=6&userId=${this.props.userLoginInfo.id}`;
      let goodsInfo = {
        url,
        method: 'GET'
      }
      let data = await getDataFromServer(goodsInfo);
      if (data.data.result === 0) {
        let newArr = [];
        for (var i = 0; i < data.data.data.length; i++) {
          newArr = newArr.concat(data.data.data[i].goodsList);
        }
        this.setState({
          lookData: newArr
        })
      }
    }else{
      this.setState({
        lookData: this.state.recommend
      })
    }
  }

  //切换查看图片
  changeImg = (actImgIndex) => {
    if (actImgIndex === this.state.actImgIndex) {
      return;
    }
    this.setState({
      actImgIndex
    })
  }

  //改变数量
  changeNum = (e) => {
    let value = Uitil.intRE(e.target.value);
    this.setState({
      goodsNum: value
    })
  }

  //编辑数量
  numEdit = (type) => {
    let {goodsNum} = this.state;
    if (type === "add") {
      goodsNum++;
    }else if (type === "dec"){
      goodsNum--;
    }
    if (parseInt(goodsNum) < 1 || parseInt(goodsNum) > 9999) {
      return;
    }
    this.setState({
      goodsNum
    })
  }

  //改变商品规格
  changeTag = (goodsId) =>{
    if (goodsId == this.state.goodsId) {
      return;
    }
    this.setState({
      goodsId
    },() => {
      this.getGoodsDetail(this.state.goodsId);
    })
  }
  

  //查看全部优惠券
  async allCoupon(){
    let {allCouponToggle,goodsId} = this.state;
    if (this.props.userLoginInfo) {
      if (!allCouponToggle) {
        let url = `${baseUrl}/coupon/promotioncenter?appkey=${appkey}&goodinfoId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let goodsInfo = {
          url,
          method: 'GET',
          data: {}
        }
        let data = await getDataFromServer(goodsInfo);
        if (data.data.result === 0) {
          this.setState({
            allCouponToggle: !allCouponToggle,
            allCouponList: data.data.data
          })
        }
      }else{
        this.setState({
          allCouponToggle: !allCouponToggle
        })
      }
    }else{
      this.props.toggleLogin();
    }
  }

  //获取推荐商品
  async getRecommend(goodsId){
    let url = `${baseUrl}/goodinfo/apprecommend/page?appkey=${appkey}&goodInfoId=${goodsId}&pageNo=1&pageSize=24`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        recommend: data.data.goodInfoList
      },() => {
        this.getFooter();
      })
    }
  }

  //切换详情和评论
  async tabClick(actType){
    this.setState({
      actType
    })
  }

  //获取评论数
  async getComments(){
    let {pageNo,pageSize,goodsId,tabList} = this.state;
    let url = `${baseUrl}/v1.8/comment/goodlist?appkey=${appkey}&pageNo=${pageNo}&pageSize=${pageSize}&level=&goodinfoId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      tabList[1].normalNum = data.data.records;
      this.setState({
        commentlist: data.data.commentlist,
        goodCount: (data.data.goodCount + data.data.middleCount),
        pageCount: data.data.pageCount,
        tabList
      })
    }
  }

  //限时抢购状态解析
  limitBuy = (salesFeatures) => {
    let {nowtime} = this.state;
    let status = "";  //start已经开始，还没结束  noStart还没开始    noLimit非限时抢购或者没有抢购库存
    let deadline = 0;
    if (salesFeatures.beginTime < nowtime && nowtime < salesFeatures.endTime) {
      status = salesFeatures.totalStock > 0 ? "start" : "noLimit";
      deadline = salesFeatures.totalStock > 0 ? salesFeatures.endTime : 0;
    }else if(nowtime < salesFeatures.beginTime){
      status = "noStart";
      deadline = salesFeatures.beginTime;
    }else if(nowtime > salesFeatures.endTime){
      status = "noLimit";
    }
    return {status,deadline};
  }

  //添加购物车
  async addCart(stock){
    if (stock <= 0) {
      message.error(<span className="msgText">对不起，该商品已抢光</span>);
    }else{
      if (this.props.userLoginInfo) {
        let {goodsNum,goodsId} = this.state;
        let url = `${baseUrl}/v1.0/b2c/user/shopping?appkey=${appkey}&goodsId=${goodsId}&quantity=${goodsNum}&promotionId=0&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let goodsInfo = {
          url,
          method: 'PUT'
        }
        let data = await getDataFromServer(goodsInfo);
        this.props.setCartNum(data.data.total);
        if (data.data.result === 0) {
          message.success(<span className="msgText">添加成功</span>);
        }else if (data.data.result === 3) {
          message.error(<span className="msgText">库存不足</span>);
        }else if (data.data.result === 4) {
          message.error(<span className="msgText">超过限量{this.props.systemData ? this.props.systemData.overSeaLimitNum : ""}件</span>);
        }else if (data.data.result === 5) {
          message.error(<span className="msgText">对不起，该商品已抢光</span>);
        }else {
          message.error(<span className="msgText">{data.data.msg}</span>);
        }
      }else{
        this.props.toggleLogin();
      }
    }
  }

  //立即购买
  async buyNow(stock){
    if (stock <= 0) {
      message.error(<span className="msgText">对不起，该商品已抢光</span>);
    }else{

    }
    // let {goodsNum,goodsId} = this.state;
    // let url = `${baseUrl}/v1.0/b2c/user/shopping?appkey=${appkey}&goodsId=${goodsId}&quantity=${goodsNum}&promotionId=-1&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
    // let goodsInfo = {
    //   url,
    //   method: 'PUT'
    // }
    // let data = await getDataFromServer(goodsInfo);
    // this.props.setCartNum(data.data.total);
    // if (data.data.result === 0) {
    //   this.props.history.push({ pathname: "/home/submitorder", state: 11008 });
    // }else if (data.data.result === 3) {
    //   message.error(<span className="msgText">库存不足</span>);
    // }else if (data.data.result === 4) {
    //   message.error(<span className="msgText">超过限量{this.props.systemData ? this.props.systemData.overSeaLimitNum : ""}件</span>);
    // }else if (data.data.result === 5) {
    //   message.error(<span className="msgText">对不起，该商品已抢光</span>);
    // }else {
    //   message.error(<span className="msgText">{data.data.msg}</span>);
    // }
  }

  //设置开抢提醒
  async kaiqiangTip(timesId,kaiqiangFlag){
    if (kaiqiangFlag === 2) {
      return;
    }
    if (this.props.userLoginInfo) {
      let {goodsNum,goodsId,goodsData} = this.state;
      let url = `${baseUrl}/openremaindrecord/insert?appkey=${appkey}&timesId=${timesId}&goodsId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
      let goodsInfo = {
        url,
        method: 'PUT'
      }
      let data = await getDataFromServer(goodsInfo);
      if (data.data.result === 0) {
        goodsData.goodinfo.salesFeatures.flag = 2;
        this.setState({
          goodsData
        })
      }
    }else{
      this.props.toggleLogin();
    }
  }

  //收藏商品
  async collectGoods(){
    if (this.props.userLoginInfo) {
      let {collect,goodsId} = this.state;
      if (!collect) {
        let url = `${baseUrl}/goodcollect/add?appkey=${appkey}&goodinfoId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let goodsInfo = {
          url,
          method: 'PUT'
        }
        let data = await getDataFromServer(goodsInfo);
        if (data.data.result === 0) {
          this.setState({
            collect: !collect
          })
        }
      }else{
        let url = `${baseUrl}/goodcollect/cancel?appkey=${appkey}&goodinfoId=${goodsId}&userId=${this.props.userLoginInfo ? this.props.userLoginInfo.id : ""}`;
        let goodsInfo = {
          url,
          method: 'POST'
        }
        let data = await getDataFromServer(goodsInfo);
        if (data.data.result === 0) {
          this.setState({
            collect: !collect
          })
        }
      }
    }else{
      this.props.toggleLogin();
    }
  }

  //分享返利开关
  shareToggle = (status) => {
    if (this.props.userLoginInfo) {
      this.setState({
        shareFanliOpen: status
      },() => {
        if (status) {
          this.getFanliData();
          clipboard = new Clipboard(".copyBtn");
          this.copyText();
        }
      })
    }else{
      this.props.toggleLogin();
    }
  }

  //复制文本
  copyText = () => {
    clipboard.on('success', function(e) {
      message.success(<span className="msgText">复制成功</span>);
    });
    clipboard.on('error', function(e) {
      message.error(<span className="msgText">复制失败</span>);
    });
  }

  render() {
    let {goodsData,actImgIndex,goodsId,goodsNum,allCouponToggle,allCouponList,recommend,lookData,shareFanliData,collect,shareFanliOpen,commentlist,goodCount,pageCount,pageNo} = this.state;
    let {systemData} = this.props;
    let hide = {display:"none"};
    let imgLength = goodsData ? goodsData.goodinfo.goodimages.length - 1 : 0;  //商品图片数量
    let dutyEle = "";
    let ciqGmodel = "";
    let validPeriod = "";
    let skinType = "";
    let efficiency = "";
    let usageMethod = "";
    let brandStory = "";
    let hasPromotion = false;
    let hasCoupon = false;
    let stock = 1;
    let status = "noLimit";  //限时抢购状态
    let deadline = 0;  //限时抢购倒计时时间戳
    let originalPriceText = "";  //原价文本
    let originalPrice = 0;  //原价
    let limitBuyNum = 0;  //限购数量
    let kaiqiangFlag = 0;  //限时抢购提醒标记
    let timesId = 0;  //限时抢购场次ID
    if (goodsData) {
      hasPromotion = goodsData.goodinfo.promotionTags.length ? true : false;
      hasCoupon = goodsData.couponList.length ? true : false;
      if (goodsData.goodinfo.dutyFreeFlag === 0) {
        dutyEle = (
          <span>
            <div className="tagMoney">预估￥{(Math.ceil(goodsData.goodinfo.taxB2CRate * goodsData.goodinfo.priceB2COverseaVAT * 10) / 10).toFixed(1)}，实际税费请以提交订单时为准</div>
            <div className="tagText">
              税费信息
              <Tooltip placement="topLeft" title="根据国家政策规定，跨境订单税费以商品实际交易价格（包括商品售价、运费）计算" arrowPointAtCenter="true">
                <img className="infoIcon" src={infoIcon} alt=""/>
              </Tooltip>
            </div>
          </span>
        )
      }else{
        dutyEle = (
          <span>
            <div className="tag">包税</div>
            <div className="redText">售价已包含税费，无需另付税费</div>
          </span>
        )
      }
      ciqGmodel = goodsData.goodinfo.ciqGmodel;
      stock = goodsData.goodinfo.warestock1;
      validPeriod = goodsData.goodinfo.validPeriod;
      skinType = goodsData.goodinfo.skinType;
      efficiency = goodsData.goodinfo.efficiency;
      usageMethod = goodsData.goodinfo.usageMethod;
      brandStory = goodsData.goodinfo.brandStory;
      status = this.limitBuy(goodsData.goodinfo.salesFeatures).status;
      deadline = this.limitBuy(goodsData.goodinfo.salesFeatures).deadline;
      if (goodsData.goodinfo.salesFeatures.limitBuyFlag === 1) {
        limitBuyNum = goodsData.goodinfo.salesFeatures.limitQty;
      }
      if (goodsData.goodinfo.virtualFlag === 1) {
        stock = goodsData.goodinfo.virtualStock;
      }
      kaiqiangFlag = goodsData.goodinfo.salesFeatures.flag;
      timesId = goodsData.goodinfo.salesFeatures.timesId;
      if (systemData) {
        if (systemData.copywritingEnable !== 1) {
          originalPriceText = systemData.copywritingText;
        }
      }
      if (status !== "noLimit") {
        originalPrice = goodsData.goodinfo.originalPrice;
        if (status === "noStart"){
          originalPriceText = "抢购价";
        }else {
          originalPriceText = "原价";
        }
      }else{
        originalPrice = goodsData.goodinfo.b2cOriginalPrice;
      }
    }
    let {rebateSwitch,regulations,rebatePercent,url} = shareFanliData;

    return (
      <div className="GoodsDetail">
        {
          shareFanliOpen && 
          <div className="shareFanliBg">
            <div className="shareFanLi">
              <div className="shareFanLiTitle">分享返利<img src={close} onClick={this.shareToggle.bind(this,false)} alt=""/></div>
              <div className="content">
                <div className="qcode">
                  {
                    url && <QRCode size={80} bgColor="#fff" value={url} />
                  }
                </div>
                <input type="text" id="shareUrl" readOnly className="shareUrl" value={url}/>
                <div className="copyBtn" onClick={this.copyText} data-clipboard-text={url}>复制链接</div>
                <div className="copyNote">复制链接或者扫码分享给好友折扣卡</div>
                <div className="copyTip">好友下单我得奖励</div>
              </div>
            </div>
          </div>
        }
        <div className="inner">
          <Sidebar isHomePage={ this.props.match.path } />
          <div className="goodsInfoBox">
            <div className="imgBox ">
              <div className="label">
                <img src={goodsData ? goodsData.goodinfo.goodimages[actImgIndex] : ""} alt="" className="imgAct"/>{stock <= 0 && <img src={qiangguang} className="qiangguang" alt=""/>}
              </div>
              {
                goodsData && goodsData.goodinfo.goodsLabels.map((item,index) =>{
                  let zindexStyle = {zIndex:"-" + index};
                  return <div key={index} className="label" style={zindexStyle}><img className="imgAct" src={item.labelImg} alt=""/><span className="labelPrice" style={item.labelType === 1?{display:"none"}:{}}><Statistic prefix="￥" value={item.priceB2COverseaVATTheir}  precision={1} groupSeparator="" /></span></div>
                })
              }
              <div className="imgSmallBox clearfix">
                <div className="prevBtn btn" onClick={actImgIndex === 0 ? null : this.changeImg.bind(this,actImgIndex - 1)}><img src={actImgIndex === 0 ? ic_arrow_left_disabled : ic_arrow_left} alt=""/></div>
                {
                  goodsData ? goodsData.goodinfo.goodimages.map((item,i) =>{
                    return <img src={item} onClick={this.changeImg.bind(this,i)} alt="" className={actImgIndex === i ? "imgSmall active" : "imgSmall"} key={i}/>
                  }) : ""
                }
                <div className="nextBtn btn" onClick={actImgIndex === imgLength ? null : this.changeImg.bind(this,actImgIndex + 1)}><img src={actImgIndex === imgLength ? ic_arrow_right_disable : ic_arrow_right} alt=""/></div>
              </div>
            </div>
            <div className="goodsInfo">
              <div className="countryBox">
                <img src={goodsData ? goodsData.goodinfo.country.logo : ""} alt="" className="countryImg"/>{goodsData ? goodsData.goodinfo.country.cnName : ""}
              </div>
              <div className="title">{goodsData ? goodsData.goodinfo.title : ""}</div>
              {
                status !== "noLimit" &&
                <div className="limitBox">
                  <img src={clock} alt=""/>
                  <span className="miaosha">限时秒杀</span>
                  <div className="daojishiBox">
                    <div className="maohao r">:</div>
                    <div className="maohao l">:</div>
                    <Countdown value={deadline * 1 ? deadline * 1 : 0} onFinish={this.getGoodsDetail.bind(this,goodsId)} valueStyle={{background:"#000",color:"#fff",display:"inline-block",padding: "0 5px 0 5px",height: "32px"}} format={"HH ：mm   ：ss"}/>
                  </div>
                  <div className="huodongTime">{status === "start" ? "距活动结束还剩" : "距开始"}</div>
                </div>
              }
              <div className="priceBox">
                <div className="priceItem">
                  <div className="priceTitle">售价</div>
                  <div className="sysbol">￥</div>
                  <div className="price">{goodsData ? goodsData.goodinfo.price : ""}</div>
                  {
                    (originalPrice > 0 && status !== "noStart") &&
                    <div className="discount">{goodsData ? (goodsData.goodinfo.price * 10 / originalPrice).toFixed(1) : "" }折</div>
                  }
                  {
                    originalPrice > 0 &&
                    <div className={status === "start" ? "originPrice lineThrough" : "originPrice"}>{originalPriceText} ￥{originalPrice}</div>
                  }
                  <div className="sales">已售 {goodsData ? goodsData.goodinfo.sales : ""}</div>
                </div>
                <div className="priceItem">
                  <div className="priceTitle">税费</div>
                  {dutyEle}
                </div>
                {
                  hasPromotion && 
                  <div className="priceItem">
                    <div className="priceTitle">促销</div>
                    {
                      goodsData ? goodsData.goodinfo.promotionTags.map((item,i) => {
                        return (
                          <span key={i}>
                            <div className="tag">{item.saleTagName}</div>
                            <div className="text">{item.tagName} ;</div>
                          </span>
                        )
                      }) : ""
                    }
                  </div>
                }
                {
                  hasCoupon && 
                  <div className="priceItem">
                    <div className="priceTitle">领券</div>
                    {
                      goodsData ? goodsData.couponList.map((item,i) =>{
                        return (
                          i <= 2 && <div className="couponSmall" key={i}><div className="banyuan left"></div>{item.description}<div className="banyuan right"></div></div>
                        )
                      }) : ""
                    }
                    <div className="lookCoupon">
                      <div className="couponNum" onClick={this.allCoupon}>全部{goodsData ? goodsData.couponList.length : 0}张优惠券<img src={allCouponToggle ? moreUp : moreDown} alt=""/></div>
                      {
                        allCouponToggle && 
                        <div className="couponBox">
                          <div className="couponBoxTitle">我的优惠券<img src={close} onClick={this.allCoupon} className="close" alt=""/></div>
                          <div className="couponContent">
                            {
                              allCouponList.map((item,i) =>{
                                return (
                                  <CouponItemSmall key={i} couponInfo={item} updateCoupon={this.getGoodsDetail.bind(this,goodsId)}/>
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
              {
                rebateSwitch === 1 && 
                <div className="shareFanli send">
                  <div className="priceTitle">分享</div>
                  <div className="sendTo">
                    分享可得奖励￥{goodsData ? (Math.ceil(goodsData.goodinfo.price * rebatePercent * 10) / 10).toFixed(1) : 0}
                    <Tooltip placement="topLeft" title={regulations} arrowPointAtCenter="true">
                      <img className="infoIcon" src={infoIcon} alt=""/>
                    </Tooltip>
                  </div>
                  <div className="shareFanliBtn" onClick={this.shareToggle.bind(this,true)}>
                    分享<img className="zhuanfaImg" src={zhuanfaIcon} alt=""/>
                  </div>
                </div>
              }
              <div className="send">
                <div className="priceTitle">配送</div>
                {/*<div className="sendTo">香港上水仓 至 {returnCitySN["cname"]}</div>*/}
                <div className="sendTo">香港新界仓 发货</div>
              </div>
              <div className="send">
                <div className="priceTitle">运费</div>
                <div className="sendTo">支持满{this.props.systemData ? this.props.systemData.freePostage : ""}免邮费</div>
              </div>
              <div className="send">
                <div className="priceTitle">重量</div>
                <div className="sendTo">{goodsData ? goodsData.goodinfo.weight : ""}g</div>
              </div>
              <div className="cartBox">
                <div className="goodsTagBox">
                  <div className="priceTitle">商品</div>
                  {
                    goodsData ? goodsData.goodStyles.map((item,i) =>{
                      return (
                        <div key={i} onClick={this.changeTag.bind(this,item.id)} className={goodsId == item.id ? "tagItem active" : "tagItem"}>{goodsId == item.id && <img className="selectCiq" src={selected} alt=""/>}<img className="ciqImg" src={item.imgpath} alt=""/>{item.ciqGmodel}</div>
                      )
                    }) : ""
                  }
                </div>
                <div className="goodsTagBox">
                  <div className="priceTitle">数量</div>
                  <div className="numControl">
                    <div className={parseInt(goodsNum) <= 1 ? "btn disabled" : "btn"} onClick={this.numEdit.bind(this,"dec")}>-</div>
                    <input type="text" maxLength="4" value={goodsNum} onChange={this.changeNum.bind(this)} className="num"/>
                    <div className={parseInt(goodsNum) >=9999 ? "btn disabled" : "btn"} onClick={this.numEdit.bind(this,"add")}>+</div>
                  </div>
                  {(limitBuyNum > 0 && status !== "noStart") && <div className="limit">该商品每个用户限购{limitBuyNum}件</div>}
                </div>
              </div>
              <div className="buyBox">
                <div style={{display:"none"}} className={stock <= 0 ? "item buyNow disable" : "item buyNow"} onClick={this.buyNow.bind(this,stock)}>立即购买</div>
                <div className={stock <= 0 ? "item buyCart disable" : "item buyCart"} onClick={this.addCart.bind(this,stock)}><img className="cartIcon" src={cart} alt=""/>加入购物车</div>
                {status === "noStart" && <div className={kaiqiangFlag === 1 ? "item kaiqiangTip" : "item kaiqiangTip active"} onClick={this.kaiqiangTip.bind(this,timesId,kaiqiangFlag)}><img className="cartIcon" src={kaiqiangFlag === 1 ? shandian_del : shandian} alt=""/>{kaiqiangFlag === 1 ? "开抢提醒" : "已设提醒"}</div>}
                <div className="item collect" onClick={this.collectGoods}><img className="ccollectIcon" src={collect ? collect_nor : collectImg} alt=""/><br/>{collect ? "已收藏" : "收藏"}</div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="recormendBox">
            <div className="recormendTitle itemTitle">热门推荐</div>
            <ZouMaDeng list={recommend} Item={ProductItemRecormend}/>
          </div>
          <div className="pageFoot">
            <div className="lookBox">
              <div className="lookTitle itemTitle">看了又看</div>
              {
                lookData.map((item,i) => {
                  return <ProductItemRecormend key={i} goodsInfo={item}/>
                })
              }
            </div>
            <div className="detailContent">
              <div className="detailTitle">
                <CommonTab tabList={this.state.tabList} actType={this.state.actType} tabChange={this.tabClick}/>
              </div>
              <div className="clearfix"></div>
              <div className="detail" style={this.state.actType === "detail" ? {} : hide}>
                <div className="detailcontent">
                  <div className="brand">品牌名称：{goodsData ? goodsData.goodinfo.brand.name : ""}</div>
                  {
                    goodsData ? <span className="detailItem">{`产地：${goodsData.goodinfo.country.cnName}`}</span> : ""
                  }
                  {
                    ciqGmodel && <span className="detailItem">{`规格：${ciqGmodel}`}</span>
                  }
                  {
                    validPeriod && <span className="detailItem">{`有效期：${validPeriod}`}</span>
                  }
                  {
                    skinType && <span className="detailItem">{`使用肤质：${skinType}`}</span>
                  }
                  {
                    efficiency && <span className="detailItem">{`功效：${efficiency}`}</span>
                  }
                  {
                    usageMethod && <div className="store">{`使用方法：${usageMethod}`}</div>
                  }
                  {
                    brandStory && <div className="store">{`品牌故事：${brandStory}`}</div>
                  }
                </div>
                <div className="imgs">
                  <img src={this.props.systemData ? this.props.systemData.headPicture : ""} alt=""/>
                  {
                    goodsData ? goodsData.goodinfo.details.map((item,i) => {
                      return <img src={item} key={i} alt=""/>
                    }) : ""
                  }
                  <img src={this.props.systemData ? this.props.systemData.footPicture : ""} alt=""/>
                </div>
              </div>
              <div className="goodsComment" style={this.state.actType === "comment" ? {} : hide}>
                <div style={commentlist.length > 0 ? {} : hide}>
                  <div className="commentsRate" style={hide}>好评率<Statistic value={commentlist.length > 0 ? ((goodCount/commentlist.length)*100).toFixed(1) : 100} suffix="%"/><Rate disabled allowHalf value={(commentlist.length > 0 ? ((goodCount/commentlist.length)*100).toFixed(1) : 100)/100*5} count={5} /></div>
                  <div className="commentBox">
                    {
                      commentlist.map((item,i) => {
                        return (
                          <div className="commentItem" key={i}>
                            <div className="headerImg">
                              <img src={item.image} alt=""/>
                            </div>
                            <div className="middle">
                              <div className="name">{item.nickname}</div>
                              <div className="score"><Rate disabled allowHalf value={item.stars/100*5} count={5} /></div>
                              <div className="commentText">{item.content}</div>
                              <div className="commentImgBox">
                              {
                                item.commentImages.map((imgItem,j) => {
                                  return (
                                    <img key={j} src={imgItem.image} alt="" className="commentImgItem"/>
                                  )
                                })
                              }
                              </div>
                            </div>
                            <div className="time">{item.createTime.split(" ")[0]}</div>
                          </div>
                        )
                      })
                    }
                    <Pager total={pageCount} current={pageNo} getPage={this.getPage}/>
                  </div>
                </div>
                <div className="noComments" style={commentlist.length > 0 ? hide : {}}>
                  <img src={ic_notice_blank} alt=""/>暂时没有用户评价哦~~
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoodsDetail;

