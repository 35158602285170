import React, {Component} from "react";
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import "./PayFinish.less";
import ZouMaDeng from "../../components/ZouMaDeng/ZouMaDeng";
import ProductItemRecormend from "../../components/ProductItemRecormend/ProductItemRecormend";
import ic_uccess_big from "../../images/ic_uccess_big.png";
import hkd_erweima from "../../images/hkd-erweima.png";

import { getDataFromServer } from '../../httpRequest/Api.js';

class PayFinish extends Component {
  constructor(props){
    super(props);
    this.state={
      recommend: []
    }
    this.getRecommend = this.getRecommend.bind(this);
  }

  componentDidMount(){
    this.getRecommend();
  }

  //获取推荐商品
  async getRecommend(goodsId){
    let url = `${baseUrl}/order/apprecommend?appkey=${appkey}&orderId=${this.props.location.state.orderInfo.order.id}`;
    let goodsInfo = {
      url,
      method: 'GET'
    }
    let data = await getDataFromServer(goodsInfo);
    if (data.data.result === 0) {
      this.setState({
        recommend: data.data.goodInfoList
      })
    }
  }

  //跳转到订单购物车
  toCart = () =>{
    this.props.history.push(`/home/buycart`);
  }

  //跳转到订单详情
  toOrderDetail = () =>{
    this.props.history.push(`/home/orderDetail/${this.props.location.state.orderInfo.order.id}`);
  }

  render() {
    return (
      <div className="PayFinish">
        <div className="inner">
          <div className="payResult">
            <img src={ic_uccess_big} alt="" className="payIcon"/>
            <div className="payContent">
              <div className="payResultText">购买成功，我们将尽快为您打包发货！</div>
              <div className="bianhao">订单编号：{this.props.location.state.orderInfo.order.outOrderNo}</div>
              <div className="bianhao">订单金额：￥{this.props.location.state.orderInfo.order.depositRMBPrice}</div>
              <div className="bianhao">支付方式：支付宝</div>
              <div className="btnBox">
                <div className="btn goCart" onClick={this.toCart}>返回购物车</div>
                <div className="btn underLine" onClick={this.toOrderDetail}>查看订单详情</div>
                <div className="btn underLine"><Link to="/home">继续逛逛</Link></div>
              </div>
              <div className="tip">温馨提示：港颜全球购不会以订单异常、系统升级为由，向您所要银行卡、微信、支付宝的密码，亦不会要求您点击任何链接进行付款。</div>
            </div>
            <div className="appCode">
              <img src={hkd_erweima} alt="" className="code"/><br/>
              上传照太麻烦?<br/>
              下载港颜APP，实名认证超方便！
            </div>
          </div>
          <div className="recormendBox">
            <div className="recormendTitle itemTitle">热门推荐</div>
            <ZouMaDeng list={this.state.recommend} Item={ProductItemRecormend}/>
          </div>
        </div>
      </div>
    );
  }
}

export default PayFinish;
