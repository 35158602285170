import React, {Component} from "react";
import "./SubmitOrder.less";
import {connect} from 'react-redux';
import { Tooltip,message,Modal } from 'antd';
import Cookies from 'js-cookie';

import iconInfo from "../../images/ic_Info_blue.png";
import hasSel from "../../images/ic_selected_triangle.png";
import moreDown from "../../images/ic_more_down.png";
import moreUp from "../../images/ic_more_up.png";
import xuanzhong from "../../images/shangpingxuanzhong_big.png";
import quanchang from "../../images/bg_coupon_dingdan.png";
import danpin from "../../images/bg_coupon_dingdan_yellow.png";
import fenlei from "../../images/bg_coupon_dingdan_purple.png";
import pinpai from "../../images/bg_coupon_dingdan_blue.png";
import close from "../../images/ic_delete.png";

import { getDataFromServer } from '../../httpRequest/Api.js';
import Uitil from '../../utils/Uitil.js';
import CommonTab from '../../components/CommonTab/CommonTab';
import loginAction from "../../actions/loginAction.js";
import AddNewAddressPopView from '../../components/AddNewAddressPopView/AddNewAddressPopView';
import DeleteAddressPopView from "../../components/DeleteAddressPopView/DeleteAddressPopView.js";

const {toggleLogin} = loginAction;

@connect(
  state => state,{toggleLogin}
)

class SubmitOrder extends Component {
  constructor(props){
    super(props)
    this.state={
			tabList:[
        {tabName:"优惠券",type:"coupon",normalNum:0,bubbleNum:0,ciq:""},
        {tabName:"余额",type:"lackMoney",normalNum:0,bubbleNum:0,ciq:""},
        {tabName:"折扣卡",type:"discountCard",normalNum:0,bubbleNum:0,ciq:""}
      ],
      actType: "coupon",
      addressList: [],
      orderShoppingCarts: [],
      addressObj: null,
      couponList: [],
      rebateCardList: [],
      useRebateCard: 1,
			useCoupon: 1,
			useRebate: 0,
			rebateCardId: "",
			selectCouponId: "",
			priceData: null,
			count: 0,
			addNew: false,
			buyName: "",
			buyIDCard: "",
			showRealname: false,
			showRealnameTip: false,
			addressId: -1,
      showDelete:false,//删除提示弹窗
			editItem: null
    }
    this.submitOrder = this.submitOrder.bind(this);
    this.editeAddress = this.editeAddress.bind(this);
    this.submitRealname = this.submitRealname.bind(this);
    this.deleteAddress = this.deleteAddress.bind(this);
  }

  componentDidMount(){
  	if(!this.props.loginReducer.userLoginInfo){
			this.props.history.push("/login/user/loginCode");
		}else{
	    this.getAllAdress();
	  	this.getCartGoods();
	  	this.getCouponList();
		}
  	message.config({
      top: 300,
      duration: 1,
      maxCount: 1
    });
  }

	//编辑地址
  edit = (item,e) => {
		this.setState({
      addNew: true,
      addressId: item.id,
      editItem: item
    });
		e.stopPropagation();
  }

  showDeleteView = (deleteIndex,e) =>{
    this.setState({
      showDelete:true,
      deleteIndex:deleteIndex,
    });
    e.stopPropagation();
  }

  hiddenDeleteView = (isDelete) =>{
    this.setState({
      showDelete:false,
    });
    if(isDelete){
      this.deleteAddress(this.state.deleteIndex);
    }
  }

  async deleteAddress(index){
    let address =  this.state.addressList[index];
    let addressId = address.id;
    let userInfo = this.props.loginReducer.userLoginInfo;
    if(userInfo){
      let userId = userInfo.id;
      let reqUrl =  `${baseUrl}/address?appTab=2&appkey=${appkey}&userId=${userId}&id=${addressId}`;
      let method = "DELETE";
      let address = {
        url:reqUrl,
        method 
      }
      let response = await getDataFromServer(address);
      if (response.data.result === 0) {
	    	this.getAllAdress();
	    }
    }
  }

	//新增收货地址
  addNewAction = () =>{    
    this.setState({
      addNew:true,
      addressId:-1,
      editItem: null
    });
  }

	//关闭新增地址
  hiddenPopView = (isSubmit,addressInfo) =>{
    this.setState({
      addNew:false,                                                                                                               
    });
    if(isSubmit){
      this.editeAddress(addressInfo);
    }
  }

  //编辑地址   如果有id就是编辑  没有则是新增
  async editeAddress(addressInfo){
    let {name,phone,province,city,county,addressDesc,isDefault} = addressInfo;
    let {addressId} = this.state;
    let userId = this.props.loginReducer.userLoginInfo.id;
    let defaultType = 0;
    if (isDefault) {
      defaultType = 1; 
    }
    let reqUrl = `${baseUrl}/address?appTab=2&appkey=${appkey}&userId=${userId}&name=${name}&phoneNum=${phone}&province=${province}&city=${city}&county=${county}&detail=${addressDesc}&isDefault=${defaultType}&postcode=`;
    let method = "PUT";
    if(addressId != -1){
      reqUrl = `${reqUrl}&id=${addressId}`;
      method = "POST";
    }
    let result = {
      url:reqUrl,
      method
    }
    let response = await getDataFromServer(result);
    if (response.data.result === 0) {
    	this.getAllAdress();
    }
  }

  //获取所有的收货地址
  async getAllAdress(){
  	let loginInfo = {
      url: `${baseUrl}/address?userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}&appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
    	let addressObj = data.data.addressList.find((item) => item.isDefault === 1);
    	let addNew = false;
    	if (!addressObj) {
				addNew = true;
    	}
			this.setState({
				addressList: data.data.addressList,
				addressObj,
				addNew
    	})
    }
  }
  
  //获取购物车商品
  async getCartGoods(){
  	let loginInfo = {
      url: `${baseUrl}/order/carttariffWebB2c?cartIds=${this.props.location.state}&appkey=${appkey}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
    	this.setState({
    		orderShoppingCarts: data.data.shoppingCartTariffs,
    		count: data.data.count
    	})
    }else{
			this.props.history.push("/home/buycart");
    }
  }

  //选项卡点击
  tabClick = (actType) => {
		this.setState({
			actType
		})
  }

  //选择地址
  selAddress = (item) => {
  	this.setState({
  		addressObj: item
  	})
  }

  //获取可用优惠列表
  async getCouponList(){
  	let {useRebateCard,useCoupon,useRebate,rebateCardId,selectCouponId,tabList} = this.state;
  	let loginInfo = {
      url: `${baseUrl}/v1.8/rebate/default?cartids=${this.props.location.state}&appkey=${appkey}&useRebateCard=${useRebateCard}&useCoupon=${useCoupon}&useRebate=${useRebate}&rebateCardId=${rebateCardId}&userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
    	if (data.data.data.goodsInActivity == 1) {

			} else {
				// 优惠卡
				if (data.data.data.usableRebateCardCount === 0) {
					tabList[2].normalNum = "";
				} else {
					if (useRebateCard == 1) {
						rebateCardId = data.data.data.defaultRebateCardId;
						tabList[2].normalNum = " -" + data.data.data.savePercent	+ "商品总价";
					} else {
						rebateCardId = "";
						tabList[2].normalNum = "";
					}
				}
			}

			// 优惠券
			if (data.data.data.usableCouponCount === 0) {
				selectCouponId = "";
				tabList[0].normalNum = "";
			} else {
				let money = 0;
				// 选出最优优惠券
				for (let i = 0; i < data.data.data.couponList.length; i++) {
					if (data.data.data.couponList[i].money * 1 > money) {
						money = data.data.data.couponList[i].money;
						selectCouponId = data.data.data.couponList[i].id;
					}
				}
      	tabList[0].normalNum = " -￥" + money;
			}
    	this.setState({
    		couponList: data.data.data.couponList,
    		usableRebateValue: data.data.data.usableRebateValue,
    		rebateCardList: data.data.data.rebateCardList,
    		rebateCardId,
    		selectCouponId,
    		tabList
    	},() => {
    		this.getMoney();
    	})
    }
  }

  //余额选择
  selLackMoney = () => {
		let {useRebate,tabList,usableRebateValue} = this.state;
		useRebate = useRebate === 1 ? 0 : 1;
		tabList[1].normalNum = useRebate ? " -￥" + usableRebateValue : "";
		this.setState({
			useRebate,
			tabList
		},() => {
			this.getCouponList();
		})
  }

  //优惠券选择
  selCoupon = (item) => {
  	let {tabList,selectCouponId} = this.state;
		selectCouponId = selectCouponId === item.id ? "" : item.id;
		tabList[0].normalNum = selectCouponId ? " -￥" + item.money : "";
		this.setState({
			selectCouponId,
			tabList
		},() => {
  		this.getMoney();
  	})
  }

  //折扣卡选择
  selRebateCardId = (item) => {
  	let {tabList,rebateCardId,useRebateCard} = this.state;
  	if (rebateCardId === item.id) {
  		rebateCardId = "";
  		useRebateCard = 0;
  	}else{
  		rebateCardId = item.id;
  		useRebateCard = 1;
  	}
		tabList[2].normalNum = rebateCardId ? " -" + item.savePercent * 100	+ "%商品总价" : "";
		this.setState({
			rebateCardId,
			useRebateCard,
			tabList
		},() => {
			this.getCouponList();
		})
  }

  //获取结算金额
  async getMoney(){
		let {useCoupon,useRebate,rebateCardId,selectCouponId,tabList,usableRebateValue} = this.state;
  	let loginInfo = {
      url: `${baseUrl}/order/settlement?cartIds=${this.props.location.state}&storeType=3&appkey=${appkey}&rebateCardId=${rebateCardId}&userCouponId=${selectCouponId}&rebateValue=${useRebate === 1 ? usableRebateValue : 0}&userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}`,
      method: 'GET'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
    	this.setState({
    		priceData: data.data
    	})
    }
  }

  //提交订单
  async submitOrder(){
  	if (this.props.loginReducer.userLoginInfo) {
			let {useCoupon,useRebate,rebateCardId,selectCouponId,tabList,usableRebateValue,addressObj,orderShoppingCarts} = this.state;
			if (!addressObj) {
				message.error(<span className="msgText">请填写收货地址信息</span>);
				return;
			}
			let isOverMax = false;
			for (var i = 0; i < orderShoppingCarts.length; i++) {
				if (orderShoppingCarts[i].invalidFlag === 1 && orderShoppingCarts[i].shoppingCartModel.length) {
					isOverMax = true;
					break;
				}
			}
			if (isOverMax) {
				message.error(<span className="msgText">超过海关限额，请返回分次结算</span>);
				return;
			}
			//获取默认实名认证
			let realname = {
	      url: `${baseUrl}/user/auth/default?appkey=${appkey}&userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}`,
	      method: 'GET'
	    }
	    let realnameData = await getDataFromServer(realname);
	    if (realnameData.data.result === 0) {
	    	if (realnameData.data.userIDcard) {
		    	if (realnameData.data.userIDcard.realname === addressObj.name) {
						//提交订单
				  	let loginInfo = {
				      url: `${baseUrl}/order/add?cartids=${this.props.location.state}&storeType=2&appkey=${appkey}&rebateCardId=${rebateCardId}&addressId=${addressObj.id}&remarks=&userCouponId=${selectCouponId}&rebateValue=${useRebate === 1 ? usableRebateValue : 0}&userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}`,
				      method: 'PUT'
				    }
				    let data = await getDataFromServer(loginInfo);
				    if (data.data.result === 0) {
				    	this.props.history.push("/home/paycenter/" + data.data.order.id);
				    	let loginInfoOrder = {
					      url: `${baseUrl}/orderanalysis/insert?orderId=${data.data.order.id}&model=web&oa=2&appkey=${appkey}&channel=web&versionNum=web`,
					      method: 'PUT'
				    	}
							let dataOrder = await getDataFromServer(loginInfoOrder);
							
							//判断cookies中是否有feedback(有就代表从值得买过来)
							let feedback = Cookies.get("feedback");
							if(feedback){
								let recordUrl = `${baseUrl}/worthbuy/recordorder?appkey=${appkey}&orderId=${data.data.order.id}&feedback=${feedback}`;
								//记录值得买订单
								let recordOrder = {
									url:recordUrl,
									method: 'POST'
								}
								let recordData = await getDataFromServer(recordOrder);
							}
							
				    }else if (data.data.result == 1) {
				    	message.error(<span className="msgText">对不起，请勿重复创建订单</span>);
						}else if (data.data.result == 2) {
				    	message.error(<span className="msgText">对不起，您还未进行实名认证</span>);
						} else if (data.data.result == 3) {
				    	message.error(<span className="msgText">对不起，您选择的地址不存在</span>);
						} else if (data.data.result == 4) {
				    	message.error(<span className="msgText">对不起，您选择商品库存不足</span>);
						} else if (data.data.result == 5) {
				    	message.error(<span className="msgText">对不起，您选择商品抢光了</span>);
						} else if (data.data.result == 6) {
				    	message.error(<span className="msgText">您选择的收货人信息今日已提交过直邮订单</span>);
						}else{
							message.error(<span className="msgText">{data.data.msg}</span>);
						}
		    	}else{
						Modal.error({
					    title: '默认实名认证和收货人姓名不一致',
					    content: '请修改收件人姓名或更改默认实名认证',
					    okText: "确定"
					  });
		    	}
		    }else{
					this.setState({
						showRealname: true
					})
		    }
	    }
		}else{
			this.props.toggleLogin();
		}
  }

  //实名认证输入框
  inputChange = (type,e) => {
		this.setState({
			[type]: e.target.value,
			showRealnameTip: false
		})
  }

  //提交实名认证
  async submitRealname(){
		let {buyName,buyIDCard} = this.state;
		let loginInfo = {
      url: `${baseUrl}/user/auth?appkey=${appkey}&realname=${buyName}&img2=&img1=&idcard=${buyIDCard}&userId=${this.props.loginReducer.userLoginInfo ? this.props.loginReducer.userLoginInfo.id : ""}`,
      method: 'PUT'
    }
    let data = await getDataFromServer(loginInfo);
    if (data.data.result === 0) {
			this.setState({
				showRealname: false
			})		
    }else{
			this.setState({
				showRealnameTip: true
			})
    }
  }

  //取消实名认证
	cancleRealname = () => {
		this.setState({
			showRealname: false
		})
	}

  render() {
  	let show = {};
  	let hide = {display:"none"};
  	let {addressList,addressObj,orderShoppingCarts,couponList,usableRebateValue,rebateCardList,useCoupon,useRebate,rebateCardId,selectCouponId,priceData,count,buyName,buyIDCard,showRealname,showRealnameTip,addressId,editItem} = this.state;
  	let goodsPrice,rebateValue,savePrice,rebateCardPrice,activitySavePrice,overseaPrice,taxPrice,freight,actualPrice = false;
  	if (priceData) {
  		goodsPrice = priceData.goodsPrice;
			rebateValue = priceData.rebateValue;
			savePrice = priceData.savePrice;
			rebateCardPrice = priceData.rebateCardPrice;
			activitySavePrice = priceData.activitySavePrice;
			overseaPrice = priceData.overseaPrice;
			taxPrice = priceData.taxPrice;
			freight = priceData.freight;
			actualPrice = priceData.actualPrice;
  	}
    return (
      <div className="SubmitOrder">
      	{
          this.state.addNew && <AddNewAddressPopView addressId={addressId} editAddress={editItem} hiddenPopView={this.hiddenPopView}/>
        }
        {
          this.state.showDelete && <DeleteAddressPopView hiddenDeleteView={this.hiddenDeleteView}/>
        }
        {
					showRealname &&
        	<div className="realName">
						<div className="realNameBox">
							<div className="realnameTitle">实名认证<img src={close} alt=""/></div>
							<div className="inputBox">
								<div className="inputTitle">*收件人姓名：</div>
								<input type="text" value={buyName} onChange={this.inputChange.bind(this,"buyName")} placeholder="请输入收件人姓名"/>
							</div>
							<div className="inputBox">
								<div className="inputTitle">*收件人身份证号：</div>
								<input type="text" maxLength="18" value={buyIDCard} onChange={this.inputChange.bind(this,"buyIDCard")} placeholder="请输入收件人身份证号"/>
							</div>
							{showRealnameTip && <div className="tip">*认证失败，请检查姓名和身份证号</div>}
							<div className="haiguanTip">温馨提示：根据海关规定，购买跨境商品需要办理清关手续，请您配合进行实名认证，以确保您购买的商品顺利通过海关检查。身份证明只用于办理跨境商品的清关手续，不作他途使用，其他任何人均无法查看。</div>
							<div className="sure btn" onClick={this.submitRealname}>确认</div>
							<div className="cancle btn" onClick={this.cancleRealname}>取消</div>
						</div>
	        </div>
        }
				<div className="inner">
					<div className="orderTitle">核对订单信息</div>
					<div className="orderInfo">
						<div className="itemTitle">
							收货人信息
							<div className="addNewAdress" onClick={this.addNewAction}>+新增收货地址</div>
						</div>
						<div className="addressBox">
							{
								addressList.map((item,i) => {
									return (
										<div key={i} onClick={this.selAddress.bind(this,item)} className={addressObj.id === item.id ? "addressItem active" : "addressItem"}>
											<div className="nameBox">
												<div className="name">{item.name}</div>
												<div className="addressDefault">{item.isDefault === 1 ? "默认地址" : ""}</div>
											</div>
											<div className="phone">{item.phone}</div>
											<div className="address">{item.province}{item.city}{item.county}{item.detail}</div>
											<div className="addressBtn">
												<div className="btn" onClick={this.edit.bind(this,item)}>编辑</div>
												<div className="btn" onClick={this.showDeleteView.bind(this,i)}>删除</div>
											</div>
											{addressObj.id === item.id && <img src={xuanzhong} className="addressSel" alt=""/>}
										</div>
									)
								})
							}
						</div>
						<div className="itemTitle">确认商品信息</div>
						<div className="tableTitleBox">
							<div className="tableTitleItem title">商品信息</div>
							<div className="tableTitleItem num">数量</div>
							<div className="tableTitleItem price">售价</div>
						</div>
						<div className="goodsTable">
							{
								orderShoppingCarts.map((item,i) => {
									return (
										<div className="cangkuItem" key={i}>
											{
												item.shoppingCartModel.length > 0 &&
												<div className={item.invalidFlag ? "cangkuName error" : "cangkuName"}>{item.invalidFlag ? "抱歉，以下商品合计超过海关限额" + item.maxPrice + "元，请返回分次结算" : item.warehouseName}</div>
											}
											{
												item.shoppingCartModel.map((proItem,j) => {
													return (
														<div className="cangkuProItem" key={j}>
															<img className="proImg" src={proItem.imgpath} alt=""/>
															<div className="titleBox">
																<div className="title">{proItem.title}</div>
																{proItem.goodsInActivity === 1 && <div className="activity">活动</div>}
															</div>
															<div className="num">X{proItem.quantity}</div>
															<div className="price">￥{proItem.price}</div>
														</div>
													)
												})
											}
										</div>
									)
								})
							}
						</div>
						<div className="itemTitle">使用优惠券/余额/折扣卡</div>
						<div className="saveBox">
							<div className="tabBox">
								<CommonTab tabList={this.state.tabList} actType={this.state.actType} tabChange={this.tabClick}/>
								<div className="clearfix"></div>
							</div>
							<div className="saveCoupon" style={this.state.actType === "coupon" ? show : hide}>
								{
									couponList.map((item,i) => {
										let typeText = "";
										let bg_img = "";
										switch (item.type) {
								      case 0:
									      //全场通用
									      bg_img = quanchang;
									      typeText = "全场通用";
								        break;
								      case 1:
								        //品牌专用
									      bg_img = pinpai;
									      typeText = "品牌专用";
								        break;
								      case 2:
								        //分类专用
									      bg_img = fenlei;
									      typeText = "分类专用";
								        break;
								      case 3:
								        //单品专用
									      bg_img = danpin;
									      typeText = "单品专用";
								        break;
								    }
										return (
											<div className="couponItem" key={i} onClick={this.selCoupon.bind(this,item)}>
												<div className="priceBox">
													<div className="simbol">￥</div>
													<div className="money">{item.money}</div>
													<div className="condition">{item.condition <= 0 ? "无门槛使用" : `满${item.condition}元可用`}</div>
												</div>
												<div className="time">有效期至：{item.invalidTime}</div>
												<div className="range">使用范围：{typeText}</div>
												<img src={bg_img} className="bg_img" alt=""/>
												{selectCouponId === item.id && <img src={xuanzhong} className="addressSel" alt=""/>}
											</div>
										)
									})
								}
								{couponList.length <= 0 && <div className="none">暂无可用优惠券~~</div>}
							</div>
							<div className="saveLackMoney" style={this.state.actType === "lackMoney" ? show : hide}>
								当前可用余额共￥{this.state.usableRebateValue}<div className={useRebate === 1 ? "useLackMoney active disableSel" : "useLackMoney disableSel"} onClick={this.selLackMoney}>{useRebate === 1 ? "" : "未"}使用余额抵扣{useRebate === 1 && <img className="selLackMoney" src={hasSel} alt=""/>}</div>
							</div>
							<div className="saveDiscount" style={this.state.actType === "discountCard" ? show : hide}>
								{
									rebateCardList.map((item,i) => {
										return (
											<div className="discountCardItem" key={i} onClick={this.selRebateCardId.bind(this,item)}>
												<div className="discountPrice">
													减<div className="discountRate">{item.savePercent * 100}</div>%
												</div>
												<div className="discountTime">有效期至：{item.validTime.split(" ")[0]}</div>
												<div className="discountOrigin">来自{item.phoneNumber}的分享</div>
												{rebateCardId === item.id && <img src={xuanzhong} className="addressSel" alt=""/>}
											</div>
										)
									})
								}
								{rebateCardList.length <= 0 && <div className="none">暂无可用折扣卡~~</div>}
							</div>
						</div>
						<div className="jiesuanBox">
							{goodsPrice != 0 && <div className="jiesuanItem"><div className="priceTitle">{count}件商品，商品总金额：</div><div className="price">￥{goodsPrice}</div></div>}
							{rebateValue != 0 && <div className="jiesuanItem"><div className="priceTitle">余额：</div><div className="price"> - ￥{rebateValue}</div></div>}
							{savePrice != 0 && <div className="jiesuanItem"><div className="priceTitle">优惠券：</div><div className="price"> - ￥{savePrice}</div></div>}
							{rebateCardPrice != 0 && <div className="jiesuanItem"><div className="priceTitle">折扣卡：</div><div className="price"> - ￥{rebateCardPrice}</div></div>}
							{activitySavePrice != 0 && <div className="jiesuanItem"><div className="priceTitle">活动促销：</div><div className="price">- ￥{activitySavePrice}</div></div>}
							{overseaPrice != 0 && <div className="jiesuanItem"><div className="priceTitle">支付宝手续费：<Tooltip placement="topLeft" title={`根据支付宝相关规定，每笔订单收取定金的${this.props.homeReducer.systemData ? this.props.homeReducer.systemData.overseaCost * 100 : ""}%的手续费，如有疑问请联系客服`} arrowPointAtCenter="true">
                <span className="icon">?</span>
              </Tooltip></div><div className="price">￥{overseaPrice}</div></div>}
							{taxPrice != 0 && <div className="jiesuanItem"><div className="priceTitle">税费：<Tooltip placement="topLeft" title="根据国家政策规定，跨境订单税费以商品实际交易价格（包括商品售价、运费）计算" arrowPointAtCenter="true">
                <span className="icon">?</span>
              </Tooltip></div><div className="price">￥{taxPrice}</div></div>}
							{freight != 0 && <div className="jiesuanItem"><div className="priceTitle">运费：<Tooltip placement="topLeft" title={this.props.homeReducer.systemData ? this.props.homeReducer.systemData.postagedes : ""} arrowPointAtCenter="true">
                <span className="icon">?</span>
              </Tooltip></div><div className="price">￥{freight}</div></div>}
							<div className="heji jiesuanItem"><div className="priceTitle">应付合计：</div><div className="price">￥{actualPrice}</div></div>
							<div className="submit" onClick={this.submitOrder}>提交订单</div>
							<div className="adressOrder">{addressObj ? addressObj.name : ""}<div className="orderPhone">{addressObj ? addressObj.phone : ""}</div></div>
							<div className="adressOrder">{addressObj ? addressObj.province : ""}{addressObj ? addressObj.city : ""}{addressObj ? addressObj.county : ""}{addressObj ? addressObj.detail : ""}</div>
						</div>
					</div>
				</div>
      </div>
    );
  }
}

export default SubmitOrder;

